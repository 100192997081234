import { Pipe, PipeTransform } from '@angular/core';
import { Observable, Subject, of, takeUntil } from 'rxjs';
import { AccountsService } from '@api/accounts.service';
import { BaseObject } from '@shared/base/base-object';
import { PortfolioCreate_AccountFieldNames } from '@shared/constants/search-constants';
import { CompanyDTO } from '@shared/dto/accounts/models';
import { SearchRequestDTO } from '@shared/dto/gateway-secured/models';
import { UiAccountDTO } from '../../dialogs/portfolio-create/dialogs/portfolio-create/portfolio-create.types';

export interface GetAccountsResult {
  loading: boolean;
  result?: UiAccountDTO[];
}

@Pipe({
  name: 'getAccounts',
})
export class GetAccountsPipe extends BaseObject implements PipeTransform {
  private stopLoadingEvent$ = new Subject<void>();

  constructor(private accountsService: AccountsService) {
    super();
  }

  public transform(companyId: string): Observable<GetAccountsResult> {
    this.stopLoadingEvent$.next();

    if (!companyId) {
      return of<GetAccountsResult>({ loading: false, result: [] });
    }

    return new Observable<GetAccountsResult>((subscriber) => {
      subscriber.next({ loading: true });

      const request: SearchRequestDTO = {
        fieldNames: PortfolioCreate_AccountFieldNames,
        filters: {
          operator: 'AND',
          elements: [
            {
              operator: 'AND',
              elements: [
                {
                  fieldName: 'accountCompanies.company.id',
                  operator: 'equals',
                  values: [companyId],
                },
              ],
            },
          ],
        },
      };

      this.accountsService
        .getAccountsWithPagination<UiAccountDTO>(request)
        .pipe(takeUntil(this.stopLoadingEvent$), takeUntil(this.destroy$))
        .subscribe((data) => {
          subscriber.next({ loading: false, result: data.rows });
          subscriber.complete();
        });
    });
  }
}
