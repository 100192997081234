/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ModuleAccessDTO {
  assignAuthority?: ModuleAccessDTO.AssignAuthorityEnum;
  createAuthority?: ModuleAccessDTO.CreateAuthorityEnum;
  deleteAuthority?: ModuleAccessDTO.DeleteAuthorityEnum;
  forceModifyAuthority?: ModuleAccessDTO.ForceModifyAuthorityEnum;
  groupName?: string;
  modifyAllAuthority?: ModuleAccessDTO.ModifyAllAuthorityEnum;
  modifyAuthority?: ModuleAccessDTO.ModifyAuthorityEnum;
  name?: string;
  readAuthority?: ModuleAccessDTO.ReadAuthorityEnum;
  statusAuthority?: ModuleAccessDTO.StatusAuthorityEnum;
  viewAllAuthority?: ModuleAccessDTO.ViewAllAuthorityEnum;
}
export namespace ModuleAccessDTO {
  export type AssignAuthorityEnum =
    | 'ACCOUNTS_CREATE'
    | 'ACCOUNTS_DELETE'
    | 'ACCOUNTS_FORCE_MODIFY'
    | 'ACCOUNTS_MODIFY'
    | 'ACCOUNTS_MODIFY_ALL'
    | 'ACCOUNTS_VIEW'
    | 'ACCOUNTS_VIEW_ALL'
    | 'ACCOUNT_STRUCTURE'
    | 'ACTUATOR_VIEW'
    | 'ADVANCED_ANALYTICS_VIEW'
    | 'ALFA_DATA_MODIFY'
    | 'ALFA_DATA_VIEW'
    | 'AMAZON_WORK_DOCS'
    | 'BANK_ACCOUNTS_CREATE'
    | 'BANK_ACCOUNTS_DELETE'
    | 'BANK_ACCOUNTS_FORCE_MODIFY'
    | 'BANK_ACCOUNTS_MODIFY'
    | 'BANK_ACCOUNTS_MODIFY_ALL'
    | 'BANK_ACCOUNTS_VIEW'
    | 'BANK_ACCOUNTS_VIEW_ALL'
    | 'BANK_STATS_VIEW'
    | 'BKS_DATA_MODIFY'
    | 'BKS_DATA_VIEW'
    | 'BKS_LOADER_SETTINGS_CREATE'
    | 'BKS_LOADER_SETTINGS_DELETE'
    | 'BKS_LOADER_SETTINGS_MODIFY'
    | 'BKS_LOADER_SETTINGS_VIEW'
    | 'BLOOMBERG_REQUESTS_VIEW'
    | 'BROKER_CREATE'
    | 'BROKER_DELETE'
    | 'BROKER_MODIFY'
    | 'BROKER_VIEW'
    | 'BUSINESS_EVENTS_VIEW'
    | 'CASS_REPORT'
    | 'CBH_BS_DATA_MODIFY'
    | 'CBH_BS_DATA_VIEW'
    | 'CBH_DATA_MODIFY'
    | 'CBH_DATA_VIEW'
    | 'CBH_LOADER_SETTINGS_CREATE'
    | 'CBH_LOADER_SETTINGS_DELETE'
    | 'CBH_LOADER_SETTINGS_MODIFY'
    | 'CBH_LOADER_SETTINGS_VIEW'
    | 'CHANGE_REQUEST_SETTINGS_CREATE'
    | 'CHANGE_REQUEST_SETTINGS_DELETE'
    | 'CHANGE_REQUEST_SETTINGS_MODIFY'
    | 'CHANGE_REQUEST_SETTINGS_VIEW'
    | 'CLIENTS_CREATE'
    | 'CLIENTS_DELETE'
    | 'CLIENTS_MODIFY'
    | 'CLIENTS_MODIFY_ALL'
    | 'CLIENTS_VIEW'
    | 'CLIENTS_VIEW_ALL'
    | 'CLIENT_COUNT_REPORT'
    | 'CLIENT_FEES_REPORT'
    | 'CLIENT_VALUATION_REPORT'
    | 'CMAR_REPORT'
    | 'COMMON_EMAIL_TEMPLATES_CREATE'
    | 'COMMON_EMAIL_TEMPLATES_DELETE'
    | 'COMMON_EMAIL_TEMPLATES_MODIFY'
    | 'COMMON_EMAIL_TEMPLATES_VIEW'
    | 'COMPANIES_CREATE'
    | 'COMPANIES_DELETE'
    | 'COMPANIES_MODIFY'
    | 'COMPANIES_TAGS_VIEW'
    | 'COMPANIES_VIEW'
    | 'COMPANY_HOLDING_CREATE'
    | 'COMPANY_HOLDING_DELETE'
    | 'COMPANY_HOLDING_MODIFY'
    | 'COMPANY_HOLDING_VIEW'
    | 'COMPANY_RISK_REPORT'
    | 'COMPLIANCE_ACCOUNT_COMPANY_CREATE'
    | 'COMPLIANCE_ACCOUNT_COMPANY_DELETE'
    | 'COMPLIANCE_ACCOUNT_COMPANY_FORCE_MODIFY'
    | 'COMPLIANCE_ACCOUNT_COMPANY_MODIFY'
    | 'COMPLIANCE_ACCOUNT_COMPANY_VIEW'
    | 'COUNTERPARTY_CREATE'
    | 'COUNTERPARTY_DELETE'
    | 'COUNTERPARTY_MODIFY'
    | 'COUNTERPARTY_VIEW'
    | 'COUPON_PROJECTION_REPORT'
    | 'CRS_REPORT'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_CREATE'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_MODIFY'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_VIEW'
    | 'CUSTODIAN_CREATE'
    | 'CUSTODIAN_DELETE'
    | 'CUSTODIAN_MODIFY'
    | 'CUSTODIAN_VIEW'
    | 'DASHBOARD_MODIFY'
    | 'DASHBOARD_VIEW'
    | 'DBS_DATA_MODIFY'
    | 'DBS_DATA_VIEW'
    | 'DEACTIVATED_TRANSACTION_VIEW'
    | 'DEPARTMENTS_CREATE'
    | 'DEPARTMENTS_DELETE'
    | 'DEPARTMENTS_MODIFY'
    | 'DEPARTMENTS_VIEW'
    | 'DOLFIN_TRANSACTIONS_MODIFY'
    | 'DOLFIN_TRANSACTIONS_VIEW'
    | 'EDR_DATA_MODIFY'
    | 'EDR_DATA_VIEW'
    | 'EDR_LOADER_SETTINGS_CREATE'
    | 'EDR_LOADER_SETTINGS_DELETE'
    | 'EDR_LOADER_SETTINGS_MODIFY'
    | 'EDR_LOADER_SETTINGS_VIEW'
    | 'EMAIL_TEMPLATE_CREATE'
    | 'EMAIL_TEMPLATE_DELETE'
    | 'EMAIL_TEMPLATE_MODIFY'
    | 'EMAIL_TEMPLATE_VIEW'
    | 'EXECGW_REPORTS_VIEW'
    | 'FEES_CREATE'
    | 'FEES_MODIFY'
    | 'FEES_VIEW'
    | 'FEES_VIEW_ALL'
    | 'FIELDS_CREATE'
    | 'FIELDS_DELETE'
    | 'FIELDS_MODIFY'
    | 'FIELDS_VIEW'
    | 'FILE_USER_LOG_VIEW'
    | 'GAP_MONITOR_VIEW'
    | 'GLOBAL_ALLOCATION_CREATE'
    | 'GLOBAL_ALLOCATION_MODIFY'
    | 'GLOBAL_ALLOCATION_VIEW'
    | 'GLOBAL_ALLOCATION_WIDGET_VIEW'
    | 'GOOGLE_AUTH_MODIFY'
    | 'GOOGLE_AUTH_VIEW'
    | 'GPP_DATA_MODIFY'
    | 'GPP_DATA_VIEW'
    | 'GPP_LOADER_SETTINGS_CREATE'
    | 'GPP_LOADER_SETTINGS_DELETE'
    | 'GPP_LOADER_SETTINGS_MODIFY'
    | 'GPP_LOADER_SETTINGS_VIEW'
    | 'HEALTH_VIEW'
    | 'HISTORY_VIEW'
    | 'IBKR_DATA_MODIFY'
    | 'IBKR_DATA_VIEW'
    | 'IBKR_FUTURES_MAPPING_CREATE'
    | 'IBKR_FUTURES_MAPPING_DELETE'
    | 'IBKR_FUTURES_MAPPING_MODIFY'
    | 'IBKR_FUTURES_MAPPING_VIEW'
    | 'IBKR_LOADER_SETTINGS_CREATE'
    | 'IBKR_LOADER_SETTINGS_DELETE'
    | 'IBKR_LOADER_SETTINGS_MODIFY'
    | 'IBKR_LOADER_SETTINGS_VIEW'
    | 'INCOME_PAYMENT_TRACKER'
    | 'INSTRUMENTS_CREATE'
    | 'INSTRUMENTS_MODIFY'
    | 'INSTRUMENTS_VIEW'
    | 'INSTRUMENT_TO_AGENT_VIEW'
    | 'INVESTEC_DATA_MODIFY'
    | 'INVESTEC_DATA_VIEW'
    | 'INVESTEC_LOADER_SETTINGS_CREATE'
    | 'INVESTEC_LOADER_SETTINGS_DELETE'
    | 'INVESTEC_LOADER_SETTINGS_MODIFY'
    | 'INVESTEC_LOADER_SETTINGS_VIEW'
    | 'INVESTMENT_VIEW'
    | 'IP_ACCESS'
    | 'JB_DATA_MODIFY'
    | 'JB_DATA_VIEW'
    | 'JB_LOADER_SETTINGS_CREATE'
    | 'JB_LOADER_SETTINGS_DELETE'
    | 'JB_LOADER_SETTINGS_MODIFY'
    | 'JB_LOADER_SETTINGS_VIEW'
    | 'JPM_DATA_MODIFY'
    | 'JPM_DATA_VIEW'
    | 'JPM_LOADER_SETTINGS_CREATE'
    | 'JPM_LOADER_SETTINGS_DELETE'
    | 'JPM_LOADER_SETTINGS_MODIFY'
    | 'JPM_LOADER_SETTINGS_VIEW'
    | 'LEAD_CREATE'
    | 'LEAD_DELETE'
    | 'LEAD_MODIFY'
    | 'LEAD_MODIFY_ALL'
    | 'LEAD_VIEW'
    | 'LEAD_VIEW_ALL'
    | 'LOGOTYPE_DELETE'
    | 'LOGOTYPE_MODIFY'
    | 'LOGOTYPE_VIEW'
    | 'MANAGERS_CREATE'
    | 'MANAGERS_DELETE'
    | 'MANAGERS_FORCE_MODIFY'
    | 'MANAGERS_MODIFY'
    | 'MANAGERS_VIEW'
    | 'MANDATES_CREATE'
    | 'MANDATES_DELETE'
    | 'MANDATES_FORCE_MODIFY'
    | 'MANDATES_MODIFY'
    | 'MANDATES_MODIFY_ALL'
    | 'MANDATES_VIEW'
    | 'MANDATES_VIEW_ALL'
    | 'MANDATE_RESTRICTIONS_CREATE'
    | 'MANDATE_RESTRICTIONS_DELETE'
    | 'MANDATE_RESTRICTIONS_MODIFY'
    | 'MANDATE_RESTRICTIONS_MODIFY_ALL'
    | 'MANDATE_RESTRICTIONS_VIEW'
    | 'MANDATE_RESTRICTIONS_VIEW_ALL'
    | 'MANDATE_RESTRICTION_REPORTS_CREATE'
    | 'MANDATE_RESTRICTION_REPORTS_DELETE'
    | 'MANDATE_RESTRICTION_REPORTS_MODIFY'
    | 'MANDATE_RESTRICTION_REPORTS_MODIFY_ALL'
    | 'MANDATE_RESTRICTION_REPORTS_VIEW'
    | 'MANDATE_RESTRICTION_REPORTS_VIEW_ALL'
    | 'MANDATE_RESTRICTION_TEMPLATES_CREATE'
    | 'MANDATE_RESTRICTION_TEMPLATES_DELETE'
    | 'MANDATE_RESTRICTION_TEMPLATES_MODIFY'
    | 'MANDATE_RESTRICTION_TEMPLATES_MODIFY_ALL'
    | 'MANDATE_RESTRICTION_TEMPLATES_VIEW'
    | 'MANDATE_RESTRICTION_TEMPLATES_VIEW_ALL'
    | 'MANDATE_RISK_PROFILE_CREATE'
    | 'MANDATE_RISK_PROFILE_DELETE'
    | 'MANDATE_RISK_PROFILE_FORCE_MODIFY'
    | 'MANDATE_RISK_PROFILE_MODIFY'
    | 'MANDATE_RISK_PROFILE_VIEW'
    | 'MANDATE_RISK_PROFILE_VIEW_ALL'
    | 'MANUAL_IMPORT_POSITIONS_CREATE'
    | 'MANUAL_IMPORT_POSITIONS_MODIFY'
    | 'MANUAL_IMPORT_POSITIONS_VIEW'
    | 'MANUAL_IMPORT_TRANSACTIONS_CREATE'
    | 'MANUAL_IMPORT_TRANSACTIONS_MODIFY'
    | 'MANUAL_IMPORT_TRANSACTIONS_VIEW'
    | 'MARKETPLACE_MODIFY'
    | 'MARKETPLACE_VIEW'
    | 'MARKET_SECURITIES_MODIFY'
    | 'MARKET_SECURITIES_VIEW'
    | 'MIFIR_MODIFY'
    | 'MIFIR_REPORT'
    | 'MIFIR_VIEW'
    | 'MODEL_PORTFOLIOS_CREATE'
    | 'MODEL_PORTFOLIOS_DELETE'
    | 'MODEL_PORTFOLIOS_MODIFY'
    | 'MODEL_PORTFOLIOS_VIEW'
    | 'MONTHLY_RECONCILIATION_REPORT'
    | 'NOTIFICATION_TEMPLATES_CREATE'
    | 'NOTIFICATION_TEMPLATES_DELETE'
    | 'NOTIFICATION_TEMPLATES_MODIFY'
    | 'NOTIFICATION_TEMPLATES_VIEW'
    | 'NOTIFICATION_USER_LOG_VIEW'
    | 'OMNIBUS'
    | 'ONLINE_PRICING_VIEW'
    | 'OPPORTUNITY_CREATE'
    | 'OPPORTUNITY_DELETE'
    | 'OPPORTUNITY_FORCE_MODIFY'
    | 'OPPORTUNITY_MODIFY'
    | 'OPPORTUNITY_MODIFY_ALL'
    | 'OPPORTUNITY_VIEW'
    | 'OPPORTUNITY_VIEW_ALL'
    | 'ORDERS_ASSIGN'
    | 'ORDERS_COMMON_CREATE'
    | 'ORDERS_COMMON_DELETE'
    | 'ORDERS_COMMON_MODIFY'
    | 'ORDERS_COMMON_VIEW'
    | 'ORDERS_CORP_ACTION_CREATE'
    | 'ORDERS_CORP_ACTION_DELETE'
    | 'ORDERS_CORP_ACTION_MODIFY'
    | 'ORDERS_CORP_ACTION_VIEW'
    | 'ORDERS_CREATE'
    | 'ORDERS_DELETE'
    | 'ORDERS_DMA_CREATE'
    | 'ORDERS_FORCE_MODIFY'
    | 'ORDERS_MODIFY'
    | 'ORDERS_MODIFY_ALL'
    | 'ORDERS_NON_TRADE_CREATE'
    | 'ORDERS_NON_TRADE_DELETE'
    | 'ORDERS_NON_TRADE_MODIFY'
    | 'ORDERS_NON_TRADE_VIEW'
    | 'ORDERS_TECH_INFO_MODIFY'
    | 'ORDERS_TECH_INFO_VIEW'
    | 'ORDERS_TRADE_CREATE'
    | 'ORDERS_TRADE_DELETE'
    | 'ORDERS_TRADE_MODIFY'
    | 'ORDERS_TRADE_VIEW'
    | 'ORDERS_VIEW'
    | 'ORDERS_VIEW_ALL'
    | 'PERSONAL_AM'
    | 'PNL_REPORT'
    | 'PORTFOLIOS_CREATE'
    | 'PORTFOLIOS_DELETE'
    | 'PORTFOLIOS_FORCE_MODIFY'
    | 'PORTFOLIOS_MODIFY'
    | 'PORTFOLIOS_MODIFY_ALL'
    | 'PORTFOLIOS_VIEW'
    | 'PORTFOLIOS_VIEW_ALL'
    | 'PORTFOLIO_CONFIGURATION_VIEW'
    | 'PORTFOLIO_COSTS_VIEW'
    | 'PORTFOLIO_FEES_VIEW'
    | 'PORTFOLIO_FLOWS_VIEW'
    | 'PORTFOLIO_ORDERS_ASSIGN'
    | 'PORTFOLIO_ORDERS_CREATE'
    | 'PORTFOLIO_ORDERS_DELETE'
    | 'PORTFOLIO_ORDERS_MODIFY'
    | 'PORTFOLIO_ORDERS_VIEW'
    | 'PORTFOLIO_PNL_VIEW'
    | 'PORTFOLIO_POSITIONS_VIEW'
    | 'PORTFOLIO_PROFILE_MODIFY'
    | 'PORTFOLIO_PROFILE_VIEW'
    | 'PORTFOLIO_RESTRICTIONS_VIEW'
    | 'PORTFOLIO_TRANSACTIONS_CREATE'
    | 'PORTFOLIO_TRANSACTIONS_DELETE'
    | 'PORTFOLIO_TRANSACTIONS_MODIFY'
    | 'PORTFOLIO_TRANSACTIONS_STATUS'
    | 'PORTFOLIO_TRANSACTIONS_VIEW'
    | 'PORTFOLIO_VALUATION_VIEW'
    | 'POSITIONS_VIEW'
    | 'PRICES_LOADING_MONITOR_VIEW'
    | 'PRIVATE_INSTRUMENT_CREATE'
    | 'PRIVATE_INSTRUMENT_DELETE'
    | 'PRIVATE_INSTRUMENT_MODIFY'
    | 'PRIVATE_INSTRUMENT_MODIFY_ALL'
    | 'PRIVATE_INSTRUMENT_VIEW'
    | 'PRIVATE_INSTRUMENT_VIEW_ALL'
    | 'PROCESS_CREATE'
    | 'PROCESS_DELETE'
    | 'PROCESS_MODIFY'
    | 'PROCESS_VIEW'
    | 'PRODUCT_GOVERNANCE_MODIFY'
    | 'PRODUCT_GOVERNANCE_VIEW'
    | 'PROFILES_CREATE'
    | 'PROFILES_DELETE'
    | 'PROFILES_MODIFY'
    | 'PROFILES_VIEW'
    | 'QUARTERLY_STATISTICS_REPORT'
    | 'RBS_F_REPORT'
    | 'RECOMMENDATION_CREATE'
    | 'RECOMMENDATION_FORCE_MODIFY'
    | 'RECOMMENDATION_MODIFY'
    | 'RECOMMENDATION_VIEW'
    | 'RECOMMENDATION_VIEW_ALL'
    | 'RECONCILIATION_REPORT'
    | 'REGULATOR_CREATE'
    | 'REGULATOR_DELETE'
    | 'REGULATOR_MODIFY'
    | 'REGULATOR_VIEW'
    | 'RELUNA_ORDERS_REPORTS_VIEW'
    | 'REPORTS_CREATE'
    | 'REPORTS_DELETE'
    | 'REPORTS_MODIFY'
    | 'REPORTS_VIEW'
    | 'RESTRICTIONS_CREATE'
    | 'RESTRICTIONS_DELETE'
    | 'RESTRICTIONS_MODIFY'
    | 'RESTRICTIONS_MODIFY_ALL'
    | 'RESTRICTIONS_VIEW'
    | 'RESTRICTIONS_VIEW_ALL'
    | 'RESTRICTION_ALERT_VIEW'
    | 'RESTRICTION_REPORTS_CREATE'
    | 'RESTRICTION_REPORTS_DELETE'
    | 'RESTRICTION_REPORTS_MODIFY'
    | 'RESTRICTION_REPORTS_MODIFY_ALL'
    | 'RESTRICTION_REPORTS_VIEW'
    | 'RESTRICTION_REPORTS_VIEW_ALL'
    | 'RESTRICTION_TEMPLATES_CREATE'
    | 'RESTRICTION_TEMPLATES_DELETE'
    | 'RESTRICTION_TEMPLATES_MODIFY'
    | 'RESTRICTION_TEMPLATES_MODIFY_ALL'
    | 'RESTRICTION_TEMPLATES_VIEW'
    | 'RESTRICTION_TEMPLATES_VIEW_ALL'
    | 'RISK_REPORT_CREATE'
    | 'RISK_REPORT_VIEW'
    | 'ROLES_CREATE'
    | 'ROLES_DELETE'
    | 'ROLES_MODIFY'
    | 'ROLES_VIEW'
    | 'RTS28_REPORT'
    | 'SALTEDGE_TRANSACTIONS_MODIFY'
    | 'SALTEDGE_TRANSACTIONS_VIEW'
    | 'SEND_REPORTS'
    | 'SETTINGS_MODIFY'
    | 'SETTINGS_VIEW'
    | 'SHOP_VIEW'
    | 'SITE_INFO_CREATE'
    | 'SITE_INFO_DELETE'
    | 'SITE_INFO_MODIFY'
    | 'SITE_INFO_VIEW'
    | 'SOVA_DATA_MODIFY'
    | 'SOVA_DATA_VIEW'
    | 'SOVA_ORDERS_MODIFY'
    | 'SOVA_ORDERS_VIEW'
    | 'STATEMENT_OF_ELIGIBLE_FUNDS_REPORT'
    | 'STATISTICS_VIEW'
    | 'STRATEGIES_CREATE'
    | 'STRATEGIES_DELETE'
    | 'STRATEGIES_FORCE_MODIFY'
    | 'STRATEGIES_MODIFY'
    | 'STRATEGIES_MODIFY_ALL'
    | 'STRATEGIES_VIEW'
    | 'STRATEGIES_VIEW_ALL'
    | 'STRUCTURED_PRODUCTS_CREATE'
    | 'STRUCTURED_PRODUCTS_DELETE'
    | 'STRUCTURED_PRODUCTS_MODIFY'
    | 'STRUCTURED_PRODUCTS_MODIFY_ALL'
    | 'STRUCTURED_PRODUCTS_VIEW'
    | 'STRUCTURED_PRODUCTS_VIEW_ALL'
    | 'SUBPORTFOLIOS_VIEW'
    | 'SUBSCRIPTION_MODIFY'
    | 'SUBSCRIPTION_VIEW'
    | 'TABLE_CONSTRUCTOR_MODIFY'
    | 'TABLE_CONSTRUCTOR_VIEW'
    | 'TAVIRA_DATA_MODIFY'
    | 'TAVIRA_DATA_VIEW'
    | 'TRANSACTIONS_CREATE'
    | 'TRANSACTIONS_DELETE'
    | 'TRANSACTIONS_FORCE_MODIFY'
    | 'TRANSACTIONS_MATCHING_MODIFY'
    | 'TRANSACTIONS_MATCHING_VIEW'
    | 'TRANSACTIONS_MODIFY'
    | 'TRANSACTIONS_MODIFY_ALL'
    | 'TRANSACTIONS_STATUS'
    | 'TRANSACTIONS_VIEW'
    | 'TRANSACTIONS_VIEW_ALL'
    | 'TRANSACTION_LOADER_CREATE'
    | 'TRANSACTION_LOADER_MODIFY'
    | 'TRANSACTION_LOADER_VIEW'
    | 'TRANSACTION_WITHOUT_BANK_VIEW'
    | 'TRIMP_DATA_MODIFY'
    | 'TRIMP_DATA_VIEW'
    | 'TTCA'
    | 'UBS_DATA_MODIFY'
    | 'UBS_DATA_VIEW'
    | 'VALUE_DATE_RULES_MODIFY'
    | 'VALUE_DATE_RULES_VIEW'
    | 'VP_DATA_MODIFY'
    | 'VP_DATA_VIEW'
    | 'VP_LOADER_SETTINGS_CREATE'
    | 'VP_LOADER_SETTINGS_DELETE'
    | 'VP_LOADER_SETTINGS_MODIFY'
    | 'VP_LOADER_SETTINGS_VIEW'
    | 'VTB_DATA_MODIFY'
    | 'VTB_DATA_VIEW'
    | 'ZOHO_SETTINGS_CREATE'
    | 'ZOHO_SETTINGS_DELETE'
    | 'ZOHO_SETTINGS_MODIFY'
    | 'ZOHO_SETTINGS_VIEW';
  export const AssignAuthorityEnum = {
    AccountsCreate: 'ACCOUNTS_CREATE' as AssignAuthorityEnum,
    AccountsDelete: 'ACCOUNTS_DELETE' as AssignAuthorityEnum,
    AccountsForceModify: 'ACCOUNTS_FORCE_MODIFY' as AssignAuthorityEnum,
    AccountsModify: 'ACCOUNTS_MODIFY' as AssignAuthorityEnum,
    AccountsModifyAll: 'ACCOUNTS_MODIFY_ALL' as AssignAuthorityEnum,
    AccountsView: 'ACCOUNTS_VIEW' as AssignAuthorityEnum,
    AccountsViewAll: 'ACCOUNTS_VIEW_ALL' as AssignAuthorityEnum,
    AccountStructure: 'ACCOUNT_STRUCTURE' as AssignAuthorityEnum,
    ActuatorView: 'ACTUATOR_VIEW' as AssignAuthorityEnum,
    AdvancedAnalyticsView: 'ADVANCED_ANALYTICS_VIEW' as AssignAuthorityEnum,
    AlfaDataModify: 'ALFA_DATA_MODIFY' as AssignAuthorityEnum,
    AlfaDataView: 'ALFA_DATA_VIEW' as AssignAuthorityEnum,
    AmazonWorkDocs: 'AMAZON_WORK_DOCS' as AssignAuthorityEnum,
    BankAccountsCreate: 'BANK_ACCOUNTS_CREATE' as AssignAuthorityEnum,
    BankAccountsDelete: 'BANK_ACCOUNTS_DELETE' as AssignAuthorityEnum,
    BankAccountsForceModify: 'BANK_ACCOUNTS_FORCE_MODIFY' as AssignAuthorityEnum,
    BankAccountsModify: 'BANK_ACCOUNTS_MODIFY' as AssignAuthorityEnum,
    BankAccountsModifyAll: 'BANK_ACCOUNTS_MODIFY_ALL' as AssignAuthorityEnum,
    BankAccountsView: 'BANK_ACCOUNTS_VIEW' as AssignAuthorityEnum,
    BankAccountsViewAll: 'BANK_ACCOUNTS_VIEW_ALL' as AssignAuthorityEnum,
    BankStatsView: 'BANK_STATS_VIEW' as AssignAuthorityEnum,
    BksDataModify: 'BKS_DATA_MODIFY' as AssignAuthorityEnum,
    BksDataView: 'BKS_DATA_VIEW' as AssignAuthorityEnum,
    BksLoaderSettingsCreate: 'BKS_LOADER_SETTINGS_CREATE' as AssignAuthorityEnum,
    BksLoaderSettingsDelete: 'BKS_LOADER_SETTINGS_DELETE' as AssignAuthorityEnum,
    BksLoaderSettingsModify: 'BKS_LOADER_SETTINGS_MODIFY' as AssignAuthorityEnum,
    BksLoaderSettingsView: 'BKS_LOADER_SETTINGS_VIEW' as AssignAuthorityEnum,
    BloombergRequestsView: 'BLOOMBERG_REQUESTS_VIEW' as AssignAuthorityEnum,
    BrokerCreate: 'BROKER_CREATE' as AssignAuthorityEnum,
    BrokerDelete: 'BROKER_DELETE' as AssignAuthorityEnum,
    BrokerModify: 'BROKER_MODIFY' as AssignAuthorityEnum,
    BrokerView: 'BROKER_VIEW' as AssignAuthorityEnum,
    BusinessEventsView: 'BUSINESS_EVENTS_VIEW' as AssignAuthorityEnum,
    CassReport: 'CASS_REPORT' as AssignAuthorityEnum,
    CbhBsDataModify: 'CBH_BS_DATA_MODIFY' as AssignAuthorityEnum,
    CbhBsDataView: 'CBH_BS_DATA_VIEW' as AssignAuthorityEnum,
    CbhDataModify: 'CBH_DATA_MODIFY' as AssignAuthorityEnum,
    CbhDataView: 'CBH_DATA_VIEW' as AssignAuthorityEnum,
    CbhLoaderSettingsCreate: 'CBH_LOADER_SETTINGS_CREATE' as AssignAuthorityEnum,
    CbhLoaderSettingsDelete: 'CBH_LOADER_SETTINGS_DELETE' as AssignAuthorityEnum,
    CbhLoaderSettingsModify: 'CBH_LOADER_SETTINGS_MODIFY' as AssignAuthorityEnum,
    CbhLoaderSettingsView: 'CBH_LOADER_SETTINGS_VIEW' as AssignAuthorityEnum,
    ChangeRequestSettingsCreate: 'CHANGE_REQUEST_SETTINGS_CREATE' as AssignAuthorityEnum,
    ChangeRequestSettingsDelete: 'CHANGE_REQUEST_SETTINGS_DELETE' as AssignAuthorityEnum,
    ChangeRequestSettingsModify: 'CHANGE_REQUEST_SETTINGS_MODIFY' as AssignAuthorityEnum,
    ChangeRequestSettingsView: 'CHANGE_REQUEST_SETTINGS_VIEW' as AssignAuthorityEnum,
    ClientsCreate: 'CLIENTS_CREATE' as AssignAuthorityEnum,
    ClientsDelete: 'CLIENTS_DELETE' as AssignAuthorityEnum,
    ClientsModify: 'CLIENTS_MODIFY' as AssignAuthorityEnum,
    ClientsModifyAll: 'CLIENTS_MODIFY_ALL' as AssignAuthorityEnum,
    ClientsView: 'CLIENTS_VIEW' as AssignAuthorityEnum,
    ClientsViewAll: 'CLIENTS_VIEW_ALL' as AssignAuthorityEnum,
    ClientCountReport: 'CLIENT_COUNT_REPORT' as AssignAuthorityEnum,
    ClientFeesReport: 'CLIENT_FEES_REPORT' as AssignAuthorityEnum,
    ClientValuationReport: 'CLIENT_VALUATION_REPORT' as AssignAuthorityEnum,
    CmarReport: 'CMAR_REPORT' as AssignAuthorityEnum,
    CommonEmailTemplatesCreate: 'COMMON_EMAIL_TEMPLATES_CREATE' as AssignAuthorityEnum,
    CommonEmailTemplatesDelete: 'COMMON_EMAIL_TEMPLATES_DELETE' as AssignAuthorityEnum,
    CommonEmailTemplatesModify: 'COMMON_EMAIL_TEMPLATES_MODIFY' as AssignAuthorityEnum,
    CommonEmailTemplatesView: 'COMMON_EMAIL_TEMPLATES_VIEW' as AssignAuthorityEnum,
    CompaniesCreate: 'COMPANIES_CREATE' as AssignAuthorityEnum,
    CompaniesDelete: 'COMPANIES_DELETE' as AssignAuthorityEnum,
    CompaniesModify: 'COMPANIES_MODIFY' as AssignAuthorityEnum,
    CompaniesTagsView: 'COMPANIES_TAGS_VIEW' as AssignAuthorityEnum,
    CompaniesView: 'COMPANIES_VIEW' as AssignAuthorityEnum,
    CompanyHoldingCreate: 'COMPANY_HOLDING_CREATE' as AssignAuthorityEnum,
    CompanyHoldingDelete: 'COMPANY_HOLDING_DELETE' as AssignAuthorityEnum,
    CompanyHoldingModify: 'COMPANY_HOLDING_MODIFY' as AssignAuthorityEnum,
    CompanyHoldingView: 'COMPANY_HOLDING_VIEW' as AssignAuthorityEnum,
    CompanyRiskReport: 'COMPANY_RISK_REPORT' as AssignAuthorityEnum,
    ComplianceAccountCompanyCreate: 'COMPLIANCE_ACCOUNT_COMPANY_CREATE' as AssignAuthorityEnum,
    ComplianceAccountCompanyDelete: 'COMPLIANCE_ACCOUNT_COMPANY_DELETE' as AssignAuthorityEnum,
    ComplianceAccountCompanyForceModify:
      'COMPLIANCE_ACCOUNT_COMPANY_FORCE_MODIFY' as AssignAuthorityEnum,
    ComplianceAccountCompanyModify: 'COMPLIANCE_ACCOUNT_COMPANY_MODIFY' as AssignAuthorityEnum,
    ComplianceAccountCompanyView: 'COMPLIANCE_ACCOUNT_COMPANY_VIEW' as AssignAuthorityEnum,
    CounterpartyCreate: 'COUNTERPARTY_CREATE' as AssignAuthorityEnum,
    CounterpartyDelete: 'COUNTERPARTY_DELETE' as AssignAuthorityEnum,
    CounterpartyModify: 'COUNTERPARTY_MODIFY' as AssignAuthorityEnum,
    CounterpartyView: 'COUNTERPARTY_VIEW' as AssignAuthorityEnum,
    CouponProjectionReport: 'COUPON_PROJECTION_REPORT' as AssignAuthorityEnum,
    CrsReport: 'CRS_REPORT' as AssignAuthorityEnum,
    CustodianAssetTypeMappingCreate: 'CUSTODIAN_ASSET_TYPE_MAPPING_CREATE' as AssignAuthorityEnum,
    CustodianAssetTypeMappingModify: 'CUSTODIAN_ASSET_TYPE_MAPPING_MODIFY' as AssignAuthorityEnum,
    CustodianAssetTypeMappingView: 'CUSTODIAN_ASSET_TYPE_MAPPING_VIEW' as AssignAuthorityEnum,
    CustodianCreate: 'CUSTODIAN_CREATE' as AssignAuthorityEnum,
    CustodianDelete: 'CUSTODIAN_DELETE' as AssignAuthorityEnum,
    CustodianModify: 'CUSTODIAN_MODIFY' as AssignAuthorityEnum,
    CustodianView: 'CUSTODIAN_VIEW' as AssignAuthorityEnum,
    DashboardModify: 'DASHBOARD_MODIFY' as AssignAuthorityEnum,
    DashboardView: 'DASHBOARD_VIEW' as AssignAuthorityEnum,
    DbsDataModify: 'DBS_DATA_MODIFY' as AssignAuthorityEnum,
    DbsDataView: 'DBS_DATA_VIEW' as AssignAuthorityEnum,
    DeactivatedTransactionView: 'DEACTIVATED_TRANSACTION_VIEW' as AssignAuthorityEnum,
    DepartmentsCreate: 'DEPARTMENTS_CREATE' as AssignAuthorityEnum,
    DepartmentsDelete: 'DEPARTMENTS_DELETE' as AssignAuthorityEnum,
    DepartmentsModify: 'DEPARTMENTS_MODIFY' as AssignAuthorityEnum,
    DepartmentsView: 'DEPARTMENTS_VIEW' as AssignAuthorityEnum,
    DolfinTransactionsModify: 'DOLFIN_TRANSACTIONS_MODIFY' as AssignAuthorityEnum,
    DolfinTransactionsView: 'DOLFIN_TRANSACTIONS_VIEW' as AssignAuthorityEnum,
    EdrDataModify: 'EDR_DATA_MODIFY' as AssignAuthorityEnum,
    EdrDataView: 'EDR_DATA_VIEW' as AssignAuthorityEnum,
    EdrLoaderSettingsCreate: 'EDR_LOADER_SETTINGS_CREATE' as AssignAuthorityEnum,
    EdrLoaderSettingsDelete: 'EDR_LOADER_SETTINGS_DELETE' as AssignAuthorityEnum,
    EdrLoaderSettingsModify: 'EDR_LOADER_SETTINGS_MODIFY' as AssignAuthorityEnum,
    EdrLoaderSettingsView: 'EDR_LOADER_SETTINGS_VIEW' as AssignAuthorityEnum,
    EmailTemplateCreate: 'EMAIL_TEMPLATE_CREATE' as AssignAuthorityEnum,
    EmailTemplateDelete: 'EMAIL_TEMPLATE_DELETE' as AssignAuthorityEnum,
    EmailTemplateModify: 'EMAIL_TEMPLATE_MODIFY' as AssignAuthorityEnum,
    EmailTemplateView: 'EMAIL_TEMPLATE_VIEW' as AssignAuthorityEnum,
    ExecgwReportsView: 'EXECGW_REPORTS_VIEW' as AssignAuthorityEnum,
    FeesCreate: 'FEES_CREATE' as AssignAuthorityEnum,
    FeesModify: 'FEES_MODIFY' as AssignAuthorityEnum,
    FeesView: 'FEES_VIEW' as AssignAuthorityEnum,
    FeesViewAll: 'FEES_VIEW_ALL' as AssignAuthorityEnum,
    FieldsCreate: 'FIELDS_CREATE' as AssignAuthorityEnum,
    FieldsDelete: 'FIELDS_DELETE' as AssignAuthorityEnum,
    FieldsModify: 'FIELDS_MODIFY' as AssignAuthorityEnum,
    FieldsView: 'FIELDS_VIEW' as AssignAuthorityEnum,
    FileUserLogView: 'FILE_USER_LOG_VIEW' as AssignAuthorityEnum,
    GapMonitorView: 'GAP_MONITOR_VIEW' as AssignAuthorityEnum,
    GlobalAllocationCreate: 'GLOBAL_ALLOCATION_CREATE' as AssignAuthorityEnum,
    GlobalAllocationModify: 'GLOBAL_ALLOCATION_MODIFY' as AssignAuthorityEnum,
    GlobalAllocationView: 'GLOBAL_ALLOCATION_VIEW' as AssignAuthorityEnum,
    GlobalAllocationWidgetView: 'GLOBAL_ALLOCATION_WIDGET_VIEW' as AssignAuthorityEnum,
    GoogleAuthModify: 'GOOGLE_AUTH_MODIFY' as AssignAuthorityEnum,
    GoogleAuthView: 'GOOGLE_AUTH_VIEW' as AssignAuthorityEnum,
    GppDataModify: 'GPP_DATA_MODIFY' as AssignAuthorityEnum,
    GppDataView: 'GPP_DATA_VIEW' as AssignAuthorityEnum,
    GppLoaderSettingsCreate: 'GPP_LOADER_SETTINGS_CREATE' as AssignAuthorityEnum,
    GppLoaderSettingsDelete: 'GPP_LOADER_SETTINGS_DELETE' as AssignAuthorityEnum,
    GppLoaderSettingsModify: 'GPP_LOADER_SETTINGS_MODIFY' as AssignAuthorityEnum,
    GppLoaderSettingsView: 'GPP_LOADER_SETTINGS_VIEW' as AssignAuthorityEnum,
    HealthView: 'HEALTH_VIEW' as AssignAuthorityEnum,
    HistoryView: 'HISTORY_VIEW' as AssignAuthorityEnum,
    IbkrDataModify: 'IBKR_DATA_MODIFY' as AssignAuthorityEnum,
    IbkrDataView: 'IBKR_DATA_VIEW' as AssignAuthorityEnum,
    IbkrFuturesMappingCreate: 'IBKR_FUTURES_MAPPING_CREATE' as AssignAuthorityEnum,
    IbkrFuturesMappingDelete: 'IBKR_FUTURES_MAPPING_DELETE' as AssignAuthorityEnum,
    IbkrFuturesMappingModify: 'IBKR_FUTURES_MAPPING_MODIFY' as AssignAuthorityEnum,
    IbkrFuturesMappingView: 'IBKR_FUTURES_MAPPING_VIEW' as AssignAuthorityEnum,
    IbkrLoaderSettingsCreate: 'IBKR_LOADER_SETTINGS_CREATE' as AssignAuthorityEnum,
    IbkrLoaderSettingsDelete: 'IBKR_LOADER_SETTINGS_DELETE' as AssignAuthorityEnum,
    IbkrLoaderSettingsModify: 'IBKR_LOADER_SETTINGS_MODIFY' as AssignAuthorityEnum,
    IbkrLoaderSettingsView: 'IBKR_LOADER_SETTINGS_VIEW' as AssignAuthorityEnum,
    IncomePaymentTracker: 'INCOME_PAYMENT_TRACKER' as AssignAuthorityEnum,
    InstrumentsCreate: 'INSTRUMENTS_CREATE' as AssignAuthorityEnum,
    InstrumentsModify: 'INSTRUMENTS_MODIFY' as AssignAuthorityEnum,
    InstrumentsView: 'INSTRUMENTS_VIEW' as AssignAuthorityEnum,
    InstrumentToAgentView: 'INSTRUMENT_TO_AGENT_VIEW' as AssignAuthorityEnum,
    InvestecDataModify: 'INVESTEC_DATA_MODIFY' as AssignAuthorityEnum,
    InvestecDataView: 'INVESTEC_DATA_VIEW' as AssignAuthorityEnum,
    InvestecLoaderSettingsCreate: 'INVESTEC_LOADER_SETTINGS_CREATE' as AssignAuthorityEnum,
    InvestecLoaderSettingsDelete: 'INVESTEC_LOADER_SETTINGS_DELETE' as AssignAuthorityEnum,
    InvestecLoaderSettingsModify: 'INVESTEC_LOADER_SETTINGS_MODIFY' as AssignAuthorityEnum,
    InvestecLoaderSettingsView: 'INVESTEC_LOADER_SETTINGS_VIEW' as AssignAuthorityEnum,
    InvestmentView: 'INVESTMENT_VIEW' as AssignAuthorityEnum,
    IpAccess: 'IP_ACCESS' as AssignAuthorityEnum,
    JbDataModify: 'JB_DATA_MODIFY' as AssignAuthorityEnum,
    JbDataView: 'JB_DATA_VIEW' as AssignAuthorityEnum,
    JbLoaderSettingsCreate: 'JB_LOADER_SETTINGS_CREATE' as AssignAuthorityEnum,
    JbLoaderSettingsDelete: 'JB_LOADER_SETTINGS_DELETE' as AssignAuthorityEnum,
    JbLoaderSettingsModify: 'JB_LOADER_SETTINGS_MODIFY' as AssignAuthorityEnum,
    JbLoaderSettingsView: 'JB_LOADER_SETTINGS_VIEW' as AssignAuthorityEnum,
    JpmDataModify: 'JPM_DATA_MODIFY' as AssignAuthorityEnum,
    JpmDataView: 'JPM_DATA_VIEW' as AssignAuthorityEnum,
    JpmLoaderSettingsCreate: 'JPM_LOADER_SETTINGS_CREATE' as AssignAuthorityEnum,
    JpmLoaderSettingsDelete: 'JPM_LOADER_SETTINGS_DELETE' as AssignAuthorityEnum,
    JpmLoaderSettingsModify: 'JPM_LOADER_SETTINGS_MODIFY' as AssignAuthorityEnum,
    JpmLoaderSettingsView: 'JPM_LOADER_SETTINGS_VIEW' as AssignAuthorityEnum,
    LeadCreate: 'LEAD_CREATE' as AssignAuthorityEnum,
    LeadDelete: 'LEAD_DELETE' as AssignAuthorityEnum,
    LeadModify: 'LEAD_MODIFY' as AssignAuthorityEnum,
    LeadModifyAll: 'LEAD_MODIFY_ALL' as AssignAuthorityEnum,
    LeadView: 'LEAD_VIEW' as AssignAuthorityEnum,
    LeadViewAll: 'LEAD_VIEW_ALL' as AssignAuthorityEnum,
    LogotypeDelete: 'LOGOTYPE_DELETE' as AssignAuthorityEnum,
    LogotypeModify: 'LOGOTYPE_MODIFY' as AssignAuthorityEnum,
    LogotypeView: 'LOGOTYPE_VIEW' as AssignAuthorityEnum,
    ManagersCreate: 'MANAGERS_CREATE' as AssignAuthorityEnum,
    ManagersDelete: 'MANAGERS_DELETE' as AssignAuthorityEnum,
    ManagersForceModify: 'MANAGERS_FORCE_MODIFY' as AssignAuthorityEnum,
    ManagersModify: 'MANAGERS_MODIFY' as AssignAuthorityEnum,
    ManagersView: 'MANAGERS_VIEW' as AssignAuthorityEnum,
    MandatesCreate: 'MANDATES_CREATE' as AssignAuthorityEnum,
    MandatesDelete: 'MANDATES_DELETE' as AssignAuthorityEnum,
    MandatesForceModify: 'MANDATES_FORCE_MODIFY' as AssignAuthorityEnum,
    MandatesModify: 'MANDATES_MODIFY' as AssignAuthorityEnum,
    MandatesModifyAll: 'MANDATES_MODIFY_ALL' as AssignAuthorityEnum,
    MandatesView: 'MANDATES_VIEW' as AssignAuthorityEnum,
    MandatesViewAll: 'MANDATES_VIEW_ALL' as AssignAuthorityEnum,
    MandateRestrictionsCreate: 'MANDATE_RESTRICTIONS_CREATE' as AssignAuthorityEnum,
    MandateRestrictionsDelete: 'MANDATE_RESTRICTIONS_DELETE' as AssignAuthorityEnum,
    MandateRestrictionsModify: 'MANDATE_RESTRICTIONS_MODIFY' as AssignAuthorityEnum,
    MandateRestrictionsModifyAll: 'MANDATE_RESTRICTIONS_MODIFY_ALL' as AssignAuthorityEnum,
    MandateRestrictionsView: 'MANDATE_RESTRICTIONS_VIEW' as AssignAuthorityEnum,
    MandateRestrictionsViewAll: 'MANDATE_RESTRICTIONS_VIEW_ALL' as AssignAuthorityEnum,
    MandateRestrictionReportsCreate: 'MANDATE_RESTRICTION_REPORTS_CREATE' as AssignAuthorityEnum,
    MandateRestrictionReportsDelete: 'MANDATE_RESTRICTION_REPORTS_DELETE' as AssignAuthorityEnum,
    MandateRestrictionReportsModify: 'MANDATE_RESTRICTION_REPORTS_MODIFY' as AssignAuthorityEnum,
    MandateRestrictionReportsModifyAll:
      'MANDATE_RESTRICTION_REPORTS_MODIFY_ALL' as AssignAuthorityEnum,
    MandateRestrictionReportsView: 'MANDATE_RESTRICTION_REPORTS_VIEW' as AssignAuthorityEnum,
    MandateRestrictionReportsViewAll: 'MANDATE_RESTRICTION_REPORTS_VIEW_ALL' as AssignAuthorityEnum,
    MandateRestrictionTemplatesCreate:
      'MANDATE_RESTRICTION_TEMPLATES_CREATE' as AssignAuthorityEnum,
    MandateRestrictionTemplatesDelete:
      'MANDATE_RESTRICTION_TEMPLATES_DELETE' as AssignAuthorityEnum,
    MandateRestrictionTemplatesModify:
      'MANDATE_RESTRICTION_TEMPLATES_MODIFY' as AssignAuthorityEnum,
    MandateRestrictionTemplatesModifyAll:
      'MANDATE_RESTRICTION_TEMPLATES_MODIFY_ALL' as AssignAuthorityEnum,
    MandateRestrictionTemplatesView: 'MANDATE_RESTRICTION_TEMPLATES_VIEW' as AssignAuthorityEnum,
    MandateRestrictionTemplatesViewAll:
      'MANDATE_RESTRICTION_TEMPLATES_VIEW_ALL' as AssignAuthorityEnum,
    MandateRiskProfileCreate: 'MANDATE_RISK_PROFILE_CREATE' as AssignAuthorityEnum,
    MandateRiskProfileDelete: 'MANDATE_RISK_PROFILE_DELETE' as AssignAuthorityEnum,
    MandateRiskProfileForceModify: 'MANDATE_RISK_PROFILE_FORCE_MODIFY' as AssignAuthorityEnum,
    MandateRiskProfileModify: 'MANDATE_RISK_PROFILE_MODIFY' as AssignAuthorityEnum,
    MandateRiskProfileView: 'MANDATE_RISK_PROFILE_VIEW' as AssignAuthorityEnum,
    MandateRiskProfileViewAll: 'MANDATE_RISK_PROFILE_VIEW_ALL' as AssignAuthorityEnum,
    ManualImportPositionsCreate: 'MANUAL_IMPORT_POSITIONS_CREATE' as AssignAuthorityEnum,
    ManualImportPositionsModify: 'MANUAL_IMPORT_POSITIONS_MODIFY' as AssignAuthorityEnum,
    ManualImportPositionsView: 'MANUAL_IMPORT_POSITIONS_VIEW' as AssignAuthorityEnum,
    ManualImportTransactionsCreate: 'MANUAL_IMPORT_TRANSACTIONS_CREATE' as AssignAuthorityEnum,
    ManualImportTransactionsModify: 'MANUAL_IMPORT_TRANSACTIONS_MODIFY' as AssignAuthorityEnum,
    ManualImportTransactionsView: 'MANUAL_IMPORT_TRANSACTIONS_VIEW' as AssignAuthorityEnum,
    MarketplaceModify: 'MARKETPLACE_MODIFY' as AssignAuthorityEnum,
    MarketplaceView: 'MARKETPLACE_VIEW' as AssignAuthorityEnum,
    MarketSecuritiesModify: 'MARKET_SECURITIES_MODIFY' as AssignAuthorityEnum,
    MarketSecuritiesView: 'MARKET_SECURITIES_VIEW' as AssignAuthorityEnum,
    MifirModify: 'MIFIR_MODIFY' as AssignAuthorityEnum,
    MifirReport: 'MIFIR_REPORT' as AssignAuthorityEnum,
    MifirView: 'MIFIR_VIEW' as AssignAuthorityEnum,
    ModelPortfoliosCreate: 'MODEL_PORTFOLIOS_CREATE' as AssignAuthorityEnum,
    ModelPortfoliosDelete: 'MODEL_PORTFOLIOS_DELETE' as AssignAuthorityEnum,
    ModelPortfoliosModify: 'MODEL_PORTFOLIOS_MODIFY' as AssignAuthorityEnum,
    ModelPortfoliosView: 'MODEL_PORTFOLIOS_VIEW' as AssignAuthorityEnum,
    MonthlyReconciliationReport: 'MONTHLY_RECONCILIATION_REPORT' as AssignAuthorityEnum,
    NotificationTemplatesCreate: 'NOTIFICATION_TEMPLATES_CREATE' as AssignAuthorityEnum,
    NotificationTemplatesDelete: 'NOTIFICATION_TEMPLATES_DELETE' as AssignAuthorityEnum,
    NotificationTemplatesModify: 'NOTIFICATION_TEMPLATES_MODIFY' as AssignAuthorityEnum,
    NotificationTemplatesView: 'NOTIFICATION_TEMPLATES_VIEW' as AssignAuthorityEnum,
    NotificationUserLogView: 'NOTIFICATION_USER_LOG_VIEW' as AssignAuthorityEnum,
    Omnibus: 'OMNIBUS' as AssignAuthorityEnum,
    OnlinePricingView: 'ONLINE_PRICING_VIEW' as AssignAuthorityEnum,
    OpportunityCreate: 'OPPORTUNITY_CREATE' as AssignAuthorityEnum,
    OpportunityDelete: 'OPPORTUNITY_DELETE' as AssignAuthorityEnum,
    OpportunityForceModify: 'OPPORTUNITY_FORCE_MODIFY' as AssignAuthorityEnum,
    OpportunityModify: 'OPPORTUNITY_MODIFY' as AssignAuthorityEnum,
    OpportunityModifyAll: 'OPPORTUNITY_MODIFY_ALL' as AssignAuthorityEnum,
    OpportunityView: 'OPPORTUNITY_VIEW' as AssignAuthorityEnum,
    OpportunityViewAll: 'OPPORTUNITY_VIEW_ALL' as AssignAuthorityEnum,
    OrdersAssign: 'ORDERS_ASSIGN' as AssignAuthorityEnum,
    OrdersCommonCreate: 'ORDERS_COMMON_CREATE' as AssignAuthorityEnum,
    OrdersCommonDelete: 'ORDERS_COMMON_DELETE' as AssignAuthorityEnum,
    OrdersCommonModify: 'ORDERS_COMMON_MODIFY' as AssignAuthorityEnum,
    OrdersCommonView: 'ORDERS_COMMON_VIEW' as AssignAuthorityEnum,
    OrdersCorpActionCreate: 'ORDERS_CORP_ACTION_CREATE' as AssignAuthorityEnum,
    OrdersCorpActionDelete: 'ORDERS_CORP_ACTION_DELETE' as AssignAuthorityEnum,
    OrdersCorpActionModify: 'ORDERS_CORP_ACTION_MODIFY' as AssignAuthorityEnum,
    OrdersCorpActionView: 'ORDERS_CORP_ACTION_VIEW' as AssignAuthorityEnum,
    OrdersCreate: 'ORDERS_CREATE' as AssignAuthorityEnum,
    OrdersDelete: 'ORDERS_DELETE' as AssignAuthorityEnum,
    OrdersDmaCreate: 'ORDERS_DMA_CREATE' as AssignAuthorityEnum,
    OrdersForceModify: 'ORDERS_FORCE_MODIFY' as AssignAuthorityEnum,
    OrdersModify: 'ORDERS_MODIFY' as AssignAuthorityEnum,
    OrdersModifyAll: 'ORDERS_MODIFY_ALL' as AssignAuthorityEnum,
    OrdersNonTradeCreate: 'ORDERS_NON_TRADE_CREATE' as AssignAuthorityEnum,
    OrdersNonTradeDelete: 'ORDERS_NON_TRADE_DELETE' as AssignAuthorityEnum,
    OrdersNonTradeModify: 'ORDERS_NON_TRADE_MODIFY' as AssignAuthorityEnum,
    OrdersNonTradeView: 'ORDERS_NON_TRADE_VIEW' as AssignAuthorityEnum,
    OrdersTechInfoModify: 'ORDERS_TECH_INFO_MODIFY' as AssignAuthorityEnum,
    OrdersTechInfoView: 'ORDERS_TECH_INFO_VIEW' as AssignAuthorityEnum,
    OrdersTradeCreate: 'ORDERS_TRADE_CREATE' as AssignAuthorityEnum,
    OrdersTradeDelete: 'ORDERS_TRADE_DELETE' as AssignAuthorityEnum,
    OrdersTradeModify: 'ORDERS_TRADE_MODIFY' as AssignAuthorityEnum,
    OrdersTradeView: 'ORDERS_TRADE_VIEW' as AssignAuthorityEnum,
    OrdersView: 'ORDERS_VIEW' as AssignAuthorityEnum,
    OrdersViewAll: 'ORDERS_VIEW_ALL' as AssignAuthorityEnum,
    PersonalAm: 'PERSONAL_AM' as AssignAuthorityEnum,
    PnlReport: 'PNL_REPORT' as AssignAuthorityEnum,
    PortfoliosCreate: 'PORTFOLIOS_CREATE' as AssignAuthorityEnum,
    PortfoliosDelete: 'PORTFOLIOS_DELETE' as AssignAuthorityEnum,
    PortfoliosForceModify: 'PORTFOLIOS_FORCE_MODIFY' as AssignAuthorityEnum,
    PortfoliosModify: 'PORTFOLIOS_MODIFY' as AssignAuthorityEnum,
    PortfoliosModifyAll: 'PORTFOLIOS_MODIFY_ALL' as AssignAuthorityEnum,
    PortfoliosView: 'PORTFOLIOS_VIEW' as AssignAuthorityEnum,
    PortfoliosViewAll: 'PORTFOLIOS_VIEW_ALL' as AssignAuthorityEnum,
    PortfolioConfigurationView: 'PORTFOLIO_CONFIGURATION_VIEW' as AssignAuthorityEnum,
    PortfolioCostsView: 'PORTFOLIO_COSTS_VIEW' as AssignAuthorityEnum,
    PortfolioFeesView: 'PORTFOLIO_FEES_VIEW' as AssignAuthorityEnum,
    PortfolioFlowsView: 'PORTFOLIO_FLOWS_VIEW' as AssignAuthorityEnum,
    PortfolioOrdersAssign: 'PORTFOLIO_ORDERS_ASSIGN' as AssignAuthorityEnum,
    PortfolioOrdersCreate: 'PORTFOLIO_ORDERS_CREATE' as AssignAuthorityEnum,
    PortfolioOrdersDelete: 'PORTFOLIO_ORDERS_DELETE' as AssignAuthorityEnum,
    PortfolioOrdersModify: 'PORTFOLIO_ORDERS_MODIFY' as AssignAuthorityEnum,
    PortfolioOrdersView: 'PORTFOLIO_ORDERS_VIEW' as AssignAuthorityEnum,
    PortfolioPnlView: 'PORTFOLIO_PNL_VIEW' as AssignAuthorityEnum,
    PortfolioPositionsView: 'PORTFOLIO_POSITIONS_VIEW' as AssignAuthorityEnum,
    PortfolioProfileModify: 'PORTFOLIO_PROFILE_MODIFY' as AssignAuthorityEnum,
    PortfolioProfileView: 'PORTFOLIO_PROFILE_VIEW' as AssignAuthorityEnum,
    PortfolioRestrictionsView: 'PORTFOLIO_RESTRICTIONS_VIEW' as AssignAuthorityEnum,
    PortfolioTransactionsCreate: 'PORTFOLIO_TRANSACTIONS_CREATE' as AssignAuthorityEnum,
    PortfolioTransactionsDelete: 'PORTFOLIO_TRANSACTIONS_DELETE' as AssignAuthorityEnum,
    PortfolioTransactionsModify: 'PORTFOLIO_TRANSACTIONS_MODIFY' as AssignAuthorityEnum,
    PortfolioTransactionsStatus: 'PORTFOLIO_TRANSACTIONS_STATUS' as AssignAuthorityEnum,
    PortfolioTransactionsView: 'PORTFOLIO_TRANSACTIONS_VIEW' as AssignAuthorityEnum,
    PortfolioValuationView: 'PORTFOLIO_VALUATION_VIEW' as AssignAuthorityEnum,
    PositionsView: 'POSITIONS_VIEW' as AssignAuthorityEnum,
    PricesLoadingMonitorView: 'PRICES_LOADING_MONITOR_VIEW' as AssignAuthorityEnum,
    PrivateInstrumentCreate: 'PRIVATE_INSTRUMENT_CREATE' as AssignAuthorityEnum,
    PrivateInstrumentDelete: 'PRIVATE_INSTRUMENT_DELETE' as AssignAuthorityEnum,
    PrivateInstrumentModify: 'PRIVATE_INSTRUMENT_MODIFY' as AssignAuthorityEnum,
    PrivateInstrumentModifyAll: 'PRIVATE_INSTRUMENT_MODIFY_ALL' as AssignAuthorityEnum,
    PrivateInstrumentView: 'PRIVATE_INSTRUMENT_VIEW' as AssignAuthorityEnum,
    PrivateInstrumentViewAll: 'PRIVATE_INSTRUMENT_VIEW_ALL' as AssignAuthorityEnum,
    ProcessCreate: 'PROCESS_CREATE' as AssignAuthorityEnum,
    ProcessDelete: 'PROCESS_DELETE' as AssignAuthorityEnum,
    ProcessModify: 'PROCESS_MODIFY' as AssignAuthorityEnum,
    ProcessView: 'PROCESS_VIEW' as AssignAuthorityEnum,
    ProductGovernanceModify: 'PRODUCT_GOVERNANCE_MODIFY' as AssignAuthorityEnum,
    ProductGovernanceView: 'PRODUCT_GOVERNANCE_VIEW' as AssignAuthorityEnum,
    ProfilesCreate: 'PROFILES_CREATE' as AssignAuthorityEnum,
    ProfilesDelete: 'PROFILES_DELETE' as AssignAuthorityEnum,
    ProfilesModify: 'PROFILES_MODIFY' as AssignAuthorityEnum,
    ProfilesView: 'PROFILES_VIEW' as AssignAuthorityEnum,
    QuarterlyStatisticsReport: 'QUARTERLY_STATISTICS_REPORT' as AssignAuthorityEnum,
    RbsFReport: 'RBS_F_REPORT' as AssignAuthorityEnum,
    RecommendationCreate: 'RECOMMENDATION_CREATE' as AssignAuthorityEnum,
    RecommendationForceModify: 'RECOMMENDATION_FORCE_MODIFY' as AssignAuthorityEnum,
    RecommendationModify: 'RECOMMENDATION_MODIFY' as AssignAuthorityEnum,
    RecommendationView: 'RECOMMENDATION_VIEW' as AssignAuthorityEnum,
    RecommendationViewAll: 'RECOMMENDATION_VIEW_ALL' as AssignAuthorityEnum,
    ReconciliationReport: 'RECONCILIATION_REPORT' as AssignAuthorityEnum,
    RegulatorCreate: 'REGULATOR_CREATE' as AssignAuthorityEnum,
    RegulatorDelete: 'REGULATOR_DELETE' as AssignAuthorityEnum,
    RegulatorModify: 'REGULATOR_MODIFY' as AssignAuthorityEnum,
    RegulatorView: 'REGULATOR_VIEW' as AssignAuthorityEnum,
    RelunaOrdersReportsView: 'RELUNA_ORDERS_REPORTS_VIEW' as AssignAuthorityEnum,
    ReportsCreate: 'REPORTS_CREATE' as AssignAuthorityEnum,
    ReportsDelete: 'REPORTS_DELETE' as AssignAuthorityEnum,
    ReportsModify: 'REPORTS_MODIFY' as AssignAuthorityEnum,
    ReportsView: 'REPORTS_VIEW' as AssignAuthorityEnum,
    RestrictionsCreate: 'RESTRICTIONS_CREATE' as AssignAuthorityEnum,
    RestrictionsDelete: 'RESTRICTIONS_DELETE' as AssignAuthorityEnum,
    RestrictionsModify: 'RESTRICTIONS_MODIFY' as AssignAuthorityEnum,
    RestrictionsModifyAll: 'RESTRICTIONS_MODIFY_ALL' as AssignAuthorityEnum,
    RestrictionsView: 'RESTRICTIONS_VIEW' as AssignAuthorityEnum,
    RestrictionsViewAll: 'RESTRICTIONS_VIEW_ALL' as AssignAuthorityEnum,
    RestrictionAlertView: 'RESTRICTION_ALERT_VIEW' as AssignAuthorityEnum,
    RestrictionReportsCreate: 'RESTRICTION_REPORTS_CREATE' as AssignAuthorityEnum,
    RestrictionReportsDelete: 'RESTRICTION_REPORTS_DELETE' as AssignAuthorityEnum,
    RestrictionReportsModify: 'RESTRICTION_REPORTS_MODIFY' as AssignAuthorityEnum,
    RestrictionReportsModifyAll: 'RESTRICTION_REPORTS_MODIFY_ALL' as AssignAuthorityEnum,
    RestrictionReportsView: 'RESTRICTION_REPORTS_VIEW' as AssignAuthorityEnum,
    RestrictionReportsViewAll: 'RESTRICTION_REPORTS_VIEW_ALL' as AssignAuthorityEnum,
    RestrictionTemplatesCreate: 'RESTRICTION_TEMPLATES_CREATE' as AssignAuthorityEnum,
    RestrictionTemplatesDelete: 'RESTRICTION_TEMPLATES_DELETE' as AssignAuthorityEnum,
    RestrictionTemplatesModify: 'RESTRICTION_TEMPLATES_MODIFY' as AssignAuthorityEnum,
    RestrictionTemplatesModifyAll: 'RESTRICTION_TEMPLATES_MODIFY_ALL' as AssignAuthorityEnum,
    RestrictionTemplatesView: 'RESTRICTION_TEMPLATES_VIEW' as AssignAuthorityEnum,
    RestrictionTemplatesViewAll: 'RESTRICTION_TEMPLATES_VIEW_ALL' as AssignAuthorityEnum,
    RiskReportCreate: 'RISK_REPORT_CREATE' as AssignAuthorityEnum,
    RiskReportView: 'RISK_REPORT_VIEW' as AssignAuthorityEnum,
    RolesCreate: 'ROLES_CREATE' as AssignAuthorityEnum,
    RolesDelete: 'ROLES_DELETE' as AssignAuthorityEnum,
    RolesModify: 'ROLES_MODIFY' as AssignAuthorityEnum,
    RolesView: 'ROLES_VIEW' as AssignAuthorityEnum,
    Rts28Report: 'RTS28_REPORT' as AssignAuthorityEnum,
    SaltedgeTransactionsModify: 'SALTEDGE_TRANSACTIONS_MODIFY' as AssignAuthorityEnum,
    SaltedgeTransactionsView: 'SALTEDGE_TRANSACTIONS_VIEW' as AssignAuthorityEnum,
    SendReports: 'SEND_REPORTS' as AssignAuthorityEnum,
    SettingsModify: 'SETTINGS_MODIFY' as AssignAuthorityEnum,
    SettingsView: 'SETTINGS_VIEW' as AssignAuthorityEnum,
    ShopView: 'SHOP_VIEW' as AssignAuthorityEnum,
    SiteInfoCreate: 'SITE_INFO_CREATE' as AssignAuthorityEnum,
    SiteInfoDelete: 'SITE_INFO_DELETE' as AssignAuthorityEnum,
    SiteInfoModify: 'SITE_INFO_MODIFY' as AssignAuthorityEnum,
    SiteInfoView: 'SITE_INFO_VIEW' as AssignAuthorityEnum,
    SovaDataModify: 'SOVA_DATA_MODIFY' as AssignAuthorityEnum,
    SovaDataView: 'SOVA_DATA_VIEW' as AssignAuthorityEnum,
    SovaOrdersModify: 'SOVA_ORDERS_MODIFY' as AssignAuthorityEnum,
    SovaOrdersView: 'SOVA_ORDERS_VIEW' as AssignAuthorityEnum,
    StatementOfEligibleFundsReport: 'STATEMENT_OF_ELIGIBLE_FUNDS_REPORT' as AssignAuthorityEnum,
    StatisticsView: 'STATISTICS_VIEW' as AssignAuthorityEnum,
    StrategiesCreate: 'STRATEGIES_CREATE' as AssignAuthorityEnum,
    StrategiesDelete: 'STRATEGIES_DELETE' as AssignAuthorityEnum,
    StrategiesForceModify: 'STRATEGIES_FORCE_MODIFY' as AssignAuthorityEnum,
    StrategiesModify: 'STRATEGIES_MODIFY' as AssignAuthorityEnum,
    StrategiesModifyAll: 'STRATEGIES_MODIFY_ALL' as AssignAuthorityEnum,
    StrategiesView: 'STRATEGIES_VIEW' as AssignAuthorityEnum,
    StrategiesViewAll: 'STRATEGIES_VIEW_ALL' as AssignAuthorityEnum,
    StructuredProductsCreate: 'STRUCTURED_PRODUCTS_CREATE' as AssignAuthorityEnum,
    StructuredProductsDelete: 'STRUCTURED_PRODUCTS_DELETE' as AssignAuthorityEnum,
    StructuredProductsModify: 'STRUCTURED_PRODUCTS_MODIFY' as AssignAuthorityEnum,
    StructuredProductsModifyAll: 'STRUCTURED_PRODUCTS_MODIFY_ALL' as AssignAuthorityEnum,
    StructuredProductsView: 'STRUCTURED_PRODUCTS_VIEW' as AssignAuthorityEnum,
    StructuredProductsViewAll: 'STRUCTURED_PRODUCTS_VIEW_ALL' as AssignAuthorityEnum,
    SubportfoliosView: 'SUBPORTFOLIOS_VIEW' as AssignAuthorityEnum,
    SubscriptionModify: 'SUBSCRIPTION_MODIFY' as AssignAuthorityEnum,
    SubscriptionView: 'SUBSCRIPTION_VIEW' as AssignAuthorityEnum,
    TableConstructorModify: 'TABLE_CONSTRUCTOR_MODIFY' as AssignAuthorityEnum,
    TableConstructorView: 'TABLE_CONSTRUCTOR_VIEW' as AssignAuthorityEnum,
    TaviraDataModify: 'TAVIRA_DATA_MODIFY' as AssignAuthorityEnum,
    TaviraDataView: 'TAVIRA_DATA_VIEW' as AssignAuthorityEnum,
    TransactionsCreate: 'TRANSACTIONS_CREATE' as AssignAuthorityEnum,
    TransactionsDelete: 'TRANSACTIONS_DELETE' as AssignAuthorityEnum,
    TransactionsForceModify: 'TRANSACTIONS_FORCE_MODIFY' as AssignAuthorityEnum,
    TransactionsMatchingModify: 'TRANSACTIONS_MATCHING_MODIFY' as AssignAuthorityEnum,
    TransactionsMatchingView: 'TRANSACTIONS_MATCHING_VIEW' as AssignAuthorityEnum,
    TransactionsModify: 'TRANSACTIONS_MODIFY' as AssignAuthorityEnum,
    TransactionsModifyAll: 'TRANSACTIONS_MODIFY_ALL' as AssignAuthorityEnum,
    TransactionsStatus: 'TRANSACTIONS_STATUS' as AssignAuthorityEnum,
    TransactionsView: 'TRANSACTIONS_VIEW' as AssignAuthorityEnum,
    TransactionsViewAll: 'TRANSACTIONS_VIEW_ALL' as AssignAuthorityEnum,
    TransactionLoaderCreate: 'TRANSACTION_LOADER_CREATE' as AssignAuthorityEnum,
    TransactionLoaderModify: 'TRANSACTION_LOADER_MODIFY' as AssignAuthorityEnum,
    TransactionLoaderView: 'TRANSACTION_LOADER_VIEW' as AssignAuthorityEnum,
    TransactionWithoutBankView: 'TRANSACTION_WITHOUT_BANK_VIEW' as AssignAuthorityEnum,
    TrimpDataModify: 'TRIMP_DATA_MODIFY' as AssignAuthorityEnum,
    TrimpDataView: 'TRIMP_DATA_VIEW' as AssignAuthorityEnum,
    Ttca: 'TTCA' as AssignAuthorityEnum,
    UbsDataModify: 'UBS_DATA_MODIFY' as AssignAuthorityEnum,
    UbsDataView: 'UBS_DATA_VIEW' as AssignAuthorityEnum,
    ValueDateRulesModify: 'VALUE_DATE_RULES_MODIFY' as AssignAuthorityEnum,
    ValueDateRulesView: 'VALUE_DATE_RULES_VIEW' as AssignAuthorityEnum,
    VpDataModify: 'VP_DATA_MODIFY' as AssignAuthorityEnum,
    VpDataView: 'VP_DATA_VIEW' as AssignAuthorityEnum,
    VpLoaderSettingsCreate: 'VP_LOADER_SETTINGS_CREATE' as AssignAuthorityEnum,
    VpLoaderSettingsDelete: 'VP_LOADER_SETTINGS_DELETE' as AssignAuthorityEnum,
    VpLoaderSettingsModify: 'VP_LOADER_SETTINGS_MODIFY' as AssignAuthorityEnum,
    VpLoaderSettingsView: 'VP_LOADER_SETTINGS_VIEW' as AssignAuthorityEnum,
    VtbDataModify: 'VTB_DATA_MODIFY' as AssignAuthorityEnum,
    VtbDataView: 'VTB_DATA_VIEW' as AssignAuthorityEnum,
    ZohoSettingsCreate: 'ZOHO_SETTINGS_CREATE' as AssignAuthorityEnum,
    ZohoSettingsDelete: 'ZOHO_SETTINGS_DELETE' as AssignAuthorityEnum,
    ZohoSettingsModify: 'ZOHO_SETTINGS_MODIFY' as AssignAuthorityEnum,
    ZohoSettingsView: 'ZOHO_SETTINGS_VIEW' as AssignAuthorityEnum,
  };
  export type CreateAuthorityEnum =
    | 'ACCOUNTS_CREATE'
    | 'ACCOUNTS_DELETE'
    | 'ACCOUNTS_FORCE_MODIFY'
    | 'ACCOUNTS_MODIFY'
    | 'ACCOUNTS_MODIFY_ALL'
    | 'ACCOUNTS_VIEW'
    | 'ACCOUNTS_VIEW_ALL'
    | 'ACCOUNT_STRUCTURE'
    | 'ACTUATOR_VIEW'
    | 'ADVANCED_ANALYTICS_VIEW'
    | 'ALFA_DATA_MODIFY'
    | 'ALFA_DATA_VIEW'
    | 'AMAZON_WORK_DOCS'
    | 'BANK_ACCOUNTS_CREATE'
    | 'BANK_ACCOUNTS_DELETE'
    | 'BANK_ACCOUNTS_FORCE_MODIFY'
    | 'BANK_ACCOUNTS_MODIFY'
    | 'BANK_ACCOUNTS_MODIFY_ALL'
    | 'BANK_ACCOUNTS_VIEW'
    | 'BANK_ACCOUNTS_VIEW_ALL'
    | 'BANK_STATS_VIEW'
    | 'BKS_DATA_MODIFY'
    | 'BKS_DATA_VIEW'
    | 'BKS_LOADER_SETTINGS_CREATE'
    | 'BKS_LOADER_SETTINGS_DELETE'
    | 'BKS_LOADER_SETTINGS_MODIFY'
    | 'BKS_LOADER_SETTINGS_VIEW'
    | 'BLOOMBERG_REQUESTS_VIEW'
    | 'BROKER_CREATE'
    | 'BROKER_DELETE'
    | 'BROKER_MODIFY'
    | 'BROKER_VIEW'
    | 'BUSINESS_EVENTS_VIEW'
    | 'CASS_REPORT'
    | 'CBH_BS_DATA_MODIFY'
    | 'CBH_BS_DATA_VIEW'
    | 'CBH_DATA_MODIFY'
    | 'CBH_DATA_VIEW'
    | 'CBH_LOADER_SETTINGS_CREATE'
    | 'CBH_LOADER_SETTINGS_DELETE'
    | 'CBH_LOADER_SETTINGS_MODIFY'
    | 'CBH_LOADER_SETTINGS_VIEW'
    | 'CHANGE_REQUEST_SETTINGS_CREATE'
    | 'CHANGE_REQUEST_SETTINGS_DELETE'
    | 'CHANGE_REQUEST_SETTINGS_MODIFY'
    | 'CHANGE_REQUEST_SETTINGS_VIEW'
    | 'CLIENTS_CREATE'
    | 'CLIENTS_DELETE'
    | 'CLIENTS_MODIFY'
    | 'CLIENTS_MODIFY_ALL'
    | 'CLIENTS_VIEW'
    | 'CLIENTS_VIEW_ALL'
    | 'CLIENT_COUNT_REPORT'
    | 'CLIENT_FEES_REPORT'
    | 'CLIENT_VALUATION_REPORT'
    | 'CMAR_REPORT'
    | 'COMMON_EMAIL_TEMPLATES_CREATE'
    | 'COMMON_EMAIL_TEMPLATES_DELETE'
    | 'COMMON_EMAIL_TEMPLATES_MODIFY'
    | 'COMMON_EMAIL_TEMPLATES_VIEW'
    | 'COMPANIES_CREATE'
    | 'COMPANIES_DELETE'
    | 'COMPANIES_MODIFY'
    | 'COMPANIES_TAGS_VIEW'
    | 'COMPANIES_VIEW'
    | 'COMPANY_HOLDING_CREATE'
    | 'COMPANY_HOLDING_DELETE'
    | 'COMPANY_HOLDING_MODIFY'
    | 'COMPANY_HOLDING_VIEW'
    | 'COMPANY_RISK_REPORT'
    | 'COMPLIANCE_ACCOUNT_COMPANY_CREATE'
    | 'COMPLIANCE_ACCOUNT_COMPANY_DELETE'
    | 'COMPLIANCE_ACCOUNT_COMPANY_FORCE_MODIFY'
    | 'COMPLIANCE_ACCOUNT_COMPANY_MODIFY'
    | 'COMPLIANCE_ACCOUNT_COMPANY_VIEW'
    | 'COUNTERPARTY_CREATE'
    | 'COUNTERPARTY_DELETE'
    | 'COUNTERPARTY_MODIFY'
    | 'COUNTERPARTY_VIEW'
    | 'COUPON_PROJECTION_REPORT'
    | 'CRS_REPORT'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_CREATE'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_MODIFY'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_VIEW'
    | 'CUSTODIAN_CREATE'
    | 'CUSTODIAN_DELETE'
    | 'CUSTODIAN_MODIFY'
    | 'CUSTODIAN_VIEW'
    | 'DASHBOARD_MODIFY'
    | 'DASHBOARD_VIEW'
    | 'DBS_DATA_MODIFY'
    | 'DBS_DATA_VIEW'
    | 'DEACTIVATED_TRANSACTION_VIEW'
    | 'DEPARTMENTS_CREATE'
    | 'DEPARTMENTS_DELETE'
    | 'DEPARTMENTS_MODIFY'
    | 'DEPARTMENTS_VIEW'
    | 'DOLFIN_TRANSACTIONS_MODIFY'
    | 'DOLFIN_TRANSACTIONS_VIEW'
    | 'EDR_DATA_MODIFY'
    | 'EDR_DATA_VIEW'
    | 'EDR_LOADER_SETTINGS_CREATE'
    | 'EDR_LOADER_SETTINGS_DELETE'
    | 'EDR_LOADER_SETTINGS_MODIFY'
    | 'EDR_LOADER_SETTINGS_VIEW'
    | 'EMAIL_TEMPLATE_CREATE'
    | 'EMAIL_TEMPLATE_DELETE'
    | 'EMAIL_TEMPLATE_MODIFY'
    | 'EMAIL_TEMPLATE_VIEW'
    | 'EXECGW_REPORTS_VIEW'
    | 'FEES_CREATE'
    | 'FEES_MODIFY'
    | 'FEES_VIEW'
    | 'FEES_VIEW_ALL'
    | 'FIELDS_CREATE'
    | 'FIELDS_DELETE'
    | 'FIELDS_MODIFY'
    | 'FIELDS_VIEW'
    | 'FILE_USER_LOG_VIEW'
    | 'GAP_MONITOR_VIEW'
    | 'GLOBAL_ALLOCATION_CREATE'
    | 'GLOBAL_ALLOCATION_MODIFY'
    | 'GLOBAL_ALLOCATION_VIEW'
    | 'GLOBAL_ALLOCATION_WIDGET_VIEW'
    | 'GOOGLE_AUTH_MODIFY'
    | 'GOOGLE_AUTH_VIEW'
    | 'GPP_DATA_MODIFY'
    | 'GPP_DATA_VIEW'
    | 'GPP_LOADER_SETTINGS_CREATE'
    | 'GPP_LOADER_SETTINGS_DELETE'
    | 'GPP_LOADER_SETTINGS_MODIFY'
    | 'GPP_LOADER_SETTINGS_VIEW'
    | 'HEALTH_VIEW'
    | 'HISTORY_VIEW'
    | 'IBKR_DATA_MODIFY'
    | 'IBKR_DATA_VIEW'
    | 'IBKR_FUTURES_MAPPING_CREATE'
    | 'IBKR_FUTURES_MAPPING_DELETE'
    | 'IBKR_FUTURES_MAPPING_MODIFY'
    | 'IBKR_FUTURES_MAPPING_VIEW'
    | 'IBKR_LOADER_SETTINGS_CREATE'
    | 'IBKR_LOADER_SETTINGS_DELETE'
    | 'IBKR_LOADER_SETTINGS_MODIFY'
    | 'IBKR_LOADER_SETTINGS_VIEW'
    | 'INCOME_PAYMENT_TRACKER'
    | 'INSTRUMENTS_CREATE'
    | 'INSTRUMENTS_MODIFY'
    | 'INSTRUMENTS_VIEW'
    | 'INSTRUMENT_TO_AGENT_VIEW'
    | 'INVESTEC_DATA_MODIFY'
    | 'INVESTEC_DATA_VIEW'
    | 'INVESTEC_LOADER_SETTINGS_CREATE'
    | 'INVESTEC_LOADER_SETTINGS_DELETE'
    | 'INVESTEC_LOADER_SETTINGS_MODIFY'
    | 'INVESTEC_LOADER_SETTINGS_VIEW'
    | 'INVESTMENT_VIEW'
    | 'IP_ACCESS'
    | 'JB_DATA_MODIFY'
    | 'JB_DATA_VIEW'
    | 'JB_LOADER_SETTINGS_CREATE'
    | 'JB_LOADER_SETTINGS_DELETE'
    | 'JB_LOADER_SETTINGS_MODIFY'
    | 'JB_LOADER_SETTINGS_VIEW'
    | 'JPM_DATA_MODIFY'
    | 'JPM_DATA_VIEW'
    | 'JPM_LOADER_SETTINGS_CREATE'
    | 'JPM_LOADER_SETTINGS_DELETE'
    | 'JPM_LOADER_SETTINGS_MODIFY'
    | 'JPM_LOADER_SETTINGS_VIEW'
    | 'LEAD_CREATE'
    | 'LEAD_DELETE'
    | 'LEAD_MODIFY'
    | 'LEAD_MODIFY_ALL'
    | 'LEAD_VIEW'
    | 'LEAD_VIEW_ALL'
    | 'LOGOTYPE_DELETE'
    | 'LOGOTYPE_MODIFY'
    | 'LOGOTYPE_VIEW'
    | 'MANAGERS_CREATE'
    | 'MANAGERS_DELETE'
    | 'MANAGERS_FORCE_MODIFY'
    | 'MANAGERS_MODIFY'
    | 'MANAGERS_VIEW'
    | 'MANDATES_CREATE'
    | 'MANDATES_DELETE'
    | 'MANDATES_FORCE_MODIFY'
    | 'MANDATES_MODIFY'
    | 'MANDATES_MODIFY_ALL'
    | 'MANDATES_VIEW'
    | 'MANDATES_VIEW_ALL'
    | 'MANDATE_RESTRICTIONS_CREATE'
    | 'MANDATE_RESTRICTIONS_DELETE'
    | 'MANDATE_RESTRICTIONS_MODIFY'
    | 'MANDATE_RESTRICTIONS_MODIFY_ALL'
    | 'MANDATE_RESTRICTIONS_VIEW'
    | 'MANDATE_RESTRICTIONS_VIEW_ALL'
    | 'MANDATE_RESTRICTION_REPORTS_CREATE'
    | 'MANDATE_RESTRICTION_REPORTS_DELETE'
    | 'MANDATE_RESTRICTION_REPORTS_MODIFY'
    | 'MANDATE_RESTRICTION_REPORTS_MODIFY_ALL'
    | 'MANDATE_RESTRICTION_REPORTS_VIEW'
    | 'MANDATE_RESTRICTION_REPORTS_VIEW_ALL'
    | 'MANDATE_RESTRICTION_TEMPLATES_CREATE'
    | 'MANDATE_RESTRICTION_TEMPLATES_DELETE'
    | 'MANDATE_RESTRICTION_TEMPLATES_MODIFY'
    | 'MANDATE_RESTRICTION_TEMPLATES_MODIFY_ALL'
    | 'MANDATE_RESTRICTION_TEMPLATES_VIEW'
    | 'MANDATE_RESTRICTION_TEMPLATES_VIEW_ALL'
    | 'MANDATE_RISK_PROFILE_CREATE'
    | 'MANDATE_RISK_PROFILE_DELETE'
    | 'MANDATE_RISK_PROFILE_FORCE_MODIFY'
    | 'MANDATE_RISK_PROFILE_MODIFY'
    | 'MANDATE_RISK_PROFILE_VIEW'
    | 'MANDATE_RISK_PROFILE_VIEW_ALL'
    | 'MANUAL_IMPORT_POSITIONS_CREATE'
    | 'MANUAL_IMPORT_POSITIONS_MODIFY'
    | 'MANUAL_IMPORT_POSITIONS_VIEW'
    | 'MANUAL_IMPORT_TRANSACTIONS_CREATE'
    | 'MANUAL_IMPORT_TRANSACTIONS_MODIFY'
    | 'MANUAL_IMPORT_TRANSACTIONS_VIEW'
    | 'MARKETPLACE_MODIFY'
    | 'MARKETPLACE_VIEW'
    | 'MARKET_SECURITIES_MODIFY'
    | 'MARKET_SECURITIES_VIEW'
    | 'MIFIR_MODIFY'
    | 'MIFIR_REPORT'
    | 'MIFIR_VIEW'
    | 'MODEL_PORTFOLIOS_CREATE'
    | 'MODEL_PORTFOLIOS_DELETE'
    | 'MODEL_PORTFOLIOS_MODIFY'
    | 'MODEL_PORTFOLIOS_VIEW'
    | 'MONTHLY_RECONCILIATION_REPORT'
    | 'NOTIFICATION_TEMPLATES_CREATE'
    | 'NOTIFICATION_TEMPLATES_DELETE'
    | 'NOTIFICATION_TEMPLATES_MODIFY'
    | 'NOTIFICATION_TEMPLATES_VIEW'
    | 'NOTIFICATION_USER_LOG_VIEW'
    | 'OMNIBUS'
    | 'ONLINE_PRICING_VIEW'
    | 'OPPORTUNITY_CREATE'
    | 'OPPORTUNITY_DELETE'
    | 'OPPORTUNITY_FORCE_MODIFY'
    | 'OPPORTUNITY_MODIFY'
    | 'OPPORTUNITY_MODIFY_ALL'
    | 'OPPORTUNITY_VIEW'
    | 'OPPORTUNITY_VIEW_ALL'
    | 'ORDERS_ASSIGN'
    | 'ORDERS_COMMON_CREATE'
    | 'ORDERS_COMMON_DELETE'
    | 'ORDERS_COMMON_MODIFY'
    | 'ORDERS_COMMON_VIEW'
    | 'ORDERS_CORP_ACTION_CREATE'
    | 'ORDERS_CORP_ACTION_DELETE'
    | 'ORDERS_CORP_ACTION_MODIFY'
    | 'ORDERS_CORP_ACTION_VIEW'
    | 'ORDERS_CREATE'
    | 'ORDERS_DELETE'
    | 'ORDERS_DMA_CREATE'
    | 'ORDERS_FORCE_MODIFY'
    | 'ORDERS_MODIFY'
    | 'ORDERS_MODIFY_ALL'
    | 'ORDERS_NON_TRADE_CREATE'
    | 'ORDERS_NON_TRADE_DELETE'
    | 'ORDERS_NON_TRADE_MODIFY'
    | 'ORDERS_NON_TRADE_VIEW'
    | 'ORDERS_TECH_INFO_MODIFY'
    | 'ORDERS_TECH_INFO_VIEW'
    | 'ORDERS_TRADE_CREATE'
    | 'ORDERS_TRADE_DELETE'
    | 'ORDERS_TRADE_MODIFY'
    | 'ORDERS_TRADE_VIEW'
    | 'ORDERS_VIEW'
    | 'ORDERS_VIEW_ALL'
    | 'PERSONAL_AM'
    | 'PNL_REPORT'
    | 'PORTFOLIOS_CREATE'
    | 'PORTFOLIOS_DELETE'
    | 'PORTFOLIOS_FORCE_MODIFY'
    | 'PORTFOLIOS_MODIFY'
    | 'PORTFOLIOS_MODIFY_ALL'
    | 'PORTFOLIOS_VIEW'
    | 'PORTFOLIOS_VIEW_ALL'
    | 'PORTFOLIO_CONFIGURATION_VIEW'
    | 'PORTFOLIO_COSTS_VIEW'
    | 'PORTFOLIO_FEES_VIEW'
    | 'PORTFOLIO_FLOWS_VIEW'
    | 'PORTFOLIO_ORDERS_ASSIGN'
    | 'PORTFOLIO_ORDERS_CREATE'
    | 'PORTFOLIO_ORDERS_DELETE'
    | 'PORTFOLIO_ORDERS_MODIFY'
    | 'PORTFOLIO_ORDERS_VIEW'
    | 'PORTFOLIO_PNL_VIEW'
    | 'PORTFOLIO_POSITIONS_VIEW'
    | 'PORTFOLIO_PROFILE_MODIFY'
    | 'PORTFOLIO_PROFILE_VIEW'
    | 'PORTFOLIO_RESTRICTIONS_VIEW'
    | 'PORTFOLIO_TRANSACTIONS_CREATE'
    | 'PORTFOLIO_TRANSACTIONS_DELETE'
    | 'PORTFOLIO_TRANSACTIONS_MODIFY'
    | 'PORTFOLIO_TRANSACTIONS_STATUS'
    | 'PORTFOLIO_TRANSACTIONS_VIEW'
    | 'PORTFOLIO_VALUATION_VIEW'
    | 'POSITIONS_VIEW'
    | 'PRICES_LOADING_MONITOR_VIEW'
    | 'PRIVATE_INSTRUMENT_CREATE'
    | 'PRIVATE_INSTRUMENT_DELETE'
    | 'PRIVATE_INSTRUMENT_MODIFY'
    | 'PRIVATE_INSTRUMENT_MODIFY_ALL'
    | 'PRIVATE_INSTRUMENT_VIEW'
    | 'PRIVATE_INSTRUMENT_VIEW_ALL'
    | 'PROCESS_CREATE'
    | 'PROCESS_DELETE'
    | 'PROCESS_MODIFY'
    | 'PROCESS_VIEW'
    | 'PRODUCT_GOVERNANCE_MODIFY'
    | 'PRODUCT_GOVERNANCE_VIEW'
    | 'PROFILES_CREATE'
    | 'PROFILES_DELETE'
    | 'PROFILES_MODIFY'
    | 'PROFILES_VIEW'
    | 'QUARTERLY_STATISTICS_REPORT'
    | 'RBS_F_REPORT'
    | 'RECOMMENDATION_CREATE'
    | 'RECOMMENDATION_FORCE_MODIFY'
    | 'RECOMMENDATION_MODIFY'
    | 'RECOMMENDATION_VIEW'
    | 'RECOMMENDATION_VIEW_ALL'
    | 'RECONCILIATION_REPORT'
    | 'REGULATOR_CREATE'
    | 'REGULATOR_DELETE'
    | 'REGULATOR_MODIFY'
    | 'REGULATOR_VIEW'
    | 'RELUNA_ORDERS_REPORTS_VIEW'
    | 'REPORTS_CREATE'
    | 'REPORTS_DELETE'
    | 'REPORTS_MODIFY'
    | 'REPORTS_VIEW'
    | 'RESTRICTIONS_CREATE'
    | 'RESTRICTIONS_DELETE'
    | 'RESTRICTIONS_MODIFY'
    | 'RESTRICTIONS_MODIFY_ALL'
    | 'RESTRICTIONS_VIEW'
    | 'RESTRICTIONS_VIEW_ALL'
    | 'RESTRICTION_ALERT_VIEW'
    | 'RESTRICTION_REPORTS_CREATE'
    | 'RESTRICTION_REPORTS_DELETE'
    | 'RESTRICTION_REPORTS_MODIFY'
    | 'RESTRICTION_REPORTS_MODIFY_ALL'
    | 'RESTRICTION_REPORTS_VIEW'
    | 'RESTRICTION_REPORTS_VIEW_ALL'
    | 'RESTRICTION_TEMPLATES_CREATE'
    | 'RESTRICTION_TEMPLATES_DELETE'
    | 'RESTRICTION_TEMPLATES_MODIFY'
    | 'RESTRICTION_TEMPLATES_MODIFY_ALL'
    | 'RESTRICTION_TEMPLATES_VIEW'
    | 'RESTRICTION_TEMPLATES_VIEW_ALL'
    | 'RISK_REPORT_CREATE'
    | 'RISK_REPORT_VIEW'
    | 'ROLES_CREATE'
    | 'ROLES_DELETE'
    | 'ROLES_MODIFY'
    | 'ROLES_VIEW'
    | 'RTS28_REPORT'
    | 'SALTEDGE_TRANSACTIONS_MODIFY'
    | 'SALTEDGE_TRANSACTIONS_VIEW'
    | 'SEND_REPORTS'
    | 'SETTINGS_MODIFY'
    | 'SETTINGS_VIEW'
    | 'SHOP_VIEW'
    | 'SITE_INFO_CREATE'
    | 'SITE_INFO_DELETE'
    | 'SITE_INFO_MODIFY'
    | 'SITE_INFO_VIEW'
    | 'SOVA_DATA_MODIFY'
    | 'SOVA_DATA_VIEW'
    | 'SOVA_ORDERS_MODIFY'
    | 'SOVA_ORDERS_VIEW'
    | 'STATEMENT_OF_ELIGIBLE_FUNDS_REPORT'
    | 'STATISTICS_VIEW'
    | 'STRATEGIES_CREATE'
    | 'STRATEGIES_DELETE'
    | 'STRATEGIES_FORCE_MODIFY'
    | 'STRATEGIES_MODIFY'
    | 'STRATEGIES_MODIFY_ALL'
    | 'STRATEGIES_VIEW'
    | 'STRATEGIES_VIEW_ALL'
    | 'STRUCTURED_PRODUCTS_CREATE'
    | 'STRUCTURED_PRODUCTS_DELETE'
    | 'STRUCTURED_PRODUCTS_MODIFY'
    | 'STRUCTURED_PRODUCTS_MODIFY_ALL'
    | 'STRUCTURED_PRODUCTS_VIEW'
    | 'STRUCTURED_PRODUCTS_VIEW_ALL'
    | 'SUBPORTFOLIOS_VIEW'
    | 'SUBSCRIPTION_MODIFY'
    | 'SUBSCRIPTION_VIEW'
    | 'TABLE_CONSTRUCTOR_MODIFY'
    | 'TABLE_CONSTRUCTOR_VIEW'
    | 'TAVIRA_DATA_MODIFY'
    | 'TAVIRA_DATA_VIEW'
    | 'TRANSACTIONS_CREATE'
    | 'TRANSACTIONS_DELETE'
    | 'TRANSACTIONS_FORCE_MODIFY'
    | 'TRANSACTIONS_MATCHING_MODIFY'
    | 'TRANSACTIONS_MATCHING_VIEW'
    | 'TRANSACTIONS_MODIFY'
    | 'TRANSACTIONS_MODIFY_ALL'
    | 'TRANSACTIONS_STATUS'
    | 'TRANSACTIONS_VIEW'
    | 'TRANSACTIONS_VIEW_ALL'
    | 'TRANSACTION_LOADER_CREATE'
    | 'TRANSACTION_LOADER_MODIFY'
    | 'TRANSACTION_LOADER_VIEW'
    | 'TRANSACTION_WITHOUT_BANK_VIEW'
    | 'TRIMP_DATA_MODIFY'
    | 'TRIMP_DATA_VIEW'
    | 'TTCA'
    | 'UBS_DATA_MODIFY'
    | 'UBS_DATA_VIEW'
    | 'VALUE_DATE_RULES_MODIFY'
    | 'VALUE_DATE_RULES_VIEW'
    | 'VP_DATA_MODIFY'
    | 'VP_DATA_VIEW'
    | 'VP_LOADER_SETTINGS_CREATE'
    | 'VP_LOADER_SETTINGS_DELETE'
    | 'VP_LOADER_SETTINGS_MODIFY'
    | 'VP_LOADER_SETTINGS_VIEW'
    | 'VTB_DATA_MODIFY'
    | 'VTB_DATA_VIEW'
    | 'ZOHO_SETTINGS_CREATE'
    | 'ZOHO_SETTINGS_DELETE'
    | 'ZOHO_SETTINGS_MODIFY'
    | 'ZOHO_SETTINGS_VIEW';
  export const CreateAuthorityEnum = {
    AccountsCreate: 'ACCOUNTS_CREATE' as CreateAuthorityEnum,
    AccountsDelete: 'ACCOUNTS_DELETE' as CreateAuthorityEnum,
    AccountsForceModify: 'ACCOUNTS_FORCE_MODIFY' as CreateAuthorityEnum,
    AccountsModify: 'ACCOUNTS_MODIFY' as CreateAuthorityEnum,
    AccountsModifyAll: 'ACCOUNTS_MODIFY_ALL' as CreateAuthorityEnum,
    AccountsView: 'ACCOUNTS_VIEW' as CreateAuthorityEnum,
    AccountsViewAll: 'ACCOUNTS_VIEW_ALL' as CreateAuthorityEnum,
    AccountStructure: 'ACCOUNT_STRUCTURE' as CreateAuthorityEnum,
    ActuatorView: 'ACTUATOR_VIEW' as CreateAuthorityEnum,
    AdvancedAnalyticsView: 'ADVANCED_ANALYTICS_VIEW' as CreateAuthorityEnum,
    AlfaDataModify: 'ALFA_DATA_MODIFY' as CreateAuthorityEnum,
    AlfaDataView: 'ALFA_DATA_VIEW' as CreateAuthorityEnum,
    AmazonWorkDocs: 'AMAZON_WORK_DOCS' as CreateAuthorityEnum,
    BankAccountsCreate: 'BANK_ACCOUNTS_CREATE' as CreateAuthorityEnum,
    BankAccountsDelete: 'BANK_ACCOUNTS_DELETE' as CreateAuthorityEnum,
    BankAccountsForceModify: 'BANK_ACCOUNTS_FORCE_MODIFY' as CreateAuthorityEnum,
    BankAccountsModify: 'BANK_ACCOUNTS_MODIFY' as CreateAuthorityEnum,
    BankAccountsModifyAll: 'BANK_ACCOUNTS_MODIFY_ALL' as CreateAuthorityEnum,
    BankAccountsView: 'BANK_ACCOUNTS_VIEW' as CreateAuthorityEnum,
    BankAccountsViewAll: 'BANK_ACCOUNTS_VIEW_ALL' as CreateAuthorityEnum,
    BankStatsView: 'BANK_STATS_VIEW' as CreateAuthorityEnum,
    BksDataModify: 'BKS_DATA_MODIFY' as CreateAuthorityEnum,
    BksDataView: 'BKS_DATA_VIEW' as CreateAuthorityEnum,
    BksLoaderSettingsCreate: 'BKS_LOADER_SETTINGS_CREATE' as CreateAuthorityEnum,
    BksLoaderSettingsDelete: 'BKS_LOADER_SETTINGS_DELETE' as CreateAuthorityEnum,
    BksLoaderSettingsModify: 'BKS_LOADER_SETTINGS_MODIFY' as CreateAuthorityEnum,
    BksLoaderSettingsView: 'BKS_LOADER_SETTINGS_VIEW' as CreateAuthorityEnum,
    BloombergRequestsView: 'BLOOMBERG_REQUESTS_VIEW' as CreateAuthorityEnum,
    BrokerCreate: 'BROKER_CREATE' as CreateAuthorityEnum,
    BrokerDelete: 'BROKER_DELETE' as CreateAuthorityEnum,
    BrokerModify: 'BROKER_MODIFY' as CreateAuthorityEnum,
    BrokerView: 'BROKER_VIEW' as CreateAuthorityEnum,
    BusinessEventsView: 'BUSINESS_EVENTS_VIEW' as CreateAuthorityEnum,
    CassReport: 'CASS_REPORT' as CreateAuthorityEnum,
    CbhBsDataModify: 'CBH_BS_DATA_MODIFY' as CreateAuthorityEnum,
    CbhBsDataView: 'CBH_BS_DATA_VIEW' as CreateAuthorityEnum,
    CbhDataModify: 'CBH_DATA_MODIFY' as CreateAuthorityEnum,
    CbhDataView: 'CBH_DATA_VIEW' as CreateAuthorityEnum,
    CbhLoaderSettingsCreate: 'CBH_LOADER_SETTINGS_CREATE' as CreateAuthorityEnum,
    CbhLoaderSettingsDelete: 'CBH_LOADER_SETTINGS_DELETE' as CreateAuthorityEnum,
    CbhLoaderSettingsModify: 'CBH_LOADER_SETTINGS_MODIFY' as CreateAuthorityEnum,
    CbhLoaderSettingsView: 'CBH_LOADER_SETTINGS_VIEW' as CreateAuthorityEnum,
    ChangeRequestSettingsCreate: 'CHANGE_REQUEST_SETTINGS_CREATE' as CreateAuthorityEnum,
    ChangeRequestSettingsDelete: 'CHANGE_REQUEST_SETTINGS_DELETE' as CreateAuthorityEnum,
    ChangeRequestSettingsModify: 'CHANGE_REQUEST_SETTINGS_MODIFY' as CreateAuthorityEnum,
    ChangeRequestSettingsView: 'CHANGE_REQUEST_SETTINGS_VIEW' as CreateAuthorityEnum,
    ClientsCreate: 'CLIENTS_CREATE' as CreateAuthorityEnum,
    ClientsDelete: 'CLIENTS_DELETE' as CreateAuthorityEnum,
    ClientsModify: 'CLIENTS_MODIFY' as CreateAuthorityEnum,
    ClientsModifyAll: 'CLIENTS_MODIFY_ALL' as CreateAuthorityEnum,
    ClientsView: 'CLIENTS_VIEW' as CreateAuthorityEnum,
    ClientsViewAll: 'CLIENTS_VIEW_ALL' as CreateAuthorityEnum,
    ClientCountReport: 'CLIENT_COUNT_REPORT' as CreateAuthorityEnum,
    ClientFeesReport: 'CLIENT_FEES_REPORT' as CreateAuthorityEnum,
    ClientValuationReport: 'CLIENT_VALUATION_REPORT' as CreateAuthorityEnum,
    CmarReport: 'CMAR_REPORT' as CreateAuthorityEnum,
    CommonEmailTemplatesCreate: 'COMMON_EMAIL_TEMPLATES_CREATE' as CreateAuthorityEnum,
    CommonEmailTemplatesDelete: 'COMMON_EMAIL_TEMPLATES_DELETE' as CreateAuthorityEnum,
    CommonEmailTemplatesModify: 'COMMON_EMAIL_TEMPLATES_MODIFY' as CreateAuthorityEnum,
    CommonEmailTemplatesView: 'COMMON_EMAIL_TEMPLATES_VIEW' as CreateAuthorityEnum,
    CompaniesCreate: 'COMPANIES_CREATE' as CreateAuthorityEnum,
    CompaniesDelete: 'COMPANIES_DELETE' as CreateAuthorityEnum,
    CompaniesModify: 'COMPANIES_MODIFY' as CreateAuthorityEnum,
    CompaniesTagsView: 'COMPANIES_TAGS_VIEW' as CreateAuthorityEnum,
    CompaniesView: 'COMPANIES_VIEW' as CreateAuthorityEnum,
    CompanyHoldingCreate: 'COMPANY_HOLDING_CREATE' as CreateAuthorityEnum,
    CompanyHoldingDelete: 'COMPANY_HOLDING_DELETE' as CreateAuthorityEnum,
    CompanyHoldingModify: 'COMPANY_HOLDING_MODIFY' as CreateAuthorityEnum,
    CompanyHoldingView: 'COMPANY_HOLDING_VIEW' as CreateAuthorityEnum,
    CompanyRiskReport: 'COMPANY_RISK_REPORT' as CreateAuthorityEnum,
    ComplianceAccountCompanyCreate: 'COMPLIANCE_ACCOUNT_COMPANY_CREATE' as CreateAuthorityEnum,
    ComplianceAccountCompanyDelete: 'COMPLIANCE_ACCOUNT_COMPANY_DELETE' as CreateAuthorityEnum,
    ComplianceAccountCompanyForceModify:
      'COMPLIANCE_ACCOUNT_COMPANY_FORCE_MODIFY' as CreateAuthorityEnum,
    ComplianceAccountCompanyModify: 'COMPLIANCE_ACCOUNT_COMPANY_MODIFY' as CreateAuthorityEnum,
    ComplianceAccountCompanyView: 'COMPLIANCE_ACCOUNT_COMPANY_VIEW' as CreateAuthorityEnum,
    CounterpartyCreate: 'COUNTERPARTY_CREATE' as CreateAuthorityEnum,
    CounterpartyDelete: 'COUNTERPARTY_DELETE' as CreateAuthorityEnum,
    CounterpartyModify: 'COUNTERPARTY_MODIFY' as CreateAuthorityEnum,
    CounterpartyView: 'COUNTERPARTY_VIEW' as CreateAuthorityEnum,
    CouponProjectionReport: 'COUPON_PROJECTION_REPORT' as CreateAuthorityEnum,
    CrsReport: 'CRS_REPORT' as CreateAuthorityEnum,
    CustodianAssetTypeMappingCreate: 'CUSTODIAN_ASSET_TYPE_MAPPING_CREATE' as CreateAuthorityEnum,
    CustodianAssetTypeMappingModify: 'CUSTODIAN_ASSET_TYPE_MAPPING_MODIFY' as CreateAuthorityEnum,
    CustodianAssetTypeMappingView: 'CUSTODIAN_ASSET_TYPE_MAPPING_VIEW' as CreateAuthorityEnum,
    CustodianCreate: 'CUSTODIAN_CREATE' as CreateAuthorityEnum,
    CustodianDelete: 'CUSTODIAN_DELETE' as CreateAuthorityEnum,
    CustodianModify: 'CUSTODIAN_MODIFY' as CreateAuthorityEnum,
    CustodianView: 'CUSTODIAN_VIEW' as CreateAuthorityEnum,
    DashboardModify: 'DASHBOARD_MODIFY' as CreateAuthorityEnum,
    DashboardView: 'DASHBOARD_VIEW' as CreateAuthorityEnum,
    DbsDataModify: 'DBS_DATA_MODIFY' as CreateAuthorityEnum,
    DbsDataView: 'DBS_DATA_VIEW' as CreateAuthorityEnum,
    DeactivatedTransactionView: 'DEACTIVATED_TRANSACTION_VIEW' as CreateAuthorityEnum,
    DepartmentsCreate: 'DEPARTMENTS_CREATE' as CreateAuthorityEnum,
    DepartmentsDelete: 'DEPARTMENTS_DELETE' as CreateAuthorityEnum,
    DepartmentsModify: 'DEPARTMENTS_MODIFY' as CreateAuthorityEnum,
    DepartmentsView: 'DEPARTMENTS_VIEW' as CreateAuthorityEnum,
    DolfinTransactionsModify: 'DOLFIN_TRANSACTIONS_MODIFY' as CreateAuthorityEnum,
    DolfinTransactionsView: 'DOLFIN_TRANSACTIONS_VIEW' as CreateAuthorityEnum,
    EdrDataModify: 'EDR_DATA_MODIFY' as CreateAuthorityEnum,
    EdrDataView: 'EDR_DATA_VIEW' as CreateAuthorityEnum,
    EdrLoaderSettingsCreate: 'EDR_LOADER_SETTINGS_CREATE' as CreateAuthorityEnum,
    EdrLoaderSettingsDelete: 'EDR_LOADER_SETTINGS_DELETE' as CreateAuthorityEnum,
    EdrLoaderSettingsModify: 'EDR_LOADER_SETTINGS_MODIFY' as CreateAuthorityEnum,
    EdrLoaderSettingsView: 'EDR_LOADER_SETTINGS_VIEW' as CreateAuthorityEnum,
    EmailTemplateCreate: 'EMAIL_TEMPLATE_CREATE' as CreateAuthorityEnum,
    EmailTemplateDelete: 'EMAIL_TEMPLATE_DELETE' as CreateAuthorityEnum,
    EmailTemplateModify: 'EMAIL_TEMPLATE_MODIFY' as CreateAuthorityEnum,
    EmailTemplateView: 'EMAIL_TEMPLATE_VIEW' as CreateAuthorityEnum,
    ExecgwReportsView: 'EXECGW_REPORTS_VIEW' as CreateAuthorityEnum,
    FeesCreate: 'FEES_CREATE' as CreateAuthorityEnum,
    FeesModify: 'FEES_MODIFY' as CreateAuthorityEnum,
    FeesView: 'FEES_VIEW' as CreateAuthorityEnum,
    FeesViewAll: 'FEES_VIEW_ALL' as CreateAuthorityEnum,
    FieldsCreate: 'FIELDS_CREATE' as CreateAuthorityEnum,
    FieldsDelete: 'FIELDS_DELETE' as CreateAuthorityEnum,
    FieldsModify: 'FIELDS_MODIFY' as CreateAuthorityEnum,
    FieldsView: 'FIELDS_VIEW' as CreateAuthorityEnum,
    FileUserLogView: 'FILE_USER_LOG_VIEW' as CreateAuthorityEnum,
    GapMonitorView: 'GAP_MONITOR_VIEW' as CreateAuthorityEnum,
    GlobalAllocationCreate: 'GLOBAL_ALLOCATION_CREATE' as CreateAuthorityEnum,
    GlobalAllocationModify: 'GLOBAL_ALLOCATION_MODIFY' as CreateAuthorityEnum,
    GlobalAllocationView: 'GLOBAL_ALLOCATION_VIEW' as CreateAuthorityEnum,
    GlobalAllocationWidgetView: 'GLOBAL_ALLOCATION_WIDGET_VIEW' as CreateAuthorityEnum,
    GoogleAuthModify: 'GOOGLE_AUTH_MODIFY' as CreateAuthorityEnum,
    GoogleAuthView: 'GOOGLE_AUTH_VIEW' as CreateAuthorityEnum,
    GppDataModify: 'GPP_DATA_MODIFY' as CreateAuthorityEnum,
    GppDataView: 'GPP_DATA_VIEW' as CreateAuthorityEnum,
    GppLoaderSettingsCreate: 'GPP_LOADER_SETTINGS_CREATE' as CreateAuthorityEnum,
    GppLoaderSettingsDelete: 'GPP_LOADER_SETTINGS_DELETE' as CreateAuthorityEnum,
    GppLoaderSettingsModify: 'GPP_LOADER_SETTINGS_MODIFY' as CreateAuthorityEnum,
    GppLoaderSettingsView: 'GPP_LOADER_SETTINGS_VIEW' as CreateAuthorityEnum,
    HealthView: 'HEALTH_VIEW' as CreateAuthorityEnum,
    HistoryView: 'HISTORY_VIEW' as CreateAuthorityEnum,
    IbkrDataModify: 'IBKR_DATA_MODIFY' as CreateAuthorityEnum,
    IbkrDataView: 'IBKR_DATA_VIEW' as CreateAuthorityEnum,
    IbkrFuturesMappingCreate: 'IBKR_FUTURES_MAPPING_CREATE' as CreateAuthorityEnum,
    IbkrFuturesMappingDelete: 'IBKR_FUTURES_MAPPING_DELETE' as CreateAuthorityEnum,
    IbkrFuturesMappingModify: 'IBKR_FUTURES_MAPPING_MODIFY' as CreateAuthorityEnum,
    IbkrFuturesMappingView: 'IBKR_FUTURES_MAPPING_VIEW' as CreateAuthorityEnum,
    IbkrLoaderSettingsCreate: 'IBKR_LOADER_SETTINGS_CREATE' as CreateAuthorityEnum,
    IbkrLoaderSettingsDelete: 'IBKR_LOADER_SETTINGS_DELETE' as CreateAuthorityEnum,
    IbkrLoaderSettingsModify: 'IBKR_LOADER_SETTINGS_MODIFY' as CreateAuthorityEnum,
    IbkrLoaderSettingsView: 'IBKR_LOADER_SETTINGS_VIEW' as CreateAuthorityEnum,
    IncomePaymentTracker: 'INCOME_PAYMENT_TRACKER' as CreateAuthorityEnum,
    InstrumentsCreate: 'INSTRUMENTS_CREATE' as CreateAuthorityEnum,
    InstrumentsModify: 'INSTRUMENTS_MODIFY' as CreateAuthorityEnum,
    InstrumentsView: 'INSTRUMENTS_VIEW' as CreateAuthorityEnum,
    InstrumentToAgentView: 'INSTRUMENT_TO_AGENT_VIEW' as CreateAuthorityEnum,
    InvestecDataModify: 'INVESTEC_DATA_MODIFY' as CreateAuthorityEnum,
    InvestecDataView: 'INVESTEC_DATA_VIEW' as CreateAuthorityEnum,
    InvestecLoaderSettingsCreate: 'INVESTEC_LOADER_SETTINGS_CREATE' as CreateAuthorityEnum,
    InvestecLoaderSettingsDelete: 'INVESTEC_LOADER_SETTINGS_DELETE' as CreateAuthorityEnum,
    InvestecLoaderSettingsModify: 'INVESTEC_LOADER_SETTINGS_MODIFY' as CreateAuthorityEnum,
    InvestecLoaderSettingsView: 'INVESTEC_LOADER_SETTINGS_VIEW' as CreateAuthorityEnum,
    InvestmentView: 'INVESTMENT_VIEW' as CreateAuthorityEnum,
    IpAccess: 'IP_ACCESS' as CreateAuthorityEnum,
    JbDataModify: 'JB_DATA_MODIFY' as CreateAuthorityEnum,
    JbDataView: 'JB_DATA_VIEW' as CreateAuthorityEnum,
    JbLoaderSettingsCreate: 'JB_LOADER_SETTINGS_CREATE' as CreateAuthorityEnum,
    JbLoaderSettingsDelete: 'JB_LOADER_SETTINGS_DELETE' as CreateAuthorityEnum,
    JbLoaderSettingsModify: 'JB_LOADER_SETTINGS_MODIFY' as CreateAuthorityEnum,
    JbLoaderSettingsView: 'JB_LOADER_SETTINGS_VIEW' as CreateAuthorityEnum,
    JpmDataModify: 'JPM_DATA_MODIFY' as CreateAuthorityEnum,
    JpmDataView: 'JPM_DATA_VIEW' as CreateAuthorityEnum,
    JpmLoaderSettingsCreate: 'JPM_LOADER_SETTINGS_CREATE' as CreateAuthorityEnum,
    JpmLoaderSettingsDelete: 'JPM_LOADER_SETTINGS_DELETE' as CreateAuthorityEnum,
    JpmLoaderSettingsModify: 'JPM_LOADER_SETTINGS_MODIFY' as CreateAuthorityEnum,
    JpmLoaderSettingsView: 'JPM_LOADER_SETTINGS_VIEW' as CreateAuthorityEnum,
    LeadCreate: 'LEAD_CREATE' as CreateAuthorityEnum,
    LeadDelete: 'LEAD_DELETE' as CreateAuthorityEnum,
    LeadModify: 'LEAD_MODIFY' as CreateAuthorityEnum,
    LeadModifyAll: 'LEAD_MODIFY_ALL' as CreateAuthorityEnum,
    LeadView: 'LEAD_VIEW' as CreateAuthorityEnum,
    LeadViewAll: 'LEAD_VIEW_ALL' as CreateAuthorityEnum,
    LogotypeDelete: 'LOGOTYPE_DELETE' as CreateAuthorityEnum,
    LogotypeModify: 'LOGOTYPE_MODIFY' as CreateAuthorityEnum,
    LogotypeView: 'LOGOTYPE_VIEW' as CreateAuthorityEnum,
    ManagersCreate: 'MANAGERS_CREATE' as CreateAuthorityEnum,
    ManagersDelete: 'MANAGERS_DELETE' as CreateAuthorityEnum,
    ManagersForceModify: 'MANAGERS_FORCE_MODIFY' as CreateAuthorityEnum,
    ManagersModify: 'MANAGERS_MODIFY' as CreateAuthorityEnum,
    ManagersView: 'MANAGERS_VIEW' as CreateAuthorityEnum,
    MandatesCreate: 'MANDATES_CREATE' as CreateAuthorityEnum,
    MandatesDelete: 'MANDATES_DELETE' as CreateAuthorityEnum,
    MandatesForceModify: 'MANDATES_FORCE_MODIFY' as CreateAuthorityEnum,
    MandatesModify: 'MANDATES_MODIFY' as CreateAuthorityEnum,
    MandatesModifyAll: 'MANDATES_MODIFY_ALL' as CreateAuthorityEnum,
    MandatesView: 'MANDATES_VIEW' as CreateAuthorityEnum,
    MandatesViewAll: 'MANDATES_VIEW_ALL' as CreateAuthorityEnum,
    MandateRestrictionsCreate: 'MANDATE_RESTRICTIONS_CREATE' as CreateAuthorityEnum,
    MandateRestrictionsDelete: 'MANDATE_RESTRICTIONS_DELETE' as CreateAuthorityEnum,
    MandateRestrictionsModify: 'MANDATE_RESTRICTIONS_MODIFY' as CreateAuthorityEnum,
    MandateRestrictionsModifyAll: 'MANDATE_RESTRICTIONS_MODIFY_ALL' as CreateAuthorityEnum,
    MandateRestrictionsView: 'MANDATE_RESTRICTIONS_VIEW' as CreateAuthorityEnum,
    MandateRestrictionsViewAll: 'MANDATE_RESTRICTIONS_VIEW_ALL' as CreateAuthorityEnum,
    MandateRestrictionReportsCreate: 'MANDATE_RESTRICTION_REPORTS_CREATE' as CreateAuthorityEnum,
    MandateRestrictionReportsDelete: 'MANDATE_RESTRICTION_REPORTS_DELETE' as CreateAuthorityEnum,
    MandateRestrictionReportsModify: 'MANDATE_RESTRICTION_REPORTS_MODIFY' as CreateAuthorityEnum,
    MandateRestrictionReportsModifyAll:
      'MANDATE_RESTRICTION_REPORTS_MODIFY_ALL' as CreateAuthorityEnum,
    MandateRestrictionReportsView: 'MANDATE_RESTRICTION_REPORTS_VIEW' as CreateAuthorityEnum,
    MandateRestrictionReportsViewAll: 'MANDATE_RESTRICTION_REPORTS_VIEW_ALL' as CreateAuthorityEnum,
    MandateRestrictionTemplatesCreate:
      'MANDATE_RESTRICTION_TEMPLATES_CREATE' as CreateAuthorityEnum,
    MandateRestrictionTemplatesDelete:
      'MANDATE_RESTRICTION_TEMPLATES_DELETE' as CreateAuthorityEnum,
    MandateRestrictionTemplatesModify:
      'MANDATE_RESTRICTION_TEMPLATES_MODIFY' as CreateAuthorityEnum,
    MandateRestrictionTemplatesModifyAll:
      'MANDATE_RESTRICTION_TEMPLATES_MODIFY_ALL' as CreateAuthorityEnum,
    MandateRestrictionTemplatesView: 'MANDATE_RESTRICTION_TEMPLATES_VIEW' as CreateAuthorityEnum,
    MandateRestrictionTemplatesViewAll:
      'MANDATE_RESTRICTION_TEMPLATES_VIEW_ALL' as CreateAuthorityEnum,
    MandateRiskProfileCreate: 'MANDATE_RISK_PROFILE_CREATE' as CreateAuthorityEnum,
    MandateRiskProfileDelete: 'MANDATE_RISK_PROFILE_DELETE' as CreateAuthorityEnum,
    MandateRiskProfileForceModify: 'MANDATE_RISK_PROFILE_FORCE_MODIFY' as CreateAuthorityEnum,
    MandateRiskProfileModify: 'MANDATE_RISK_PROFILE_MODIFY' as CreateAuthorityEnum,
    MandateRiskProfileView: 'MANDATE_RISK_PROFILE_VIEW' as CreateAuthorityEnum,
    MandateRiskProfileViewAll: 'MANDATE_RISK_PROFILE_VIEW_ALL' as CreateAuthorityEnum,
    ManualImportPositionsCreate: 'MANUAL_IMPORT_POSITIONS_CREATE' as CreateAuthorityEnum,
    ManualImportPositionsModify: 'MANUAL_IMPORT_POSITIONS_MODIFY' as CreateAuthorityEnum,
    ManualImportPositionsView: 'MANUAL_IMPORT_POSITIONS_VIEW' as CreateAuthorityEnum,
    ManualImportTransactionsCreate: 'MANUAL_IMPORT_TRANSACTIONS_CREATE' as CreateAuthorityEnum,
    ManualImportTransactionsModify: 'MANUAL_IMPORT_TRANSACTIONS_MODIFY' as CreateAuthorityEnum,
    ManualImportTransactionsView: 'MANUAL_IMPORT_TRANSACTIONS_VIEW' as CreateAuthorityEnum,
    MarketplaceModify: 'MARKETPLACE_MODIFY' as CreateAuthorityEnum,
    MarketplaceView: 'MARKETPLACE_VIEW' as CreateAuthorityEnum,
    MarketSecuritiesModify: 'MARKET_SECURITIES_MODIFY' as CreateAuthorityEnum,
    MarketSecuritiesView: 'MARKET_SECURITIES_VIEW' as CreateAuthorityEnum,
    MifirModify: 'MIFIR_MODIFY' as CreateAuthorityEnum,
    MifirReport: 'MIFIR_REPORT' as CreateAuthorityEnum,
    MifirView: 'MIFIR_VIEW' as CreateAuthorityEnum,
    ModelPortfoliosCreate: 'MODEL_PORTFOLIOS_CREATE' as CreateAuthorityEnum,
    ModelPortfoliosDelete: 'MODEL_PORTFOLIOS_DELETE' as CreateAuthorityEnum,
    ModelPortfoliosModify: 'MODEL_PORTFOLIOS_MODIFY' as CreateAuthorityEnum,
    ModelPortfoliosView: 'MODEL_PORTFOLIOS_VIEW' as CreateAuthorityEnum,
    MonthlyReconciliationReport: 'MONTHLY_RECONCILIATION_REPORT' as CreateAuthorityEnum,
    NotificationTemplatesCreate: 'NOTIFICATION_TEMPLATES_CREATE' as CreateAuthorityEnum,
    NotificationTemplatesDelete: 'NOTIFICATION_TEMPLATES_DELETE' as CreateAuthorityEnum,
    NotificationTemplatesModify: 'NOTIFICATION_TEMPLATES_MODIFY' as CreateAuthorityEnum,
    NotificationTemplatesView: 'NOTIFICATION_TEMPLATES_VIEW' as CreateAuthorityEnum,
    NotificationUserLogView: 'NOTIFICATION_USER_LOG_VIEW' as CreateAuthorityEnum,
    Omnibus: 'OMNIBUS' as CreateAuthorityEnum,
    OnlinePricingView: 'ONLINE_PRICING_VIEW' as CreateAuthorityEnum,
    OpportunityCreate: 'OPPORTUNITY_CREATE' as CreateAuthorityEnum,
    OpportunityDelete: 'OPPORTUNITY_DELETE' as CreateAuthorityEnum,
    OpportunityForceModify: 'OPPORTUNITY_FORCE_MODIFY' as CreateAuthorityEnum,
    OpportunityModify: 'OPPORTUNITY_MODIFY' as CreateAuthorityEnum,
    OpportunityModifyAll: 'OPPORTUNITY_MODIFY_ALL' as CreateAuthorityEnum,
    OpportunityView: 'OPPORTUNITY_VIEW' as CreateAuthorityEnum,
    OpportunityViewAll: 'OPPORTUNITY_VIEW_ALL' as CreateAuthorityEnum,
    OrdersAssign: 'ORDERS_ASSIGN' as CreateAuthorityEnum,
    OrdersCommonCreate: 'ORDERS_COMMON_CREATE' as CreateAuthorityEnum,
    OrdersCommonDelete: 'ORDERS_COMMON_DELETE' as CreateAuthorityEnum,
    OrdersCommonModify: 'ORDERS_COMMON_MODIFY' as CreateAuthorityEnum,
    OrdersCommonView: 'ORDERS_COMMON_VIEW' as CreateAuthorityEnum,
    OrdersCorpActionCreate: 'ORDERS_CORP_ACTION_CREATE' as CreateAuthorityEnum,
    OrdersCorpActionDelete: 'ORDERS_CORP_ACTION_DELETE' as CreateAuthorityEnum,
    OrdersCorpActionModify: 'ORDERS_CORP_ACTION_MODIFY' as CreateAuthorityEnum,
    OrdersCorpActionView: 'ORDERS_CORP_ACTION_VIEW' as CreateAuthorityEnum,
    OrdersCreate: 'ORDERS_CREATE' as CreateAuthorityEnum,
    OrdersDelete: 'ORDERS_DELETE' as CreateAuthorityEnum,
    OrdersDmaCreate: 'ORDERS_DMA_CREATE' as CreateAuthorityEnum,
    OrdersForceModify: 'ORDERS_FORCE_MODIFY' as CreateAuthorityEnum,
    OrdersModify: 'ORDERS_MODIFY' as CreateAuthorityEnum,
    OrdersModifyAll: 'ORDERS_MODIFY_ALL' as CreateAuthorityEnum,
    OrdersNonTradeCreate: 'ORDERS_NON_TRADE_CREATE' as CreateAuthorityEnum,
    OrdersNonTradeDelete: 'ORDERS_NON_TRADE_DELETE' as CreateAuthorityEnum,
    OrdersNonTradeModify: 'ORDERS_NON_TRADE_MODIFY' as CreateAuthorityEnum,
    OrdersNonTradeView: 'ORDERS_NON_TRADE_VIEW' as CreateAuthorityEnum,
    OrdersTechInfoModify: 'ORDERS_TECH_INFO_MODIFY' as CreateAuthorityEnum,
    OrdersTechInfoView: 'ORDERS_TECH_INFO_VIEW' as CreateAuthorityEnum,
    OrdersTradeCreate: 'ORDERS_TRADE_CREATE' as CreateAuthorityEnum,
    OrdersTradeDelete: 'ORDERS_TRADE_DELETE' as CreateAuthorityEnum,
    OrdersTradeModify: 'ORDERS_TRADE_MODIFY' as CreateAuthorityEnum,
    OrdersTradeView: 'ORDERS_TRADE_VIEW' as CreateAuthorityEnum,
    OrdersView: 'ORDERS_VIEW' as CreateAuthorityEnum,
    OrdersViewAll: 'ORDERS_VIEW_ALL' as CreateAuthorityEnum,
    PersonalAm: 'PERSONAL_AM' as CreateAuthorityEnum,
    PnlReport: 'PNL_REPORT' as CreateAuthorityEnum,
    PortfoliosCreate: 'PORTFOLIOS_CREATE' as CreateAuthorityEnum,
    PortfoliosDelete: 'PORTFOLIOS_DELETE' as CreateAuthorityEnum,
    PortfoliosForceModify: 'PORTFOLIOS_FORCE_MODIFY' as CreateAuthorityEnum,
    PortfoliosModify: 'PORTFOLIOS_MODIFY' as CreateAuthorityEnum,
    PortfoliosModifyAll: 'PORTFOLIOS_MODIFY_ALL' as CreateAuthorityEnum,
    PortfoliosView: 'PORTFOLIOS_VIEW' as CreateAuthorityEnum,
    PortfoliosViewAll: 'PORTFOLIOS_VIEW_ALL' as CreateAuthorityEnum,
    PortfolioConfigurationView: 'PORTFOLIO_CONFIGURATION_VIEW' as CreateAuthorityEnum,
    PortfolioCostsView: 'PORTFOLIO_COSTS_VIEW' as CreateAuthorityEnum,
    PortfolioFeesView: 'PORTFOLIO_FEES_VIEW' as CreateAuthorityEnum,
    PortfolioFlowsView: 'PORTFOLIO_FLOWS_VIEW' as CreateAuthorityEnum,
    PortfolioOrdersAssign: 'PORTFOLIO_ORDERS_ASSIGN' as CreateAuthorityEnum,
    PortfolioOrdersCreate: 'PORTFOLIO_ORDERS_CREATE' as CreateAuthorityEnum,
    PortfolioOrdersDelete: 'PORTFOLIO_ORDERS_DELETE' as CreateAuthorityEnum,
    PortfolioOrdersModify: 'PORTFOLIO_ORDERS_MODIFY' as CreateAuthorityEnum,
    PortfolioOrdersView: 'PORTFOLIO_ORDERS_VIEW' as CreateAuthorityEnum,
    PortfolioPnlView: 'PORTFOLIO_PNL_VIEW' as CreateAuthorityEnum,
    PortfolioPositionsView: 'PORTFOLIO_POSITIONS_VIEW' as CreateAuthorityEnum,
    PortfolioProfileModify: 'PORTFOLIO_PROFILE_MODIFY' as CreateAuthorityEnum,
    PortfolioProfileView: 'PORTFOLIO_PROFILE_VIEW' as CreateAuthorityEnum,
    PortfolioRestrictionsView: 'PORTFOLIO_RESTRICTIONS_VIEW' as CreateAuthorityEnum,
    PortfolioTransactionsCreate: 'PORTFOLIO_TRANSACTIONS_CREATE' as CreateAuthorityEnum,
    PortfolioTransactionsDelete: 'PORTFOLIO_TRANSACTIONS_DELETE' as CreateAuthorityEnum,
    PortfolioTransactionsModify: 'PORTFOLIO_TRANSACTIONS_MODIFY' as CreateAuthorityEnum,
    PortfolioTransactionsStatus: 'PORTFOLIO_TRANSACTIONS_STATUS' as CreateAuthorityEnum,
    PortfolioTransactionsView: 'PORTFOLIO_TRANSACTIONS_VIEW' as CreateAuthorityEnum,
    PortfolioValuationView: 'PORTFOLIO_VALUATION_VIEW' as CreateAuthorityEnum,
    PositionsView: 'POSITIONS_VIEW' as CreateAuthorityEnum,
    PricesLoadingMonitorView: 'PRICES_LOADING_MONITOR_VIEW' as CreateAuthorityEnum,
    PrivateInstrumentCreate: 'PRIVATE_INSTRUMENT_CREATE' as CreateAuthorityEnum,
    PrivateInstrumentDelete: 'PRIVATE_INSTRUMENT_DELETE' as CreateAuthorityEnum,
    PrivateInstrumentModify: 'PRIVATE_INSTRUMENT_MODIFY' as CreateAuthorityEnum,
    PrivateInstrumentModifyAll: 'PRIVATE_INSTRUMENT_MODIFY_ALL' as CreateAuthorityEnum,
    PrivateInstrumentView: 'PRIVATE_INSTRUMENT_VIEW' as CreateAuthorityEnum,
    PrivateInstrumentViewAll: 'PRIVATE_INSTRUMENT_VIEW_ALL' as CreateAuthorityEnum,
    ProcessCreate: 'PROCESS_CREATE' as CreateAuthorityEnum,
    ProcessDelete: 'PROCESS_DELETE' as CreateAuthorityEnum,
    ProcessModify: 'PROCESS_MODIFY' as CreateAuthorityEnum,
    ProcessView: 'PROCESS_VIEW' as CreateAuthorityEnum,
    ProductGovernanceModify: 'PRODUCT_GOVERNANCE_MODIFY' as CreateAuthorityEnum,
    ProductGovernanceView: 'PRODUCT_GOVERNANCE_VIEW' as CreateAuthorityEnum,
    ProfilesCreate: 'PROFILES_CREATE' as CreateAuthorityEnum,
    ProfilesDelete: 'PROFILES_DELETE' as CreateAuthorityEnum,
    ProfilesModify: 'PROFILES_MODIFY' as CreateAuthorityEnum,
    ProfilesView: 'PROFILES_VIEW' as CreateAuthorityEnum,
    QuarterlyStatisticsReport: 'QUARTERLY_STATISTICS_REPORT' as CreateAuthorityEnum,
    RbsFReport: 'RBS_F_REPORT' as CreateAuthorityEnum,
    RecommendationCreate: 'RECOMMENDATION_CREATE' as CreateAuthorityEnum,
    RecommendationForceModify: 'RECOMMENDATION_FORCE_MODIFY' as CreateAuthorityEnum,
    RecommendationModify: 'RECOMMENDATION_MODIFY' as CreateAuthorityEnum,
    RecommendationView: 'RECOMMENDATION_VIEW' as CreateAuthorityEnum,
    RecommendationViewAll: 'RECOMMENDATION_VIEW_ALL' as CreateAuthorityEnum,
    ReconciliationReport: 'RECONCILIATION_REPORT' as CreateAuthorityEnum,
    RegulatorCreate: 'REGULATOR_CREATE' as CreateAuthorityEnum,
    RegulatorDelete: 'REGULATOR_DELETE' as CreateAuthorityEnum,
    RegulatorModify: 'REGULATOR_MODIFY' as CreateAuthorityEnum,
    RegulatorView: 'REGULATOR_VIEW' as CreateAuthorityEnum,
    RelunaOrdersReportsView: 'RELUNA_ORDERS_REPORTS_VIEW' as CreateAuthorityEnum,
    ReportsCreate: 'REPORTS_CREATE' as CreateAuthorityEnum,
    ReportsDelete: 'REPORTS_DELETE' as CreateAuthorityEnum,
    ReportsModify: 'REPORTS_MODIFY' as CreateAuthorityEnum,
    ReportsView: 'REPORTS_VIEW' as CreateAuthorityEnum,
    RestrictionsCreate: 'RESTRICTIONS_CREATE' as CreateAuthorityEnum,
    RestrictionsDelete: 'RESTRICTIONS_DELETE' as CreateAuthorityEnum,
    RestrictionsModify: 'RESTRICTIONS_MODIFY' as CreateAuthorityEnum,
    RestrictionsModifyAll: 'RESTRICTIONS_MODIFY_ALL' as CreateAuthorityEnum,
    RestrictionsView: 'RESTRICTIONS_VIEW' as CreateAuthorityEnum,
    RestrictionsViewAll: 'RESTRICTIONS_VIEW_ALL' as CreateAuthorityEnum,
    RestrictionAlertView: 'RESTRICTION_ALERT_VIEW' as CreateAuthorityEnum,
    RestrictionReportsCreate: 'RESTRICTION_REPORTS_CREATE' as CreateAuthorityEnum,
    RestrictionReportsDelete: 'RESTRICTION_REPORTS_DELETE' as CreateAuthorityEnum,
    RestrictionReportsModify: 'RESTRICTION_REPORTS_MODIFY' as CreateAuthorityEnum,
    RestrictionReportsModifyAll: 'RESTRICTION_REPORTS_MODIFY_ALL' as CreateAuthorityEnum,
    RestrictionReportsView: 'RESTRICTION_REPORTS_VIEW' as CreateAuthorityEnum,
    RestrictionReportsViewAll: 'RESTRICTION_REPORTS_VIEW_ALL' as CreateAuthorityEnum,
    RestrictionTemplatesCreate: 'RESTRICTION_TEMPLATES_CREATE' as CreateAuthorityEnum,
    RestrictionTemplatesDelete: 'RESTRICTION_TEMPLATES_DELETE' as CreateAuthorityEnum,
    RestrictionTemplatesModify: 'RESTRICTION_TEMPLATES_MODIFY' as CreateAuthorityEnum,
    RestrictionTemplatesModifyAll: 'RESTRICTION_TEMPLATES_MODIFY_ALL' as CreateAuthorityEnum,
    RestrictionTemplatesView: 'RESTRICTION_TEMPLATES_VIEW' as CreateAuthorityEnum,
    RestrictionTemplatesViewAll: 'RESTRICTION_TEMPLATES_VIEW_ALL' as CreateAuthorityEnum,
    RiskReportCreate: 'RISK_REPORT_CREATE' as CreateAuthorityEnum,
    RiskReportView: 'RISK_REPORT_VIEW' as CreateAuthorityEnum,
    RolesCreate: 'ROLES_CREATE' as CreateAuthorityEnum,
    RolesDelete: 'ROLES_DELETE' as CreateAuthorityEnum,
    RolesModify: 'ROLES_MODIFY' as CreateAuthorityEnum,
    RolesView: 'ROLES_VIEW' as CreateAuthorityEnum,
    Rts28Report: 'RTS28_REPORT' as CreateAuthorityEnum,
    SaltedgeTransactionsModify: 'SALTEDGE_TRANSACTIONS_MODIFY' as CreateAuthorityEnum,
    SaltedgeTransactionsView: 'SALTEDGE_TRANSACTIONS_VIEW' as CreateAuthorityEnum,
    SendReports: 'SEND_REPORTS' as CreateAuthorityEnum,
    SettingsModify: 'SETTINGS_MODIFY' as CreateAuthorityEnum,
    SettingsView: 'SETTINGS_VIEW' as CreateAuthorityEnum,
    ShopView: 'SHOP_VIEW' as CreateAuthorityEnum,
    SiteInfoCreate: 'SITE_INFO_CREATE' as CreateAuthorityEnum,
    SiteInfoDelete: 'SITE_INFO_DELETE' as CreateAuthorityEnum,
    SiteInfoModify: 'SITE_INFO_MODIFY' as CreateAuthorityEnum,
    SiteInfoView: 'SITE_INFO_VIEW' as CreateAuthorityEnum,
    SovaDataModify: 'SOVA_DATA_MODIFY' as CreateAuthorityEnum,
    SovaDataView: 'SOVA_DATA_VIEW' as CreateAuthorityEnum,
    SovaOrdersModify: 'SOVA_ORDERS_MODIFY' as CreateAuthorityEnum,
    SovaOrdersView: 'SOVA_ORDERS_VIEW' as CreateAuthorityEnum,
    StatementOfEligibleFundsReport: 'STATEMENT_OF_ELIGIBLE_FUNDS_REPORT' as CreateAuthorityEnum,
    StatisticsView: 'STATISTICS_VIEW' as CreateAuthorityEnum,
    StrategiesCreate: 'STRATEGIES_CREATE' as CreateAuthorityEnum,
    StrategiesDelete: 'STRATEGIES_DELETE' as CreateAuthorityEnum,
    StrategiesForceModify: 'STRATEGIES_FORCE_MODIFY' as CreateAuthorityEnum,
    StrategiesModify: 'STRATEGIES_MODIFY' as CreateAuthorityEnum,
    StrategiesModifyAll: 'STRATEGIES_MODIFY_ALL' as CreateAuthorityEnum,
    StrategiesView: 'STRATEGIES_VIEW' as CreateAuthorityEnum,
    StrategiesViewAll: 'STRATEGIES_VIEW_ALL' as CreateAuthorityEnum,
    StructuredProductsCreate: 'STRUCTURED_PRODUCTS_CREATE' as CreateAuthorityEnum,
    StructuredProductsDelete: 'STRUCTURED_PRODUCTS_DELETE' as CreateAuthorityEnum,
    StructuredProductsModify: 'STRUCTURED_PRODUCTS_MODIFY' as CreateAuthorityEnum,
    StructuredProductsModifyAll: 'STRUCTURED_PRODUCTS_MODIFY_ALL' as CreateAuthorityEnum,
    StructuredProductsView: 'STRUCTURED_PRODUCTS_VIEW' as CreateAuthorityEnum,
    StructuredProductsViewAll: 'STRUCTURED_PRODUCTS_VIEW_ALL' as CreateAuthorityEnum,
    SubportfoliosView: 'SUBPORTFOLIOS_VIEW' as CreateAuthorityEnum,
    SubscriptionModify: 'SUBSCRIPTION_MODIFY' as CreateAuthorityEnum,
    SubscriptionView: 'SUBSCRIPTION_VIEW' as CreateAuthorityEnum,
    TableConstructorModify: 'TABLE_CONSTRUCTOR_MODIFY' as CreateAuthorityEnum,
    TableConstructorView: 'TABLE_CONSTRUCTOR_VIEW' as CreateAuthorityEnum,
    TaviraDataModify: 'TAVIRA_DATA_MODIFY' as CreateAuthorityEnum,
    TaviraDataView: 'TAVIRA_DATA_VIEW' as CreateAuthorityEnum,
    TransactionsCreate: 'TRANSACTIONS_CREATE' as CreateAuthorityEnum,
    TransactionsDelete: 'TRANSACTIONS_DELETE' as CreateAuthorityEnum,
    TransactionsForceModify: 'TRANSACTIONS_FORCE_MODIFY' as CreateAuthorityEnum,
    TransactionsMatchingModify: 'TRANSACTIONS_MATCHING_MODIFY' as CreateAuthorityEnum,
    TransactionsMatchingView: 'TRANSACTIONS_MATCHING_VIEW' as CreateAuthorityEnum,
    TransactionsModify: 'TRANSACTIONS_MODIFY' as CreateAuthorityEnum,
    TransactionsModifyAll: 'TRANSACTIONS_MODIFY_ALL' as CreateAuthorityEnum,
    TransactionsStatus: 'TRANSACTIONS_STATUS' as CreateAuthorityEnum,
    TransactionsView: 'TRANSACTIONS_VIEW' as CreateAuthorityEnum,
    TransactionsViewAll: 'TRANSACTIONS_VIEW_ALL' as CreateAuthorityEnum,
    TransactionLoaderCreate: 'TRANSACTION_LOADER_CREATE' as CreateAuthorityEnum,
    TransactionLoaderModify: 'TRANSACTION_LOADER_MODIFY' as CreateAuthorityEnum,
    TransactionLoaderView: 'TRANSACTION_LOADER_VIEW' as CreateAuthorityEnum,
    TransactionWithoutBankView: 'TRANSACTION_WITHOUT_BANK_VIEW' as CreateAuthorityEnum,
    TrimpDataModify: 'TRIMP_DATA_MODIFY' as CreateAuthorityEnum,
    TrimpDataView: 'TRIMP_DATA_VIEW' as CreateAuthorityEnum,
    Ttca: 'TTCA' as CreateAuthorityEnum,
    UbsDataModify: 'UBS_DATA_MODIFY' as CreateAuthorityEnum,
    UbsDataView: 'UBS_DATA_VIEW' as CreateAuthorityEnum,
    ValueDateRulesModify: 'VALUE_DATE_RULES_MODIFY' as CreateAuthorityEnum,
    ValueDateRulesView: 'VALUE_DATE_RULES_VIEW' as CreateAuthorityEnum,
    VpDataModify: 'VP_DATA_MODIFY' as CreateAuthorityEnum,
    VpDataView: 'VP_DATA_VIEW' as CreateAuthorityEnum,
    VpLoaderSettingsCreate: 'VP_LOADER_SETTINGS_CREATE' as CreateAuthorityEnum,
    VpLoaderSettingsDelete: 'VP_LOADER_SETTINGS_DELETE' as CreateAuthorityEnum,
    VpLoaderSettingsModify: 'VP_LOADER_SETTINGS_MODIFY' as CreateAuthorityEnum,
    VpLoaderSettingsView: 'VP_LOADER_SETTINGS_VIEW' as CreateAuthorityEnum,
    VtbDataModify: 'VTB_DATA_MODIFY' as CreateAuthorityEnum,
    VtbDataView: 'VTB_DATA_VIEW' as CreateAuthorityEnum,
    ZohoSettingsCreate: 'ZOHO_SETTINGS_CREATE' as CreateAuthorityEnum,
    ZohoSettingsDelete: 'ZOHO_SETTINGS_DELETE' as CreateAuthorityEnum,
    ZohoSettingsModify: 'ZOHO_SETTINGS_MODIFY' as CreateAuthorityEnum,
    ZohoSettingsView: 'ZOHO_SETTINGS_VIEW' as CreateAuthorityEnum,
  };
  export type DeleteAuthorityEnum =
    | 'ACCOUNTS_CREATE'
    | 'ACCOUNTS_DELETE'
    | 'ACCOUNTS_FORCE_MODIFY'
    | 'ACCOUNTS_MODIFY'
    | 'ACCOUNTS_MODIFY_ALL'
    | 'ACCOUNTS_VIEW'
    | 'ACCOUNTS_VIEW_ALL'
    | 'ACCOUNT_STRUCTURE'
    | 'ACTUATOR_VIEW'
    | 'ADVANCED_ANALYTICS_VIEW'
    | 'ALFA_DATA_MODIFY'
    | 'ALFA_DATA_VIEW'
    | 'AMAZON_WORK_DOCS'
    | 'BANK_ACCOUNTS_CREATE'
    | 'BANK_ACCOUNTS_DELETE'
    | 'BANK_ACCOUNTS_FORCE_MODIFY'
    | 'BANK_ACCOUNTS_MODIFY'
    | 'BANK_ACCOUNTS_MODIFY_ALL'
    | 'BANK_ACCOUNTS_VIEW'
    | 'BANK_ACCOUNTS_VIEW_ALL'
    | 'BANK_STATS_VIEW'
    | 'BKS_DATA_MODIFY'
    | 'BKS_DATA_VIEW'
    | 'BKS_LOADER_SETTINGS_CREATE'
    | 'BKS_LOADER_SETTINGS_DELETE'
    | 'BKS_LOADER_SETTINGS_MODIFY'
    | 'BKS_LOADER_SETTINGS_VIEW'
    | 'BLOOMBERG_REQUESTS_VIEW'
    | 'BROKER_CREATE'
    | 'BROKER_DELETE'
    | 'BROKER_MODIFY'
    | 'BROKER_VIEW'
    | 'BUSINESS_EVENTS_VIEW'
    | 'CASS_REPORT'
    | 'CBH_BS_DATA_MODIFY'
    | 'CBH_BS_DATA_VIEW'
    | 'CBH_DATA_MODIFY'
    | 'CBH_DATA_VIEW'
    | 'CBH_LOADER_SETTINGS_CREATE'
    | 'CBH_LOADER_SETTINGS_DELETE'
    | 'CBH_LOADER_SETTINGS_MODIFY'
    | 'CBH_LOADER_SETTINGS_VIEW'
    | 'CHANGE_REQUEST_SETTINGS_CREATE'
    | 'CHANGE_REQUEST_SETTINGS_DELETE'
    | 'CHANGE_REQUEST_SETTINGS_MODIFY'
    | 'CHANGE_REQUEST_SETTINGS_VIEW'
    | 'CLIENTS_CREATE'
    | 'CLIENTS_DELETE'
    | 'CLIENTS_MODIFY'
    | 'CLIENTS_MODIFY_ALL'
    | 'CLIENTS_VIEW'
    | 'CLIENTS_VIEW_ALL'
    | 'CLIENT_COUNT_REPORT'
    | 'CLIENT_FEES_REPORT'
    | 'CLIENT_VALUATION_REPORT'
    | 'CMAR_REPORT'
    | 'COMMON_EMAIL_TEMPLATES_CREATE'
    | 'COMMON_EMAIL_TEMPLATES_DELETE'
    | 'COMMON_EMAIL_TEMPLATES_MODIFY'
    | 'COMMON_EMAIL_TEMPLATES_VIEW'
    | 'COMPANIES_CREATE'
    | 'COMPANIES_DELETE'
    | 'COMPANIES_MODIFY'
    | 'COMPANIES_TAGS_VIEW'
    | 'COMPANIES_VIEW'
    | 'COMPANY_HOLDING_CREATE'
    | 'COMPANY_HOLDING_DELETE'
    | 'COMPANY_HOLDING_MODIFY'
    | 'COMPANY_HOLDING_VIEW'
    | 'COMPANY_RISK_REPORT'
    | 'COMPLIANCE_ACCOUNT_COMPANY_CREATE'
    | 'COMPLIANCE_ACCOUNT_COMPANY_DELETE'
    | 'COMPLIANCE_ACCOUNT_COMPANY_FORCE_MODIFY'
    | 'COMPLIANCE_ACCOUNT_COMPANY_MODIFY'
    | 'COMPLIANCE_ACCOUNT_COMPANY_VIEW'
    | 'COUNTERPARTY_CREATE'
    | 'COUNTERPARTY_DELETE'
    | 'COUNTERPARTY_MODIFY'
    | 'COUNTERPARTY_VIEW'
    | 'COUPON_PROJECTION_REPORT'
    | 'CRS_REPORT'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_CREATE'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_MODIFY'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_VIEW'
    | 'CUSTODIAN_CREATE'
    | 'CUSTODIAN_DELETE'
    | 'CUSTODIAN_MODIFY'
    | 'CUSTODIAN_VIEW'
    | 'DASHBOARD_MODIFY'
    | 'DASHBOARD_VIEW'
    | 'DBS_DATA_MODIFY'
    | 'DBS_DATA_VIEW'
    | 'DEACTIVATED_TRANSACTION_VIEW'
    | 'DEPARTMENTS_CREATE'
    | 'DEPARTMENTS_DELETE'
    | 'DEPARTMENTS_MODIFY'
    | 'DEPARTMENTS_VIEW'
    | 'DOLFIN_TRANSACTIONS_MODIFY'
    | 'DOLFIN_TRANSACTIONS_VIEW'
    | 'EDR_DATA_MODIFY'
    | 'EDR_DATA_VIEW'
    | 'EDR_LOADER_SETTINGS_CREATE'
    | 'EDR_LOADER_SETTINGS_DELETE'
    | 'EDR_LOADER_SETTINGS_MODIFY'
    | 'EDR_LOADER_SETTINGS_VIEW'
    | 'EMAIL_TEMPLATE_CREATE'
    | 'EMAIL_TEMPLATE_DELETE'
    | 'EMAIL_TEMPLATE_MODIFY'
    | 'EMAIL_TEMPLATE_VIEW'
    | 'EXECGW_REPORTS_VIEW'
    | 'FEES_CREATE'
    | 'FEES_MODIFY'
    | 'FEES_VIEW'
    | 'FEES_VIEW_ALL'
    | 'FIELDS_CREATE'
    | 'FIELDS_DELETE'
    | 'FIELDS_MODIFY'
    | 'FIELDS_VIEW'
    | 'FILE_USER_LOG_VIEW'
    | 'GAP_MONITOR_VIEW'
    | 'GLOBAL_ALLOCATION_CREATE'
    | 'GLOBAL_ALLOCATION_MODIFY'
    | 'GLOBAL_ALLOCATION_VIEW'
    | 'GLOBAL_ALLOCATION_WIDGET_VIEW'
    | 'GOOGLE_AUTH_MODIFY'
    | 'GOOGLE_AUTH_VIEW'
    | 'GPP_DATA_MODIFY'
    | 'GPP_DATA_VIEW'
    | 'GPP_LOADER_SETTINGS_CREATE'
    | 'GPP_LOADER_SETTINGS_DELETE'
    | 'GPP_LOADER_SETTINGS_MODIFY'
    | 'GPP_LOADER_SETTINGS_VIEW'
    | 'HEALTH_VIEW'
    | 'HISTORY_VIEW'
    | 'IBKR_DATA_MODIFY'
    | 'IBKR_DATA_VIEW'
    | 'IBKR_FUTURES_MAPPING_CREATE'
    | 'IBKR_FUTURES_MAPPING_DELETE'
    | 'IBKR_FUTURES_MAPPING_MODIFY'
    | 'IBKR_FUTURES_MAPPING_VIEW'
    | 'IBKR_LOADER_SETTINGS_CREATE'
    | 'IBKR_LOADER_SETTINGS_DELETE'
    | 'IBKR_LOADER_SETTINGS_MODIFY'
    | 'IBKR_LOADER_SETTINGS_VIEW'
    | 'INCOME_PAYMENT_TRACKER'
    | 'INSTRUMENTS_CREATE'
    | 'INSTRUMENTS_MODIFY'
    | 'INSTRUMENTS_VIEW'
    | 'INSTRUMENT_TO_AGENT_VIEW'
    | 'INVESTEC_DATA_MODIFY'
    | 'INVESTEC_DATA_VIEW'
    | 'INVESTEC_LOADER_SETTINGS_CREATE'
    | 'INVESTEC_LOADER_SETTINGS_DELETE'
    | 'INVESTEC_LOADER_SETTINGS_MODIFY'
    | 'INVESTEC_LOADER_SETTINGS_VIEW'
    | 'INVESTMENT_VIEW'
    | 'IP_ACCESS'
    | 'JB_DATA_MODIFY'
    | 'JB_DATA_VIEW'
    | 'JB_LOADER_SETTINGS_CREATE'
    | 'JB_LOADER_SETTINGS_DELETE'
    | 'JB_LOADER_SETTINGS_MODIFY'
    | 'JB_LOADER_SETTINGS_VIEW'
    | 'JPM_DATA_MODIFY'
    | 'JPM_DATA_VIEW'
    | 'JPM_LOADER_SETTINGS_CREATE'
    | 'JPM_LOADER_SETTINGS_DELETE'
    | 'JPM_LOADER_SETTINGS_MODIFY'
    | 'JPM_LOADER_SETTINGS_VIEW'
    | 'LEAD_CREATE'
    | 'LEAD_DELETE'
    | 'LEAD_MODIFY'
    | 'LEAD_MODIFY_ALL'
    | 'LEAD_VIEW'
    | 'LEAD_VIEW_ALL'
    | 'LOGOTYPE_DELETE'
    | 'LOGOTYPE_MODIFY'
    | 'LOGOTYPE_VIEW'
    | 'MANAGERS_CREATE'
    | 'MANAGERS_DELETE'
    | 'MANAGERS_FORCE_MODIFY'
    | 'MANAGERS_MODIFY'
    | 'MANAGERS_VIEW'
    | 'MANDATES_CREATE'
    | 'MANDATES_DELETE'
    | 'MANDATES_FORCE_MODIFY'
    | 'MANDATES_MODIFY'
    | 'MANDATES_MODIFY_ALL'
    | 'MANDATES_VIEW'
    | 'MANDATES_VIEW_ALL'
    | 'MANDATE_RESTRICTIONS_CREATE'
    | 'MANDATE_RESTRICTIONS_DELETE'
    | 'MANDATE_RESTRICTIONS_MODIFY'
    | 'MANDATE_RESTRICTIONS_MODIFY_ALL'
    | 'MANDATE_RESTRICTIONS_VIEW'
    | 'MANDATE_RESTRICTIONS_VIEW_ALL'
    | 'MANDATE_RESTRICTION_REPORTS_CREATE'
    | 'MANDATE_RESTRICTION_REPORTS_DELETE'
    | 'MANDATE_RESTRICTION_REPORTS_MODIFY'
    | 'MANDATE_RESTRICTION_REPORTS_MODIFY_ALL'
    | 'MANDATE_RESTRICTION_REPORTS_VIEW'
    | 'MANDATE_RESTRICTION_REPORTS_VIEW_ALL'
    | 'MANDATE_RESTRICTION_TEMPLATES_CREATE'
    | 'MANDATE_RESTRICTION_TEMPLATES_DELETE'
    | 'MANDATE_RESTRICTION_TEMPLATES_MODIFY'
    | 'MANDATE_RESTRICTION_TEMPLATES_MODIFY_ALL'
    | 'MANDATE_RESTRICTION_TEMPLATES_VIEW'
    | 'MANDATE_RESTRICTION_TEMPLATES_VIEW_ALL'
    | 'MANDATE_RISK_PROFILE_CREATE'
    | 'MANDATE_RISK_PROFILE_DELETE'
    | 'MANDATE_RISK_PROFILE_FORCE_MODIFY'
    | 'MANDATE_RISK_PROFILE_MODIFY'
    | 'MANDATE_RISK_PROFILE_VIEW'
    | 'MANDATE_RISK_PROFILE_VIEW_ALL'
    | 'MANUAL_IMPORT_POSITIONS_CREATE'
    | 'MANUAL_IMPORT_POSITIONS_MODIFY'
    | 'MANUAL_IMPORT_POSITIONS_VIEW'
    | 'MANUAL_IMPORT_TRANSACTIONS_CREATE'
    | 'MANUAL_IMPORT_TRANSACTIONS_MODIFY'
    | 'MANUAL_IMPORT_TRANSACTIONS_VIEW'
    | 'MARKETPLACE_MODIFY'
    | 'MARKETPLACE_VIEW'
    | 'MARKET_SECURITIES_MODIFY'
    | 'MARKET_SECURITIES_VIEW'
    | 'MIFIR_MODIFY'
    | 'MIFIR_REPORT'
    | 'MIFIR_VIEW'
    | 'MODEL_PORTFOLIOS_CREATE'
    | 'MODEL_PORTFOLIOS_DELETE'
    | 'MODEL_PORTFOLIOS_MODIFY'
    | 'MODEL_PORTFOLIOS_VIEW'
    | 'MONTHLY_RECONCILIATION_REPORT'
    | 'NOTIFICATION_TEMPLATES_CREATE'
    | 'NOTIFICATION_TEMPLATES_DELETE'
    | 'NOTIFICATION_TEMPLATES_MODIFY'
    | 'NOTIFICATION_TEMPLATES_VIEW'
    | 'NOTIFICATION_USER_LOG_VIEW'
    | 'OMNIBUS'
    | 'ONLINE_PRICING_VIEW'
    | 'OPPORTUNITY_CREATE'
    | 'OPPORTUNITY_DELETE'
    | 'OPPORTUNITY_FORCE_MODIFY'
    | 'OPPORTUNITY_MODIFY'
    | 'OPPORTUNITY_MODIFY_ALL'
    | 'OPPORTUNITY_VIEW'
    | 'OPPORTUNITY_VIEW_ALL'
    | 'ORDERS_ASSIGN'
    | 'ORDERS_COMMON_CREATE'
    | 'ORDERS_COMMON_DELETE'
    | 'ORDERS_COMMON_MODIFY'
    | 'ORDERS_COMMON_VIEW'
    | 'ORDERS_CORP_ACTION_CREATE'
    | 'ORDERS_CORP_ACTION_DELETE'
    | 'ORDERS_CORP_ACTION_MODIFY'
    | 'ORDERS_CORP_ACTION_VIEW'
    | 'ORDERS_CREATE'
    | 'ORDERS_DELETE'
    | 'ORDERS_DMA_CREATE'
    | 'ORDERS_FORCE_MODIFY'
    | 'ORDERS_MODIFY'
    | 'ORDERS_MODIFY_ALL'
    | 'ORDERS_NON_TRADE_CREATE'
    | 'ORDERS_NON_TRADE_DELETE'
    | 'ORDERS_NON_TRADE_MODIFY'
    | 'ORDERS_NON_TRADE_VIEW'
    | 'ORDERS_TECH_INFO_MODIFY'
    | 'ORDERS_TECH_INFO_VIEW'
    | 'ORDERS_TRADE_CREATE'
    | 'ORDERS_TRADE_DELETE'
    | 'ORDERS_TRADE_MODIFY'
    | 'ORDERS_TRADE_VIEW'
    | 'ORDERS_VIEW'
    | 'ORDERS_VIEW_ALL'
    | 'PERSONAL_AM'
    | 'PNL_REPORT'
    | 'PORTFOLIOS_CREATE'
    | 'PORTFOLIOS_DELETE'
    | 'PORTFOLIOS_FORCE_MODIFY'
    | 'PORTFOLIOS_MODIFY'
    | 'PORTFOLIOS_MODIFY_ALL'
    | 'PORTFOLIOS_VIEW'
    | 'PORTFOLIOS_VIEW_ALL'
    | 'PORTFOLIO_CONFIGURATION_VIEW'
    | 'PORTFOLIO_COSTS_VIEW'
    | 'PORTFOLIO_FEES_VIEW'
    | 'PORTFOLIO_FLOWS_VIEW'
    | 'PORTFOLIO_ORDERS_ASSIGN'
    | 'PORTFOLIO_ORDERS_CREATE'
    | 'PORTFOLIO_ORDERS_DELETE'
    | 'PORTFOLIO_ORDERS_MODIFY'
    | 'PORTFOLIO_ORDERS_VIEW'
    | 'PORTFOLIO_PNL_VIEW'
    | 'PORTFOLIO_POSITIONS_VIEW'
    | 'PORTFOLIO_PROFILE_MODIFY'
    | 'PORTFOLIO_PROFILE_VIEW'
    | 'PORTFOLIO_RESTRICTIONS_VIEW'
    | 'PORTFOLIO_TRANSACTIONS_CREATE'
    | 'PORTFOLIO_TRANSACTIONS_DELETE'
    | 'PORTFOLIO_TRANSACTIONS_MODIFY'
    | 'PORTFOLIO_TRANSACTIONS_STATUS'
    | 'PORTFOLIO_TRANSACTIONS_VIEW'
    | 'PORTFOLIO_VALUATION_VIEW'
    | 'POSITIONS_VIEW'
    | 'PRICES_LOADING_MONITOR_VIEW'
    | 'PRIVATE_INSTRUMENT_CREATE'
    | 'PRIVATE_INSTRUMENT_DELETE'
    | 'PRIVATE_INSTRUMENT_MODIFY'
    | 'PRIVATE_INSTRUMENT_MODIFY_ALL'
    | 'PRIVATE_INSTRUMENT_VIEW'
    | 'PRIVATE_INSTRUMENT_VIEW_ALL'
    | 'PROCESS_CREATE'
    | 'PROCESS_DELETE'
    | 'PROCESS_MODIFY'
    | 'PROCESS_VIEW'
    | 'PRODUCT_GOVERNANCE_MODIFY'
    | 'PRODUCT_GOVERNANCE_VIEW'
    | 'PROFILES_CREATE'
    | 'PROFILES_DELETE'
    | 'PROFILES_MODIFY'
    | 'PROFILES_VIEW'
    | 'QUARTERLY_STATISTICS_REPORT'
    | 'RBS_F_REPORT'
    | 'RECOMMENDATION_CREATE'
    | 'RECOMMENDATION_FORCE_MODIFY'
    | 'RECOMMENDATION_MODIFY'
    | 'RECOMMENDATION_VIEW'
    | 'RECOMMENDATION_VIEW_ALL'
    | 'RECONCILIATION_REPORT'
    | 'REGULATOR_CREATE'
    | 'REGULATOR_DELETE'
    | 'REGULATOR_MODIFY'
    | 'REGULATOR_VIEW'
    | 'RELUNA_ORDERS_REPORTS_VIEW'
    | 'REPORTS_CREATE'
    | 'REPORTS_DELETE'
    | 'REPORTS_MODIFY'
    | 'REPORTS_VIEW'
    | 'RESTRICTIONS_CREATE'
    | 'RESTRICTIONS_DELETE'
    | 'RESTRICTIONS_MODIFY'
    | 'RESTRICTIONS_MODIFY_ALL'
    | 'RESTRICTIONS_VIEW'
    | 'RESTRICTIONS_VIEW_ALL'
    | 'RESTRICTION_ALERT_VIEW'
    | 'RESTRICTION_REPORTS_CREATE'
    | 'RESTRICTION_REPORTS_DELETE'
    | 'RESTRICTION_REPORTS_MODIFY'
    | 'RESTRICTION_REPORTS_MODIFY_ALL'
    | 'RESTRICTION_REPORTS_VIEW'
    | 'RESTRICTION_REPORTS_VIEW_ALL'
    | 'RESTRICTION_TEMPLATES_CREATE'
    | 'RESTRICTION_TEMPLATES_DELETE'
    | 'RESTRICTION_TEMPLATES_MODIFY'
    | 'RESTRICTION_TEMPLATES_MODIFY_ALL'
    | 'RESTRICTION_TEMPLATES_VIEW'
    | 'RESTRICTION_TEMPLATES_VIEW_ALL'
    | 'RISK_REPORT_CREATE'
    | 'RISK_REPORT_VIEW'
    | 'ROLES_CREATE'
    | 'ROLES_DELETE'
    | 'ROLES_MODIFY'
    | 'ROLES_VIEW'
    | 'RTS28_REPORT'
    | 'SALTEDGE_TRANSACTIONS_MODIFY'
    | 'SALTEDGE_TRANSACTIONS_VIEW'
    | 'SEND_REPORTS'
    | 'SETTINGS_MODIFY'
    | 'SETTINGS_VIEW'
    | 'SHOP_VIEW'
    | 'SITE_INFO_CREATE'
    | 'SITE_INFO_DELETE'
    | 'SITE_INFO_MODIFY'
    | 'SITE_INFO_VIEW'
    | 'SOVA_DATA_MODIFY'
    | 'SOVA_DATA_VIEW'
    | 'SOVA_ORDERS_MODIFY'
    | 'SOVA_ORDERS_VIEW'
    | 'STATEMENT_OF_ELIGIBLE_FUNDS_REPORT'
    | 'STATISTICS_VIEW'
    | 'STRATEGIES_CREATE'
    | 'STRATEGIES_DELETE'
    | 'STRATEGIES_FORCE_MODIFY'
    | 'STRATEGIES_MODIFY'
    | 'STRATEGIES_MODIFY_ALL'
    | 'STRATEGIES_VIEW'
    | 'STRATEGIES_VIEW_ALL'
    | 'STRUCTURED_PRODUCTS_CREATE'
    | 'STRUCTURED_PRODUCTS_DELETE'
    | 'STRUCTURED_PRODUCTS_MODIFY'
    | 'STRUCTURED_PRODUCTS_MODIFY_ALL'
    | 'STRUCTURED_PRODUCTS_VIEW'
    | 'STRUCTURED_PRODUCTS_VIEW_ALL'
    | 'SUBPORTFOLIOS_VIEW'
    | 'SUBSCRIPTION_MODIFY'
    | 'SUBSCRIPTION_VIEW'
    | 'TABLE_CONSTRUCTOR_MODIFY'
    | 'TABLE_CONSTRUCTOR_VIEW'
    | 'TAVIRA_DATA_MODIFY'
    | 'TAVIRA_DATA_VIEW'
    | 'TRANSACTIONS_CREATE'
    | 'TRANSACTIONS_DELETE'
    | 'TRANSACTIONS_FORCE_MODIFY'
    | 'TRANSACTIONS_MATCHING_MODIFY'
    | 'TRANSACTIONS_MATCHING_VIEW'
    | 'TRANSACTIONS_MODIFY'
    | 'TRANSACTIONS_MODIFY_ALL'
    | 'TRANSACTIONS_STATUS'
    | 'TRANSACTIONS_VIEW'
    | 'TRANSACTIONS_VIEW_ALL'
    | 'TRANSACTION_LOADER_CREATE'
    | 'TRANSACTION_LOADER_MODIFY'
    | 'TRANSACTION_LOADER_VIEW'
    | 'TRANSACTION_WITHOUT_BANK_VIEW'
    | 'TRIMP_DATA_MODIFY'
    | 'TRIMP_DATA_VIEW'
    | 'TTCA'
    | 'UBS_DATA_MODIFY'
    | 'UBS_DATA_VIEW'
    | 'VALUE_DATE_RULES_MODIFY'
    | 'VALUE_DATE_RULES_VIEW'
    | 'VP_DATA_MODIFY'
    | 'VP_DATA_VIEW'
    | 'VP_LOADER_SETTINGS_CREATE'
    | 'VP_LOADER_SETTINGS_DELETE'
    | 'VP_LOADER_SETTINGS_MODIFY'
    | 'VP_LOADER_SETTINGS_VIEW'
    | 'VTB_DATA_MODIFY'
    | 'VTB_DATA_VIEW'
    | 'ZOHO_SETTINGS_CREATE'
    | 'ZOHO_SETTINGS_DELETE'
    | 'ZOHO_SETTINGS_MODIFY'
    | 'ZOHO_SETTINGS_VIEW';
  export const DeleteAuthorityEnum = {
    AccountsCreate: 'ACCOUNTS_CREATE' as DeleteAuthorityEnum,
    AccountsDelete: 'ACCOUNTS_DELETE' as DeleteAuthorityEnum,
    AccountsForceModify: 'ACCOUNTS_FORCE_MODIFY' as DeleteAuthorityEnum,
    AccountsModify: 'ACCOUNTS_MODIFY' as DeleteAuthorityEnum,
    AccountsModifyAll: 'ACCOUNTS_MODIFY_ALL' as DeleteAuthorityEnum,
    AccountsView: 'ACCOUNTS_VIEW' as DeleteAuthorityEnum,
    AccountsViewAll: 'ACCOUNTS_VIEW_ALL' as DeleteAuthorityEnum,
    AccountStructure: 'ACCOUNT_STRUCTURE' as DeleteAuthorityEnum,
    ActuatorView: 'ACTUATOR_VIEW' as DeleteAuthorityEnum,
    AdvancedAnalyticsView: 'ADVANCED_ANALYTICS_VIEW' as DeleteAuthorityEnum,
    AlfaDataModify: 'ALFA_DATA_MODIFY' as DeleteAuthorityEnum,
    AlfaDataView: 'ALFA_DATA_VIEW' as DeleteAuthorityEnum,
    AmazonWorkDocs: 'AMAZON_WORK_DOCS' as DeleteAuthorityEnum,
    BankAccountsCreate: 'BANK_ACCOUNTS_CREATE' as DeleteAuthorityEnum,
    BankAccountsDelete: 'BANK_ACCOUNTS_DELETE' as DeleteAuthorityEnum,
    BankAccountsForceModify: 'BANK_ACCOUNTS_FORCE_MODIFY' as DeleteAuthorityEnum,
    BankAccountsModify: 'BANK_ACCOUNTS_MODIFY' as DeleteAuthorityEnum,
    BankAccountsModifyAll: 'BANK_ACCOUNTS_MODIFY_ALL' as DeleteAuthorityEnum,
    BankAccountsView: 'BANK_ACCOUNTS_VIEW' as DeleteAuthorityEnum,
    BankAccountsViewAll: 'BANK_ACCOUNTS_VIEW_ALL' as DeleteAuthorityEnum,
    BankStatsView: 'BANK_STATS_VIEW' as DeleteAuthorityEnum,
    BksDataModify: 'BKS_DATA_MODIFY' as DeleteAuthorityEnum,
    BksDataView: 'BKS_DATA_VIEW' as DeleteAuthorityEnum,
    BksLoaderSettingsCreate: 'BKS_LOADER_SETTINGS_CREATE' as DeleteAuthorityEnum,
    BksLoaderSettingsDelete: 'BKS_LOADER_SETTINGS_DELETE' as DeleteAuthorityEnum,
    BksLoaderSettingsModify: 'BKS_LOADER_SETTINGS_MODIFY' as DeleteAuthorityEnum,
    BksLoaderSettingsView: 'BKS_LOADER_SETTINGS_VIEW' as DeleteAuthorityEnum,
    BloombergRequestsView: 'BLOOMBERG_REQUESTS_VIEW' as DeleteAuthorityEnum,
    BrokerCreate: 'BROKER_CREATE' as DeleteAuthorityEnum,
    BrokerDelete: 'BROKER_DELETE' as DeleteAuthorityEnum,
    BrokerModify: 'BROKER_MODIFY' as DeleteAuthorityEnum,
    BrokerView: 'BROKER_VIEW' as DeleteAuthorityEnum,
    BusinessEventsView: 'BUSINESS_EVENTS_VIEW' as DeleteAuthorityEnum,
    CassReport: 'CASS_REPORT' as DeleteAuthorityEnum,
    CbhBsDataModify: 'CBH_BS_DATA_MODIFY' as DeleteAuthorityEnum,
    CbhBsDataView: 'CBH_BS_DATA_VIEW' as DeleteAuthorityEnum,
    CbhDataModify: 'CBH_DATA_MODIFY' as DeleteAuthorityEnum,
    CbhDataView: 'CBH_DATA_VIEW' as DeleteAuthorityEnum,
    CbhLoaderSettingsCreate: 'CBH_LOADER_SETTINGS_CREATE' as DeleteAuthorityEnum,
    CbhLoaderSettingsDelete: 'CBH_LOADER_SETTINGS_DELETE' as DeleteAuthorityEnum,
    CbhLoaderSettingsModify: 'CBH_LOADER_SETTINGS_MODIFY' as DeleteAuthorityEnum,
    CbhLoaderSettingsView: 'CBH_LOADER_SETTINGS_VIEW' as DeleteAuthorityEnum,
    ChangeRequestSettingsCreate: 'CHANGE_REQUEST_SETTINGS_CREATE' as DeleteAuthorityEnum,
    ChangeRequestSettingsDelete: 'CHANGE_REQUEST_SETTINGS_DELETE' as DeleteAuthorityEnum,
    ChangeRequestSettingsModify: 'CHANGE_REQUEST_SETTINGS_MODIFY' as DeleteAuthorityEnum,
    ChangeRequestSettingsView: 'CHANGE_REQUEST_SETTINGS_VIEW' as DeleteAuthorityEnum,
    ClientsCreate: 'CLIENTS_CREATE' as DeleteAuthorityEnum,
    ClientsDelete: 'CLIENTS_DELETE' as DeleteAuthorityEnum,
    ClientsModify: 'CLIENTS_MODIFY' as DeleteAuthorityEnum,
    ClientsModifyAll: 'CLIENTS_MODIFY_ALL' as DeleteAuthorityEnum,
    ClientsView: 'CLIENTS_VIEW' as DeleteAuthorityEnum,
    ClientsViewAll: 'CLIENTS_VIEW_ALL' as DeleteAuthorityEnum,
    ClientCountReport: 'CLIENT_COUNT_REPORT' as DeleteAuthorityEnum,
    ClientFeesReport: 'CLIENT_FEES_REPORT' as DeleteAuthorityEnum,
    ClientValuationReport: 'CLIENT_VALUATION_REPORT' as DeleteAuthorityEnum,
    CmarReport: 'CMAR_REPORT' as DeleteAuthorityEnum,
    CommonEmailTemplatesCreate: 'COMMON_EMAIL_TEMPLATES_CREATE' as DeleteAuthorityEnum,
    CommonEmailTemplatesDelete: 'COMMON_EMAIL_TEMPLATES_DELETE' as DeleteAuthorityEnum,
    CommonEmailTemplatesModify: 'COMMON_EMAIL_TEMPLATES_MODIFY' as DeleteAuthorityEnum,
    CommonEmailTemplatesView: 'COMMON_EMAIL_TEMPLATES_VIEW' as DeleteAuthorityEnum,
    CompaniesCreate: 'COMPANIES_CREATE' as DeleteAuthorityEnum,
    CompaniesDelete: 'COMPANIES_DELETE' as DeleteAuthorityEnum,
    CompaniesModify: 'COMPANIES_MODIFY' as DeleteAuthorityEnum,
    CompaniesTagsView: 'COMPANIES_TAGS_VIEW' as DeleteAuthorityEnum,
    CompaniesView: 'COMPANIES_VIEW' as DeleteAuthorityEnum,
    CompanyHoldingCreate: 'COMPANY_HOLDING_CREATE' as DeleteAuthorityEnum,
    CompanyHoldingDelete: 'COMPANY_HOLDING_DELETE' as DeleteAuthorityEnum,
    CompanyHoldingModify: 'COMPANY_HOLDING_MODIFY' as DeleteAuthorityEnum,
    CompanyHoldingView: 'COMPANY_HOLDING_VIEW' as DeleteAuthorityEnum,
    CompanyRiskReport: 'COMPANY_RISK_REPORT' as DeleteAuthorityEnum,
    ComplianceAccountCompanyCreate: 'COMPLIANCE_ACCOUNT_COMPANY_CREATE' as DeleteAuthorityEnum,
    ComplianceAccountCompanyDelete: 'COMPLIANCE_ACCOUNT_COMPANY_DELETE' as DeleteAuthorityEnum,
    ComplianceAccountCompanyForceModify:
      'COMPLIANCE_ACCOUNT_COMPANY_FORCE_MODIFY' as DeleteAuthorityEnum,
    ComplianceAccountCompanyModify: 'COMPLIANCE_ACCOUNT_COMPANY_MODIFY' as DeleteAuthorityEnum,
    ComplianceAccountCompanyView: 'COMPLIANCE_ACCOUNT_COMPANY_VIEW' as DeleteAuthorityEnum,
    CounterpartyCreate: 'COUNTERPARTY_CREATE' as DeleteAuthorityEnum,
    CounterpartyDelete: 'COUNTERPARTY_DELETE' as DeleteAuthorityEnum,
    CounterpartyModify: 'COUNTERPARTY_MODIFY' as DeleteAuthorityEnum,
    CounterpartyView: 'COUNTERPARTY_VIEW' as DeleteAuthorityEnum,
    CouponProjectionReport: 'COUPON_PROJECTION_REPORT' as DeleteAuthorityEnum,
    CrsReport: 'CRS_REPORT' as DeleteAuthorityEnum,
    CustodianAssetTypeMappingCreate: 'CUSTODIAN_ASSET_TYPE_MAPPING_CREATE' as DeleteAuthorityEnum,
    CustodianAssetTypeMappingModify: 'CUSTODIAN_ASSET_TYPE_MAPPING_MODIFY' as DeleteAuthorityEnum,
    CustodianAssetTypeMappingView: 'CUSTODIAN_ASSET_TYPE_MAPPING_VIEW' as DeleteAuthorityEnum,
    CustodianCreate: 'CUSTODIAN_CREATE' as DeleteAuthorityEnum,
    CustodianDelete: 'CUSTODIAN_DELETE' as DeleteAuthorityEnum,
    CustodianModify: 'CUSTODIAN_MODIFY' as DeleteAuthorityEnum,
    CustodianView: 'CUSTODIAN_VIEW' as DeleteAuthorityEnum,
    DashboardModify: 'DASHBOARD_MODIFY' as DeleteAuthorityEnum,
    DashboardView: 'DASHBOARD_VIEW' as DeleteAuthorityEnum,
    DbsDataModify: 'DBS_DATA_MODIFY' as DeleteAuthorityEnum,
    DbsDataView: 'DBS_DATA_VIEW' as DeleteAuthorityEnum,
    DeactivatedTransactionView: 'DEACTIVATED_TRANSACTION_VIEW' as DeleteAuthorityEnum,
    DepartmentsCreate: 'DEPARTMENTS_CREATE' as DeleteAuthorityEnum,
    DepartmentsDelete: 'DEPARTMENTS_DELETE' as DeleteAuthorityEnum,
    DepartmentsModify: 'DEPARTMENTS_MODIFY' as DeleteAuthorityEnum,
    DepartmentsView: 'DEPARTMENTS_VIEW' as DeleteAuthorityEnum,
    DolfinTransactionsModify: 'DOLFIN_TRANSACTIONS_MODIFY' as DeleteAuthorityEnum,
    DolfinTransactionsView: 'DOLFIN_TRANSACTIONS_VIEW' as DeleteAuthorityEnum,
    EdrDataModify: 'EDR_DATA_MODIFY' as DeleteAuthorityEnum,
    EdrDataView: 'EDR_DATA_VIEW' as DeleteAuthorityEnum,
    EdrLoaderSettingsCreate: 'EDR_LOADER_SETTINGS_CREATE' as DeleteAuthorityEnum,
    EdrLoaderSettingsDelete: 'EDR_LOADER_SETTINGS_DELETE' as DeleteAuthorityEnum,
    EdrLoaderSettingsModify: 'EDR_LOADER_SETTINGS_MODIFY' as DeleteAuthorityEnum,
    EdrLoaderSettingsView: 'EDR_LOADER_SETTINGS_VIEW' as DeleteAuthorityEnum,
    EmailTemplateCreate: 'EMAIL_TEMPLATE_CREATE' as DeleteAuthorityEnum,
    EmailTemplateDelete: 'EMAIL_TEMPLATE_DELETE' as DeleteAuthorityEnum,
    EmailTemplateModify: 'EMAIL_TEMPLATE_MODIFY' as DeleteAuthorityEnum,
    EmailTemplateView: 'EMAIL_TEMPLATE_VIEW' as DeleteAuthorityEnum,
    ExecgwReportsView: 'EXECGW_REPORTS_VIEW' as DeleteAuthorityEnum,
    FeesCreate: 'FEES_CREATE' as DeleteAuthorityEnum,
    FeesModify: 'FEES_MODIFY' as DeleteAuthorityEnum,
    FeesView: 'FEES_VIEW' as DeleteAuthorityEnum,
    FeesViewAll: 'FEES_VIEW_ALL' as DeleteAuthorityEnum,
    FieldsCreate: 'FIELDS_CREATE' as DeleteAuthorityEnum,
    FieldsDelete: 'FIELDS_DELETE' as DeleteAuthorityEnum,
    FieldsModify: 'FIELDS_MODIFY' as DeleteAuthorityEnum,
    FieldsView: 'FIELDS_VIEW' as DeleteAuthorityEnum,
    FileUserLogView: 'FILE_USER_LOG_VIEW' as DeleteAuthorityEnum,
    GapMonitorView: 'GAP_MONITOR_VIEW' as DeleteAuthorityEnum,
    GlobalAllocationCreate: 'GLOBAL_ALLOCATION_CREATE' as DeleteAuthorityEnum,
    GlobalAllocationModify: 'GLOBAL_ALLOCATION_MODIFY' as DeleteAuthorityEnum,
    GlobalAllocationView: 'GLOBAL_ALLOCATION_VIEW' as DeleteAuthorityEnum,
    GlobalAllocationWidgetView: 'GLOBAL_ALLOCATION_WIDGET_VIEW' as DeleteAuthorityEnum,
    GoogleAuthModify: 'GOOGLE_AUTH_MODIFY' as DeleteAuthorityEnum,
    GoogleAuthView: 'GOOGLE_AUTH_VIEW' as DeleteAuthorityEnum,
    GppDataModify: 'GPP_DATA_MODIFY' as DeleteAuthorityEnum,
    GppDataView: 'GPP_DATA_VIEW' as DeleteAuthorityEnum,
    GppLoaderSettingsCreate: 'GPP_LOADER_SETTINGS_CREATE' as DeleteAuthorityEnum,
    GppLoaderSettingsDelete: 'GPP_LOADER_SETTINGS_DELETE' as DeleteAuthorityEnum,
    GppLoaderSettingsModify: 'GPP_LOADER_SETTINGS_MODIFY' as DeleteAuthorityEnum,
    GppLoaderSettingsView: 'GPP_LOADER_SETTINGS_VIEW' as DeleteAuthorityEnum,
    HealthView: 'HEALTH_VIEW' as DeleteAuthorityEnum,
    HistoryView: 'HISTORY_VIEW' as DeleteAuthorityEnum,
    IbkrDataModify: 'IBKR_DATA_MODIFY' as DeleteAuthorityEnum,
    IbkrDataView: 'IBKR_DATA_VIEW' as DeleteAuthorityEnum,
    IbkrFuturesMappingCreate: 'IBKR_FUTURES_MAPPING_CREATE' as DeleteAuthorityEnum,
    IbkrFuturesMappingDelete: 'IBKR_FUTURES_MAPPING_DELETE' as DeleteAuthorityEnum,
    IbkrFuturesMappingModify: 'IBKR_FUTURES_MAPPING_MODIFY' as DeleteAuthorityEnum,
    IbkrFuturesMappingView: 'IBKR_FUTURES_MAPPING_VIEW' as DeleteAuthorityEnum,
    IbkrLoaderSettingsCreate: 'IBKR_LOADER_SETTINGS_CREATE' as DeleteAuthorityEnum,
    IbkrLoaderSettingsDelete: 'IBKR_LOADER_SETTINGS_DELETE' as DeleteAuthorityEnum,
    IbkrLoaderSettingsModify: 'IBKR_LOADER_SETTINGS_MODIFY' as DeleteAuthorityEnum,
    IbkrLoaderSettingsView: 'IBKR_LOADER_SETTINGS_VIEW' as DeleteAuthorityEnum,
    IncomePaymentTracker: 'INCOME_PAYMENT_TRACKER' as DeleteAuthorityEnum,
    InstrumentsCreate: 'INSTRUMENTS_CREATE' as DeleteAuthorityEnum,
    InstrumentsModify: 'INSTRUMENTS_MODIFY' as DeleteAuthorityEnum,
    InstrumentsView: 'INSTRUMENTS_VIEW' as DeleteAuthorityEnum,
    InstrumentToAgentView: 'INSTRUMENT_TO_AGENT_VIEW' as DeleteAuthorityEnum,
    InvestecDataModify: 'INVESTEC_DATA_MODIFY' as DeleteAuthorityEnum,
    InvestecDataView: 'INVESTEC_DATA_VIEW' as DeleteAuthorityEnum,
    InvestecLoaderSettingsCreate: 'INVESTEC_LOADER_SETTINGS_CREATE' as DeleteAuthorityEnum,
    InvestecLoaderSettingsDelete: 'INVESTEC_LOADER_SETTINGS_DELETE' as DeleteAuthorityEnum,
    InvestecLoaderSettingsModify: 'INVESTEC_LOADER_SETTINGS_MODIFY' as DeleteAuthorityEnum,
    InvestecLoaderSettingsView: 'INVESTEC_LOADER_SETTINGS_VIEW' as DeleteAuthorityEnum,
    InvestmentView: 'INVESTMENT_VIEW' as DeleteAuthorityEnum,
    IpAccess: 'IP_ACCESS' as DeleteAuthorityEnum,
    JbDataModify: 'JB_DATA_MODIFY' as DeleteAuthorityEnum,
    JbDataView: 'JB_DATA_VIEW' as DeleteAuthorityEnum,
    JbLoaderSettingsCreate: 'JB_LOADER_SETTINGS_CREATE' as DeleteAuthorityEnum,
    JbLoaderSettingsDelete: 'JB_LOADER_SETTINGS_DELETE' as DeleteAuthorityEnum,
    JbLoaderSettingsModify: 'JB_LOADER_SETTINGS_MODIFY' as DeleteAuthorityEnum,
    JbLoaderSettingsView: 'JB_LOADER_SETTINGS_VIEW' as DeleteAuthorityEnum,
    JpmDataModify: 'JPM_DATA_MODIFY' as DeleteAuthorityEnum,
    JpmDataView: 'JPM_DATA_VIEW' as DeleteAuthorityEnum,
    JpmLoaderSettingsCreate: 'JPM_LOADER_SETTINGS_CREATE' as DeleteAuthorityEnum,
    JpmLoaderSettingsDelete: 'JPM_LOADER_SETTINGS_DELETE' as DeleteAuthorityEnum,
    JpmLoaderSettingsModify: 'JPM_LOADER_SETTINGS_MODIFY' as DeleteAuthorityEnum,
    JpmLoaderSettingsView: 'JPM_LOADER_SETTINGS_VIEW' as DeleteAuthorityEnum,
    LeadCreate: 'LEAD_CREATE' as DeleteAuthorityEnum,
    LeadDelete: 'LEAD_DELETE' as DeleteAuthorityEnum,
    LeadModify: 'LEAD_MODIFY' as DeleteAuthorityEnum,
    LeadModifyAll: 'LEAD_MODIFY_ALL' as DeleteAuthorityEnum,
    LeadView: 'LEAD_VIEW' as DeleteAuthorityEnum,
    LeadViewAll: 'LEAD_VIEW_ALL' as DeleteAuthorityEnum,
    LogotypeDelete: 'LOGOTYPE_DELETE' as DeleteAuthorityEnum,
    LogotypeModify: 'LOGOTYPE_MODIFY' as DeleteAuthorityEnum,
    LogotypeView: 'LOGOTYPE_VIEW' as DeleteAuthorityEnum,
    ManagersCreate: 'MANAGERS_CREATE' as DeleteAuthorityEnum,
    ManagersDelete: 'MANAGERS_DELETE' as DeleteAuthorityEnum,
    ManagersForceModify: 'MANAGERS_FORCE_MODIFY' as DeleteAuthorityEnum,
    ManagersModify: 'MANAGERS_MODIFY' as DeleteAuthorityEnum,
    ManagersView: 'MANAGERS_VIEW' as DeleteAuthorityEnum,
    MandatesCreate: 'MANDATES_CREATE' as DeleteAuthorityEnum,
    MandatesDelete: 'MANDATES_DELETE' as DeleteAuthorityEnum,
    MandatesForceModify: 'MANDATES_FORCE_MODIFY' as DeleteAuthorityEnum,
    MandatesModify: 'MANDATES_MODIFY' as DeleteAuthorityEnum,
    MandatesModifyAll: 'MANDATES_MODIFY_ALL' as DeleteAuthorityEnum,
    MandatesView: 'MANDATES_VIEW' as DeleteAuthorityEnum,
    MandatesViewAll: 'MANDATES_VIEW_ALL' as DeleteAuthorityEnum,
    MandateRestrictionsCreate: 'MANDATE_RESTRICTIONS_CREATE' as DeleteAuthorityEnum,
    MandateRestrictionsDelete: 'MANDATE_RESTRICTIONS_DELETE' as DeleteAuthorityEnum,
    MandateRestrictionsModify: 'MANDATE_RESTRICTIONS_MODIFY' as DeleteAuthorityEnum,
    MandateRestrictionsModifyAll: 'MANDATE_RESTRICTIONS_MODIFY_ALL' as DeleteAuthorityEnum,
    MandateRestrictionsView: 'MANDATE_RESTRICTIONS_VIEW' as DeleteAuthorityEnum,
    MandateRestrictionsViewAll: 'MANDATE_RESTRICTIONS_VIEW_ALL' as DeleteAuthorityEnum,
    MandateRestrictionReportsCreate: 'MANDATE_RESTRICTION_REPORTS_CREATE' as DeleteAuthorityEnum,
    MandateRestrictionReportsDelete: 'MANDATE_RESTRICTION_REPORTS_DELETE' as DeleteAuthorityEnum,
    MandateRestrictionReportsModify: 'MANDATE_RESTRICTION_REPORTS_MODIFY' as DeleteAuthorityEnum,
    MandateRestrictionReportsModifyAll:
      'MANDATE_RESTRICTION_REPORTS_MODIFY_ALL' as DeleteAuthorityEnum,
    MandateRestrictionReportsView: 'MANDATE_RESTRICTION_REPORTS_VIEW' as DeleteAuthorityEnum,
    MandateRestrictionReportsViewAll: 'MANDATE_RESTRICTION_REPORTS_VIEW_ALL' as DeleteAuthorityEnum,
    MandateRestrictionTemplatesCreate:
      'MANDATE_RESTRICTION_TEMPLATES_CREATE' as DeleteAuthorityEnum,
    MandateRestrictionTemplatesDelete:
      'MANDATE_RESTRICTION_TEMPLATES_DELETE' as DeleteAuthorityEnum,
    MandateRestrictionTemplatesModify:
      'MANDATE_RESTRICTION_TEMPLATES_MODIFY' as DeleteAuthorityEnum,
    MandateRestrictionTemplatesModifyAll:
      'MANDATE_RESTRICTION_TEMPLATES_MODIFY_ALL' as DeleteAuthorityEnum,
    MandateRestrictionTemplatesView: 'MANDATE_RESTRICTION_TEMPLATES_VIEW' as DeleteAuthorityEnum,
    MandateRestrictionTemplatesViewAll:
      'MANDATE_RESTRICTION_TEMPLATES_VIEW_ALL' as DeleteAuthorityEnum,
    MandateRiskProfileCreate: 'MANDATE_RISK_PROFILE_CREATE' as DeleteAuthorityEnum,
    MandateRiskProfileDelete: 'MANDATE_RISK_PROFILE_DELETE' as DeleteAuthorityEnum,
    MandateRiskProfileForceModify: 'MANDATE_RISK_PROFILE_FORCE_MODIFY' as DeleteAuthorityEnum,
    MandateRiskProfileModify: 'MANDATE_RISK_PROFILE_MODIFY' as DeleteAuthorityEnum,
    MandateRiskProfileView: 'MANDATE_RISK_PROFILE_VIEW' as DeleteAuthorityEnum,
    MandateRiskProfileViewAll: 'MANDATE_RISK_PROFILE_VIEW_ALL' as DeleteAuthorityEnum,
    ManualImportPositionsCreate: 'MANUAL_IMPORT_POSITIONS_CREATE' as DeleteAuthorityEnum,
    ManualImportPositionsModify: 'MANUAL_IMPORT_POSITIONS_MODIFY' as DeleteAuthorityEnum,
    ManualImportPositionsView: 'MANUAL_IMPORT_POSITIONS_VIEW' as DeleteAuthorityEnum,
    ManualImportTransactionsCreate: 'MANUAL_IMPORT_TRANSACTIONS_CREATE' as DeleteAuthorityEnum,
    ManualImportTransactionsModify: 'MANUAL_IMPORT_TRANSACTIONS_MODIFY' as DeleteAuthorityEnum,
    ManualImportTransactionsView: 'MANUAL_IMPORT_TRANSACTIONS_VIEW' as DeleteAuthorityEnum,
    MarketplaceModify: 'MARKETPLACE_MODIFY' as DeleteAuthorityEnum,
    MarketplaceView: 'MARKETPLACE_VIEW' as DeleteAuthorityEnum,
    MarketSecuritiesModify: 'MARKET_SECURITIES_MODIFY' as DeleteAuthorityEnum,
    MarketSecuritiesView: 'MARKET_SECURITIES_VIEW' as DeleteAuthorityEnum,
    MifirModify: 'MIFIR_MODIFY' as DeleteAuthorityEnum,
    MifirReport: 'MIFIR_REPORT' as DeleteAuthorityEnum,
    MifirView: 'MIFIR_VIEW' as DeleteAuthorityEnum,
    ModelPortfoliosCreate: 'MODEL_PORTFOLIOS_CREATE' as DeleteAuthorityEnum,
    ModelPortfoliosDelete: 'MODEL_PORTFOLIOS_DELETE' as DeleteAuthorityEnum,
    ModelPortfoliosModify: 'MODEL_PORTFOLIOS_MODIFY' as DeleteAuthorityEnum,
    ModelPortfoliosView: 'MODEL_PORTFOLIOS_VIEW' as DeleteAuthorityEnum,
    MonthlyReconciliationReport: 'MONTHLY_RECONCILIATION_REPORT' as DeleteAuthorityEnum,
    NotificationTemplatesCreate: 'NOTIFICATION_TEMPLATES_CREATE' as DeleteAuthorityEnum,
    NotificationTemplatesDelete: 'NOTIFICATION_TEMPLATES_DELETE' as DeleteAuthorityEnum,
    NotificationTemplatesModify: 'NOTIFICATION_TEMPLATES_MODIFY' as DeleteAuthorityEnum,
    NotificationTemplatesView: 'NOTIFICATION_TEMPLATES_VIEW' as DeleteAuthorityEnum,
    NotificationUserLogView: 'NOTIFICATION_USER_LOG_VIEW' as DeleteAuthorityEnum,
    Omnibus: 'OMNIBUS' as DeleteAuthorityEnum,
    OnlinePricingView: 'ONLINE_PRICING_VIEW' as DeleteAuthorityEnum,
    OpportunityCreate: 'OPPORTUNITY_CREATE' as DeleteAuthorityEnum,
    OpportunityDelete: 'OPPORTUNITY_DELETE' as DeleteAuthorityEnum,
    OpportunityForceModify: 'OPPORTUNITY_FORCE_MODIFY' as DeleteAuthorityEnum,
    OpportunityModify: 'OPPORTUNITY_MODIFY' as DeleteAuthorityEnum,
    OpportunityModifyAll: 'OPPORTUNITY_MODIFY_ALL' as DeleteAuthorityEnum,
    OpportunityView: 'OPPORTUNITY_VIEW' as DeleteAuthorityEnum,
    OpportunityViewAll: 'OPPORTUNITY_VIEW_ALL' as DeleteAuthorityEnum,
    OrdersAssign: 'ORDERS_ASSIGN' as DeleteAuthorityEnum,
    OrdersCommonCreate: 'ORDERS_COMMON_CREATE' as DeleteAuthorityEnum,
    OrdersCommonDelete: 'ORDERS_COMMON_DELETE' as DeleteAuthorityEnum,
    OrdersCommonModify: 'ORDERS_COMMON_MODIFY' as DeleteAuthorityEnum,
    OrdersCommonView: 'ORDERS_COMMON_VIEW' as DeleteAuthorityEnum,
    OrdersCorpActionCreate: 'ORDERS_CORP_ACTION_CREATE' as DeleteAuthorityEnum,
    OrdersCorpActionDelete: 'ORDERS_CORP_ACTION_DELETE' as DeleteAuthorityEnum,
    OrdersCorpActionModify: 'ORDERS_CORP_ACTION_MODIFY' as DeleteAuthorityEnum,
    OrdersCorpActionView: 'ORDERS_CORP_ACTION_VIEW' as DeleteAuthorityEnum,
    OrdersCreate: 'ORDERS_CREATE' as DeleteAuthorityEnum,
    OrdersDelete: 'ORDERS_DELETE' as DeleteAuthorityEnum,
    OrdersDmaCreate: 'ORDERS_DMA_CREATE' as DeleteAuthorityEnum,
    OrdersForceModify: 'ORDERS_FORCE_MODIFY' as DeleteAuthorityEnum,
    OrdersModify: 'ORDERS_MODIFY' as DeleteAuthorityEnum,
    OrdersModifyAll: 'ORDERS_MODIFY_ALL' as DeleteAuthorityEnum,
    OrdersNonTradeCreate: 'ORDERS_NON_TRADE_CREATE' as DeleteAuthorityEnum,
    OrdersNonTradeDelete: 'ORDERS_NON_TRADE_DELETE' as DeleteAuthorityEnum,
    OrdersNonTradeModify: 'ORDERS_NON_TRADE_MODIFY' as DeleteAuthorityEnum,
    OrdersNonTradeView: 'ORDERS_NON_TRADE_VIEW' as DeleteAuthorityEnum,
    OrdersTechInfoModify: 'ORDERS_TECH_INFO_MODIFY' as DeleteAuthorityEnum,
    OrdersTechInfoView: 'ORDERS_TECH_INFO_VIEW' as DeleteAuthorityEnum,
    OrdersTradeCreate: 'ORDERS_TRADE_CREATE' as DeleteAuthorityEnum,
    OrdersTradeDelete: 'ORDERS_TRADE_DELETE' as DeleteAuthorityEnum,
    OrdersTradeModify: 'ORDERS_TRADE_MODIFY' as DeleteAuthorityEnum,
    OrdersTradeView: 'ORDERS_TRADE_VIEW' as DeleteAuthorityEnum,
    OrdersView: 'ORDERS_VIEW' as DeleteAuthorityEnum,
    OrdersViewAll: 'ORDERS_VIEW_ALL' as DeleteAuthorityEnum,
    PersonalAm: 'PERSONAL_AM' as DeleteAuthorityEnum,
    PnlReport: 'PNL_REPORT' as DeleteAuthorityEnum,
    PortfoliosCreate: 'PORTFOLIOS_CREATE' as DeleteAuthorityEnum,
    PortfoliosDelete: 'PORTFOLIOS_DELETE' as DeleteAuthorityEnum,
    PortfoliosForceModify: 'PORTFOLIOS_FORCE_MODIFY' as DeleteAuthorityEnum,
    PortfoliosModify: 'PORTFOLIOS_MODIFY' as DeleteAuthorityEnum,
    PortfoliosModifyAll: 'PORTFOLIOS_MODIFY_ALL' as DeleteAuthorityEnum,
    PortfoliosView: 'PORTFOLIOS_VIEW' as DeleteAuthorityEnum,
    PortfoliosViewAll: 'PORTFOLIOS_VIEW_ALL' as DeleteAuthorityEnum,
    PortfolioConfigurationView: 'PORTFOLIO_CONFIGURATION_VIEW' as DeleteAuthorityEnum,
    PortfolioCostsView: 'PORTFOLIO_COSTS_VIEW' as DeleteAuthorityEnum,
    PortfolioFeesView: 'PORTFOLIO_FEES_VIEW' as DeleteAuthorityEnum,
    PortfolioFlowsView: 'PORTFOLIO_FLOWS_VIEW' as DeleteAuthorityEnum,
    PortfolioOrdersAssign: 'PORTFOLIO_ORDERS_ASSIGN' as DeleteAuthorityEnum,
    PortfolioOrdersCreate: 'PORTFOLIO_ORDERS_CREATE' as DeleteAuthorityEnum,
    PortfolioOrdersDelete: 'PORTFOLIO_ORDERS_DELETE' as DeleteAuthorityEnum,
    PortfolioOrdersModify: 'PORTFOLIO_ORDERS_MODIFY' as DeleteAuthorityEnum,
    PortfolioOrdersView: 'PORTFOLIO_ORDERS_VIEW' as DeleteAuthorityEnum,
    PortfolioPnlView: 'PORTFOLIO_PNL_VIEW' as DeleteAuthorityEnum,
    PortfolioPositionsView: 'PORTFOLIO_POSITIONS_VIEW' as DeleteAuthorityEnum,
    PortfolioProfileModify: 'PORTFOLIO_PROFILE_MODIFY' as DeleteAuthorityEnum,
    PortfolioProfileView: 'PORTFOLIO_PROFILE_VIEW' as DeleteAuthorityEnum,
    PortfolioRestrictionsView: 'PORTFOLIO_RESTRICTIONS_VIEW' as DeleteAuthorityEnum,
    PortfolioTransactionsCreate: 'PORTFOLIO_TRANSACTIONS_CREATE' as DeleteAuthorityEnum,
    PortfolioTransactionsDelete: 'PORTFOLIO_TRANSACTIONS_DELETE' as DeleteAuthorityEnum,
    PortfolioTransactionsModify: 'PORTFOLIO_TRANSACTIONS_MODIFY' as DeleteAuthorityEnum,
    PortfolioTransactionsStatus: 'PORTFOLIO_TRANSACTIONS_STATUS' as DeleteAuthorityEnum,
    PortfolioTransactionsView: 'PORTFOLIO_TRANSACTIONS_VIEW' as DeleteAuthorityEnum,
    PortfolioValuationView: 'PORTFOLIO_VALUATION_VIEW' as DeleteAuthorityEnum,
    PositionsView: 'POSITIONS_VIEW' as DeleteAuthorityEnum,
    PricesLoadingMonitorView: 'PRICES_LOADING_MONITOR_VIEW' as DeleteAuthorityEnum,
    PrivateInstrumentCreate: 'PRIVATE_INSTRUMENT_CREATE' as DeleteAuthorityEnum,
    PrivateInstrumentDelete: 'PRIVATE_INSTRUMENT_DELETE' as DeleteAuthorityEnum,
    PrivateInstrumentModify: 'PRIVATE_INSTRUMENT_MODIFY' as DeleteAuthorityEnum,
    PrivateInstrumentModifyAll: 'PRIVATE_INSTRUMENT_MODIFY_ALL' as DeleteAuthorityEnum,
    PrivateInstrumentView: 'PRIVATE_INSTRUMENT_VIEW' as DeleteAuthorityEnum,
    PrivateInstrumentViewAll: 'PRIVATE_INSTRUMENT_VIEW_ALL' as DeleteAuthorityEnum,
    ProcessCreate: 'PROCESS_CREATE' as DeleteAuthorityEnum,
    ProcessDelete: 'PROCESS_DELETE' as DeleteAuthorityEnum,
    ProcessModify: 'PROCESS_MODIFY' as DeleteAuthorityEnum,
    ProcessView: 'PROCESS_VIEW' as DeleteAuthorityEnum,
    ProductGovernanceModify: 'PRODUCT_GOVERNANCE_MODIFY' as DeleteAuthorityEnum,
    ProductGovernanceView: 'PRODUCT_GOVERNANCE_VIEW' as DeleteAuthorityEnum,
    ProfilesCreate: 'PROFILES_CREATE' as DeleteAuthorityEnum,
    ProfilesDelete: 'PROFILES_DELETE' as DeleteAuthorityEnum,
    ProfilesModify: 'PROFILES_MODIFY' as DeleteAuthorityEnum,
    ProfilesView: 'PROFILES_VIEW' as DeleteAuthorityEnum,
    QuarterlyStatisticsReport: 'QUARTERLY_STATISTICS_REPORT' as DeleteAuthorityEnum,
    RbsFReport: 'RBS_F_REPORT' as DeleteAuthorityEnum,
    RecommendationCreate: 'RECOMMENDATION_CREATE' as DeleteAuthorityEnum,
    RecommendationForceModify: 'RECOMMENDATION_FORCE_MODIFY' as DeleteAuthorityEnum,
    RecommendationModify: 'RECOMMENDATION_MODIFY' as DeleteAuthorityEnum,
    RecommendationView: 'RECOMMENDATION_VIEW' as DeleteAuthorityEnum,
    RecommendationViewAll: 'RECOMMENDATION_VIEW_ALL' as DeleteAuthorityEnum,
    ReconciliationReport: 'RECONCILIATION_REPORT' as DeleteAuthorityEnum,
    RegulatorCreate: 'REGULATOR_CREATE' as DeleteAuthorityEnum,
    RegulatorDelete: 'REGULATOR_DELETE' as DeleteAuthorityEnum,
    RegulatorModify: 'REGULATOR_MODIFY' as DeleteAuthorityEnum,
    RegulatorView: 'REGULATOR_VIEW' as DeleteAuthorityEnum,
    RelunaOrdersReportsView: 'RELUNA_ORDERS_REPORTS_VIEW' as DeleteAuthorityEnum,
    ReportsCreate: 'REPORTS_CREATE' as DeleteAuthorityEnum,
    ReportsDelete: 'REPORTS_DELETE' as DeleteAuthorityEnum,
    ReportsModify: 'REPORTS_MODIFY' as DeleteAuthorityEnum,
    ReportsView: 'REPORTS_VIEW' as DeleteAuthorityEnum,
    RestrictionsCreate: 'RESTRICTIONS_CREATE' as DeleteAuthorityEnum,
    RestrictionsDelete: 'RESTRICTIONS_DELETE' as DeleteAuthorityEnum,
    RestrictionsModify: 'RESTRICTIONS_MODIFY' as DeleteAuthorityEnum,
    RestrictionsModifyAll: 'RESTRICTIONS_MODIFY_ALL' as DeleteAuthorityEnum,
    RestrictionsView: 'RESTRICTIONS_VIEW' as DeleteAuthorityEnum,
    RestrictionsViewAll: 'RESTRICTIONS_VIEW_ALL' as DeleteAuthorityEnum,
    RestrictionAlertView: 'RESTRICTION_ALERT_VIEW' as DeleteAuthorityEnum,
    RestrictionReportsCreate: 'RESTRICTION_REPORTS_CREATE' as DeleteAuthorityEnum,
    RestrictionReportsDelete: 'RESTRICTION_REPORTS_DELETE' as DeleteAuthorityEnum,
    RestrictionReportsModify: 'RESTRICTION_REPORTS_MODIFY' as DeleteAuthorityEnum,
    RestrictionReportsModifyAll: 'RESTRICTION_REPORTS_MODIFY_ALL' as DeleteAuthorityEnum,
    RestrictionReportsView: 'RESTRICTION_REPORTS_VIEW' as DeleteAuthorityEnum,
    RestrictionReportsViewAll: 'RESTRICTION_REPORTS_VIEW_ALL' as DeleteAuthorityEnum,
    RestrictionTemplatesCreate: 'RESTRICTION_TEMPLATES_CREATE' as DeleteAuthorityEnum,
    RestrictionTemplatesDelete: 'RESTRICTION_TEMPLATES_DELETE' as DeleteAuthorityEnum,
    RestrictionTemplatesModify: 'RESTRICTION_TEMPLATES_MODIFY' as DeleteAuthorityEnum,
    RestrictionTemplatesModifyAll: 'RESTRICTION_TEMPLATES_MODIFY_ALL' as DeleteAuthorityEnum,
    RestrictionTemplatesView: 'RESTRICTION_TEMPLATES_VIEW' as DeleteAuthorityEnum,
    RestrictionTemplatesViewAll: 'RESTRICTION_TEMPLATES_VIEW_ALL' as DeleteAuthorityEnum,
    RiskReportCreate: 'RISK_REPORT_CREATE' as DeleteAuthorityEnum,
    RiskReportView: 'RISK_REPORT_VIEW' as DeleteAuthorityEnum,
    RolesCreate: 'ROLES_CREATE' as DeleteAuthorityEnum,
    RolesDelete: 'ROLES_DELETE' as DeleteAuthorityEnum,
    RolesModify: 'ROLES_MODIFY' as DeleteAuthorityEnum,
    RolesView: 'ROLES_VIEW' as DeleteAuthorityEnum,
    Rts28Report: 'RTS28_REPORT' as DeleteAuthorityEnum,
    SaltedgeTransactionsModify: 'SALTEDGE_TRANSACTIONS_MODIFY' as DeleteAuthorityEnum,
    SaltedgeTransactionsView: 'SALTEDGE_TRANSACTIONS_VIEW' as DeleteAuthorityEnum,
    SendReports: 'SEND_REPORTS' as DeleteAuthorityEnum,
    SettingsModify: 'SETTINGS_MODIFY' as DeleteAuthorityEnum,
    SettingsView: 'SETTINGS_VIEW' as DeleteAuthorityEnum,
    ShopView: 'SHOP_VIEW' as DeleteAuthorityEnum,
    SiteInfoCreate: 'SITE_INFO_CREATE' as DeleteAuthorityEnum,
    SiteInfoDelete: 'SITE_INFO_DELETE' as DeleteAuthorityEnum,
    SiteInfoModify: 'SITE_INFO_MODIFY' as DeleteAuthorityEnum,
    SiteInfoView: 'SITE_INFO_VIEW' as DeleteAuthorityEnum,
    SovaDataModify: 'SOVA_DATA_MODIFY' as DeleteAuthorityEnum,
    SovaDataView: 'SOVA_DATA_VIEW' as DeleteAuthorityEnum,
    SovaOrdersModify: 'SOVA_ORDERS_MODIFY' as DeleteAuthorityEnum,
    SovaOrdersView: 'SOVA_ORDERS_VIEW' as DeleteAuthorityEnum,
    StatementOfEligibleFundsReport: 'STATEMENT_OF_ELIGIBLE_FUNDS_REPORT' as DeleteAuthorityEnum,
    StatisticsView: 'STATISTICS_VIEW' as DeleteAuthorityEnum,
    StrategiesCreate: 'STRATEGIES_CREATE' as DeleteAuthorityEnum,
    StrategiesDelete: 'STRATEGIES_DELETE' as DeleteAuthorityEnum,
    StrategiesForceModify: 'STRATEGIES_FORCE_MODIFY' as DeleteAuthorityEnum,
    StrategiesModify: 'STRATEGIES_MODIFY' as DeleteAuthorityEnum,
    StrategiesModifyAll: 'STRATEGIES_MODIFY_ALL' as DeleteAuthorityEnum,
    StrategiesView: 'STRATEGIES_VIEW' as DeleteAuthorityEnum,
    StrategiesViewAll: 'STRATEGIES_VIEW_ALL' as DeleteAuthorityEnum,
    StructuredProductsCreate: 'STRUCTURED_PRODUCTS_CREATE' as DeleteAuthorityEnum,
    StructuredProductsDelete: 'STRUCTURED_PRODUCTS_DELETE' as DeleteAuthorityEnum,
    StructuredProductsModify: 'STRUCTURED_PRODUCTS_MODIFY' as DeleteAuthorityEnum,
    StructuredProductsModifyAll: 'STRUCTURED_PRODUCTS_MODIFY_ALL' as DeleteAuthorityEnum,
    StructuredProductsView: 'STRUCTURED_PRODUCTS_VIEW' as DeleteAuthorityEnum,
    StructuredProductsViewAll: 'STRUCTURED_PRODUCTS_VIEW_ALL' as DeleteAuthorityEnum,
    SubportfoliosView: 'SUBPORTFOLIOS_VIEW' as DeleteAuthorityEnum,
    SubscriptionModify: 'SUBSCRIPTION_MODIFY' as DeleteAuthorityEnum,
    SubscriptionView: 'SUBSCRIPTION_VIEW' as DeleteAuthorityEnum,
    TableConstructorModify: 'TABLE_CONSTRUCTOR_MODIFY' as DeleteAuthorityEnum,
    TableConstructorView: 'TABLE_CONSTRUCTOR_VIEW' as DeleteAuthorityEnum,
    TaviraDataModify: 'TAVIRA_DATA_MODIFY' as DeleteAuthorityEnum,
    TaviraDataView: 'TAVIRA_DATA_VIEW' as DeleteAuthorityEnum,
    TransactionsCreate: 'TRANSACTIONS_CREATE' as DeleteAuthorityEnum,
    TransactionsDelete: 'TRANSACTIONS_DELETE' as DeleteAuthorityEnum,
    TransactionsForceModify: 'TRANSACTIONS_FORCE_MODIFY' as DeleteAuthorityEnum,
    TransactionsMatchingModify: 'TRANSACTIONS_MATCHING_MODIFY' as DeleteAuthorityEnum,
    TransactionsMatchingView: 'TRANSACTIONS_MATCHING_VIEW' as DeleteAuthorityEnum,
    TransactionsModify: 'TRANSACTIONS_MODIFY' as DeleteAuthorityEnum,
    TransactionsModifyAll: 'TRANSACTIONS_MODIFY_ALL' as DeleteAuthorityEnum,
    TransactionsStatus: 'TRANSACTIONS_STATUS' as DeleteAuthorityEnum,
    TransactionsView: 'TRANSACTIONS_VIEW' as DeleteAuthorityEnum,
    TransactionsViewAll: 'TRANSACTIONS_VIEW_ALL' as DeleteAuthorityEnum,
    TransactionLoaderCreate: 'TRANSACTION_LOADER_CREATE' as DeleteAuthorityEnum,
    TransactionLoaderModify: 'TRANSACTION_LOADER_MODIFY' as DeleteAuthorityEnum,
    TransactionLoaderView: 'TRANSACTION_LOADER_VIEW' as DeleteAuthorityEnum,
    TransactionWithoutBankView: 'TRANSACTION_WITHOUT_BANK_VIEW' as DeleteAuthorityEnum,
    TrimpDataModify: 'TRIMP_DATA_MODIFY' as DeleteAuthorityEnum,
    TrimpDataView: 'TRIMP_DATA_VIEW' as DeleteAuthorityEnum,
    Ttca: 'TTCA' as DeleteAuthorityEnum,
    UbsDataModify: 'UBS_DATA_MODIFY' as DeleteAuthorityEnum,
    UbsDataView: 'UBS_DATA_VIEW' as DeleteAuthorityEnum,
    ValueDateRulesModify: 'VALUE_DATE_RULES_MODIFY' as DeleteAuthorityEnum,
    ValueDateRulesView: 'VALUE_DATE_RULES_VIEW' as DeleteAuthorityEnum,
    VpDataModify: 'VP_DATA_MODIFY' as DeleteAuthorityEnum,
    VpDataView: 'VP_DATA_VIEW' as DeleteAuthorityEnum,
    VpLoaderSettingsCreate: 'VP_LOADER_SETTINGS_CREATE' as DeleteAuthorityEnum,
    VpLoaderSettingsDelete: 'VP_LOADER_SETTINGS_DELETE' as DeleteAuthorityEnum,
    VpLoaderSettingsModify: 'VP_LOADER_SETTINGS_MODIFY' as DeleteAuthorityEnum,
    VpLoaderSettingsView: 'VP_LOADER_SETTINGS_VIEW' as DeleteAuthorityEnum,
    VtbDataModify: 'VTB_DATA_MODIFY' as DeleteAuthorityEnum,
    VtbDataView: 'VTB_DATA_VIEW' as DeleteAuthorityEnum,
    ZohoSettingsCreate: 'ZOHO_SETTINGS_CREATE' as DeleteAuthorityEnum,
    ZohoSettingsDelete: 'ZOHO_SETTINGS_DELETE' as DeleteAuthorityEnum,
    ZohoSettingsModify: 'ZOHO_SETTINGS_MODIFY' as DeleteAuthorityEnum,
    ZohoSettingsView: 'ZOHO_SETTINGS_VIEW' as DeleteAuthorityEnum,
  };
  export type ForceModifyAuthorityEnum =
    | 'ACCOUNTS_CREATE'
    | 'ACCOUNTS_DELETE'
    | 'ACCOUNTS_FORCE_MODIFY'
    | 'ACCOUNTS_MODIFY'
    | 'ACCOUNTS_MODIFY_ALL'
    | 'ACCOUNTS_VIEW'
    | 'ACCOUNTS_VIEW_ALL'
    | 'ACCOUNT_STRUCTURE'
    | 'ACTUATOR_VIEW'
    | 'ADVANCED_ANALYTICS_VIEW'
    | 'ALFA_DATA_MODIFY'
    | 'ALFA_DATA_VIEW'
    | 'AMAZON_WORK_DOCS'
    | 'BANK_ACCOUNTS_CREATE'
    | 'BANK_ACCOUNTS_DELETE'
    | 'BANK_ACCOUNTS_FORCE_MODIFY'
    | 'BANK_ACCOUNTS_MODIFY'
    | 'BANK_ACCOUNTS_MODIFY_ALL'
    | 'BANK_ACCOUNTS_VIEW'
    | 'BANK_ACCOUNTS_VIEW_ALL'
    | 'BANK_STATS_VIEW'
    | 'BKS_DATA_MODIFY'
    | 'BKS_DATA_VIEW'
    | 'BKS_LOADER_SETTINGS_CREATE'
    | 'BKS_LOADER_SETTINGS_DELETE'
    | 'BKS_LOADER_SETTINGS_MODIFY'
    | 'BKS_LOADER_SETTINGS_VIEW'
    | 'BLOOMBERG_REQUESTS_VIEW'
    | 'BROKER_CREATE'
    | 'BROKER_DELETE'
    | 'BROKER_MODIFY'
    | 'BROKER_VIEW'
    | 'BUSINESS_EVENTS_VIEW'
    | 'CASS_REPORT'
    | 'CBH_BS_DATA_MODIFY'
    | 'CBH_BS_DATA_VIEW'
    | 'CBH_DATA_MODIFY'
    | 'CBH_DATA_VIEW'
    | 'CBH_LOADER_SETTINGS_CREATE'
    | 'CBH_LOADER_SETTINGS_DELETE'
    | 'CBH_LOADER_SETTINGS_MODIFY'
    | 'CBH_LOADER_SETTINGS_VIEW'
    | 'CHANGE_REQUEST_SETTINGS_CREATE'
    | 'CHANGE_REQUEST_SETTINGS_DELETE'
    | 'CHANGE_REQUEST_SETTINGS_MODIFY'
    | 'CHANGE_REQUEST_SETTINGS_VIEW'
    | 'CLIENTS_CREATE'
    | 'CLIENTS_DELETE'
    | 'CLIENTS_MODIFY'
    | 'CLIENTS_MODIFY_ALL'
    | 'CLIENTS_VIEW'
    | 'CLIENTS_VIEW_ALL'
    | 'CLIENT_COUNT_REPORT'
    | 'CLIENT_FEES_REPORT'
    | 'CLIENT_VALUATION_REPORT'
    | 'CMAR_REPORT'
    | 'COMMON_EMAIL_TEMPLATES_CREATE'
    | 'COMMON_EMAIL_TEMPLATES_DELETE'
    | 'COMMON_EMAIL_TEMPLATES_MODIFY'
    | 'COMMON_EMAIL_TEMPLATES_VIEW'
    | 'COMPANIES_CREATE'
    | 'COMPANIES_DELETE'
    | 'COMPANIES_MODIFY'
    | 'COMPANIES_TAGS_VIEW'
    | 'COMPANIES_VIEW'
    | 'COMPANY_HOLDING_CREATE'
    | 'COMPANY_HOLDING_DELETE'
    | 'COMPANY_HOLDING_MODIFY'
    | 'COMPANY_HOLDING_VIEW'
    | 'COMPANY_RISK_REPORT'
    | 'COMPLIANCE_ACCOUNT_COMPANY_CREATE'
    | 'COMPLIANCE_ACCOUNT_COMPANY_DELETE'
    | 'COMPLIANCE_ACCOUNT_COMPANY_FORCE_MODIFY'
    | 'COMPLIANCE_ACCOUNT_COMPANY_MODIFY'
    | 'COMPLIANCE_ACCOUNT_COMPANY_VIEW'
    | 'COUNTERPARTY_CREATE'
    | 'COUNTERPARTY_DELETE'
    | 'COUNTERPARTY_MODIFY'
    | 'COUNTERPARTY_VIEW'
    | 'COUPON_PROJECTION_REPORT'
    | 'CRS_REPORT'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_CREATE'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_MODIFY'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_VIEW'
    | 'CUSTODIAN_CREATE'
    | 'CUSTODIAN_DELETE'
    | 'CUSTODIAN_MODIFY'
    | 'CUSTODIAN_VIEW'
    | 'DASHBOARD_MODIFY'
    | 'DASHBOARD_VIEW'
    | 'DBS_DATA_MODIFY'
    | 'DBS_DATA_VIEW'
    | 'DEACTIVATED_TRANSACTION_VIEW'
    | 'DEPARTMENTS_CREATE'
    | 'DEPARTMENTS_DELETE'
    | 'DEPARTMENTS_MODIFY'
    | 'DEPARTMENTS_VIEW'
    | 'DOLFIN_TRANSACTIONS_MODIFY'
    | 'DOLFIN_TRANSACTIONS_VIEW'
    | 'EDR_DATA_MODIFY'
    | 'EDR_DATA_VIEW'
    | 'EDR_LOADER_SETTINGS_CREATE'
    | 'EDR_LOADER_SETTINGS_DELETE'
    | 'EDR_LOADER_SETTINGS_MODIFY'
    | 'EDR_LOADER_SETTINGS_VIEW'
    | 'EMAIL_TEMPLATE_CREATE'
    | 'EMAIL_TEMPLATE_DELETE'
    | 'EMAIL_TEMPLATE_MODIFY'
    | 'EMAIL_TEMPLATE_VIEW'
    | 'EXECGW_REPORTS_VIEW'
    | 'FEES_CREATE'
    | 'FEES_MODIFY'
    | 'FEES_VIEW'
    | 'FEES_VIEW_ALL'
    | 'FIELDS_CREATE'
    | 'FIELDS_DELETE'
    | 'FIELDS_MODIFY'
    | 'FIELDS_VIEW'
    | 'FILE_USER_LOG_VIEW'
    | 'GAP_MONITOR_VIEW'
    | 'GLOBAL_ALLOCATION_CREATE'
    | 'GLOBAL_ALLOCATION_MODIFY'
    | 'GLOBAL_ALLOCATION_VIEW'
    | 'GLOBAL_ALLOCATION_WIDGET_VIEW'
    | 'GOOGLE_AUTH_MODIFY'
    | 'GOOGLE_AUTH_VIEW'
    | 'GPP_DATA_MODIFY'
    | 'GPP_DATA_VIEW'
    | 'GPP_LOADER_SETTINGS_CREATE'
    | 'GPP_LOADER_SETTINGS_DELETE'
    | 'GPP_LOADER_SETTINGS_MODIFY'
    | 'GPP_LOADER_SETTINGS_VIEW'
    | 'HEALTH_VIEW'
    | 'HISTORY_VIEW'
    | 'IBKR_DATA_MODIFY'
    | 'IBKR_DATA_VIEW'
    | 'IBKR_FUTURES_MAPPING_CREATE'
    | 'IBKR_FUTURES_MAPPING_DELETE'
    | 'IBKR_FUTURES_MAPPING_MODIFY'
    | 'IBKR_FUTURES_MAPPING_VIEW'
    | 'IBKR_LOADER_SETTINGS_CREATE'
    | 'IBKR_LOADER_SETTINGS_DELETE'
    | 'IBKR_LOADER_SETTINGS_MODIFY'
    | 'IBKR_LOADER_SETTINGS_VIEW'
    | 'INCOME_PAYMENT_TRACKER'
    | 'INSTRUMENTS_CREATE'
    | 'INSTRUMENTS_MODIFY'
    | 'INSTRUMENTS_VIEW'
    | 'INSTRUMENT_TO_AGENT_VIEW'
    | 'INVESTEC_DATA_MODIFY'
    | 'INVESTEC_DATA_VIEW'
    | 'INVESTEC_LOADER_SETTINGS_CREATE'
    | 'INVESTEC_LOADER_SETTINGS_DELETE'
    | 'INVESTEC_LOADER_SETTINGS_MODIFY'
    | 'INVESTEC_LOADER_SETTINGS_VIEW'
    | 'INVESTMENT_VIEW'
    | 'IP_ACCESS'
    | 'JB_DATA_MODIFY'
    | 'JB_DATA_VIEW'
    | 'JB_LOADER_SETTINGS_CREATE'
    | 'JB_LOADER_SETTINGS_DELETE'
    | 'JB_LOADER_SETTINGS_MODIFY'
    | 'JB_LOADER_SETTINGS_VIEW'
    | 'JPM_DATA_MODIFY'
    | 'JPM_DATA_VIEW'
    | 'JPM_LOADER_SETTINGS_CREATE'
    | 'JPM_LOADER_SETTINGS_DELETE'
    | 'JPM_LOADER_SETTINGS_MODIFY'
    | 'JPM_LOADER_SETTINGS_VIEW'
    | 'LEAD_CREATE'
    | 'LEAD_DELETE'
    | 'LEAD_MODIFY'
    | 'LEAD_MODIFY_ALL'
    | 'LEAD_VIEW'
    | 'LEAD_VIEW_ALL'
    | 'LOGOTYPE_DELETE'
    | 'LOGOTYPE_MODIFY'
    | 'LOGOTYPE_VIEW'
    | 'MANAGERS_CREATE'
    | 'MANAGERS_DELETE'
    | 'MANAGERS_FORCE_MODIFY'
    | 'MANAGERS_MODIFY'
    | 'MANAGERS_VIEW'
    | 'MANDATES_CREATE'
    | 'MANDATES_DELETE'
    | 'MANDATES_FORCE_MODIFY'
    | 'MANDATES_MODIFY'
    | 'MANDATES_MODIFY_ALL'
    | 'MANDATES_VIEW'
    | 'MANDATES_VIEW_ALL'
    | 'MANDATE_RESTRICTIONS_CREATE'
    | 'MANDATE_RESTRICTIONS_DELETE'
    | 'MANDATE_RESTRICTIONS_MODIFY'
    | 'MANDATE_RESTRICTIONS_MODIFY_ALL'
    | 'MANDATE_RESTRICTIONS_VIEW'
    | 'MANDATE_RESTRICTIONS_VIEW_ALL'
    | 'MANDATE_RESTRICTION_REPORTS_CREATE'
    | 'MANDATE_RESTRICTION_REPORTS_DELETE'
    | 'MANDATE_RESTRICTION_REPORTS_MODIFY'
    | 'MANDATE_RESTRICTION_REPORTS_MODIFY_ALL'
    | 'MANDATE_RESTRICTION_REPORTS_VIEW'
    | 'MANDATE_RESTRICTION_REPORTS_VIEW_ALL'
    | 'MANDATE_RESTRICTION_TEMPLATES_CREATE'
    | 'MANDATE_RESTRICTION_TEMPLATES_DELETE'
    | 'MANDATE_RESTRICTION_TEMPLATES_MODIFY'
    | 'MANDATE_RESTRICTION_TEMPLATES_MODIFY_ALL'
    | 'MANDATE_RESTRICTION_TEMPLATES_VIEW'
    | 'MANDATE_RESTRICTION_TEMPLATES_VIEW_ALL'
    | 'MANDATE_RISK_PROFILE_CREATE'
    | 'MANDATE_RISK_PROFILE_DELETE'
    | 'MANDATE_RISK_PROFILE_FORCE_MODIFY'
    | 'MANDATE_RISK_PROFILE_MODIFY'
    | 'MANDATE_RISK_PROFILE_VIEW'
    | 'MANDATE_RISK_PROFILE_VIEW_ALL'
    | 'MANUAL_IMPORT_POSITIONS_CREATE'
    | 'MANUAL_IMPORT_POSITIONS_MODIFY'
    | 'MANUAL_IMPORT_POSITIONS_VIEW'
    | 'MANUAL_IMPORT_TRANSACTIONS_CREATE'
    | 'MANUAL_IMPORT_TRANSACTIONS_MODIFY'
    | 'MANUAL_IMPORT_TRANSACTIONS_VIEW'
    | 'MARKETPLACE_MODIFY'
    | 'MARKETPLACE_VIEW'
    | 'MARKET_SECURITIES_MODIFY'
    | 'MARKET_SECURITIES_VIEW'
    | 'MIFIR_MODIFY'
    | 'MIFIR_REPORT'
    | 'MIFIR_VIEW'
    | 'MODEL_PORTFOLIOS_CREATE'
    | 'MODEL_PORTFOLIOS_DELETE'
    | 'MODEL_PORTFOLIOS_MODIFY'
    | 'MODEL_PORTFOLIOS_VIEW'
    | 'MONTHLY_RECONCILIATION_REPORT'
    | 'NOTIFICATION_TEMPLATES_CREATE'
    | 'NOTIFICATION_TEMPLATES_DELETE'
    | 'NOTIFICATION_TEMPLATES_MODIFY'
    | 'NOTIFICATION_TEMPLATES_VIEW'
    | 'NOTIFICATION_USER_LOG_VIEW'
    | 'OMNIBUS'
    | 'ONLINE_PRICING_VIEW'
    | 'OPPORTUNITY_CREATE'
    | 'OPPORTUNITY_DELETE'
    | 'OPPORTUNITY_FORCE_MODIFY'
    | 'OPPORTUNITY_MODIFY'
    | 'OPPORTUNITY_MODIFY_ALL'
    | 'OPPORTUNITY_VIEW'
    | 'OPPORTUNITY_VIEW_ALL'
    | 'ORDERS_ASSIGN'
    | 'ORDERS_COMMON_CREATE'
    | 'ORDERS_COMMON_DELETE'
    | 'ORDERS_COMMON_MODIFY'
    | 'ORDERS_COMMON_VIEW'
    | 'ORDERS_CORP_ACTION_CREATE'
    | 'ORDERS_CORP_ACTION_DELETE'
    | 'ORDERS_CORP_ACTION_MODIFY'
    | 'ORDERS_CORP_ACTION_VIEW'
    | 'ORDERS_CREATE'
    | 'ORDERS_DELETE'
    | 'ORDERS_DMA_CREATE'
    | 'ORDERS_FORCE_MODIFY'
    | 'ORDERS_MODIFY'
    | 'ORDERS_MODIFY_ALL'
    | 'ORDERS_NON_TRADE_CREATE'
    | 'ORDERS_NON_TRADE_DELETE'
    | 'ORDERS_NON_TRADE_MODIFY'
    | 'ORDERS_NON_TRADE_VIEW'
    | 'ORDERS_TECH_INFO_MODIFY'
    | 'ORDERS_TECH_INFO_VIEW'
    | 'ORDERS_TRADE_CREATE'
    | 'ORDERS_TRADE_DELETE'
    | 'ORDERS_TRADE_MODIFY'
    | 'ORDERS_TRADE_VIEW'
    | 'ORDERS_VIEW'
    | 'ORDERS_VIEW_ALL'
    | 'PERSONAL_AM'
    | 'PNL_REPORT'
    | 'PORTFOLIOS_CREATE'
    | 'PORTFOLIOS_DELETE'
    | 'PORTFOLIOS_FORCE_MODIFY'
    | 'PORTFOLIOS_MODIFY'
    | 'PORTFOLIOS_MODIFY_ALL'
    | 'PORTFOLIOS_VIEW'
    | 'PORTFOLIOS_VIEW_ALL'
    | 'PORTFOLIO_CONFIGURATION_VIEW'
    | 'PORTFOLIO_COSTS_VIEW'
    | 'PORTFOLIO_FEES_VIEW'
    | 'PORTFOLIO_FLOWS_VIEW'
    | 'PORTFOLIO_ORDERS_ASSIGN'
    | 'PORTFOLIO_ORDERS_CREATE'
    | 'PORTFOLIO_ORDERS_DELETE'
    | 'PORTFOLIO_ORDERS_MODIFY'
    | 'PORTFOLIO_ORDERS_VIEW'
    | 'PORTFOLIO_PNL_VIEW'
    | 'PORTFOLIO_POSITIONS_VIEW'
    | 'PORTFOLIO_PROFILE_MODIFY'
    | 'PORTFOLIO_PROFILE_VIEW'
    | 'PORTFOLIO_RESTRICTIONS_VIEW'
    | 'PORTFOLIO_TRANSACTIONS_CREATE'
    | 'PORTFOLIO_TRANSACTIONS_DELETE'
    | 'PORTFOLIO_TRANSACTIONS_MODIFY'
    | 'PORTFOLIO_TRANSACTIONS_STATUS'
    | 'PORTFOLIO_TRANSACTIONS_VIEW'
    | 'PORTFOLIO_VALUATION_VIEW'
    | 'POSITIONS_VIEW'
    | 'PRICES_LOADING_MONITOR_VIEW'
    | 'PRIVATE_INSTRUMENT_CREATE'
    | 'PRIVATE_INSTRUMENT_DELETE'
    | 'PRIVATE_INSTRUMENT_MODIFY'
    | 'PRIVATE_INSTRUMENT_MODIFY_ALL'
    | 'PRIVATE_INSTRUMENT_VIEW'
    | 'PRIVATE_INSTRUMENT_VIEW_ALL'
    | 'PROCESS_CREATE'
    | 'PROCESS_DELETE'
    | 'PROCESS_MODIFY'
    | 'PROCESS_VIEW'
    | 'PRODUCT_GOVERNANCE_MODIFY'
    | 'PRODUCT_GOVERNANCE_VIEW'
    | 'PROFILES_CREATE'
    | 'PROFILES_DELETE'
    | 'PROFILES_MODIFY'
    | 'PROFILES_VIEW'
    | 'QUARTERLY_STATISTICS_REPORT'
    | 'RBS_F_REPORT'
    | 'RECOMMENDATION_CREATE'
    | 'RECOMMENDATION_FORCE_MODIFY'
    | 'RECOMMENDATION_MODIFY'
    | 'RECOMMENDATION_VIEW'
    | 'RECOMMENDATION_VIEW_ALL'
    | 'RECONCILIATION_REPORT'
    | 'REGULATOR_CREATE'
    | 'REGULATOR_DELETE'
    | 'REGULATOR_MODIFY'
    | 'REGULATOR_VIEW'
    | 'RELUNA_ORDERS_REPORTS_VIEW'
    | 'REPORTS_CREATE'
    | 'REPORTS_DELETE'
    | 'REPORTS_MODIFY'
    | 'REPORTS_VIEW'
    | 'RESTRICTIONS_CREATE'
    | 'RESTRICTIONS_DELETE'
    | 'RESTRICTIONS_MODIFY'
    | 'RESTRICTIONS_MODIFY_ALL'
    | 'RESTRICTIONS_VIEW'
    | 'RESTRICTIONS_VIEW_ALL'
    | 'RESTRICTION_ALERT_VIEW'
    | 'RESTRICTION_REPORTS_CREATE'
    | 'RESTRICTION_REPORTS_DELETE'
    | 'RESTRICTION_REPORTS_MODIFY'
    | 'RESTRICTION_REPORTS_MODIFY_ALL'
    | 'RESTRICTION_REPORTS_VIEW'
    | 'RESTRICTION_REPORTS_VIEW_ALL'
    | 'RESTRICTION_TEMPLATES_CREATE'
    | 'RESTRICTION_TEMPLATES_DELETE'
    | 'RESTRICTION_TEMPLATES_MODIFY'
    | 'RESTRICTION_TEMPLATES_MODIFY_ALL'
    | 'RESTRICTION_TEMPLATES_VIEW'
    | 'RESTRICTION_TEMPLATES_VIEW_ALL'
    | 'RISK_REPORT_CREATE'
    | 'RISK_REPORT_VIEW'
    | 'ROLES_CREATE'
    | 'ROLES_DELETE'
    | 'ROLES_MODIFY'
    | 'ROLES_VIEW'
    | 'RTS28_REPORT'
    | 'SALTEDGE_TRANSACTIONS_MODIFY'
    | 'SALTEDGE_TRANSACTIONS_VIEW'
    | 'SEND_REPORTS'
    | 'SETTINGS_MODIFY'
    | 'SETTINGS_VIEW'
    | 'SHOP_VIEW'
    | 'SITE_INFO_CREATE'
    | 'SITE_INFO_DELETE'
    | 'SITE_INFO_MODIFY'
    | 'SITE_INFO_VIEW'
    | 'SOVA_DATA_MODIFY'
    | 'SOVA_DATA_VIEW'
    | 'SOVA_ORDERS_MODIFY'
    | 'SOVA_ORDERS_VIEW'
    | 'STATEMENT_OF_ELIGIBLE_FUNDS_REPORT'
    | 'STATISTICS_VIEW'
    | 'STRATEGIES_CREATE'
    | 'STRATEGIES_DELETE'
    | 'STRATEGIES_FORCE_MODIFY'
    | 'STRATEGIES_MODIFY'
    | 'STRATEGIES_MODIFY_ALL'
    | 'STRATEGIES_VIEW'
    | 'STRATEGIES_VIEW_ALL'
    | 'STRUCTURED_PRODUCTS_CREATE'
    | 'STRUCTURED_PRODUCTS_DELETE'
    | 'STRUCTURED_PRODUCTS_MODIFY'
    | 'STRUCTURED_PRODUCTS_MODIFY_ALL'
    | 'STRUCTURED_PRODUCTS_VIEW'
    | 'STRUCTURED_PRODUCTS_VIEW_ALL'
    | 'SUBPORTFOLIOS_VIEW'
    | 'SUBSCRIPTION_MODIFY'
    | 'SUBSCRIPTION_VIEW'
    | 'TABLE_CONSTRUCTOR_MODIFY'
    | 'TABLE_CONSTRUCTOR_VIEW'
    | 'TAVIRA_DATA_MODIFY'
    | 'TAVIRA_DATA_VIEW'
    | 'TRANSACTIONS_CREATE'
    | 'TRANSACTIONS_DELETE'
    | 'TRANSACTIONS_FORCE_MODIFY'
    | 'TRANSACTIONS_MATCHING_MODIFY'
    | 'TRANSACTIONS_MATCHING_VIEW'
    | 'TRANSACTIONS_MODIFY'
    | 'TRANSACTIONS_MODIFY_ALL'
    | 'TRANSACTIONS_STATUS'
    | 'TRANSACTIONS_VIEW'
    | 'TRANSACTIONS_VIEW_ALL'
    | 'TRANSACTION_LOADER_CREATE'
    | 'TRANSACTION_LOADER_MODIFY'
    | 'TRANSACTION_LOADER_VIEW'
    | 'TRANSACTION_WITHOUT_BANK_VIEW'
    | 'TRIMP_DATA_MODIFY'
    | 'TRIMP_DATA_VIEW'
    | 'TTCA'
    | 'UBS_DATA_MODIFY'
    | 'UBS_DATA_VIEW'
    | 'VALUE_DATE_RULES_MODIFY'
    | 'VALUE_DATE_RULES_VIEW'
    | 'VP_DATA_MODIFY'
    | 'VP_DATA_VIEW'
    | 'VP_LOADER_SETTINGS_CREATE'
    | 'VP_LOADER_SETTINGS_DELETE'
    | 'VP_LOADER_SETTINGS_MODIFY'
    | 'VP_LOADER_SETTINGS_VIEW'
    | 'VTB_DATA_MODIFY'
    | 'VTB_DATA_VIEW'
    | 'ZOHO_SETTINGS_CREATE'
    | 'ZOHO_SETTINGS_DELETE'
    | 'ZOHO_SETTINGS_MODIFY'
    | 'ZOHO_SETTINGS_VIEW';
  export const ForceModifyAuthorityEnum = {
    AccountsCreate: 'ACCOUNTS_CREATE' as ForceModifyAuthorityEnum,
    AccountsDelete: 'ACCOUNTS_DELETE' as ForceModifyAuthorityEnum,
    AccountsForceModify: 'ACCOUNTS_FORCE_MODIFY' as ForceModifyAuthorityEnum,
    AccountsModify: 'ACCOUNTS_MODIFY' as ForceModifyAuthorityEnum,
    AccountsModifyAll: 'ACCOUNTS_MODIFY_ALL' as ForceModifyAuthorityEnum,
    AccountsView: 'ACCOUNTS_VIEW' as ForceModifyAuthorityEnum,
    AccountsViewAll: 'ACCOUNTS_VIEW_ALL' as ForceModifyAuthorityEnum,
    AccountStructure: 'ACCOUNT_STRUCTURE' as ForceModifyAuthorityEnum,
    ActuatorView: 'ACTUATOR_VIEW' as ForceModifyAuthorityEnum,
    AdvancedAnalyticsView: 'ADVANCED_ANALYTICS_VIEW' as ForceModifyAuthorityEnum,
    AlfaDataModify: 'ALFA_DATA_MODIFY' as ForceModifyAuthorityEnum,
    AlfaDataView: 'ALFA_DATA_VIEW' as ForceModifyAuthorityEnum,
    AmazonWorkDocs: 'AMAZON_WORK_DOCS' as ForceModifyAuthorityEnum,
    BankAccountsCreate: 'BANK_ACCOUNTS_CREATE' as ForceModifyAuthorityEnum,
    BankAccountsDelete: 'BANK_ACCOUNTS_DELETE' as ForceModifyAuthorityEnum,
    BankAccountsForceModify: 'BANK_ACCOUNTS_FORCE_MODIFY' as ForceModifyAuthorityEnum,
    BankAccountsModify: 'BANK_ACCOUNTS_MODIFY' as ForceModifyAuthorityEnum,
    BankAccountsModifyAll: 'BANK_ACCOUNTS_MODIFY_ALL' as ForceModifyAuthorityEnum,
    BankAccountsView: 'BANK_ACCOUNTS_VIEW' as ForceModifyAuthorityEnum,
    BankAccountsViewAll: 'BANK_ACCOUNTS_VIEW_ALL' as ForceModifyAuthorityEnum,
    BankStatsView: 'BANK_STATS_VIEW' as ForceModifyAuthorityEnum,
    BksDataModify: 'BKS_DATA_MODIFY' as ForceModifyAuthorityEnum,
    BksDataView: 'BKS_DATA_VIEW' as ForceModifyAuthorityEnum,
    BksLoaderSettingsCreate: 'BKS_LOADER_SETTINGS_CREATE' as ForceModifyAuthorityEnum,
    BksLoaderSettingsDelete: 'BKS_LOADER_SETTINGS_DELETE' as ForceModifyAuthorityEnum,
    BksLoaderSettingsModify: 'BKS_LOADER_SETTINGS_MODIFY' as ForceModifyAuthorityEnum,
    BksLoaderSettingsView: 'BKS_LOADER_SETTINGS_VIEW' as ForceModifyAuthorityEnum,
    BloombergRequestsView: 'BLOOMBERG_REQUESTS_VIEW' as ForceModifyAuthorityEnum,
    BrokerCreate: 'BROKER_CREATE' as ForceModifyAuthorityEnum,
    BrokerDelete: 'BROKER_DELETE' as ForceModifyAuthorityEnum,
    BrokerModify: 'BROKER_MODIFY' as ForceModifyAuthorityEnum,
    BrokerView: 'BROKER_VIEW' as ForceModifyAuthorityEnum,
    BusinessEventsView: 'BUSINESS_EVENTS_VIEW' as ForceModifyAuthorityEnum,
    CassReport: 'CASS_REPORT' as ForceModifyAuthorityEnum,
    CbhBsDataModify: 'CBH_BS_DATA_MODIFY' as ForceModifyAuthorityEnum,
    CbhBsDataView: 'CBH_BS_DATA_VIEW' as ForceModifyAuthorityEnum,
    CbhDataModify: 'CBH_DATA_MODIFY' as ForceModifyAuthorityEnum,
    CbhDataView: 'CBH_DATA_VIEW' as ForceModifyAuthorityEnum,
    CbhLoaderSettingsCreate: 'CBH_LOADER_SETTINGS_CREATE' as ForceModifyAuthorityEnum,
    CbhLoaderSettingsDelete: 'CBH_LOADER_SETTINGS_DELETE' as ForceModifyAuthorityEnum,
    CbhLoaderSettingsModify: 'CBH_LOADER_SETTINGS_MODIFY' as ForceModifyAuthorityEnum,
    CbhLoaderSettingsView: 'CBH_LOADER_SETTINGS_VIEW' as ForceModifyAuthorityEnum,
    ChangeRequestSettingsCreate: 'CHANGE_REQUEST_SETTINGS_CREATE' as ForceModifyAuthorityEnum,
    ChangeRequestSettingsDelete: 'CHANGE_REQUEST_SETTINGS_DELETE' as ForceModifyAuthorityEnum,
    ChangeRequestSettingsModify: 'CHANGE_REQUEST_SETTINGS_MODIFY' as ForceModifyAuthorityEnum,
    ChangeRequestSettingsView: 'CHANGE_REQUEST_SETTINGS_VIEW' as ForceModifyAuthorityEnum,
    ClientsCreate: 'CLIENTS_CREATE' as ForceModifyAuthorityEnum,
    ClientsDelete: 'CLIENTS_DELETE' as ForceModifyAuthorityEnum,
    ClientsModify: 'CLIENTS_MODIFY' as ForceModifyAuthorityEnum,
    ClientsModifyAll: 'CLIENTS_MODIFY_ALL' as ForceModifyAuthorityEnum,
    ClientsView: 'CLIENTS_VIEW' as ForceModifyAuthorityEnum,
    ClientsViewAll: 'CLIENTS_VIEW_ALL' as ForceModifyAuthorityEnum,
    ClientCountReport: 'CLIENT_COUNT_REPORT' as ForceModifyAuthorityEnum,
    ClientFeesReport: 'CLIENT_FEES_REPORT' as ForceModifyAuthorityEnum,
    ClientValuationReport: 'CLIENT_VALUATION_REPORT' as ForceModifyAuthorityEnum,
    CmarReport: 'CMAR_REPORT' as ForceModifyAuthorityEnum,
    CommonEmailTemplatesCreate: 'COMMON_EMAIL_TEMPLATES_CREATE' as ForceModifyAuthorityEnum,
    CommonEmailTemplatesDelete: 'COMMON_EMAIL_TEMPLATES_DELETE' as ForceModifyAuthorityEnum,
    CommonEmailTemplatesModify: 'COMMON_EMAIL_TEMPLATES_MODIFY' as ForceModifyAuthorityEnum,
    CommonEmailTemplatesView: 'COMMON_EMAIL_TEMPLATES_VIEW' as ForceModifyAuthorityEnum,
    CompaniesCreate: 'COMPANIES_CREATE' as ForceModifyAuthorityEnum,
    CompaniesDelete: 'COMPANIES_DELETE' as ForceModifyAuthorityEnum,
    CompaniesModify: 'COMPANIES_MODIFY' as ForceModifyAuthorityEnum,
    CompaniesTagsView: 'COMPANIES_TAGS_VIEW' as ForceModifyAuthorityEnum,
    CompaniesView: 'COMPANIES_VIEW' as ForceModifyAuthorityEnum,
    CompanyHoldingCreate: 'COMPANY_HOLDING_CREATE' as ForceModifyAuthorityEnum,
    CompanyHoldingDelete: 'COMPANY_HOLDING_DELETE' as ForceModifyAuthorityEnum,
    CompanyHoldingModify: 'COMPANY_HOLDING_MODIFY' as ForceModifyAuthorityEnum,
    CompanyHoldingView: 'COMPANY_HOLDING_VIEW' as ForceModifyAuthorityEnum,
    CompanyRiskReport: 'COMPANY_RISK_REPORT' as ForceModifyAuthorityEnum,
    ComplianceAccountCompanyCreate: 'COMPLIANCE_ACCOUNT_COMPANY_CREATE' as ForceModifyAuthorityEnum,
    ComplianceAccountCompanyDelete: 'COMPLIANCE_ACCOUNT_COMPANY_DELETE' as ForceModifyAuthorityEnum,
    ComplianceAccountCompanyForceModify:
      'COMPLIANCE_ACCOUNT_COMPANY_FORCE_MODIFY' as ForceModifyAuthorityEnum,
    ComplianceAccountCompanyModify: 'COMPLIANCE_ACCOUNT_COMPANY_MODIFY' as ForceModifyAuthorityEnum,
    ComplianceAccountCompanyView: 'COMPLIANCE_ACCOUNT_COMPANY_VIEW' as ForceModifyAuthorityEnum,
    CounterpartyCreate: 'COUNTERPARTY_CREATE' as ForceModifyAuthorityEnum,
    CounterpartyDelete: 'COUNTERPARTY_DELETE' as ForceModifyAuthorityEnum,
    CounterpartyModify: 'COUNTERPARTY_MODIFY' as ForceModifyAuthorityEnum,
    CounterpartyView: 'COUNTERPARTY_VIEW' as ForceModifyAuthorityEnum,
    CouponProjectionReport: 'COUPON_PROJECTION_REPORT' as ForceModifyAuthorityEnum,
    CrsReport: 'CRS_REPORT' as ForceModifyAuthorityEnum,
    CustodianAssetTypeMappingCreate:
      'CUSTODIAN_ASSET_TYPE_MAPPING_CREATE' as ForceModifyAuthorityEnum,
    CustodianAssetTypeMappingModify:
      'CUSTODIAN_ASSET_TYPE_MAPPING_MODIFY' as ForceModifyAuthorityEnum,
    CustodianAssetTypeMappingView: 'CUSTODIAN_ASSET_TYPE_MAPPING_VIEW' as ForceModifyAuthorityEnum,
    CustodianCreate: 'CUSTODIAN_CREATE' as ForceModifyAuthorityEnum,
    CustodianDelete: 'CUSTODIAN_DELETE' as ForceModifyAuthorityEnum,
    CustodianModify: 'CUSTODIAN_MODIFY' as ForceModifyAuthorityEnum,
    CustodianView: 'CUSTODIAN_VIEW' as ForceModifyAuthorityEnum,
    DashboardModify: 'DASHBOARD_MODIFY' as ForceModifyAuthorityEnum,
    DashboardView: 'DASHBOARD_VIEW' as ForceModifyAuthorityEnum,
    DbsDataModify: 'DBS_DATA_MODIFY' as ForceModifyAuthorityEnum,
    DbsDataView: 'DBS_DATA_VIEW' as ForceModifyAuthorityEnum,
    DeactivatedTransactionView: 'DEACTIVATED_TRANSACTION_VIEW' as ForceModifyAuthorityEnum,
    DepartmentsCreate: 'DEPARTMENTS_CREATE' as ForceModifyAuthorityEnum,
    DepartmentsDelete: 'DEPARTMENTS_DELETE' as ForceModifyAuthorityEnum,
    DepartmentsModify: 'DEPARTMENTS_MODIFY' as ForceModifyAuthorityEnum,
    DepartmentsView: 'DEPARTMENTS_VIEW' as ForceModifyAuthorityEnum,
    DolfinTransactionsModify: 'DOLFIN_TRANSACTIONS_MODIFY' as ForceModifyAuthorityEnum,
    DolfinTransactionsView: 'DOLFIN_TRANSACTIONS_VIEW' as ForceModifyAuthorityEnum,
    EdrDataModify: 'EDR_DATA_MODIFY' as ForceModifyAuthorityEnum,
    EdrDataView: 'EDR_DATA_VIEW' as ForceModifyAuthorityEnum,
    EdrLoaderSettingsCreate: 'EDR_LOADER_SETTINGS_CREATE' as ForceModifyAuthorityEnum,
    EdrLoaderSettingsDelete: 'EDR_LOADER_SETTINGS_DELETE' as ForceModifyAuthorityEnum,
    EdrLoaderSettingsModify: 'EDR_LOADER_SETTINGS_MODIFY' as ForceModifyAuthorityEnum,
    EdrLoaderSettingsView: 'EDR_LOADER_SETTINGS_VIEW' as ForceModifyAuthorityEnum,
    EmailTemplateCreate: 'EMAIL_TEMPLATE_CREATE' as ForceModifyAuthorityEnum,
    EmailTemplateDelete: 'EMAIL_TEMPLATE_DELETE' as ForceModifyAuthorityEnum,
    EmailTemplateModify: 'EMAIL_TEMPLATE_MODIFY' as ForceModifyAuthorityEnum,
    EmailTemplateView: 'EMAIL_TEMPLATE_VIEW' as ForceModifyAuthorityEnum,
    ExecgwReportsView: 'EXECGW_REPORTS_VIEW' as ForceModifyAuthorityEnum,
    FeesCreate: 'FEES_CREATE' as ForceModifyAuthorityEnum,
    FeesModify: 'FEES_MODIFY' as ForceModifyAuthorityEnum,
    FeesView: 'FEES_VIEW' as ForceModifyAuthorityEnum,
    FeesViewAll: 'FEES_VIEW_ALL' as ForceModifyAuthorityEnum,
    FieldsCreate: 'FIELDS_CREATE' as ForceModifyAuthorityEnum,
    FieldsDelete: 'FIELDS_DELETE' as ForceModifyAuthorityEnum,
    FieldsModify: 'FIELDS_MODIFY' as ForceModifyAuthorityEnum,
    FieldsView: 'FIELDS_VIEW' as ForceModifyAuthorityEnum,
    FileUserLogView: 'FILE_USER_LOG_VIEW' as ForceModifyAuthorityEnum,
    GapMonitorView: 'GAP_MONITOR_VIEW' as ForceModifyAuthorityEnum,
    GlobalAllocationCreate: 'GLOBAL_ALLOCATION_CREATE' as ForceModifyAuthorityEnum,
    GlobalAllocationModify: 'GLOBAL_ALLOCATION_MODIFY' as ForceModifyAuthorityEnum,
    GlobalAllocationView: 'GLOBAL_ALLOCATION_VIEW' as ForceModifyAuthorityEnum,
    GlobalAllocationWidgetView: 'GLOBAL_ALLOCATION_WIDGET_VIEW' as ForceModifyAuthorityEnum,
    GoogleAuthModify: 'GOOGLE_AUTH_MODIFY' as ForceModifyAuthorityEnum,
    GoogleAuthView: 'GOOGLE_AUTH_VIEW' as ForceModifyAuthorityEnum,
    GppDataModify: 'GPP_DATA_MODIFY' as ForceModifyAuthorityEnum,
    GppDataView: 'GPP_DATA_VIEW' as ForceModifyAuthorityEnum,
    GppLoaderSettingsCreate: 'GPP_LOADER_SETTINGS_CREATE' as ForceModifyAuthorityEnum,
    GppLoaderSettingsDelete: 'GPP_LOADER_SETTINGS_DELETE' as ForceModifyAuthorityEnum,
    GppLoaderSettingsModify: 'GPP_LOADER_SETTINGS_MODIFY' as ForceModifyAuthorityEnum,
    GppLoaderSettingsView: 'GPP_LOADER_SETTINGS_VIEW' as ForceModifyAuthorityEnum,
    HealthView: 'HEALTH_VIEW' as ForceModifyAuthorityEnum,
    HistoryView: 'HISTORY_VIEW' as ForceModifyAuthorityEnum,
    IbkrDataModify: 'IBKR_DATA_MODIFY' as ForceModifyAuthorityEnum,
    IbkrDataView: 'IBKR_DATA_VIEW' as ForceModifyAuthorityEnum,
    IbkrFuturesMappingCreate: 'IBKR_FUTURES_MAPPING_CREATE' as ForceModifyAuthorityEnum,
    IbkrFuturesMappingDelete: 'IBKR_FUTURES_MAPPING_DELETE' as ForceModifyAuthorityEnum,
    IbkrFuturesMappingModify: 'IBKR_FUTURES_MAPPING_MODIFY' as ForceModifyAuthorityEnum,
    IbkrFuturesMappingView: 'IBKR_FUTURES_MAPPING_VIEW' as ForceModifyAuthorityEnum,
    IbkrLoaderSettingsCreate: 'IBKR_LOADER_SETTINGS_CREATE' as ForceModifyAuthorityEnum,
    IbkrLoaderSettingsDelete: 'IBKR_LOADER_SETTINGS_DELETE' as ForceModifyAuthorityEnum,
    IbkrLoaderSettingsModify: 'IBKR_LOADER_SETTINGS_MODIFY' as ForceModifyAuthorityEnum,
    IbkrLoaderSettingsView: 'IBKR_LOADER_SETTINGS_VIEW' as ForceModifyAuthorityEnum,
    IncomePaymentTracker: 'INCOME_PAYMENT_TRACKER' as ForceModifyAuthorityEnum,
    InstrumentsCreate: 'INSTRUMENTS_CREATE' as ForceModifyAuthorityEnum,
    InstrumentsModify: 'INSTRUMENTS_MODIFY' as ForceModifyAuthorityEnum,
    InstrumentsView: 'INSTRUMENTS_VIEW' as ForceModifyAuthorityEnum,
    InstrumentToAgentView: 'INSTRUMENT_TO_AGENT_VIEW' as ForceModifyAuthorityEnum,
    InvestecDataModify: 'INVESTEC_DATA_MODIFY' as ForceModifyAuthorityEnum,
    InvestecDataView: 'INVESTEC_DATA_VIEW' as ForceModifyAuthorityEnum,
    InvestecLoaderSettingsCreate: 'INVESTEC_LOADER_SETTINGS_CREATE' as ForceModifyAuthorityEnum,
    InvestecLoaderSettingsDelete: 'INVESTEC_LOADER_SETTINGS_DELETE' as ForceModifyAuthorityEnum,
    InvestecLoaderSettingsModify: 'INVESTEC_LOADER_SETTINGS_MODIFY' as ForceModifyAuthorityEnum,
    InvestecLoaderSettingsView: 'INVESTEC_LOADER_SETTINGS_VIEW' as ForceModifyAuthorityEnum,
    InvestmentView: 'INVESTMENT_VIEW' as ForceModifyAuthorityEnum,
    IpAccess: 'IP_ACCESS' as ForceModifyAuthorityEnum,
    JbDataModify: 'JB_DATA_MODIFY' as ForceModifyAuthorityEnum,
    JbDataView: 'JB_DATA_VIEW' as ForceModifyAuthorityEnum,
    JbLoaderSettingsCreate: 'JB_LOADER_SETTINGS_CREATE' as ForceModifyAuthorityEnum,
    JbLoaderSettingsDelete: 'JB_LOADER_SETTINGS_DELETE' as ForceModifyAuthorityEnum,
    JbLoaderSettingsModify: 'JB_LOADER_SETTINGS_MODIFY' as ForceModifyAuthorityEnum,
    JbLoaderSettingsView: 'JB_LOADER_SETTINGS_VIEW' as ForceModifyAuthorityEnum,
    JpmDataModify: 'JPM_DATA_MODIFY' as ForceModifyAuthorityEnum,
    JpmDataView: 'JPM_DATA_VIEW' as ForceModifyAuthorityEnum,
    JpmLoaderSettingsCreate: 'JPM_LOADER_SETTINGS_CREATE' as ForceModifyAuthorityEnum,
    JpmLoaderSettingsDelete: 'JPM_LOADER_SETTINGS_DELETE' as ForceModifyAuthorityEnum,
    JpmLoaderSettingsModify: 'JPM_LOADER_SETTINGS_MODIFY' as ForceModifyAuthorityEnum,
    JpmLoaderSettingsView: 'JPM_LOADER_SETTINGS_VIEW' as ForceModifyAuthorityEnum,
    LeadCreate: 'LEAD_CREATE' as ForceModifyAuthorityEnum,
    LeadDelete: 'LEAD_DELETE' as ForceModifyAuthorityEnum,
    LeadModify: 'LEAD_MODIFY' as ForceModifyAuthorityEnum,
    LeadModifyAll: 'LEAD_MODIFY_ALL' as ForceModifyAuthorityEnum,
    LeadView: 'LEAD_VIEW' as ForceModifyAuthorityEnum,
    LeadViewAll: 'LEAD_VIEW_ALL' as ForceModifyAuthorityEnum,
    LogotypeDelete: 'LOGOTYPE_DELETE' as ForceModifyAuthorityEnum,
    LogotypeModify: 'LOGOTYPE_MODIFY' as ForceModifyAuthorityEnum,
    LogotypeView: 'LOGOTYPE_VIEW' as ForceModifyAuthorityEnum,
    ManagersCreate: 'MANAGERS_CREATE' as ForceModifyAuthorityEnum,
    ManagersDelete: 'MANAGERS_DELETE' as ForceModifyAuthorityEnum,
    ManagersForceModify: 'MANAGERS_FORCE_MODIFY' as ForceModifyAuthorityEnum,
    ManagersModify: 'MANAGERS_MODIFY' as ForceModifyAuthorityEnum,
    ManagersView: 'MANAGERS_VIEW' as ForceModifyAuthorityEnum,
    MandatesCreate: 'MANDATES_CREATE' as ForceModifyAuthorityEnum,
    MandatesDelete: 'MANDATES_DELETE' as ForceModifyAuthorityEnum,
    MandatesForceModify: 'MANDATES_FORCE_MODIFY' as ForceModifyAuthorityEnum,
    MandatesModify: 'MANDATES_MODIFY' as ForceModifyAuthorityEnum,
    MandatesModifyAll: 'MANDATES_MODIFY_ALL' as ForceModifyAuthorityEnum,
    MandatesView: 'MANDATES_VIEW' as ForceModifyAuthorityEnum,
    MandatesViewAll: 'MANDATES_VIEW_ALL' as ForceModifyAuthorityEnum,
    MandateRestrictionsCreate: 'MANDATE_RESTRICTIONS_CREATE' as ForceModifyAuthorityEnum,
    MandateRestrictionsDelete: 'MANDATE_RESTRICTIONS_DELETE' as ForceModifyAuthorityEnum,
    MandateRestrictionsModify: 'MANDATE_RESTRICTIONS_MODIFY' as ForceModifyAuthorityEnum,
    MandateRestrictionsModifyAll: 'MANDATE_RESTRICTIONS_MODIFY_ALL' as ForceModifyAuthorityEnum,
    MandateRestrictionsView: 'MANDATE_RESTRICTIONS_VIEW' as ForceModifyAuthorityEnum,
    MandateRestrictionsViewAll: 'MANDATE_RESTRICTIONS_VIEW_ALL' as ForceModifyAuthorityEnum,
    MandateRestrictionReportsCreate:
      'MANDATE_RESTRICTION_REPORTS_CREATE' as ForceModifyAuthorityEnum,
    MandateRestrictionReportsDelete:
      'MANDATE_RESTRICTION_REPORTS_DELETE' as ForceModifyAuthorityEnum,
    MandateRestrictionReportsModify:
      'MANDATE_RESTRICTION_REPORTS_MODIFY' as ForceModifyAuthorityEnum,
    MandateRestrictionReportsModifyAll:
      'MANDATE_RESTRICTION_REPORTS_MODIFY_ALL' as ForceModifyAuthorityEnum,
    MandateRestrictionReportsView: 'MANDATE_RESTRICTION_REPORTS_VIEW' as ForceModifyAuthorityEnum,
    MandateRestrictionReportsViewAll:
      'MANDATE_RESTRICTION_REPORTS_VIEW_ALL' as ForceModifyAuthorityEnum,
    MandateRestrictionTemplatesCreate:
      'MANDATE_RESTRICTION_TEMPLATES_CREATE' as ForceModifyAuthorityEnum,
    MandateRestrictionTemplatesDelete:
      'MANDATE_RESTRICTION_TEMPLATES_DELETE' as ForceModifyAuthorityEnum,
    MandateRestrictionTemplatesModify:
      'MANDATE_RESTRICTION_TEMPLATES_MODIFY' as ForceModifyAuthorityEnum,
    MandateRestrictionTemplatesModifyAll:
      'MANDATE_RESTRICTION_TEMPLATES_MODIFY_ALL' as ForceModifyAuthorityEnum,
    MandateRestrictionTemplatesView:
      'MANDATE_RESTRICTION_TEMPLATES_VIEW' as ForceModifyAuthorityEnum,
    MandateRestrictionTemplatesViewAll:
      'MANDATE_RESTRICTION_TEMPLATES_VIEW_ALL' as ForceModifyAuthorityEnum,
    MandateRiskProfileCreate: 'MANDATE_RISK_PROFILE_CREATE' as ForceModifyAuthorityEnum,
    MandateRiskProfileDelete: 'MANDATE_RISK_PROFILE_DELETE' as ForceModifyAuthorityEnum,
    MandateRiskProfileForceModify: 'MANDATE_RISK_PROFILE_FORCE_MODIFY' as ForceModifyAuthorityEnum,
    MandateRiskProfileModify: 'MANDATE_RISK_PROFILE_MODIFY' as ForceModifyAuthorityEnum,
    MandateRiskProfileView: 'MANDATE_RISK_PROFILE_VIEW' as ForceModifyAuthorityEnum,
    MandateRiskProfileViewAll: 'MANDATE_RISK_PROFILE_VIEW_ALL' as ForceModifyAuthorityEnum,
    ManualImportPositionsCreate: 'MANUAL_IMPORT_POSITIONS_CREATE' as ForceModifyAuthorityEnum,
    ManualImportPositionsModify: 'MANUAL_IMPORT_POSITIONS_MODIFY' as ForceModifyAuthorityEnum,
    ManualImportPositionsView: 'MANUAL_IMPORT_POSITIONS_VIEW' as ForceModifyAuthorityEnum,
    ManualImportTransactionsCreate: 'MANUAL_IMPORT_TRANSACTIONS_CREATE' as ForceModifyAuthorityEnum,
    ManualImportTransactionsModify: 'MANUAL_IMPORT_TRANSACTIONS_MODIFY' as ForceModifyAuthorityEnum,
    ManualImportTransactionsView: 'MANUAL_IMPORT_TRANSACTIONS_VIEW' as ForceModifyAuthorityEnum,
    MarketplaceModify: 'MARKETPLACE_MODIFY' as ForceModifyAuthorityEnum,
    MarketplaceView: 'MARKETPLACE_VIEW' as ForceModifyAuthorityEnum,
    MarketSecuritiesModify: 'MARKET_SECURITIES_MODIFY' as ForceModifyAuthorityEnum,
    MarketSecuritiesView: 'MARKET_SECURITIES_VIEW' as ForceModifyAuthorityEnum,
    MifirModify: 'MIFIR_MODIFY' as ForceModifyAuthorityEnum,
    MifirReport: 'MIFIR_REPORT' as ForceModifyAuthorityEnum,
    MifirView: 'MIFIR_VIEW' as ForceModifyAuthorityEnum,
    ModelPortfoliosCreate: 'MODEL_PORTFOLIOS_CREATE' as ForceModifyAuthorityEnum,
    ModelPortfoliosDelete: 'MODEL_PORTFOLIOS_DELETE' as ForceModifyAuthorityEnum,
    ModelPortfoliosModify: 'MODEL_PORTFOLIOS_MODIFY' as ForceModifyAuthorityEnum,
    ModelPortfoliosView: 'MODEL_PORTFOLIOS_VIEW' as ForceModifyAuthorityEnum,
    MonthlyReconciliationReport: 'MONTHLY_RECONCILIATION_REPORT' as ForceModifyAuthorityEnum,
    NotificationTemplatesCreate: 'NOTIFICATION_TEMPLATES_CREATE' as ForceModifyAuthorityEnum,
    NotificationTemplatesDelete: 'NOTIFICATION_TEMPLATES_DELETE' as ForceModifyAuthorityEnum,
    NotificationTemplatesModify: 'NOTIFICATION_TEMPLATES_MODIFY' as ForceModifyAuthorityEnum,
    NotificationTemplatesView: 'NOTIFICATION_TEMPLATES_VIEW' as ForceModifyAuthorityEnum,
    NotificationUserLogView: 'NOTIFICATION_USER_LOG_VIEW' as ForceModifyAuthorityEnum,
    Omnibus: 'OMNIBUS' as ForceModifyAuthorityEnum,
    OnlinePricingView: 'ONLINE_PRICING_VIEW' as ForceModifyAuthorityEnum,
    OpportunityCreate: 'OPPORTUNITY_CREATE' as ForceModifyAuthorityEnum,
    OpportunityDelete: 'OPPORTUNITY_DELETE' as ForceModifyAuthorityEnum,
    OpportunityForceModify: 'OPPORTUNITY_FORCE_MODIFY' as ForceModifyAuthorityEnum,
    OpportunityModify: 'OPPORTUNITY_MODIFY' as ForceModifyAuthorityEnum,
    OpportunityModifyAll: 'OPPORTUNITY_MODIFY_ALL' as ForceModifyAuthorityEnum,
    OpportunityView: 'OPPORTUNITY_VIEW' as ForceModifyAuthorityEnum,
    OpportunityViewAll: 'OPPORTUNITY_VIEW_ALL' as ForceModifyAuthorityEnum,
    OrdersAssign: 'ORDERS_ASSIGN' as ForceModifyAuthorityEnum,
    OrdersCommonCreate: 'ORDERS_COMMON_CREATE' as ForceModifyAuthorityEnum,
    OrdersCommonDelete: 'ORDERS_COMMON_DELETE' as ForceModifyAuthorityEnum,
    OrdersCommonModify: 'ORDERS_COMMON_MODIFY' as ForceModifyAuthorityEnum,
    OrdersCommonView: 'ORDERS_COMMON_VIEW' as ForceModifyAuthorityEnum,
    OrdersCorpActionCreate: 'ORDERS_CORP_ACTION_CREATE' as ForceModifyAuthorityEnum,
    OrdersCorpActionDelete: 'ORDERS_CORP_ACTION_DELETE' as ForceModifyAuthorityEnum,
    OrdersCorpActionModify: 'ORDERS_CORP_ACTION_MODIFY' as ForceModifyAuthorityEnum,
    OrdersCorpActionView: 'ORDERS_CORP_ACTION_VIEW' as ForceModifyAuthorityEnum,
    OrdersCreate: 'ORDERS_CREATE' as ForceModifyAuthorityEnum,
    OrdersDelete: 'ORDERS_DELETE' as ForceModifyAuthorityEnum,
    OrdersDmaCreate: 'ORDERS_DMA_CREATE' as ForceModifyAuthorityEnum,
    OrdersForceModify: 'ORDERS_FORCE_MODIFY' as ForceModifyAuthorityEnum,
    OrdersModify: 'ORDERS_MODIFY' as ForceModifyAuthorityEnum,
    OrdersModifyAll: 'ORDERS_MODIFY_ALL' as ForceModifyAuthorityEnum,
    OrdersNonTradeCreate: 'ORDERS_NON_TRADE_CREATE' as ForceModifyAuthorityEnum,
    OrdersNonTradeDelete: 'ORDERS_NON_TRADE_DELETE' as ForceModifyAuthorityEnum,
    OrdersNonTradeModify: 'ORDERS_NON_TRADE_MODIFY' as ForceModifyAuthorityEnum,
    OrdersNonTradeView: 'ORDERS_NON_TRADE_VIEW' as ForceModifyAuthorityEnum,
    OrdersTechInfoModify: 'ORDERS_TECH_INFO_MODIFY' as ForceModifyAuthorityEnum,
    OrdersTechInfoView: 'ORDERS_TECH_INFO_VIEW' as ForceModifyAuthorityEnum,
    OrdersTradeCreate: 'ORDERS_TRADE_CREATE' as ForceModifyAuthorityEnum,
    OrdersTradeDelete: 'ORDERS_TRADE_DELETE' as ForceModifyAuthorityEnum,
    OrdersTradeModify: 'ORDERS_TRADE_MODIFY' as ForceModifyAuthorityEnum,
    OrdersTradeView: 'ORDERS_TRADE_VIEW' as ForceModifyAuthorityEnum,
    OrdersView: 'ORDERS_VIEW' as ForceModifyAuthorityEnum,
    OrdersViewAll: 'ORDERS_VIEW_ALL' as ForceModifyAuthorityEnum,
    PersonalAm: 'PERSONAL_AM' as ForceModifyAuthorityEnum,
    PnlReport: 'PNL_REPORT' as ForceModifyAuthorityEnum,
    PortfoliosCreate: 'PORTFOLIOS_CREATE' as ForceModifyAuthorityEnum,
    PortfoliosDelete: 'PORTFOLIOS_DELETE' as ForceModifyAuthorityEnum,
    PortfoliosForceModify: 'PORTFOLIOS_FORCE_MODIFY' as ForceModifyAuthorityEnum,
    PortfoliosModify: 'PORTFOLIOS_MODIFY' as ForceModifyAuthorityEnum,
    PortfoliosModifyAll: 'PORTFOLIOS_MODIFY_ALL' as ForceModifyAuthorityEnum,
    PortfoliosView: 'PORTFOLIOS_VIEW' as ForceModifyAuthorityEnum,
    PortfoliosViewAll: 'PORTFOLIOS_VIEW_ALL' as ForceModifyAuthorityEnum,
    PortfolioConfigurationView: 'PORTFOLIO_CONFIGURATION_VIEW' as ForceModifyAuthorityEnum,
    PortfolioCostsView: 'PORTFOLIO_COSTS_VIEW' as ForceModifyAuthorityEnum,
    PortfolioFeesView: 'PORTFOLIO_FEES_VIEW' as ForceModifyAuthorityEnum,
    PortfolioFlowsView: 'PORTFOLIO_FLOWS_VIEW' as ForceModifyAuthorityEnum,
    PortfolioOrdersAssign: 'PORTFOLIO_ORDERS_ASSIGN' as ForceModifyAuthorityEnum,
    PortfolioOrdersCreate: 'PORTFOLIO_ORDERS_CREATE' as ForceModifyAuthorityEnum,
    PortfolioOrdersDelete: 'PORTFOLIO_ORDERS_DELETE' as ForceModifyAuthorityEnum,
    PortfolioOrdersModify: 'PORTFOLIO_ORDERS_MODIFY' as ForceModifyAuthorityEnum,
    PortfolioOrdersView: 'PORTFOLIO_ORDERS_VIEW' as ForceModifyAuthorityEnum,
    PortfolioPnlView: 'PORTFOLIO_PNL_VIEW' as ForceModifyAuthorityEnum,
    PortfolioPositionsView: 'PORTFOLIO_POSITIONS_VIEW' as ForceModifyAuthorityEnum,
    PortfolioProfileModify: 'PORTFOLIO_PROFILE_MODIFY' as ForceModifyAuthorityEnum,
    PortfolioProfileView: 'PORTFOLIO_PROFILE_VIEW' as ForceModifyAuthorityEnum,
    PortfolioRestrictionsView: 'PORTFOLIO_RESTRICTIONS_VIEW' as ForceModifyAuthorityEnum,
    PortfolioTransactionsCreate: 'PORTFOLIO_TRANSACTIONS_CREATE' as ForceModifyAuthorityEnum,
    PortfolioTransactionsDelete: 'PORTFOLIO_TRANSACTIONS_DELETE' as ForceModifyAuthorityEnum,
    PortfolioTransactionsModify: 'PORTFOLIO_TRANSACTIONS_MODIFY' as ForceModifyAuthorityEnum,
    PortfolioTransactionsStatus: 'PORTFOLIO_TRANSACTIONS_STATUS' as ForceModifyAuthorityEnum,
    PortfolioTransactionsView: 'PORTFOLIO_TRANSACTIONS_VIEW' as ForceModifyAuthorityEnum,
    PortfolioValuationView: 'PORTFOLIO_VALUATION_VIEW' as ForceModifyAuthorityEnum,
    PositionsView: 'POSITIONS_VIEW' as ForceModifyAuthorityEnum,
    PricesLoadingMonitorView: 'PRICES_LOADING_MONITOR_VIEW' as ForceModifyAuthorityEnum,
    PrivateInstrumentCreate: 'PRIVATE_INSTRUMENT_CREATE' as ForceModifyAuthorityEnum,
    PrivateInstrumentDelete: 'PRIVATE_INSTRUMENT_DELETE' as ForceModifyAuthorityEnum,
    PrivateInstrumentModify: 'PRIVATE_INSTRUMENT_MODIFY' as ForceModifyAuthorityEnum,
    PrivateInstrumentModifyAll: 'PRIVATE_INSTRUMENT_MODIFY_ALL' as ForceModifyAuthorityEnum,
    PrivateInstrumentView: 'PRIVATE_INSTRUMENT_VIEW' as ForceModifyAuthorityEnum,
    PrivateInstrumentViewAll: 'PRIVATE_INSTRUMENT_VIEW_ALL' as ForceModifyAuthorityEnum,
    ProcessCreate: 'PROCESS_CREATE' as ForceModifyAuthorityEnum,
    ProcessDelete: 'PROCESS_DELETE' as ForceModifyAuthorityEnum,
    ProcessModify: 'PROCESS_MODIFY' as ForceModifyAuthorityEnum,
    ProcessView: 'PROCESS_VIEW' as ForceModifyAuthorityEnum,
    ProductGovernanceModify: 'PRODUCT_GOVERNANCE_MODIFY' as ForceModifyAuthorityEnum,
    ProductGovernanceView: 'PRODUCT_GOVERNANCE_VIEW' as ForceModifyAuthorityEnum,
    ProfilesCreate: 'PROFILES_CREATE' as ForceModifyAuthorityEnum,
    ProfilesDelete: 'PROFILES_DELETE' as ForceModifyAuthorityEnum,
    ProfilesModify: 'PROFILES_MODIFY' as ForceModifyAuthorityEnum,
    ProfilesView: 'PROFILES_VIEW' as ForceModifyAuthorityEnum,
    QuarterlyStatisticsReport: 'QUARTERLY_STATISTICS_REPORT' as ForceModifyAuthorityEnum,
    RbsFReport: 'RBS_F_REPORT' as ForceModifyAuthorityEnum,
    RecommendationCreate: 'RECOMMENDATION_CREATE' as ForceModifyAuthorityEnum,
    RecommendationForceModify: 'RECOMMENDATION_FORCE_MODIFY' as ForceModifyAuthorityEnum,
    RecommendationModify: 'RECOMMENDATION_MODIFY' as ForceModifyAuthorityEnum,
    RecommendationView: 'RECOMMENDATION_VIEW' as ForceModifyAuthorityEnum,
    RecommendationViewAll: 'RECOMMENDATION_VIEW_ALL' as ForceModifyAuthorityEnum,
    ReconciliationReport: 'RECONCILIATION_REPORT' as ForceModifyAuthorityEnum,
    RegulatorCreate: 'REGULATOR_CREATE' as ForceModifyAuthorityEnum,
    RegulatorDelete: 'REGULATOR_DELETE' as ForceModifyAuthorityEnum,
    RegulatorModify: 'REGULATOR_MODIFY' as ForceModifyAuthorityEnum,
    RegulatorView: 'REGULATOR_VIEW' as ForceModifyAuthorityEnum,
    RelunaOrdersReportsView: 'RELUNA_ORDERS_REPORTS_VIEW' as ForceModifyAuthorityEnum,
    ReportsCreate: 'REPORTS_CREATE' as ForceModifyAuthorityEnum,
    ReportsDelete: 'REPORTS_DELETE' as ForceModifyAuthorityEnum,
    ReportsModify: 'REPORTS_MODIFY' as ForceModifyAuthorityEnum,
    ReportsView: 'REPORTS_VIEW' as ForceModifyAuthorityEnum,
    RestrictionsCreate: 'RESTRICTIONS_CREATE' as ForceModifyAuthorityEnum,
    RestrictionsDelete: 'RESTRICTIONS_DELETE' as ForceModifyAuthorityEnum,
    RestrictionsModify: 'RESTRICTIONS_MODIFY' as ForceModifyAuthorityEnum,
    RestrictionsModifyAll: 'RESTRICTIONS_MODIFY_ALL' as ForceModifyAuthorityEnum,
    RestrictionsView: 'RESTRICTIONS_VIEW' as ForceModifyAuthorityEnum,
    RestrictionsViewAll: 'RESTRICTIONS_VIEW_ALL' as ForceModifyAuthorityEnum,
    RestrictionAlertView: 'RESTRICTION_ALERT_VIEW' as ForceModifyAuthorityEnum,
    RestrictionReportsCreate: 'RESTRICTION_REPORTS_CREATE' as ForceModifyAuthorityEnum,
    RestrictionReportsDelete: 'RESTRICTION_REPORTS_DELETE' as ForceModifyAuthorityEnum,
    RestrictionReportsModify: 'RESTRICTION_REPORTS_MODIFY' as ForceModifyAuthorityEnum,
    RestrictionReportsModifyAll: 'RESTRICTION_REPORTS_MODIFY_ALL' as ForceModifyAuthorityEnum,
    RestrictionReportsView: 'RESTRICTION_REPORTS_VIEW' as ForceModifyAuthorityEnum,
    RestrictionReportsViewAll: 'RESTRICTION_REPORTS_VIEW_ALL' as ForceModifyAuthorityEnum,
    RestrictionTemplatesCreate: 'RESTRICTION_TEMPLATES_CREATE' as ForceModifyAuthorityEnum,
    RestrictionTemplatesDelete: 'RESTRICTION_TEMPLATES_DELETE' as ForceModifyAuthorityEnum,
    RestrictionTemplatesModify: 'RESTRICTION_TEMPLATES_MODIFY' as ForceModifyAuthorityEnum,
    RestrictionTemplatesModifyAll: 'RESTRICTION_TEMPLATES_MODIFY_ALL' as ForceModifyAuthorityEnum,
    RestrictionTemplatesView: 'RESTRICTION_TEMPLATES_VIEW' as ForceModifyAuthorityEnum,
    RestrictionTemplatesViewAll: 'RESTRICTION_TEMPLATES_VIEW_ALL' as ForceModifyAuthorityEnum,
    RiskReportCreate: 'RISK_REPORT_CREATE' as ForceModifyAuthorityEnum,
    RiskReportView: 'RISK_REPORT_VIEW' as ForceModifyAuthorityEnum,
    RolesCreate: 'ROLES_CREATE' as ForceModifyAuthorityEnum,
    RolesDelete: 'ROLES_DELETE' as ForceModifyAuthorityEnum,
    RolesModify: 'ROLES_MODIFY' as ForceModifyAuthorityEnum,
    RolesView: 'ROLES_VIEW' as ForceModifyAuthorityEnum,
    Rts28Report: 'RTS28_REPORT' as ForceModifyAuthorityEnum,
    SaltedgeTransactionsModify: 'SALTEDGE_TRANSACTIONS_MODIFY' as ForceModifyAuthorityEnum,
    SaltedgeTransactionsView: 'SALTEDGE_TRANSACTIONS_VIEW' as ForceModifyAuthorityEnum,
    SendReports: 'SEND_REPORTS' as ForceModifyAuthorityEnum,
    SettingsModify: 'SETTINGS_MODIFY' as ForceModifyAuthorityEnum,
    SettingsView: 'SETTINGS_VIEW' as ForceModifyAuthorityEnum,
    ShopView: 'SHOP_VIEW' as ForceModifyAuthorityEnum,
    SiteInfoCreate: 'SITE_INFO_CREATE' as ForceModifyAuthorityEnum,
    SiteInfoDelete: 'SITE_INFO_DELETE' as ForceModifyAuthorityEnum,
    SiteInfoModify: 'SITE_INFO_MODIFY' as ForceModifyAuthorityEnum,
    SiteInfoView: 'SITE_INFO_VIEW' as ForceModifyAuthorityEnum,
    SovaDataModify: 'SOVA_DATA_MODIFY' as ForceModifyAuthorityEnum,
    SovaDataView: 'SOVA_DATA_VIEW' as ForceModifyAuthorityEnum,
    SovaOrdersModify: 'SOVA_ORDERS_MODIFY' as ForceModifyAuthorityEnum,
    SovaOrdersView: 'SOVA_ORDERS_VIEW' as ForceModifyAuthorityEnum,
    StatementOfEligibleFundsReport:
      'STATEMENT_OF_ELIGIBLE_FUNDS_REPORT' as ForceModifyAuthorityEnum,
    StatisticsView: 'STATISTICS_VIEW' as ForceModifyAuthorityEnum,
    StrategiesCreate: 'STRATEGIES_CREATE' as ForceModifyAuthorityEnum,
    StrategiesDelete: 'STRATEGIES_DELETE' as ForceModifyAuthorityEnum,
    StrategiesForceModify: 'STRATEGIES_FORCE_MODIFY' as ForceModifyAuthorityEnum,
    StrategiesModify: 'STRATEGIES_MODIFY' as ForceModifyAuthorityEnum,
    StrategiesModifyAll: 'STRATEGIES_MODIFY_ALL' as ForceModifyAuthorityEnum,
    StrategiesView: 'STRATEGIES_VIEW' as ForceModifyAuthorityEnum,
    StrategiesViewAll: 'STRATEGIES_VIEW_ALL' as ForceModifyAuthorityEnum,
    StructuredProductsCreate: 'STRUCTURED_PRODUCTS_CREATE' as ForceModifyAuthorityEnum,
    StructuredProductsDelete: 'STRUCTURED_PRODUCTS_DELETE' as ForceModifyAuthorityEnum,
    StructuredProductsModify: 'STRUCTURED_PRODUCTS_MODIFY' as ForceModifyAuthorityEnum,
    StructuredProductsModifyAll: 'STRUCTURED_PRODUCTS_MODIFY_ALL' as ForceModifyAuthorityEnum,
    StructuredProductsView: 'STRUCTURED_PRODUCTS_VIEW' as ForceModifyAuthorityEnum,
    StructuredProductsViewAll: 'STRUCTURED_PRODUCTS_VIEW_ALL' as ForceModifyAuthorityEnum,
    SubportfoliosView: 'SUBPORTFOLIOS_VIEW' as ForceModifyAuthorityEnum,
    SubscriptionModify: 'SUBSCRIPTION_MODIFY' as ForceModifyAuthorityEnum,
    SubscriptionView: 'SUBSCRIPTION_VIEW' as ForceModifyAuthorityEnum,
    TableConstructorModify: 'TABLE_CONSTRUCTOR_MODIFY' as ForceModifyAuthorityEnum,
    TableConstructorView: 'TABLE_CONSTRUCTOR_VIEW' as ForceModifyAuthorityEnum,
    TaviraDataModify: 'TAVIRA_DATA_MODIFY' as ForceModifyAuthorityEnum,
    TaviraDataView: 'TAVIRA_DATA_VIEW' as ForceModifyAuthorityEnum,
    TransactionsCreate: 'TRANSACTIONS_CREATE' as ForceModifyAuthorityEnum,
    TransactionsDelete: 'TRANSACTIONS_DELETE' as ForceModifyAuthorityEnum,
    TransactionsForceModify: 'TRANSACTIONS_FORCE_MODIFY' as ForceModifyAuthorityEnum,
    TransactionsMatchingModify: 'TRANSACTIONS_MATCHING_MODIFY' as ForceModifyAuthorityEnum,
    TransactionsMatchingView: 'TRANSACTIONS_MATCHING_VIEW' as ForceModifyAuthorityEnum,
    TransactionsModify: 'TRANSACTIONS_MODIFY' as ForceModifyAuthorityEnum,
    TransactionsModifyAll: 'TRANSACTIONS_MODIFY_ALL' as ForceModifyAuthorityEnum,
    TransactionsStatus: 'TRANSACTIONS_STATUS' as ForceModifyAuthorityEnum,
    TransactionsView: 'TRANSACTIONS_VIEW' as ForceModifyAuthorityEnum,
    TransactionsViewAll: 'TRANSACTIONS_VIEW_ALL' as ForceModifyAuthorityEnum,
    TransactionLoaderCreate: 'TRANSACTION_LOADER_CREATE' as ForceModifyAuthorityEnum,
    TransactionLoaderModify: 'TRANSACTION_LOADER_MODIFY' as ForceModifyAuthorityEnum,
    TransactionLoaderView: 'TRANSACTION_LOADER_VIEW' as ForceModifyAuthorityEnum,
    TransactionWithoutBankView: 'TRANSACTION_WITHOUT_BANK_VIEW' as ForceModifyAuthorityEnum,
    TrimpDataModify: 'TRIMP_DATA_MODIFY' as ForceModifyAuthorityEnum,
    TrimpDataView: 'TRIMP_DATA_VIEW' as ForceModifyAuthorityEnum,
    Ttca: 'TTCA' as ForceModifyAuthorityEnum,
    UbsDataModify: 'UBS_DATA_MODIFY' as ForceModifyAuthorityEnum,
    UbsDataView: 'UBS_DATA_VIEW' as ForceModifyAuthorityEnum,
    ValueDateRulesModify: 'VALUE_DATE_RULES_MODIFY' as ForceModifyAuthorityEnum,
    ValueDateRulesView: 'VALUE_DATE_RULES_VIEW' as ForceModifyAuthorityEnum,
    VpDataModify: 'VP_DATA_MODIFY' as ForceModifyAuthorityEnum,
    VpDataView: 'VP_DATA_VIEW' as ForceModifyAuthorityEnum,
    VpLoaderSettingsCreate: 'VP_LOADER_SETTINGS_CREATE' as ForceModifyAuthorityEnum,
    VpLoaderSettingsDelete: 'VP_LOADER_SETTINGS_DELETE' as ForceModifyAuthorityEnum,
    VpLoaderSettingsModify: 'VP_LOADER_SETTINGS_MODIFY' as ForceModifyAuthorityEnum,
    VpLoaderSettingsView: 'VP_LOADER_SETTINGS_VIEW' as ForceModifyAuthorityEnum,
    VtbDataModify: 'VTB_DATA_MODIFY' as ForceModifyAuthorityEnum,
    VtbDataView: 'VTB_DATA_VIEW' as ForceModifyAuthorityEnum,
    ZohoSettingsCreate: 'ZOHO_SETTINGS_CREATE' as ForceModifyAuthorityEnum,
    ZohoSettingsDelete: 'ZOHO_SETTINGS_DELETE' as ForceModifyAuthorityEnum,
    ZohoSettingsModify: 'ZOHO_SETTINGS_MODIFY' as ForceModifyAuthorityEnum,
    ZohoSettingsView: 'ZOHO_SETTINGS_VIEW' as ForceModifyAuthorityEnum,
  };
  export type ModifyAllAuthorityEnum =
    | 'ACCOUNTS_CREATE'
    | 'ACCOUNTS_DELETE'
    | 'ACCOUNTS_FORCE_MODIFY'
    | 'ACCOUNTS_MODIFY'
    | 'ACCOUNTS_MODIFY_ALL'
    | 'ACCOUNTS_VIEW'
    | 'ACCOUNTS_VIEW_ALL'
    | 'ACCOUNT_STRUCTURE'
    | 'ACTUATOR_VIEW'
    | 'ADVANCED_ANALYTICS_VIEW'
    | 'ALFA_DATA_MODIFY'
    | 'ALFA_DATA_VIEW'
    | 'AMAZON_WORK_DOCS'
    | 'BANK_ACCOUNTS_CREATE'
    | 'BANK_ACCOUNTS_DELETE'
    | 'BANK_ACCOUNTS_FORCE_MODIFY'
    | 'BANK_ACCOUNTS_MODIFY'
    | 'BANK_ACCOUNTS_MODIFY_ALL'
    | 'BANK_ACCOUNTS_VIEW'
    | 'BANK_ACCOUNTS_VIEW_ALL'
    | 'BANK_STATS_VIEW'
    | 'BKS_DATA_MODIFY'
    | 'BKS_DATA_VIEW'
    | 'BKS_LOADER_SETTINGS_CREATE'
    | 'BKS_LOADER_SETTINGS_DELETE'
    | 'BKS_LOADER_SETTINGS_MODIFY'
    | 'BKS_LOADER_SETTINGS_VIEW'
    | 'BLOOMBERG_REQUESTS_VIEW'
    | 'BROKER_CREATE'
    | 'BROKER_DELETE'
    | 'BROKER_MODIFY'
    | 'BROKER_VIEW'
    | 'BUSINESS_EVENTS_VIEW'
    | 'CASS_REPORT'
    | 'CBH_BS_DATA_MODIFY'
    | 'CBH_BS_DATA_VIEW'
    | 'CBH_DATA_MODIFY'
    | 'CBH_DATA_VIEW'
    | 'CBH_LOADER_SETTINGS_CREATE'
    | 'CBH_LOADER_SETTINGS_DELETE'
    | 'CBH_LOADER_SETTINGS_MODIFY'
    | 'CBH_LOADER_SETTINGS_VIEW'
    | 'CHANGE_REQUEST_SETTINGS_CREATE'
    | 'CHANGE_REQUEST_SETTINGS_DELETE'
    | 'CHANGE_REQUEST_SETTINGS_MODIFY'
    | 'CHANGE_REQUEST_SETTINGS_VIEW'
    | 'CLIENTS_CREATE'
    | 'CLIENTS_DELETE'
    | 'CLIENTS_MODIFY'
    | 'CLIENTS_MODIFY_ALL'
    | 'CLIENTS_VIEW'
    | 'CLIENTS_VIEW_ALL'
    | 'CLIENT_COUNT_REPORT'
    | 'CLIENT_FEES_REPORT'
    | 'CLIENT_VALUATION_REPORT'
    | 'CMAR_REPORT'
    | 'COMMON_EMAIL_TEMPLATES_CREATE'
    | 'COMMON_EMAIL_TEMPLATES_DELETE'
    | 'COMMON_EMAIL_TEMPLATES_MODIFY'
    | 'COMMON_EMAIL_TEMPLATES_VIEW'
    | 'COMPANIES_CREATE'
    | 'COMPANIES_DELETE'
    | 'COMPANIES_MODIFY'
    | 'COMPANIES_TAGS_VIEW'
    | 'COMPANIES_VIEW'
    | 'COMPANY_HOLDING_CREATE'
    | 'COMPANY_HOLDING_DELETE'
    | 'COMPANY_HOLDING_MODIFY'
    | 'COMPANY_HOLDING_VIEW'
    | 'COMPANY_RISK_REPORT'
    | 'COMPLIANCE_ACCOUNT_COMPANY_CREATE'
    | 'COMPLIANCE_ACCOUNT_COMPANY_DELETE'
    | 'COMPLIANCE_ACCOUNT_COMPANY_FORCE_MODIFY'
    | 'COMPLIANCE_ACCOUNT_COMPANY_MODIFY'
    | 'COMPLIANCE_ACCOUNT_COMPANY_VIEW'
    | 'COUNTERPARTY_CREATE'
    | 'COUNTERPARTY_DELETE'
    | 'COUNTERPARTY_MODIFY'
    | 'COUNTERPARTY_VIEW'
    | 'COUPON_PROJECTION_REPORT'
    | 'CRS_REPORT'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_CREATE'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_MODIFY'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_VIEW'
    | 'CUSTODIAN_CREATE'
    | 'CUSTODIAN_DELETE'
    | 'CUSTODIAN_MODIFY'
    | 'CUSTODIAN_VIEW'
    | 'DASHBOARD_MODIFY'
    | 'DASHBOARD_VIEW'
    | 'DBS_DATA_MODIFY'
    | 'DBS_DATA_VIEW'
    | 'DEACTIVATED_TRANSACTION_VIEW'
    | 'DEPARTMENTS_CREATE'
    | 'DEPARTMENTS_DELETE'
    | 'DEPARTMENTS_MODIFY'
    | 'DEPARTMENTS_VIEW'
    | 'DOLFIN_TRANSACTIONS_MODIFY'
    | 'DOLFIN_TRANSACTIONS_VIEW'
    | 'EDR_DATA_MODIFY'
    | 'EDR_DATA_VIEW'
    | 'EDR_LOADER_SETTINGS_CREATE'
    | 'EDR_LOADER_SETTINGS_DELETE'
    | 'EDR_LOADER_SETTINGS_MODIFY'
    | 'EDR_LOADER_SETTINGS_VIEW'
    | 'EMAIL_TEMPLATE_CREATE'
    | 'EMAIL_TEMPLATE_DELETE'
    | 'EMAIL_TEMPLATE_MODIFY'
    | 'EMAIL_TEMPLATE_VIEW'
    | 'EXECGW_REPORTS_VIEW'
    | 'FEES_CREATE'
    | 'FEES_MODIFY'
    | 'FEES_VIEW'
    | 'FEES_VIEW_ALL'
    | 'FIELDS_CREATE'
    | 'FIELDS_DELETE'
    | 'FIELDS_MODIFY'
    | 'FIELDS_VIEW'
    | 'FILE_USER_LOG_VIEW'
    | 'GAP_MONITOR_VIEW'
    | 'GLOBAL_ALLOCATION_CREATE'
    | 'GLOBAL_ALLOCATION_MODIFY'
    | 'GLOBAL_ALLOCATION_VIEW'
    | 'GLOBAL_ALLOCATION_WIDGET_VIEW'
    | 'GOOGLE_AUTH_MODIFY'
    | 'GOOGLE_AUTH_VIEW'
    | 'GPP_DATA_MODIFY'
    | 'GPP_DATA_VIEW'
    | 'GPP_LOADER_SETTINGS_CREATE'
    | 'GPP_LOADER_SETTINGS_DELETE'
    | 'GPP_LOADER_SETTINGS_MODIFY'
    | 'GPP_LOADER_SETTINGS_VIEW'
    | 'HEALTH_VIEW'
    | 'HISTORY_VIEW'
    | 'IBKR_DATA_MODIFY'
    | 'IBKR_DATA_VIEW'
    | 'IBKR_FUTURES_MAPPING_CREATE'
    | 'IBKR_FUTURES_MAPPING_DELETE'
    | 'IBKR_FUTURES_MAPPING_MODIFY'
    | 'IBKR_FUTURES_MAPPING_VIEW'
    | 'IBKR_LOADER_SETTINGS_CREATE'
    | 'IBKR_LOADER_SETTINGS_DELETE'
    | 'IBKR_LOADER_SETTINGS_MODIFY'
    | 'IBKR_LOADER_SETTINGS_VIEW'
    | 'INCOME_PAYMENT_TRACKER'
    | 'INSTRUMENTS_CREATE'
    | 'INSTRUMENTS_MODIFY'
    | 'INSTRUMENTS_VIEW'
    | 'INSTRUMENT_TO_AGENT_VIEW'
    | 'INVESTEC_DATA_MODIFY'
    | 'INVESTEC_DATA_VIEW'
    | 'INVESTEC_LOADER_SETTINGS_CREATE'
    | 'INVESTEC_LOADER_SETTINGS_DELETE'
    | 'INVESTEC_LOADER_SETTINGS_MODIFY'
    | 'INVESTEC_LOADER_SETTINGS_VIEW'
    | 'INVESTMENT_VIEW'
    | 'IP_ACCESS'
    | 'JB_DATA_MODIFY'
    | 'JB_DATA_VIEW'
    | 'JB_LOADER_SETTINGS_CREATE'
    | 'JB_LOADER_SETTINGS_DELETE'
    | 'JB_LOADER_SETTINGS_MODIFY'
    | 'JB_LOADER_SETTINGS_VIEW'
    | 'JPM_DATA_MODIFY'
    | 'JPM_DATA_VIEW'
    | 'JPM_LOADER_SETTINGS_CREATE'
    | 'JPM_LOADER_SETTINGS_DELETE'
    | 'JPM_LOADER_SETTINGS_MODIFY'
    | 'JPM_LOADER_SETTINGS_VIEW'
    | 'LEAD_CREATE'
    | 'LEAD_DELETE'
    | 'LEAD_MODIFY'
    | 'LEAD_MODIFY_ALL'
    | 'LEAD_VIEW'
    | 'LEAD_VIEW_ALL'
    | 'LOGOTYPE_DELETE'
    | 'LOGOTYPE_MODIFY'
    | 'LOGOTYPE_VIEW'
    | 'MANAGERS_CREATE'
    | 'MANAGERS_DELETE'
    | 'MANAGERS_FORCE_MODIFY'
    | 'MANAGERS_MODIFY'
    | 'MANAGERS_VIEW'
    | 'MANDATES_CREATE'
    | 'MANDATES_DELETE'
    | 'MANDATES_FORCE_MODIFY'
    | 'MANDATES_MODIFY'
    | 'MANDATES_MODIFY_ALL'
    | 'MANDATES_VIEW'
    | 'MANDATES_VIEW_ALL'
    | 'MANDATE_RESTRICTIONS_CREATE'
    | 'MANDATE_RESTRICTIONS_DELETE'
    | 'MANDATE_RESTRICTIONS_MODIFY'
    | 'MANDATE_RESTRICTIONS_MODIFY_ALL'
    | 'MANDATE_RESTRICTIONS_VIEW'
    | 'MANDATE_RESTRICTIONS_VIEW_ALL'
    | 'MANDATE_RESTRICTION_REPORTS_CREATE'
    | 'MANDATE_RESTRICTION_REPORTS_DELETE'
    | 'MANDATE_RESTRICTION_REPORTS_MODIFY'
    | 'MANDATE_RESTRICTION_REPORTS_MODIFY_ALL'
    | 'MANDATE_RESTRICTION_REPORTS_VIEW'
    | 'MANDATE_RESTRICTION_REPORTS_VIEW_ALL'
    | 'MANDATE_RESTRICTION_TEMPLATES_CREATE'
    | 'MANDATE_RESTRICTION_TEMPLATES_DELETE'
    | 'MANDATE_RESTRICTION_TEMPLATES_MODIFY'
    | 'MANDATE_RESTRICTION_TEMPLATES_MODIFY_ALL'
    | 'MANDATE_RESTRICTION_TEMPLATES_VIEW'
    | 'MANDATE_RESTRICTION_TEMPLATES_VIEW_ALL'
    | 'MANDATE_RISK_PROFILE_CREATE'
    | 'MANDATE_RISK_PROFILE_DELETE'
    | 'MANDATE_RISK_PROFILE_FORCE_MODIFY'
    | 'MANDATE_RISK_PROFILE_MODIFY'
    | 'MANDATE_RISK_PROFILE_VIEW'
    | 'MANDATE_RISK_PROFILE_VIEW_ALL'
    | 'MANUAL_IMPORT_POSITIONS_CREATE'
    | 'MANUAL_IMPORT_POSITIONS_MODIFY'
    | 'MANUAL_IMPORT_POSITIONS_VIEW'
    | 'MANUAL_IMPORT_TRANSACTIONS_CREATE'
    | 'MANUAL_IMPORT_TRANSACTIONS_MODIFY'
    | 'MANUAL_IMPORT_TRANSACTIONS_VIEW'
    | 'MARKETPLACE_MODIFY'
    | 'MARKETPLACE_VIEW'
    | 'MARKET_SECURITIES_MODIFY'
    | 'MARKET_SECURITIES_VIEW'
    | 'MIFIR_MODIFY'
    | 'MIFIR_REPORT'
    | 'MIFIR_VIEW'
    | 'MODEL_PORTFOLIOS_CREATE'
    | 'MODEL_PORTFOLIOS_DELETE'
    | 'MODEL_PORTFOLIOS_MODIFY'
    | 'MODEL_PORTFOLIOS_VIEW'
    | 'MONTHLY_RECONCILIATION_REPORT'
    | 'NOTIFICATION_TEMPLATES_CREATE'
    | 'NOTIFICATION_TEMPLATES_DELETE'
    | 'NOTIFICATION_TEMPLATES_MODIFY'
    | 'NOTIFICATION_TEMPLATES_VIEW'
    | 'NOTIFICATION_USER_LOG_VIEW'
    | 'OMNIBUS'
    | 'ONLINE_PRICING_VIEW'
    | 'OPPORTUNITY_CREATE'
    | 'OPPORTUNITY_DELETE'
    | 'OPPORTUNITY_FORCE_MODIFY'
    | 'OPPORTUNITY_MODIFY'
    | 'OPPORTUNITY_MODIFY_ALL'
    | 'OPPORTUNITY_VIEW'
    | 'OPPORTUNITY_VIEW_ALL'
    | 'ORDERS_ASSIGN'
    | 'ORDERS_COMMON_CREATE'
    | 'ORDERS_COMMON_DELETE'
    | 'ORDERS_COMMON_MODIFY'
    | 'ORDERS_COMMON_VIEW'
    | 'ORDERS_CORP_ACTION_CREATE'
    | 'ORDERS_CORP_ACTION_DELETE'
    | 'ORDERS_CORP_ACTION_MODIFY'
    | 'ORDERS_CORP_ACTION_VIEW'
    | 'ORDERS_CREATE'
    | 'ORDERS_DELETE'
    | 'ORDERS_DMA_CREATE'
    | 'ORDERS_FORCE_MODIFY'
    | 'ORDERS_MODIFY'
    | 'ORDERS_MODIFY_ALL'
    | 'ORDERS_NON_TRADE_CREATE'
    | 'ORDERS_NON_TRADE_DELETE'
    | 'ORDERS_NON_TRADE_MODIFY'
    | 'ORDERS_NON_TRADE_VIEW'
    | 'ORDERS_TECH_INFO_MODIFY'
    | 'ORDERS_TECH_INFO_VIEW'
    | 'ORDERS_TRADE_CREATE'
    | 'ORDERS_TRADE_DELETE'
    | 'ORDERS_TRADE_MODIFY'
    | 'ORDERS_TRADE_VIEW'
    | 'ORDERS_VIEW'
    | 'ORDERS_VIEW_ALL'
    | 'PERSONAL_AM'
    | 'PNL_REPORT'
    | 'PORTFOLIOS_CREATE'
    | 'PORTFOLIOS_DELETE'
    | 'PORTFOLIOS_FORCE_MODIFY'
    | 'PORTFOLIOS_MODIFY'
    | 'PORTFOLIOS_MODIFY_ALL'
    | 'PORTFOLIOS_VIEW'
    | 'PORTFOLIOS_VIEW_ALL'
    | 'PORTFOLIO_CONFIGURATION_VIEW'
    | 'PORTFOLIO_COSTS_VIEW'
    | 'PORTFOLIO_FEES_VIEW'
    | 'PORTFOLIO_FLOWS_VIEW'
    | 'PORTFOLIO_ORDERS_ASSIGN'
    | 'PORTFOLIO_ORDERS_CREATE'
    | 'PORTFOLIO_ORDERS_DELETE'
    | 'PORTFOLIO_ORDERS_MODIFY'
    | 'PORTFOLIO_ORDERS_VIEW'
    | 'PORTFOLIO_PNL_VIEW'
    | 'PORTFOLIO_POSITIONS_VIEW'
    | 'PORTFOLIO_PROFILE_MODIFY'
    | 'PORTFOLIO_PROFILE_VIEW'
    | 'PORTFOLIO_RESTRICTIONS_VIEW'
    | 'PORTFOLIO_TRANSACTIONS_CREATE'
    | 'PORTFOLIO_TRANSACTIONS_DELETE'
    | 'PORTFOLIO_TRANSACTIONS_MODIFY'
    | 'PORTFOLIO_TRANSACTIONS_STATUS'
    | 'PORTFOLIO_TRANSACTIONS_VIEW'
    | 'PORTFOLIO_VALUATION_VIEW'
    | 'POSITIONS_VIEW'
    | 'PRICES_LOADING_MONITOR_VIEW'
    | 'PRIVATE_INSTRUMENT_CREATE'
    | 'PRIVATE_INSTRUMENT_DELETE'
    | 'PRIVATE_INSTRUMENT_MODIFY'
    | 'PRIVATE_INSTRUMENT_MODIFY_ALL'
    | 'PRIVATE_INSTRUMENT_VIEW'
    | 'PRIVATE_INSTRUMENT_VIEW_ALL'
    | 'PROCESS_CREATE'
    | 'PROCESS_DELETE'
    | 'PROCESS_MODIFY'
    | 'PROCESS_VIEW'
    | 'PRODUCT_GOVERNANCE_MODIFY'
    | 'PRODUCT_GOVERNANCE_VIEW'
    | 'PROFILES_CREATE'
    | 'PROFILES_DELETE'
    | 'PROFILES_MODIFY'
    | 'PROFILES_VIEW'
    | 'QUARTERLY_STATISTICS_REPORT'
    | 'RBS_F_REPORT'
    | 'RECOMMENDATION_CREATE'
    | 'RECOMMENDATION_FORCE_MODIFY'
    | 'RECOMMENDATION_MODIFY'
    | 'RECOMMENDATION_VIEW'
    | 'RECOMMENDATION_VIEW_ALL'
    | 'RECONCILIATION_REPORT'
    | 'REGULATOR_CREATE'
    | 'REGULATOR_DELETE'
    | 'REGULATOR_MODIFY'
    | 'REGULATOR_VIEW'
    | 'RELUNA_ORDERS_REPORTS_VIEW'
    | 'REPORTS_CREATE'
    | 'REPORTS_DELETE'
    | 'REPORTS_MODIFY'
    | 'REPORTS_VIEW'
    | 'RESTRICTIONS_CREATE'
    | 'RESTRICTIONS_DELETE'
    | 'RESTRICTIONS_MODIFY'
    | 'RESTRICTIONS_MODIFY_ALL'
    | 'RESTRICTIONS_VIEW'
    | 'RESTRICTIONS_VIEW_ALL'
    | 'RESTRICTION_ALERT_VIEW'
    | 'RESTRICTION_REPORTS_CREATE'
    | 'RESTRICTION_REPORTS_DELETE'
    | 'RESTRICTION_REPORTS_MODIFY'
    | 'RESTRICTION_REPORTS_MODIFY_ALL'
    | 'RESTRICTION_REPORTS_VIEW'
    | 'RESTRICTION_REPORTS_VIEW_ALL'
    | 'RESTRICTION_TEMPLATES_CREATE'
    | 'RESTRICTION_TEMPLATES_DELETE'
    | 'RESTRICTION_TEMPLATES_MODIFY'
    | 'RESTRICTION_TEMPLATES_MODIFY_ALL'
    | 'RESTRICTION_TEMPLATES_VIEW'
    | 'RESTRICTION_TEMPLATES_VIEW_ALL'
    | 'RISK_REPORT_CREATE'
    | 'RISK_REPORT_VIEW'
    | 'ROLES_CREATE'
    | 'ROLES_DELETE'
    | 'ROLES_MODIFY'
    | 'ROLES_VIEW'
    | 'RTS28_REPORT'
    | 'SALTEDGE_TRANSACTIONS_MODIFY'
    | 'SALTEDGE_TRANSACTIONS_VIEW'
    | 'SEND_REPORTS'
    | 'SETTINGS_MODIFY'
    | 'SETTINGS_VIEW'
    | 'SHOP_VIEW'
    | 'SITE_INFO_CREATE'
    | 'SITE_INFO_DELETE'
    | 'SITE_INFO_MODIFY'
    | 'SITE_INFO_VIEW'
    | 'SOVA_DATA_MODIFY'
    | 'SOVA_DATA_VIEW'
    | 'SOVA_ORDERS_MODIFY'
    | 'SOVA_ORDERS_VIEW'
    | 'STATEMENT_OF_ELIGIBLE_FUNDS_REPORT'
    | 'STATISTICS_VIEW'
    | 'STRATEGIES_CREATE'
    | 'STRATEGIES_DELETE'
    | 'STRATEGIES_FORCE_MODIFY'
    | 'STRATEGIES_MODIFY'
    | 'STRATEGIES_MODIFY_ALL'
    | 'STRATEGIES_VIEW'
    | 'STRATEGIES_VIEW_ALL'
    | 'STRUCTURED_PRODUCTS_CREATE'
    | 'STRUCTURED_PRODUCTS_DELETE'
    | 'STRUCTURED_PRODUCTS_MODIFY'
    | 'STRUCTURED_PRODUCTS_MODIFY_ALL'
    | 'STRUCTURED_PRODUCTS_VIEW'
    | 'STRUCTURED_PRODUCTS_VIEW_ALL'
    | 'SUBPORTFOLIOS_VIEW'
    | 'SUBSCRIPTION_MODIFY'
    | 'SUBSCRIPTION_VIEW'
    | 'TABLE_CONSTRUCTOR_MODIFY'
    | 'TABLE_CONSTRUCTOR_VIEW'
    | 'TAVIRA_DATA_MODIFY'
    | 'TAVIRA_DATA_VIEW'
    | 'TRANSACTIONS_CREATE'
    | 'TRANSACTIONS_DELETE'
    | 'TRANSACTIONS_FORCE_MODIFY'
    | 'TRANSACTIONS_MATCHING_MODIFY'
    | 'TRANSACTIONS_MATCHING_VIEW'
    | 'TRANSACTIONS_MODIFY'
    | 'TRANSACTIONS_MODIFY_ALL'
    | 'TRANSACTIONS_STATUS'
    | 'TRANSACTIONS_VIEW'
    | 'TRANSACTIONS_VIEW_ALL'
    | 'TRANSACTION_LOADER_CREATE'
    | 'TRANSACTION_LOADER_MODIFY'
    | 'TRANSACTION_LOADER_VIEW'
    | 'TRANSACTION_WITHOUT_BANK_VIEW'
    | 'TRIMP_DATA_MODIFY'
    | 'TRIMP_DATA_VIEW'
    | 'TTCA'
    | 'UBS_DATA_MODIFY'
    | 'UBS_DATA_VIEW'
    | 'VALUE_DATE_RULES_MODIFY'
    | 'VALUE_DATE_RULES_VIEW'
    | 'VP_DATA_MODIFY'
    | 'VP_DATA_VIEW'
    | 'VP_LOADER_SETTINGS_CREATE'
    | 'VP_LOADER_SETTINGS_DELETE'
    | 'VP_LOADER_SETTINGS_MODIFY'
    | 'VP_LOADER_SETTINGS_VIEW'
    | 'VTB_DATA_MODIFY'
    | 'VTB_DATA_VIEW'
    | 'ZOHO_SETTINGS_CREATE'
    | 'ZOHO_SETTINGS_DELETE'
    | 'ZOHO_SETTINGS_MODIFY'
    | 'ZOHO_SETTINGS_VIEW';
  export const ModifyAllAuthorityEnum = {
    AccountsCreate: 'ACCOUNTS_CREATE' as ModifyAllAuthorityEnum,
    AccountsDelete: 'ACCOUNTS_DELETE' as ModifyAllAuthorityEnum,
    AccountsForceModify: 'ACCOUNTS_FORCE_MODIFY' as ModifyAllAuthorityEnum,
    AccountsModify: 'ACCOUNTS_MODIFY' as ModifyAllAuthorityEnum,
    AccountsModifyAll: 'ACCOUNTS_MODIFY_ALL' as ModifyAllAuthorityEnum,
    AccountsView: 'ACCOUNTS_VIEW' as ModifyAllAuthorityEnum,
    AccountsViewAll: 'ACCOUNTS_VIEW_ALL' as ModifyAllAuthorityEnum,
    AccountStructure: 'ACCOUNT_STRUCTURE' as ModifyAllAuthorityEnum,
    ActuatorView: 'ACTUATOR_VIEW' as ModifyAllAuthorityEnum,
    AdvancedAnalyticsView: 'ADVANCED_ANALYTICS_VIEW' as ModifyAllAuthorityEnum,
    AlfaDataModify: 'ALFA_DATA_MODIFY' as ModifyAllAuthorityEnum,
    AlfaDataView: 'ALFA_DATA_VIEW' as ModifyAllAuthorityEnum,
    AmazonWorkDocs: 'AMAZON_WORK_DOCS' as ModifyAllAuthorityEnum,
    BankAccountsCreate: 'BANK_ACCOUNTS_CREATE' as ModifyAllAuthorityEnum,
    BankAccountsDelete: 'BANK_ACCOUNTS_DELETE' as ModifyAllAuthorityEnum,
    BankAccountsForceModify: 'BANK_ACCOUNTS_FORCE_MODIFY' as ModifyAllAuthorityEnum,
    BankAccountsModify: 'BANK_ACCOUNTS_MODIFY' as ModifyAllAuthorityEnum,
    BankAccountsModifyAll: 'BANK_ACCOUNTS_MODIFY_ALL' as ModifyAllAuthorityEnum,
    BankAccountsView: 'BANK_ACCOUNTS_VIEW' as ModifyAllAuthorityEnum,
    BankAccountsViewAll: 'BANK_ACCOUNTS_VIEW_ALL' as ModifyAllAuthorityEnum,
    BankStatsView: 'BANK_STATS_VIEW' as ModifyAllAuthorityEnum,
    BksDataModify: 'BKS_DATA_MODIFY' as ModifyAllAuthorityEnum,
    BksDataView: 'BKS_DATA_VIEW' as ModifyAllAuthorityEnum,
    BksLoaderSettingsCreate: 'BKS_LOADER_SETTINGS_CREATE' as ModifyAllAuthorityEnum,
    BksLoaderSettingsDelete: 'BKS_LOADER_SETTINGS_DELETE' as ModifyAllAuthorityEnum,
    BksLoaderSettingsModify: 'BKS_LOADER_SETTINGS_MODIFY' as ModifyAllAuthorityEnum,
    BksLoaderSettingsView: 'BKS_LOADER_SETTINGS_VIEW' as ModifyAllAuthorityEnum,
    BloombergRequestsView: 'BLOOMBERG_REQUESTS_VIEW' as ModifyAllAuthorityEnum,
    BrokerCreate: 'BROKER_CREATE' as ModifyAllAuthorityEnum,
    BrokerDelete: 'BROKER_DELETE' as ModifyAllAuthorityEnum,
    BrokerModify: 'BROKER_MODIFY' as ModifyAllAuthorityEnum,
    BrokerView: 'BROKER_VIEW' as ModifyAllAuthorityEnum,
    BusinessEventsView: 'BUSINESS_EVENTS_VIEW' as ModifyAllAuthorityEnum,
    CassReport: 'CASS_REPORT' as ModifyAllAuthorityEnum,
    CbhBsDataModify: 'CBH_BS_DATA_MODIFY' as ModifyAllAuthorityEnum,
    CbhBsDataView: 'CBH_BS_DATA_VIEW' as ModifyAllAuthorityEnum,
    CbhDataModify: 'CBH_DATA_MODIFY' as ModifyAllAuthorityEnum,
    CbhDataView: 'CBH_DATA_VIEW' as ModifyAllAuthorityEnum,
    CbhLoaderSettingsCreate: 'CBH_LOADER_SETTINGS_CREATE' as ModifyAllAuthorityEnum,
    CbhLoaderSettingsDelete: 'CBH_LOADER_SETTINGS_DELETE' as ModifyAllAuthorityEnum,
    CbhLoaderSettingsModify: 'CBH_LOADER_SETTINGS_MODIFY' as ModifyAllAuthorityEnum,
    CbhLoaderSettingsView: 'CBH_LOADER_SETTINGS_VIEW' as ModifyAllAuthorityEnum,
    ChangeRequestSettingsCreate: 'CHANGE_REQUEST_SETTINGS_CREATE' as ModifyAllAuthorityEnum,
    ChangeRequestSettingsDelete: 'CHANGE_REQUEST_SETTINGS_DELETE' as ModifyAllAuthorityEnum,
    ChangeRequestSettingsModify: 'CHANGE_REQUEST_SETTINGS_MODIFY' as ModifyAllAuthorityEnum,
    ChangeRequestSettingsView: 'CHANGE_REQUEST_SETTINGS_VIEW' as ModifyAllAuthorityEnum,
    ClientsCreate: 'CLIENTS_CREATE' as ModifyAllAuthorityEnum,
    ClientsDelete: 'CLIENTS_DELETE' as ModifyAllAuthorityEnum,
    ClientsModify: 'CLIENTS_MODIFY' as ModifyAllAuthorityEnum,
    ClientsModifyAll: 'CLIENTS_MODIFY_ALL' as ModifyAllAuthorityEnum,
    ClientsView: 'CLIENTS_VIEW' as ModifyAllAuthorityEnum,
    ClientsViewAll: 'CLIENTS_VIEW_ALL' as ModifyAllAuthorityEnum,
    ClientCountReport: 'CLIENT_COUNT_REPORT' as ModifyAllAuthorityEnum,
    ClientFeesReport: 'CLIENT_FEES_REPORT' as ModifyAllAuthorityEnum,
    ClientValuationReport: 'CLIENT_VALUATION_REPORT' as ModifyAllAuthorityEnum,
    CmarReport: 'CMAR_REPORT' as ModifyAllAuthorityEnum,
    CommonEmailTemplatesCreate: 'COMMON_EMAIL_TEMPLATES_CREATE' as ModifyAllAuthorityEnum,
    CommonEmailTemplatesDelete: 'COMMON_EMAIL_TEMPLATES_DELETE' as ModifyAllAuthorityEnum,
    CommonEmailTemplatesModify: 'COMMON_EMAIL_TEMPLATES_MODIFY' as ModifyAllAuthorityEnum,
    CommonEmailTemplatesView: 'COMMON_EMAIL_TEMPLATES_VIEW' as ModifyAllAuthorityEnum,
    CompaniesCreate: 'COMPANIES_CREATE' as ModifyAllAuthorityEnum,
    CompaniesDelete: 'COMPANIES_DELETE' as ModifyAllAuthorityEnum,
    CompaniesModify: 'COMPANIES_MODIFY' as ModifyAllAuthorityEnum,
    CompaniesTagsView: 'COMPANIES_TAGS_VIEW' as ModifyAllAuthorityEnum,
    CompaniesView: 'COMPANIES_VIEW' as ModifyAllAuthorityEnum,
    CompanyHoldingCreate: 'COMPANY_HOLDING_CREATE' as ModifyAllAuthorityEnum,
    CompanyHoldingDelete: 'COMPANY_HOLDING_DELETE' as ModifyAllAuthorityEnum,
    CompanyHoldingModify: 'COMPANY_HOLDING_MODIFY' as ModifyAllAuthorityEnum,
    CompanyHoldingView: 'COMPANY_HOLDING_VIEW' as ModifyAllAuthorityEnum,
    CompanyRiskReport: 'COMPANY_RISK_REPORT' as ModifyAllAuthorityEnum,
    ComplianceAccountCompanyCreate: 'COMPLIANCE_ACCOUNT_COMPANY_CREATE' as ModifyAllAuthorityEnum,
    ComplianceAccountCompanyDelete: 'COMPLIANCE_ACCOUNT_COMPANY_DELETE' as ModifyAllAuthorityEnum,
    ComplianceAccountCompanyForceModify:
      'COMPLIANCE_ACCOUNT_COMPANY_FORCE_MODIFY' as ModifyAllAuthorityEnum,
    ComplianceAccountCompanyModify: 'COMPLIANCE_ACCOUNT_COMPANY_MODIFY' as ModifyAllAuthorityEnum,
    ComplianceAccountCompanyView: 'COMPLIANCE_ACCOUNT_COMPANY_VIEW' as ModifyAllAuthorityEnum,
    CounterpartyCreate: 'COUNTERPARTY_CREATE' as ModifyAllAuthorityEnum,
    CounterpartyDelete: 'COUNTERPARTY_DELETE' as ModifyAllAuthorityEnum,
    CounterpartyModify: 'COUNTERPARTY_MODIFY' as ModifyAllAuthorityEnum,
    CounterpartyView: 'COUNTERPARTY_VIEW' as ModifyAllAuthorityEnum,
    CouponProjectionReport: 'COUPON_PROJECTION_REPORT' as ModifyAllAuthorityEnum,
    CrsReport: 'CRS_REPORT' as ModifyAllAuthorityEnum,
    CustodianAssetTypeMappingCreate:
      'CUSTODIAN_ASSET_TYPE_MAPPING_CREATE' as ModifyAllAuthorityEnum,
    CustodianAssetTypeMappingModify:
      'CUSTODIAN_ASSET_TYPE_MAPPING_MODIFY' as ModifyAllAuthorityEnum,
    CustodianAssetTypeMappingView: 'CUSTODIAN_ASSET_TYPE_MAPPING_VIEW' as ModifyAllAuthorityEnum,
    CustodianCreate: 'CUSTODIAN_CREATE' as ModifyAllAuthorityEnum,
    CustodianDelete: 'CUSTODIAN_DELETE' as ModifyAllAuthorityEnum,
    CustodianModify: 'CUSTODIAN_MODIFY' as ModifyAllAuthorityEnum,
    CustodianView: 'CUSTODIAN_VIEW' as ModifyAllAuthorityEnum,
    DashboardModify: 'DASHBOARD_MODIFY' as ModifyAllAuthorityEnum,
    DashboardView: 'DASHBOARD_VIEW' as ModifyAllAuthorityEnum,
    DbsDataModify: 'DBS_DATA_MODIFY' as ModifyAllAuthorityEnum,
    DbsDataView: 'DBS_DATA_VIEW' as ModifyAllAuthorityEnum,
    DeactivatedTransactionView: 'DEACTIVATED_TRANSACTION_VIEW' as ModifyAllAuthorityEnum,
    DepartmentsCreate: 'DEPARTMENTS_CREATE' as ModifyAllAuthorityEnum,
    DepartmentsDelete: 'DEPARTMENTS_DELETE' as ModifyAllAuthorityEnum,
    DepartmentsModify: 'DEPARTMENTS_MODIFY' as ModifyAllAuthorityEnum,
    DepartmentsView: 'DEPARTMENTS_VIEW' as ModifyAllAuthorityEnum,
    DolfinTransactionsModify: 'DOLFIN_TRANSACTIONS_MODIFY' as ModifyAllAuthorityEnum,
    DolfinTransactionsView: 'DOLFIN_TRANSACTIONS_VIEW' as ModifyAllAuthorityEnum,
    EdrDataModify: 'EDR_DATA_MODIFY' as ModifyAllAuthorityEnum,
    EdrDataView: 'EDR_DATA_VIEW' as ModifyAllAuthorityEnum,
    EdrLoaderSettingsCreate: 'EDR_LOADER_SETTINGS_CREATE' as ModifyAllAuthorityEnum,
    EdrLoaderSettingsDelete: 'EDR_LOADER_SETTINGS_DELETE' as ModifyAllAuthorityEnum,
    EdrLoaderSettingsModify: 'EDR_LOADER_SETTINGS_MODIFY' as ModifyAllAuthorityEnum,
    EdrLoaderSettingsView: 'EDR_LOADER_SETTINGS_VIEW' as ModifyAllAuthorityEnum,
    EmailTemplateCreate: 'EMAIL_TEMPLATE_CREATE' as ModifyAllAuthorityEnum,
    EmailTemplateDelete: 'EMAIL_TEMPLATE_DELETE' as ModifyAllAuthorityEnum,
    EmailTemplateModify: 'EMAIL_TEMPLATE_MODIFY' as ModifyAllAuthorityEnum,
    EmailTemplateView: 'EMAIL_TEMPLATE_VIEW' as ModifyAllAuthorityEnum,
    ExecgwReportsView: 'EXECGW_REPORTS_VIEW' as ModifyAllAuthorityEnum,
    FeesCreate: 'FEES_CREATE' as ModifyAllAuthorityEnum,
    FeesModify: 'FEES_MODIFY' as ModifyAllAuthorityEnum,
    FeesView: 'FEES_VIEW' as ModifyAllAuthorityEnum,
    FeesViewAll: 'FEES_VIEW_ALL' as ModifyAllAuthorityEnum,
    FieldsCreate: 'FIELDS_CREATE' as ModifyAllAuthorityEnum,
    FieldsDelete: 'FIELDS_DELETE' as ModifyAllAuthorityEnum,
    FieldsModify: 'FIELDS_MODIFY' as ModifyAllAuthorityEnum,
    FieldsView: 'FIELDS_VIEW' as ModifyAllAuthorityEnum,
    FileUserLogView: 'FILE_USER_LOG_VIEW' as ModifyAllAuthorityEnum,
    GapMonitorView: 'GAP_MONITOR_VIEW' as ModifyAllAuthorityEnum,
    GlobalAllocationCreate: 'GLOBAL_ALLOCATION_CREATE' as ModifyAllAuthorityEnum,
    GlobalAllocationModify: 'GLOBAL_ALLOCATION_MODIFY' as ModifyAllAuthorityEnum,
    GlobalAllocationView: 'GLOBAL_ALLOCATION_VIEW' as ModifyAllAuthorityEnum,
    GlobalAllocationWidgetView: 'GLOBAL_ALLOCATION_WIDGET_VIEW' as ModifyAllAuthorityEnum,
    GoogleAuthModify: 'GOOGLE_AUTH_MODIFY' as ModifyAllAuthorityEnum,
    GoogleAuthView: 'GOOGLE_AUTH_VIEW' as ModifyAllAuthorityEnum,
    GppDataModify: 'GPP_DATA_MODIFY' as ModifyAllAuthorityEnum,
    GppDataView: 'GPP_DATA_VIEW' as ModifyAllAuthorityEnum,
    GppLoaderSettingsCreate: 'GPP_LOADER_SETTINGS_CREATE' as ModifyAllAuthorityEnum,
    GppLoaderSettingsDelete: 'GPP_LOADER_SETTINGS_DELETE' as ModifyAllAuthorityEnum,
    GppLoaderSettingsModify: 'GPP_LOADER_SETTINGS_MODIFY' as ModifyAllAuthorityEnum,
    GppLoaderSettingsView: 'GPP_LOADER_SETTINGS_VIEW' as ModifyAllAuthorityEnum,
    HealthView: 'HEALTH_VIEW' as ModifyAllAuthorityEnum,
    HistoryView: 'HISTORY_VIEW' as ModifyAllAuthorityEnum,
    IbkrDataModify: 'IBKR_DATA_MODIFY' as ModifyAllAuthorityEnum,
    IbkrDataView: 'IBKR_DATA_VIEW' as ModifyAllAuthorityEnum,
    IbkrFuturesMappingCreate: 'IBKR_FUTURES_MAPPING_CREATE' as ModifyAllAuthorityEnum,
    IbkrFuturesMappingDelete: 'IBKR_FUTURES_MAPPING_DELETE' as ModifyAllAuthorityEnum,
    IbkrFuturesMappingModify: 'IBKR_FUTURES_MAPPING_MODIFY' as ModifyAllAuthorityEnum,
    IbkrFuturesMappingView: 'IBKR_FUTURES_MAPPING_VIEW' as ModifyAllAuthorityEnum,
    IbkrLoaderSettingsCreate: 'IBKR_LOADER_SETTINGS_CREATE' as ModifyAllAuthorityEnum,
    IbkrLoaderSettingsDelete: 'IBKR_LOADER_SETTINGS_DELETE' as ModifyAllAuthorityEnum,
    IbkrLoaderSettingsModify: 'IBKR_LOADER_SETTINGS_MODIFY' as ModifyAllAuthorityEnum,
    IbkrLoaderSettingsView: 'IBKR_LOADER_SETTINGS_VIEW' as ModifyAllAuthorityEnum,
    IncomePaymentTracker: 'INCOME_PAYMENT_TRACKER' as ModifyAllAuthorityEnum,
    InstrumentsCreate: 'INSTRUMENTS_CREATE' as ModifyAllAuthorityEnum,
    InstrumentsModify: 'INSTRUMENTS_MODIFY' as ModifyAllAuthorityEnum,
    InstrumentsView: 'INSTRUMENTS_VIEW' as ModifyAllAuthorityEnum,
    InstrumentToAgentView: 'INSTRUMENT_TO_AGENT_VIEW' as ModifyAllAuthorityEnum,
    InvestecDataModify: 'INVESTEC_DATA_MODIFY' as ModifyAllAuthorityEnum,
    InvestecDataView: 'INVESTEC_DATA_VIEW' as ModifyAllAuthorityEnum,
    InvestecLoaderSettingsCreate: 'INVESTEC_LOADER_SETTINGS_CREATE' as ModifyAllAuthorityEnum,
    InvestecLoaderSettingsDelete: 'INVESTEC_LOADER_SETTINGS_DELETE' as ModifyAllAuthorityEnum,
    InvestecLoaderSettingsModify: 'INVESTEC_LOADER_SETTINGS_MODIFY' as ModifyAllAuthorityEnum,
    InvestecLoaderSettingsView: 'INVESTEC_LOADER_SETTINGS_VIEW' as ModifyAllAuthorityEnum,
    InvestmentView: 'INVESTMENT_VIEW' as ModifyAllAuthorityEnum,
    IpAccess: 'IP_ACCESS' as ModifyAllAuthorityEnum,
    JbDataModify: 'JB_DATA_MODIFY' as ModifyAllAuthorityEnum,
    JbDataView: 'JB_DATA_VIEW' as ModifyAllAuthorityEnum,
    JbLoaderSettingsCreate: 'JB_LOADER_SETTINGS_CREATE' as ModifyAllAuthorityEnum,
    JbLoaderSettingsDelete: 'JB_LOADER_SETTINGS_DELETE' as ModifyAllAuthorityEnum,
    JbLoaderSettingsModify: 'JB_LOADER_SETTINGS_MODIFY' as ModifyAllAuthorityEnum,
    JbLoaderSettingsView: 'JB_LOADER_SETTINGS_VIEW' as ModifyAllAuthorityEnum,
    JpmDataModify: 'JPM_DATA_MODIFY' as ModifyAllAuthorityEnum,
    JpmDataView: 'JPM_DATA_VIEW' as ModifyAllAuthorityEnum,
    JpmLoaderSettingsCreate: 'JPM_LOADER_SETTINGS_CREATE' as ModifyAllAuthorityEnum,
    JpmLoaderSettingsDelete: 'JPM_LOADER_SETTINGS_DELETE' as ModifyAllAuthorityEnum,
    JpmLoaderSettingsModify: 'JPM_LOADER_SETTINGS_MODIFY' as ModifyAllAuthorityEnum,
    JpmLoaderSettingsView: 'JPM_LOADER_SETTINGS_VIEW' as ModifyAllAuthorityEnum,
    LeadCreate: 'LEAD_CREATE' as ModifyAllAuthorityEnum,
    LeadDelete: 'LEAD_DELETE' as ModifyAllAuthorityEnum,
    LeadModify: 'LEAD_MODIFY' as ModifyAllAuthorityEnum,
    LeadModifyAll: 'LEAD_MODIFY_ALL' as ModifyAllAuthorityEnum,
    LeadView: 'LEAD_VIEW' as ModifyAllAuthorityEnum,
    LeadViewAll: 'LEAD_VIEW_ALL' as ModifyAllAuthorityEnum,
    LogotypeDelete: 'LOGOTYPE_DELETE' as ModifyAllAuthorityEnum,
    LogotypeModify: 'LOGOTYPE_MODIFY' as ModifyAllAuthorityEnum,
    LogotypeView: 'LOGOTYPE_VIEW' as ModifyAllAuthorityEnum,
    ManagersCreate: 'MANAGERS_CREATE' as ModifyAllAuthorityEnum,
    ManagersDelete: 'MANAGERS_DELETE' as ModifyAllAuthorityEnum,
    ManagersForceModify: 'MANAGERS_FORCE_MODIFY' as ModifyAllAuthorityEnum,
    ManagersModify: 'MANAGERS_MODIFY' as ModifyAllAuthorityEnum,
    ManagersView: 'MANAGERS_VIEW' as ModifyAllAuthorityEnum,
    MandatesCreate: 'MANDATES_CREATE' as ModifyAllAuthorityEnum,
    MandatesDelete: 'MANDATES_DELETE' as ModifyAllAuthorityEnum,
    MandatesForceModify: 'MANDATES_FORCE_MODIFY' as ModifyAllAuthorityEnum,
    MandatesModify: 'MANDATES_MODIFY' as ModifyAllAuthorityEnum,
    MandatesModifyAll: 'MANDATES_MODIFY_ALL' as ModifyAllAuthorityEnum,
    MandatesView: 'MANDATES_VIEW' as ModifyAllAuthorityEnum,
    MandatesViewAll: 'MANDATES_VIEW_ALL' as ModifyAllAuthorityEnum,
    MandateRestrictionsCreate: 'MANDATE_RESTRICTIONS_CREATE' as ModifyAllAuthorityEnum,
    MandateRestrictionsDelete: 'MANDATE_RESTRICTIONS_DELETE' as ModifyAllAuthorityEnum,
    MandateRestrictionsModify: 'MANDATE_RESTRICTIONS_MODIFY' as ModifyAllAuthorityEnum,
    MandateRestrictionsModifyAll: 'MANDATE_RESTRICTIONS_MODIFY_ALL' as ModifyAllAuthorityEnum,
    MandateRestrictionsView: 'MANDATE_RESTRICTIONS_VIEW' as ModifyAllAuthorityEnum,
    MandateRestrictionsViewAll: 'MANDATE_RESTRICTIONS_VIEW_ALL' as ModifyAllAuthorityEnum,
    MandateRestrictionReportsCreate: 'MANDATE_RESTRICTION_REPORTS_CREATE' as ModifyAllAuthorityEnum,
    MandateRestrictionReportsDelete: 'MANDATE_RESTRICTION_REPORTS_DELETE' as ModifyAllAuthorityEnum,
    MandateRestrictionReportsModify: 'MANDATE_RESTRICTION_REPORTS_MODIFY' as ModifyAllAuthorityEnum,
    MandateRestrictionReportsModifyAll:
      'MANDATE_RESTRICTION_REPORTS_MODIFY_ALL' as ModifyAllAuthorityEnum,
    MandateRestrictionReportsView: 'MANDATE_RESTRICTION_REPORTS_VIEW' as ModifyAllAuthorityEnum,
    MandateRestrictionReportsViewAll:
      'MANDATE_RESTRICTION_REPORTS_VIEW_ALL' as ModifyAllAuthorityEnum,
    MandateRestrictionTemplatesCreate:
      'MANDATE_RESTRICTION_TEMPLATES_CREATE' as ModifyAllAuthorityEnum,
    MandateRestrictionTemplatesDelete:
      'MANDATE_RESTRICTION_TEMPLATES_DELETE' as ModifyAllAuthorityEnum,
    MandateRestrictionTemplatesModify:
      'MANDATE_RESTRICTION_TEMPLATES_MODIFY' as ModifyAllAuthorityEnum,
    MandateRestrictionTemplatesModifyAll:
      'MANDATE_RESTRICTION_TEMPLATES_MODIFY_ALL' as ModifyAllAuthorityEnum,
    MandateRestrictionTemplatesView: 'MANDATE_RESTRICTION_TEMPLATES_VIEW' as ModifyAllAuthorityEnum,
    MandateRestrictionTemplatesViewAll:
      'MANDATE_RESTRICTION_TEMPLATES_VIEW_ALL' as ModifyAllAuthorityEnum,
    MandateRiskProfileCreate: 'MANDATE_RISK_PROFILE_CREATE' as ModifyAllAuthorityEnum,
    MandateRiskProfileDelete: 'MANDATE_RISK_PROFILE_DELETE' as ModifyAllAuthorityEnum,
    MandateRiskProfileForceModify: 'MANDATE_RISK_PROFILE_FORCE_MODIFY' as ModifyAllAuthorityEnum,
    MandateRiskProfileModify: 'MANDATE_RISK_PROFILE_MODIFY' as ModifyAllAuthorityEnum,
    MandateRiskProfileView: 'MANDATE_RISK_PROFILE_VIEW' as ModifyAllAuthorityEnum,
    MandateRiskProfileViewAll: 'MANDATE_RISK_PROFILE_VIEW_ALL' as ModifyAllAuthorityEnum,
    ManualImportPositionsCreate: 'MANUAL_IMPORT_POSITIONS_CREATE' as ModifyAllAuthorityEnum,
    ManualImportPositionsModify: 'MANUAL_IMPORT_POSITIONS_MODIFY' as ModifyAllAuthorityEnum,
    ManualImportPositionsView: 'MANUAL_IMPORT_POSITIONS_VIEW' as ModifyAllAuthorityEnum,
    ManualImportTransactionsCreate: 'MANUAL_IMPORT_TRANSACTIONS_CREATE' as ModifyAllAuthorityEnum,
    ManualImportTransactionsModify: 'MANUAL_IMPORT_TRANSACTIONS_MODIFY' as ModifyAllAuthorityEnum,
    ManualImportTransactionsView: 'MANUAL_IMPORT_TRANSACTIONS_VIEW' as ModifyAllAuthorityEnum,
    MarketplaceModify: 'MARKETPLACE_MODIFY' as ModifyAllAuthorityEnum,
    MarketplaceView: 'MARKETPLACE_VIEW' as ModifyAllAuthorityEnum,
    MarketSecuritiesModify: 'MARKET_SECURITIES_MODIFY' as ModifyAllAuthorityEnum,
    MarketSecuritiesView: 'MARKET_SECURITIES_VIEW' as ModifyAllAuthorityEnum,
    MifirModify: 'MIFIR_MODIFY' as ModifyAllAuthorityEnum,
    MifirReport: 'MIFIR_REPORT' as ModifyAllAuthorityEnum,
    MifirView: 'MIFIR_VIEW' as ModifyAllAuthorityEnum,
    ModelPortfoliosCreate: 'MODEL_PORTFOLIOS_CREATE' as ModifyAllAuthorityEnum,
    ModelPortfoliosDelete: 'MODEL_PORTFOLIOS_DELETE' as ModifyAllAuthorityEnum,
    ModelPortfoliosModify: 'MODEL_PORTFOLIOS_MODIFY' as ModifyAllAuthorityEnum,
    ModelPortfoliosView: 'MODEL_PORTFOLIOS_VIEW' as ModifyAllAuthorityEnum,
    MonthlyReconciliationReport: 'MONTHLY_RECONCILIATION_REPORT' as ModifyAllAuthorityEnum,
    NotificationTemplatesCreate: 'NOTIFICATION_TEMPLATES_CREATE' as ModifyAllAuthorityEnum,
    NotificationTemplatesDelete: 'NOTIFICATION_TEMPLATES_DELETE' as ModifyAllAuthorityEnum,
    NotificationTemplatesModify: 'NOTIFICATION_TEMPLATES_MODIFY' as ModifyAllAuthorityEnum,
    NotificationTemplatesView: 'NOTIFICATION_TEMPLATES_VIEW' as ModifyAllAuthorityEnum,
    NotificationUserLogView: 'NOTIFICATION_USER_LOG_VIEW' as ModifyAllAuthorityEnum,
    Omnibus: 'OMNIBUS' as ModifyAllAuthorityEnum,
    OnlinePricingView: 'ONLINE_PRICING_VIEW' as ModifyAllAuthorityEnum,
    OpportunityCreate: 'OPPORTUNITY_CREATE' as ModifyAllAuthorityEnum,
    OpportunityDelete: 'OPPORTUNITY_DELETE' as ModifyAllAuthorityEnum,
    OpportunityForceModify: 'OPPORTUNITY_FORCE_MODIFY' as ModifyAllAuthorityEnum,
    OpportunityModify: 'OPPORTUNITY_MODIFY' as ModifyAllAuthorityEnum,
    OpportunityModifyAll: 'OPPORTUNITY_MODIFY_ALL' as ModifyAllAuthorityEnum,
    OpportunityView: 'OPPORTUNITY_VIEW' as ModifyAllAuthorityEnum,
    OpportunityViewAll: 'OPPORTUNITY_VIEW_ALL' as ModifyAllAuthorityEnum,
    OrdersAssign: 'ORDERS_ASSIGN' as ModifyAllAuthorityEnum,
    OrdersCommonCreate: 'ORDERS_COMMON_CREATE' as ModifyAllAuthorityEnum,
    OrdersCommonDelete: 'ORDERS_COMMON_DELETE' as ModifyAllAuthorityEnum,
    OrdersCommonModify: 'ORDERS_COMMON_MODIFY' as ModifyAllAuthorityEnum,
    OrdersCommonView: 'ORDERS_COMMON_VIEW' as ModifyAllAuthorityEnum,
    OrdersCorpActionCreate: 'ORDERS_CORP_ACTION_CREATE' as ModifyAllAuthorityEnum,
    OrdersCorpActionDelete: 'ORDERS_CORP_ACTION_DELETE' as ModifyAllAuthorityEnum,
    OrdersCorpActionModify: 'ORDERS_CORP_ACTION_MODIFY' as ModifyAllAuthorityEnum,
    OrdersCorpActionView: 'ORDERS_CORP_ACTION_VIEW' as ModifyAllAuthorityEnum,
    OrdersCreate: 'ORDERS_CREATE' as ModifyAllAuthorityEnum,
    OrdersDelete: 'ORDERS_DELETE' as ModifyAllAuthorityEnum,
    OrdersDmaCreate: 'ORDERS_DMA_CREATE' as ModifyAllAuthorityEnum,
    OrdersForceModify: 'ORDERS_FORCE_MODIFY' as ModifyAllAuthorityEnum,
    OrdersModify: 'ORDERS_MODIFY' as ModifyAllAuthorityEnum,
    OrdersModifyAll: 'ORDERS_MODIFY_ALL' as ModifyAllAuthorityEnum,
    OrdersNonTradeCreate: 'ORDERS_NON_TRADE_CREATE' as ModifyAllAuthorityEnum,
    OrdersNonTradeDelete: 'ORDERS_NON_TRADE_DELETE' as ModifyAllAuthorityEnum,
    OrdersNonTradeModify: 'ORDERS_NON_TRADE_MODIFY' as ModifyAllAuthorityEnum,
    OrdersNonTradeView: 'ORDERS_NON_TRADE_VIEW' as ModifyAllAuthorityEnum,
    OrdersTechInfoModify: 'ORDERS_TECH_INFO_MODIFY' as ModifyAllAuthorityEnum,
    OrdersTechInfoView: 'ORDERS_TECH_INFO_VIEW' as ModifyAllAuthorityEnum,
    OrdersTradeCreate: 'ORDERS_TRADE_CREATE' as ModifyAllAuthorityEnum,
    OrdersTradeDelete: 'ORDERS_TRADE_DELETE' as ModifyAllAuthorityEnum,
    OrdersTradeModify: 'ORDERS_TRADE_MODIFY' as ModifyAllAuthorityEnum,
    OrdersTradeView: 'ORDERS_TRADE_VIEW' as ModifyAllAuthorityEnum,
    OrdersView: 'ORDERS_VIEW' as ModifyAllAuthorityEnum,
    OrdersViewAll: 'ORDERS_VIEW_ALL' as ModifyAllAuthorityEnum,
    PersonalAm: 'PERSONAL_AM' as ModifyAllAuthorityEnum,
    PnlReport: 'PNL_REPORT' as ModifyAllAuthorityEnum,
    PortfoliosCreate: 'PORTFOLIOS_CREATE' as ModifyAllAuthorityEnum,
    PortfoliosDelete: 'PORTFOLIOS_DELETE' as ModifyAllAuthorityEnum,
    PortfoliosForceModify: 'PORTFOLIOS_FORCE_MODIFY' as ModifyAllAuthorityEnum,
    PortfoliosModify: 'PORTFOLIOS_MODIFY' as ModifyAllAuthorityEnum,
    PortfoliosModifyAll: 'PORTFOLIOS_MODIFY_ALL' as ModifyAllAuthorityEnum,
    PortfoliosView: 'PORTFOLIOS_VIEW' as ModifyAllAuthorityEnum,
    PortfoliosViewAll: 'PORTFOLIOS_VIEW_ALL' as ModifyAllAuthorityEnum,
    PortfolioConfigurationView: 'PORTFOLIO_CONFIGURATION_VIEW' as ModifyAllAuthorityEnum,
    PortfolioCostsView: 'PORTFOLIO_COSTS_VIEW' as ModifyAllAuthorityEnum,
    PortfolioFeesView: 'PORTFOLIO_FEES_VIEW' as ModifyAllAuthorityEnum,
    PortfolioFlowsView: 'PORTFOLIO_FLOWS_VIEW' as ModifyAllAuthorityEnum,
    PortfolioOrdersAssign: 'PORTFOLIO_ORDERS_ASSIGN' as ModifyAllAuthorityEnum,
    PortfolioOrdersCreate: 'PORTFOLIO_ORDERS_CREATE' as ModifyAllAuthorityEnum,
    PortfolioOrdersDelete: 'PORTFOLIO_ORDERS_DELETE' as ModifyAllAuthorityEnum,
    PortfolioOrdersModify: 'PORTFOLIO_ORDERS_MODIFY' as ModifyAllAuthorityEnum,
    PortfolioOrdersView: 'PORTFOLIO_ORDERS_VIEW' as ModifyAllAuthorityEnum,
    PortfolioPnlView: 'PORTFOLIO_PNL_VIEW' as ModifyAllAuthorityEnum,
    PortfolioPositionsView: 'PORTFOLIO_POSITIONS_VIEW' as ModifyAllAuthorityEnum,
    PortfolioProfileModify: 'PORTFOLIO_PROFILE_MODIFY' as ModifyAllAuthorityEnum,
    PortfolioProfileView: 'PORTFOLIO_PROFILE_VIEW' as ModifyAllAuthorityEnum,
    PortfolioRestrictionsView: 'PORTFOLIO_RESTRICTIONS_VIEW' as ModifyAllAuthorityEnum,
    PortfolioTransactionsCreate: 'PORTFOLIO_TRANSACTIONS_CREATE' as ModifyAllAuthorityEnum,
    PortfolioTransactionsDelete: 'PORTFOLIO_TRANSACTIONS_DELETE' as ModifyAllAuthorityEnum,
    PortfolioTransactionsModify: 'PORTFOLIO_TRANSACTIONS_MODIFY' as ModifyAllAuthorityEnum,
    PortfolioTransactionsStatus: 'PORTFOLIO_TRANSACTIONS_STATUS' as ModifyAllAuthorityEnum,
    PortfolioTransactionsView: 'PORTFOLIO_TRANSACTIONS_VIEW' as ModifyAllAuthorityEnum,
    PortfolioValuationView: 'PORTFOLIO_VALUATION_VIEW' as ModifyAllAuthorityEnum,
    PositionsView: 'POSITIONS_VIEW' as ModifyAllAuthorityEnum,
    PricesLoadingMonitorView: 'PRICES_LOADING_MONITOR_VIEW' as ModifyAllAuthorityEnum,
    PrivateInstrumentCreate: 'PRIVATE_INSTRUMENT_CREATE' as ModifyAllAuthorityEnum,
    PrivateInstrumentDelete: 'PRIVATE_INSTRUMENT_DELETE' as ModifyAllAuthorityEnum,
    PrivateInstrumentModify: 'PRIVATE_INSTRUMENT_MODIFY' as ModifyAllAuthorityEnum,
    PrivateInstrumentModifyAll: 'PRIVATE_INSTRUMENT_MODIFY_ALL' as ModifyAllAuthorityEnum,
    PrivateInstrumentView: 'PRIVATE_INSTRUMENT_VIEW' as ModifyAllAuthorityEnum,
    PrivateInstrumentViewAll: 'PRIVATE_INSTRUMENT_VIEW_ALL' as ModifyAllAuthorityEnum,
    ProcessCreate: 'PROCESS_CREATE' as ModifyAllAuthorityEnum,
    ProcessDelete: 'PROCESS_DELETE' as ModifyAllAuthorityEnum,
    ProcessModify: 'PROCESS_MODIFY' as ModifyAllAuthorityEnum,
    ProcessView: 'PROCESS_VIEW' as ModifyAllAuthorityEnum,
    ProductGovernanceModify: 'PRODUCT_GOVERNANCE_MODIFY' as ModifyAllAuthorityEnum,
    ProductGovernanceView: 'PRODUCT_GOVERNANCE_VIEW' as ModifyAllAuthorityEnum,
    ProfilesCreate: 'PROFILES_CREATE' as ModifyAllAuthorityEnum,
    ProfilesDelete: 'PROFILES_DELETE' as ModifyAllAuthorityEnum,
    ProfilesModify: 'PROFILES_MODIFY' as ModifyAllAuthorityEnum,
    ProfilesView: 'PROFILES_VIEW' as ModifyAllAuthorityEnum,
    QuarterlyStatisticsReport: 'QUARTERLY_STATISTICS_REPORT' as ModifyAllAuthorityEnum,
    RbsFReport: 'RBS_F_REPORT' as ModifyAllAuthorityEnum,
    RecommendationCreate: 'RECOMMENDATION_CREATE' as ModifyAllAuthorityEnum,
    RecommendationForceModify: 'RECOMMENDATION_FORCE_MODIFY' as ModifyAllAuthorityEnum,
    RecommendationModify: 'RECOMMENDATION_MODIFY' as ModifyAllAuthorityEnum,
    RecommendationView: 'RECOMMENDATION_VIEW' as ModifyAllAuthorityEnum,
    RecommendationViewAll: 'RECOMMENDATION_VIEW_ALL' as ModifyAllAuthorityEnum,
    ReconciliationReport: 'RECONCILIATION_REPORT' as ModifyAllAuthorityEnum,
    RegulatorCreate: 'REGULATOR_CREATE' as ModifyAllAuthorityEnum,
    RegulatorDelete: 'REGULATOR_DELETE' as ModifyAllAuthorityEnum,
    RegulatorModify: 'REGULATOR_MODIFY' as ModifyAllAuthorityEnum,
    RegulatorView: 'REGULATOR_VIEW' as ModifyAllAuthorityEnum,
    RelunaOrdersReportsView: 'RELUNA_ORDERS_REPORTS_VIEW' as ModifyAllAuthorityEnum,
    ReportsCreate: 'REPORTS_CREATE' as ModifyAllAuthorityEnum,
    ReportsDelete: 'REPORTS_DELETE' as ModifyAllAuthorityEnum,
    ReportsModify: 'REPORTS_MODIFY' as ModifyAllAuthorityEnum,
    ReportsView: 'REPORTS_VIEW' as ModifyAllAuthorityEnum,
    RestrictionsCreate: 'RESTRICTIONS_CREATE' as ModifyAllAuthorityEnum,
    RestrictionsDelete: 'RESTRICTIONS_DELETE' as ModifyAllAuthorityEnum,
    RestrictionsModify: 'RESTRICTIONS_MODIFY' as ModifyAllAuthorityEnum,
    RestrictionsModifyAll: 'RESTRICTIONS_MODIFY_ALL' as ModifyAllAuthorityEnum,
    RestrictionsView: 'RESTRICTIONS_VIEW' as ModifyAllAuthorityEnum,
    RestrictionsViewAll: 'RESTRICTIONS_VIEW_ALL' as ModifyAllAuthorityEnum,
    RestrictionAlertView: 'RESTRICTION_ALERT_VIEW' as ModifyAllAuthorityEnum,
    RestrictionReportsCreate: 'RESTRICTION_REPORTS_CREATE' as ModifyAllAuthorityEnum,
    RestrictionReportsDelete: 'RESTRICTION_REPORTS_DELETE' as ModifyAllAuthorityEnum,
    RestrictionReportsModify: 'RESTRICTION_REPORTS_MODIFY' as ModifyAllAuthorityEnum,
    RestrictionReportsModifyAll: 'RESTRICTION_REPORTS_MODIFY_ALL' as ModifyAllAuthorityEnum,
    RestrictionReportsView: 'RESTRICTION_REPORTS_VIEW' as ModifyAllAuthorityEnum,
    RestrictionReportsViewAll: 'RESTRICTION_REPORTS_VIEW_ALL' as ModifyAllAuthorityEnum,
    RestrictionTemplatesCreate: 'RESTRICTION_TEMPLATES_CREATE' as ModifyAllAuthorityEnum,
    RestrictionTemplatesDelete: 'RESTRICTION_TEMPLATES_DELETE' as ModifyAllAuthorityEnum,
    RestrictionTemplatesModify: 'RESTRICTION_TEMPLATES_MODIFY' as ModifyAllAuthorityEnum,
    RestrictionTemplatesModifyAll: 'RESTRICTION_TEMPLATES_MODIFY_ALL' as ModifyAllAuthorityEnum,
    RestrictionTemplatesView: 'RESTRICTION_TEMPLATES_VIEW' as ModifyAllAuthorityEnum,
    RestrictionTemplatesViewAll: 'RESTRICTION_TEMPLATES_VIEW_ALL' as ModifyAllAuthorityEnum,
    RiskReportCreate: 'RISK_REPORT_CREATE' as ModifyAllAuthorityEnum,
    RiskReportView: 'RISK_REPORT_VIEW' as ModifyAllAuthorityEnum,
    RolesCreate: 'ROLES_CREATE' as ModifyAllAuthorityEnum,
    RolesDelete: 'ROLES_DELETE' as ModifyAllAuthorityEnum,
    RolesModify: 'ROLES_MODIFY' as ModifyAllAuthorityEnum,
    RolesView: 'ROLES_VIEW' as ModifyAllAuthorityEnum,
    Rts28Report: 'RTS28_REPORT' as ModifyAllAuthorityEnum,
    SaltedgeTransactionsModify: 'SALTEDGE_TRANSACTIONS_MODIFY' as ModifyAllAuthorityEnum,
    SaltedgeTransactionsView: 'SALTEDGE_TRANSACTIONS_VIEW' as ModifyAllAuthorityEnum,
    SendReports: 'SEND_REPORTS' as ModifyAllAuthorityEnum,
    SettingsModify: 'SETTINGS_MODIFY' as ModifyAllAuthorityEnum,
    SettingsView: 'SETTINGS_VIEW' as ModifyAllAuthorityEnum,
    ShopView: 'SHOP_VIEW' as ModifyAllAuthorityEnum,
    SiteInfoCreate: 'SITE_INFO_CREATE' as ModifyAllAuthorityEnum,
    SiteInfoDelete: 'SITE_INFO_DELETE' as ModifyAllAuthorityEnum,
    SiteInfoModify: 'SITE_INFO_MODIFY' as ModifyAllAuthorityEnum,
    SiteInfoView: 'SITE_INFO_VIEW' as ModifyAllAuthorityEnum,
    SovaDataModify: 'SOVA_DATA_MODIFY' as ModifyAllAuthorityEnum,
    SovaDataView: 'SOVA_DATA_VIEW' as ModifyAllAuthorityEnum,
    SovaOrdersModify: 'SOVA_ORDERS_MODIFY' as ModifyAllAuthorityEnum,
    SovaOrdersView: 'SOVA_ORDERS_VIEW' as ModifyAllAuthorityEnum,
    StatementOfEligibleFundsReport: 'STATEMENT_OF_ELIGIBLE_FUNDS_REPORT' as ModifyAllAuthorityEnum,
    StatisticsView: 'STATISTICS_VIEW' as ModifyAllAuthorityEnum,
    StrategiesCreate: 'STRATEGIES_CREATE' as ModifyAllAuthorityEnum,
    StrategiesDelete: 'STRATEGIES_DELETE' as ModifyAllAuthorityEnum,
    StrategiesForceModify: 'STRATEGIES_FORCE_MODIFY' as ModifyAllAuthorityEnum,
    StrategiesModify: 'STRATEGIES_MODIFY' as ModifyAllAuthorityEnum,
    StrategiesModifyAll: 'STRATEGIES_MODIFY_ALL' as ModifyAllAuthorityEnum,
    StrategiesView: 'STRATEGIES_VIEW' as ModifyAllAuthorityEnum,
    StrategiesViewAll: 'STRATEGIES_VIEW_ALL' as ModifyAllAuthorityEnum,
    StructuredProductsCreate: 'STRUCTURED_PRODUCTS_CREATE' as ModifyAllAuthorityEnum,
    StructuredProductsDelete: 'STRUCTURED_PRODUCTS_DELETE' as ModifyAllAuthorityEnum,
    StructuredProductsModify: 'STRUCTURED_PRODUCTS_MODIFY' as ModifyAllAuthorityEnum,
    StructuredProductsModifyAll: 'STRUCTURED_PRODUCTS_MODIFY_ALL' as ModifyAllAuthorityEnum,
    StructuredProductsView: 'STRUCTURED_PRODUCTS_VIEW' as ModifyAllAuthorityEnum,
    StructuredProductsViewAll: 'STRUCTURED_PRODUCTS_VIEW_ALL' as ModifyAllAuthorityEnum,
    SubportfoliosView: 'SUBPORTFOLIOS_VIEW' as ModifyAllAuthorityEnum,
    SubscriptionModify: 'SUBSCRIPTION_MODIFY' as ModifyAllAuthorityEnum,
    SubscriptionView: 'SUBSCRIPTION_VIEW' as ModifyAllAuthorityEnum,
    TableConstructorModify: 'TABLE_CONSTRUCTOR_MODIFY' as ModifyAllAuthorityEnum,
    TableConstructorView: 'TABLE_CONSTRUCTOR_VIEW' as ModifyAllAuthorityEnum,
    TaviraDataModify: 'TAVIRA_DATA_MODIFY' as ModifyAllAuthorityEnum,
    TaviraDataView: 'TAVIRA_DATA_VIEW' as ModifyAllAuthorityEnum,
    TransactionsCreate: 'TRANSACTIONS_CREATE' as ModifyAllAuthorityEnum,
    TransactionsDelete: 'TRANSACTIONS_DELETE' as ModifyAllAuthorityEnum,
    TransactionsForceModify: 'TRANSACTIONS_FORCE_MODIFY' as ModifyAllAuthorityEnum,
    TransactionsMatchingModify: 'TRANSACTIONS_MATCHING_MODIFY' as ModifyAllAuthorityEnum,
    TransactionsMatchingView: 'TRANSACTIONS_MATCHING_VIEW' as ModifyAllAuthorityEnum,
    TransactionsModify: 'TRANSACTIONS_MODIFY' as ModifyAllAuthorityEnum,
    TransactionsModifyAll: 'TRANSACTIONS_MODIFY_ALL' as ModifyAllAuthorityEnum,
    TransactionsStatus: 'TRANSACTIONS_STATUS' as ModifyAllAuthorityEnum,
    TransactionsView: 'TRANSACTIONS_VIEW' as ModifyAllAuthorityEnum,
    TransactionsViewAll: 'TRANSACTIONS_VIEW_ALL' as ModifyAllAuthorityEnum,
    TransactionLoaderCreate: 'TRANSACTION_LOADER_CREATE' as ModifyAllAuthorityEnum,
    TransactionLoaderModify: 'TRANSACTION_LOADER_MODIFY' as ModifyAllAuthorityEnum,
    TransactionLoaderView: 'TRANSACTION_LOADER_VIEW' as ModifyAllAuthorityEnum,
    TransactionWithoutBankView: 'TRANSACTION_WITHOUT_BANK_VIEW' as ModifyAllAuthorityEnum,
    TrimpDataModify: 'TRIMP_DATA_MODIFY' as ModifyAllAuthorityEnum,
    TrimpDataView: 'TRIMP_DATA_VIEW' as ModifyAllAuthorityEnum,
    Ttca: 'TTCA' as ModifyAllAuthorityEnum,
    UbsDataModify: 'UBS_DATA_MODIFY' as ModifyAllAuthorityEnum,
    UbsDataView: 'UBS_DATA_VIEW' as ModifyAllAuthorityEnum,
    ValueDateRulesModify: 'VALUE_DATE_RULES_MODIFY' as ModifyAllAuthorityEnum,
    ValueDateRulesView: 'VALUE_DATE_RULES_VIEW' as ModifyAllAuthorityEnum,
    VpDataModify: 'VP_DATA_MODIFY' as ModifyAllAuthorityEnum,
    VpDataView: 'VP_DATA_VIEW' as ModifyAllAuthorityEnum,
    VpLoaderSettingsCreate: 'VP_LOADER_SETTINGS_CREATE' as ModifyAllAuthorityEnum,
    VpLoaderSettingsDelete: 'VP_LOADER_SETTINGS_DELETE' as ModifyAllAuthorityEnum,
    VpLoaderSettingsModify: 'VP_LOADER_SETTINGS_MODIFY' as ModifyAllAuthorityEnum,
    VpLoaderSettingsView: 'VP_LOADER_SETTINGS_VIEW' as ModifyAllAuthorityEnum,
    VtbDataModify: 'VTB_DATA_MODIFY' as ModifyAllAuthorityEnum,
    VtbDataView: 'VTB_DATA_VIEW' as ModifyAllAuthorityEnum,
    ZohoSettingsCreate: 'ZOHO_SETTINGS_CREATE' as ModifyAllAuthorityEnum,
    ZohoSettingsDelete: 'ZOHO_SETTINGS_DELETE' as ModifyAllAuthorityEnum,
    ZohoSettingsModify: 'ZOHO_SETTINGS_MODIFY' as ModifyAllAuthorityEnum,
    ZohoSettingsView: 'ZOHO_SETTINGS_VIEW' as ModifyAllAuthorityEnum,
  };
  export type ModifyAuthorityEnum =
    | 'ACCOUNTS_CREATE'
    | 'ACCOUNTS_DELETE'
    | 'ACCOUNTS_FORCE_MODIFY'
    | 'ACCOUNTS_MODIFY'
    | 'ACCOUNTS_MODIFY_ALL'
    | 'ACCOUNTS_VIEW'
    | 'ACCOUNTS_VIEW_ALL'
    | 'ACCOUNT_STRUCTURE'
    | 'ACTUATOR_VIEW'
    | 'ADVANCED_ANALYTICS_VIEW'
    | 'ALFA_DATA_MODIFY'
    | 'ALFA_DATA_VIEW'
    | 'AMAZON_WORK_DOCS'
    | 'BANK_ACCOUNTS_CREATE'
    | 'BANK_ACCOUNTS_DELETE'
    | 'BANK_ACCOUNTS_FORCE_MODIFY'
    | 'BANK_ACCOUNTS_MODIFY'
    | 'BANK_ACCOUNTS_MODIFY_ALL'
    | 'BANK_ACCOUNTS_VIEW'
    | 'BANK_ACCOUNTS_VIEW_ALL'
    | 'BANK_STATS_VIEW'
    | 'BKS_DATA_MODIFY'
    | 'BKS_DATA_VIEW'
    | 'BKS_LOADER_SETTINGS_CREATE'
    | 'BKS_LOADER_SETTINGS_DELETE'
    | 'BKS_LOADER_SETTINGS_MODIFY'
    | 'BKS_LOADER_SETTINGS_VIEW'
    | 'BLOOMBERG_REQUESTS_VIEW'
    | 'BROKER_CREATE'
    | 'BROKER_DELETE'
    | 'BROKER_MODIFY'
    | 'BROKER_VIEW'
    | 'BUSINESS_EVENTS_VIEW'
    | 'CASS_REPORT'
    | 'CBH_BS_DATA_MODIFY'
    | 'CBH_BS_DATA_VIEW'
    | 'CBH_DATA_MODIFY'
    | 'CBH_DATA_VIEW'
    | 'CBH_LOADER_SETTINGS_CREATE'
    | 'CBH_LOADER_SETTINGS_DELETE'
    | 'CBH_LOADER_SETTINGS_MODIFY'
    | 'CBH_LOADER_SETTINGS_VIEW'
    | 'CHANGE_REQUEST_SETTINGS_CREATE'
    | 'CHANGE_REQUEST_SETTINGS_DELETE'
    | 'CHANGE_REQUEST_SETTINGS_MODIFY'
    | 'CHANGE_REQUEST_SETTINGS_VIEW'
    | 'CLIENTS_CREATE'
    | 'CLIENTS_DELETE'
    | 'CLIENTS_MODIFY'
    | 'CLIENTS_MODIFY_ALL'
    | 'CLIENTS_VIEW'
    | 'CLIENTS_VIEW_ALL'
    | 'CLIENT_COUNT_REPORT'
    | 'CLIENT_FEES_REPORT'
    | 'CLIENT_VALUATION_REPORT'
    | 'CMAR_REPORT'
    | 'COMMON_EMAIL_TEMPLATES_CREATE'
    | 'COMMON_EMAIL_TEMPLATES_DELETE'
    | 'COMMON_EMAIL_TEMPLATES_MODIFY'
    | 'COMMON_EMAIL_TEMPLATES_VIEW'
    | 'COMPANIES_CREATE'
    | 'COMPANIES_DELETE'
    | 'COMPANIES_MODIFY'
    | 'COMPANIES_TAGS_VIEW'
    | 'COMPANIES_VIEW'
    | 'COMPANY_HOLDING_CREATE'
    | 'COMPANY_HOLDING_DELETE'
    | 'COMPANY_HOLDING_MODIFY'
    | 'COMPANY_HOLDING_VIEW'
    | 'COMPANY_RISK_REPORT'
    | 'COMPLIANCE_ACCOUNT_COMPANY_CREATE'
    | 'COMPLIANCE_ACCOUNT_COMPANY_DELETE'
    | 'COMPLIANCE_ACCOUNT_COMPANY_FORCE_MODIFY'
    | 'COMPLIANCE_ACCOUNT_COMPANY_MODIFY'
    | 'COMPLIANCE_ACCOUNT_COMPANY_VIEW'
    | 'COUNTERPARTY_CREATE'
    | 'COUNTERPARTY_DELETE'
    | 'COUNTERPARTY_MODIFY'
    | 'COUNTERPARTY_VIEW'
    | 'COUPON_PROJECTION_REPORT'
    | 'CRS_REPORT'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_CREATE'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_MODIFY'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_VIEW'
    | 'CUSTODIAN_CREATE'
    | 'CUSTODIAN_DELETE'
    | 'CUSTODIAN_MODIFY'
    | 'CUSTODIAN_VIEW'
    | 'DASHBOARD_MODIFY'
    | 'DASHBOARD_VIEW'
    | 'DBS_DATA_MODIFY'
    | 'DBS_DATA_VIEW'
    | 'DEACTIVATED_TRANSACTION_VIEW'
    | 'DEPARTMENTS_CREATE'
    | 'DEPARTMENTS_DELETE'
    | 'DEPARTMENTS_MODIFY'
    | 'DEPARTMENTS_VIEW'
    | 'DOLFIN_TRANSACTIONS_MODIFY'
    | 'DOLFIN_TRANSACTIONS_VIEW'
    | 'EDR_DATA_MODIFY'
    | 'EDR_DATA_VIEW'
    | 'EDR_LOADER_SETTINGS_CREATE'
    | 'EDR_LOADER_SETTINGS_DELETE'
    | 'EDR_LOADER_SETTINGS_MODIFY'
    | 'EDR_LOADER_SETTINGS_VIEW'
    | 'EMAIL_TEMPLATE_CREATE'
    | 'EMAIL_TEMPLATE_DELETE'
    | 'EMAIL_TEMPLATE_MODIFY'
    | 'EMAIL_TEMPLATE_VIEW'
    | 'EXECGW_REPORTS_VIEW'
    | 'FEES_CREATE'
    | 'FEES_MODIFY'
    | 'FEES_VIEW'
    | 'FEES_VIEW_ALL'
    | 'FIELDS_CREATE'
    | 'FIELDS_DELETE'
    | 'FIELDS_MODIFY'
    | 'FIELDS_VIEW'
    | 'FILE_USER_LOG_VIEW'
    | 'GAP_MONITOR_VIEW'
    | 'GLOBAL_ALLOCATION_CREATE'
    | 'GLOBAL_ALLOCATION_MODIFY'
    | 'GLOBAL_ALLOCATION_VIEW'
    | 'GLOBAL_ALLOCATION_WIDGET_VIEW'
    | 'GOOGLE_AUTH_MODIFY'
    | 'GOOGLE_AUTH_VIEW'
    | 'GPP_DATA_MODIFY'
    | 'GPP_DATA_VIEW'
    | 'GPP_LOADER_SETTINGS_CREATE'
    | 'GPP_LOADER_SETTINGS_DELETE'
    | 'GPP_LOADER_SETTINGS_MODIFY'
    | 'GPP_LOADER_SETTINGS_VIEW'
    | 'HEALTH_VIEW'
    | 'HISTORY_VIEW'
    | 'IBKR_DATA_MODIFY'
    | 'IBKR_DATA_VIEW'
    | 'IBKR_FUTURES_MAPPING_CREATE'
    | 'IBKR_FUTURES_MAPPING_DELETE'
    | 'IBKR_FUTURES_MAPPING_MODIFY'
    | 'IBKR_FUTURES_MAPPING_VIEW'
    | 'IBKR_LOADER_SETTINGS_CREATE'
    | 'IBKR_LOADER_SETTINGS_DELETE'
    | 'IBKR_LOADER_SETTINGS_MODIFY'
    | 'IBKR_LOADER_SETTINGS_VIEW'
    | 'INCOME_PAYMENT_TRACKER'
    | 'INSTRUMENTS_CREATE'
    | 'INSTRUMENTS_MODIFY'
    | 'INSTRUMENTS_VIEW'
    | 'INSTRUMENT_TO_AGENT_VIEW'
    | 'INVESTEC_DATA_MODIFY'
    | 'INVESTEC_DATA_VIEW'
    | 'INVESTEC_LOADER_SETTINGS_CREATE'
    | 'INVESTEC_LOADER_SETTINGS_DELETE'
    | 'INVESTEC_LOADER_SETTINGS_MODIFY'
    | 'INVESTEC_LOADER_SETTINGS_VIEW'
    | 'INVESTMENT_VIEW'
    | 'IP_ACCESS'
    | 'JB_DATA_MODIFY'
    | 'JB_DATA_VIEW'
    | 'JB_LOADER_SETTINGS_CREATE'
    | 'JB_LOADER_SETTINGS_DELETE'
    | 'JB_LOADER_SETTINGS_MODIFY'
    | 'JB_LOADER_SETTINGS_VIEW'
    | 'JPM_DATA_MODIFY'
    | 'JPM_DATA_VIEW'
    | 'JPM_LOADER_SETTINGS_CREATE'
    | 'JPM_LOADER_SETTINGS_DELETE'
    | 'JPM_LOADER_SETTINGS_MODIFY'
    | 'JPM_LOADER_SETTINGS_VIEW'
    | 'LEAD_CREATE'
    | 'LEAD_DELETE'
    | 'LEAD_MODIFY'
    | 'LEAD_MODIFY_ALL'
    | 'LEAD_VIEW'
    | 'LEAD_VIEW_ALL'
    | 'LOGOTYPE_DELETE'
    | 'LOGOTYPE_MODIFY'
    | 'LOGOTYPE_VIEW'
    | 'MANAGERS_CREATE'
    | 'MANAGERS_DELETE'
    | 'MANAGERS_FORCE_MODIFY'
    | 'MANAGERS_MODIFY'
    | 'MANAGERS_VIEW'
    | 'MANDATES_CREATE'
    | 'MANDATES_DELETE'
    | 'MANDATES_FORCE_MODIFY'
    | 'MANDATES_MODIFY'
    | 'MANDATES_MODIFY_ALL'
    | 'MANDATES_VIEW'
    | 'MANDATES_VIEW_ALL'
    | 'MANDATE_RESTRICTIONS_CREATE'
    | 'MANDATE_RESTRICTIONS_DELETE'
    | 'MANDATE_RESTRICTIONS_MODIFY'
    | 'MANDATE_RESTRICTIONS_MODIFY_ALL'
    | 'MANDATE_RESTRICTIONS_VIEW'
    | 'MANDATE_RESTRICTIONS_VIEW_ALL'
    | 'MANDATE_RESTRICTION_REPORTS_CREATE'
    | 'MANDATE_RESTRICTION_REPORTS_DELETE'
    | 'MANDATE_RESTRICTION_REPORTS_MODIFY'
    | 'MANDATE_RESTRICTION_REPORTS_MODIFY_ALL'
    | 'MANDATE_RESTRICTION_REPORTS_VIEW'
    | 'MANDATE_RESTRICTION_REPORTS_VIEW_ALL'
    | 'MANDATE_RESTRICTION_TEMPLATES_CREATE'
    | 'MANDATE_RESTRICTION_TEMPLATES_DELETE'
    | 'MANDATE_RESTRICTION_TEMPLATES_MODIFY'
    | 'MANDATE_RESTRICTION_TEMPLATES_MODIFY_ALL'
    | 'MANDATE_RESTRICTION_TEMPLATES_VIEW'
    | 'MANDATE_RESTRICTION_TEMPLATES_VIEW_ALL'
    | 'MANDATE_RISK_PROFILE_CREATE'
    | 'MANDATE_RISK_PROFILE_DELETE'
    | 'MANDATE_RISK_PROFILE_FORCE_MODIFY'
    | 'MANDATE_RISK_PROFILE_MODIFY'
    | 'MANDATE_RISK_PROFILE_VIEW'
    | 'MANDATE_RISK_PROFILE_VIEW_ALL'
    | 'MANUAL_IMPORT_POSITIONS_CREATE'
    | 'MANUAL_IMPORT_POSITIONS_MODIFY'
    | 'MANUAL_IMPORT_POSITIONS_VIEW'
    | 'MANUAL_IMPORT_TRANSACTIONS_CREATE'
    | 'MANUAL_IMPORT_TRANSACTIONS_MODIFY'
    | 'MANUAL_IMPORT_TRANSACTIONS_VIEW'
    | 'MARKETPLACE_MODIFY'
    | 'MARKETPLACE_VIEW'
    | 'MARKET_SECURITIES_MODIFY'
    | 'MARKET_SECURITIES_VIEW'
    | 'MIFIR_MODIFY'
    | 'MIFIR_REPORT'
    | 'MIFIR_VIEW'
    | 'MODEL_PORTFOLIOS_CREATE'
    | 'MODEL_PORTFOLIOS_DELETE'
    | 'MODEL_PORTFOLIOS_MODIFY'
    | 'MODEL_PORTFOLIOS_VIEW'
    | 'MONTHLY_RECONCILIATION_REPORT'
    | 'NOTIFICATION_TEMPLATES_CREATE'
    | 'NOTIFICATION_TEMPLATES_DELETE'
    | 'NOTIFICATION_TEMPLATES_MODIFY'
    | 'NOTIFICATION_TEMPLATES_VIEW'
    | 'NOTIFICATION_USER_LOG_VIEW'
    | 'OMNIBUS'
    | 'ONLINE_PRICING_VIEW'
    | 'OPPORTUNITY_CREATE'
    | 'OPPORTUNITY_DELETE'
    | 'OPPORTUNITY_FORCE_MODIFY'
    | 'OPPORTUNITY_MODIFY'
    | 'OPPORTUNITY_MODIFY_ALL'
    | 'OPPORTUNITY_VIEW'
    | 'OPPORTUNITY_VIEW_ALL'
    | 'ORDERS_ASSIGN'
    | 'ORDERS_COMMON_CREATE'
    | 'ORDERS_COMMON_DELETE'
    | 'ORDERS_COMMON_MODIFY'
    | 'ORDERS_COMMON_VIEW'
    | 'ORDERS_CORP_ACTION_CREATE'
    | 'ORDERS_CORP_ACTION_DELETE'
    | 'ORDERS_CORP_ACTION_MODIFY'
    | 'ORDERS_CORP_ACTION_VIEW'
    | 'ORDERS_CREATE'
    | 'ORDERS_DELETE'
    | 'ORDERS_DMA_CREATE'
    | 'ORDERS_FORCE_MODIFY'
    | 'ORDERS_MODIFY'
    | 'ORDERS_MODIFY_ALL'
    | 'ORDERS_NON_TRADE_CREATE'
    | 'ORDERS_NON_TRADE_DELETE'
    | 'ORDERS_NON_TRADE_MODIFY'
    | 'ORDERS_NON_TRADE_VIEW'
    | 'ORDERS_TECH_INFO_MODIFY'
    | 'ORDERS_TECH_INFO_VIEW'
    | 'ORDERS_TRADE_CREATE'
    | 'ORDERS_TRADE_DELETE'
    | 'ORDERS_TRADE_MODIFY'
    | 'ORDERS_TRADE_VIEW'
    | 'ORDERS_VIEW'
    | 'ORDERS_VIEW_ALL'
    | 'PERSONAL_AM'
    | 'PNL_REPORT'
    | 'PORTFOLIOS_CREATE'
    | 'PORTFOLIOS_DELETE'
    | 'PORTFOLIOS_FORCE_MODIFY'
    | 'PORTFOLIOS_MODIFY'
    | 'PORTFOLIOS_MODIFY_ALL'
    | 'PORTFOLIOS_VIEW'
    | 'PORTFOLIOS_VIEW_ALL'
    | 'PORTFOLIO_CONFIGURATION_VIEW'
    | 'PORTFOLIO_COSTS_VIEW'
    | 'PORTFOLIO_FEES_VIEW'
    | 'PORTFOLIO_FLOWS_VIEW'
    | 'PORTFOLIO_ORDERS_ASSIGN'
    | 'PORTFOLIO_ORDERS_CREATE'
    | 'PORTFOLIO_ORDERS_DELETE'
    | 'PORTFOLIO_ORDERS_MODIFY'
    | 'PORTFOLIO_ORDERS_VIEW'
    | 'PORTFOLIO_PNL_VIEW'
    | 'PORTFOLIO_POSITIONS_VIEW'
    | 'PORTFOLIO_PROFILE_MODIFY'
    | 'PORTFOLIO_PROFILE_VIEW'
    | 'PORTFOLIO_RESTRICTIONS_VIEW'
    | 'PORTFOLIO_TRANSACTIONS_CREATE'
    | 'PORTFOLIO_TRANSACTIONS_DELETE'
    | 'PORTFOLIO_TRANSACTIONS_MODIFY'
    | 'PORTFOLIO_TRANSACTIONS_STATUS'
    | 'PORTFOLIO_TRANSACTIONS_VIEW'
    | 'PORTFOLIO_VALUATION_VIEW'
    | 'POSITIONS_VIEW'
    | 'PRICES_LOADING_MONITOR_VIEW'
    | 'PRIVATE_INSTRUMENT_CREATE'
    | 'PRIVATE_INSTRUMENT_DELETE'
    | 'PRIVATE_INSTRUMENT_MODIFY'
    | 'PRIVATE_INSTRUMENT_MODIFY_ALL'
    | 'PRIVATE_INSTRUMENT_VIEW'
    | 'PRIVATE_INSTRUMENT_VIEW_ALL'
    | 'PROCESS_CREATE'
    | 'PROCESS_DELETE'
    | 'PROCESS_MODIFY'
    | 'PROCESS_VIEW'
    | 'PRODUCT_GOVERNANCE_MODIFY'
    | 'PRODUCT_GOVERNANCE_VIEW'
    | 'PROFILES_CREATE'
    | 'PROFILES_DELETE'
    | 'PROFILES_MODIFY'
    | 'PROFILES_VIEW'
    | 'QUARTERLY_STATISTICS_REPORT'
    | 'RBS_F_REPORT'
    | 'RECOMMENDATION_CREATE'
    | 'RECOMMENDATION_FORCE_MODIFY'
    | 'RECOMMENDATION_MODIFY'
    | 'RECOMMENDATION_VIEW'
    | 'RECOMMENDATION_VIEW_ALL'
    | 'RECONCILIATION_REPORT'
    | 'REGULATOR_CREATE'
    | 'REGULATOR_DELETE'
    | 'REGULATOR_MODIFY'
    | 'REGULATOR_VIEW'
    | 'RELUNA_ORDERS_REPORTS_VIEW'
    | 'REPORTS_CREATE'
    | 'REPORTS_DELETE'
    | 'REPORTS_MODIFY'
    | 'REPORTS_VIEW'
    | 'RESTRICTIONS_CREATE'
    | 'RESTRICTIONS_DELETE'
    | 'RESTRICTIONS_MODIFY'
    | 'RESTRICTIONS_MODIFY_ALL'
    | 'RESTRICTIONS_VIEW'
    | 'RESTRICTIONS_VIEW_ALL'
    | 'RESTRICTION_ALERT_VIEW'
    | 'RESTRICTION_REPORTS_CREATE'
    | 'RESTRICTION_REPORTS_DELETE'
    | 'RESTRICTION_REPORTS_MODIFY'
    | 'RESTRICTION_REPORTS_MODIFY_ALL'
    | 'RESTRICTION_REPORTS_VIEW'
    | 'RESTRICTION_REPORTS_VIEW_ALL'
    | 'RESTRICTION_TEMPLATES_CREATE'
    | 'RESTRICTION_TEMPLATES_DELETE'
    | 'RESTRICTION_TEMPLATES_MODIFY'
    | 'RESTRICTION_TEMPLATES_MODIFY_ALL'
    | 'RESTRICTION_TEMPLATES_VIEW'
    | 'RESTRICTION_TEMPLATES_VIEW_ALL'
    | 'RISK_REPORT_CREATE'
    | 'RISK_REPORT_VIEW'
    | 'ROLES_CREATE'
    | 'ROLES_DELETE'
    | 'ROLES_MODIFY'
    | 'ROLES_VIEW'
    | 'RTS28_REPORT'
    | 'SALTEDGE_TRANSACTIONS_MODIFY'
    | 'SALTEDGE_TRANSACTIONS_VIEW'
    | 'SEND_REPORTS'
    | 'SETTINGS_MODIFY'
    | 'SETTINGS_VIEW'
    | 'SHOP_VIEW'
    | 'SITE_INFO_CREATE'
    | 'SITE_INFO_DELETE'
    | 'SITE_INFO_MODIFY'
    | 'SITE_INFO_VIEW'
    | 'SOVA_DATA_MODIFY'
    | 'SOVA_DATA_VIEW'
    | 'SOVA_ORDERS_MODIFY'
    | 'SOVA_ORDERS_VIEW'
    | 'STATEMENT_OF_ELIGIBLE_FUNDS_REPORT'
    | 'STATISTICS_VIEW'
    | 'STRATEGIES_CREATE'
    | 'STRATEGIES_DELETE'
    | 'STRATEGIES_FORCE_MODIFY'
    | 'STRATEGIES_MODIFY'
    | 'STRATEGIES_MODIFY_ALL'
    | 'STRATEGIES_VIEW'
    | 'STRATEGIES_VIEW_ALL'
    | 'STRUCTURED_PRODUCTS_CREATE'
    | 'STRUCTURED_PRODUCTS_DELETE'
    | 'STRUCTURED_PRODUCTS_MODIFY'
    | 'STRUCTURED_PRODUCTS_MODIFY_ALL'
    | 'STRUCTURED_PRODUCTS_VIEW'
    | 'STRUCTURED_PRODUCTS_VIEW_ALL'
    | 'SUBPORTFOLIOS_VIEW'
    | 'SUBSCRIPTION_MODIFY'
    | 'SUBSCRIPTION_VIEW'
    | 'TABLE_CONSTRUCTOR_MODIFY'
    | 'TABLE_CONSTRUCTOR_VIEW'
    | 'TAVIRA_DATA_MODIFY'
    | 'TAVIRA_DATA_VIEW'
    | 'TRANSACTIONS_CREATE'
    | 'TRANSACTIONS_DELETE'
    | 'TRANSACTIONS_FORCE_MODIFY'
    | 'TRANSACTIONS_MATCHING_MODIFY'
    | 'TRANSACTIONS_MATCHING_VIEW'
    | 'TRANSACTIONS_MODIFY'
    | 'TRANSACTIONS_MODIFY_ALL'
    | 'TRANSACTIONS_STATUS'
    | 'TRANSACTIONS_VIEW'
    | 'TRANSACTIONS_VIEW_ALL'
    | 'TRANSACTION_LOADER_CREATE'
    | 'TRANSACTION_LOADER_MODIFY'
    | 'TRANSACTION_LOADER_VIEW'
    | 'TRANSACTION_WITHOUT_BANK_VIEW'
    | 'TRIMP_DATA_MODIFY'
    | 'TRIMP_DATA_VIEW'
    | 'TTCA'
    | 'UBS_DATA_MODIFY'
    | 'UBS_DATA_VIEW'
    | 'VALUE_DATE_RULES_MODIFY'
    | 'VALUE_DATE_RULES_VIEW'
    | 'VP_DATA_MODIFY'
    | 'VP_DATA_VIEW'
    | 'VP_LOADER_SETTINGS_CREATE'
    | 'VP_LOADER_SETTINGS_DELETE'
    | 'VP_LOADER_SETTINGS_MODIFY'
    | 'VP_LOADER_SETTINGS_VIEW'
    | 'VTB_DATA_MODIFY'
    | 'VTB_DATA_VIEW'
    | 'ZOHO_SETTINGS_CREATE'
    | 'ZOHO_SETTINGS_DELETE'
    | 'ZOHO_SETTINGS_MODIFY'
    | 'ZOHO_SETTINGS_VIEW';
  export const ModifyAuthorityEnum = {
    AccountsCreate: 'ACCOUNTS_CREATE' as ModifyAuthorityEnum,
    AccountsDelete: 'ACCOUNTS_DELETE' as ModifyAuthorityEnum,
    AccountsForceModify: 'ACCOUNTS_FORCE_MODIFY' as ModifyAuthorityEnum,
    AccountsModify: 'ACCOUNTS_MODIFY' as ModifyAuthorityEnum,
    AccountsModifyAll: 'ACCOUNTS_MODIFY_ALL' as ModifyAuthorityEnum,
    AccountsView: 'ACCOUNTS_VIEW' as ModifyAuthorityEnum,
    AccountsViewAll: 'ACCOUNTS_VIEW_ALL' as ModifyAuthorityEnum,
    AccountStructure: 'ACCOUNT_STRUCTURE' as ModifyAuthorityEnum,
    ActuatorView: 'ACTUATOR_VIEW' as ModifyAuthorityEnum,
    AdvancedAnalyticsView: 'ADVANCED_ANALYTICS_VIEW' as ModifyAuthorityEnum,
    AlfaDataModify: 'ALFA_DATA_MODIFY' as ModifyAuthorityEnum,
    AlfaDataView: 'ALFA_DATA_VIEW' as ModifyAuthorityEnum,
    AmazonWorkDocs: 'AMAZON_WORK_DOCS' as ModifyAuthorityEnum,
    BankAccountsCreate: 'BANK_ACCOUNTS_CREATE' as ModifyAuthorityEnum,
    BankAccountsDelete: 'BANK_ACCOUNTS_DELETE' as ModifyAuthorityEnum,
    BankAccountsForceModify: 'BANK_ACCOUNTS_FORCE_MODIFY' as ModifyAuthorityEnum,
    BankAccountsModify: 'BANK_ACCOUNTS_MODIFY' as ModifyAuthorityEnum,
    BankAccountsModifyAll: 'BANK_ACCOUNTS_MODIFY_ALL' as ModifyAuthorityEnum,
    BankAccountsView: 'BANK_ACCOUNTS_VIEW' as ModifyAuthorityEnum,
    BankAccountsViewAll: 'BANK_ACCOUNTS_VIEW_ALL' as ModifyAuthorityEnum,
    BankStatsView: 'BANK_STATS_VIEW' as ModifyAuthorityEnum,
    BksDataModify: 'BKS_DATA_MODIFY' as ModifyAuthorityEnum,
    BksDataView: 'BKS_DATA_VIEW' as ModifyAuthorityEnum,
    BksLoaderSettingsCreate: 'BKS_LOADER_SETTINGS_CREATE' as ModifyAuthorityEnum,
    BksLoaderSettingsDelete: 'BKS_LOADER_SETTINGS_DELETE' as ModifyAuthorityEnum,
    BksLoaderSettingsModify: 'BKS_LOADER_SETTINGS_MODIFY' as ModifyAuthorityEnum,
    BksLoaderSettingsView: 'BKS_LOADER_SETTINGS_VIEW' as ModifyAuthorityEnum,
    BloombergRequestsView: 'BLOOMBERG_REQUESTS_VIEW' as ModifyAuthorityEnum,
    BrokerCreate: 'BROKER_CREATE' as ModifyAuthorityEnum,
    BrokerDelete: 'BROKER_DELETE' as ModifyAuthorityEnum,
    BrokerModify: 'BROKER_MODIFY' as ModifyAuthorityEnum,
    BrokerView: 'BROKER_VIEW' as ModifyAuthorityEnum,
    BusinessEventsView: 'BUSINESS_EVENTS_VIEW' as ModifyAuthorityEnum,
    CassReport: 'CASS_REPORT' as ModifyAuthorityEnum,
    CbhBsDataModify: 'CBH_BS_DATA_MODIFY' as ModifyAuthorityEnum,
    CbhBsDataView: 'CBH_BS_DATA_VIEW' as ModifyAuthorityEnum,
    CbhDataModify: 'CBH_DATA_MODIFY' as ModifyAuthorityEnum,
    CbhDataView: 'CBH_DATA_VIEW' as ModifyAuthorityEnum,
    CbhLoaderSettingsCreate: 'CBH_LOADER_SETTINGS_CREATE' as ModifyAuthorityEnum,
    CbhLoaderSettingsDelete: 'CBH_LOADER_SETTINGS_DELETE' as ModifyAuthorityEnum,
    CbhLoaderSettingsModify: 'CBH_LOADER_SETTINGS_MODIFY' as ModifyAuthorityEnum,
    CbhLoaderSettingsView: 'CBH_LOADER_SETTINGS_VIEW' as ModifyAuthorityEnum,
    ChangeRequestSettingsCreate: 'CHANGE_REQUEST_SETTINGS_CREATE' as ModifyAuthorityEnum,
    ChangeRequestSettingsDelete: 'CHANGE_REQUEST_SETTINGS_DELETE' as ModifyAuthorityEnum,
    ChangeRequestSettingsModify: 'CHANGE_REQUEST_SETTINGS_MODIFY' as ModifyAuthorityEnum,
    ChangeRequestSettingsView: 'CHANGE_REQUEST_SETTINGS_VIEW' as ModifyAuthorityEnum,
    ClientsCreate: 'CLIENTS_CREATE' as ModifyAuthorityEnum,
    ClientsDelete: 'CLIENTS_DELETE' as ModifyAuthorityEnum,
    ClientsModify: 'CLIENTS_MODIFY' as ModifyAuthorityEnum,
    ClientsModifyAll: 'CLIENTS_MODIFY_ALL' as ModifyAuthorityEnum,
    ClientsView: 'CLIENTS_VIEW' as ModifyAuthorityEnum,
    ClientsViewAll: 'CLIENTS_VIEW_ALL' as ModifyAuthorityEnum,
    ClientCountReport: 'CLIENT_COUNT_REPORT' as ModifyAuthorityEnum,
    ClientFeesReport: 'CLIENT_FEES_REPORT' as ModifyAuthorityEnum,
    ClientValuationReport: 'CLIENT_VALUATION_REPORT' as ModifyAuthorityEnum,
    CmarReport: 'CMAR_REPORT' as ModifyAuthorityEnum,
    CommonEmailTemplatesCreate: 'COMMON_EMAIL_TEMPLATES_CREATE' as ModifyAuthorityEnum,
    CommonEmailTemplatesDelete: 'COMMON_EMAIL_TEMPLATES_DELETE' as ModifyAuthorityEnum,
    CommonEmailTemplatesModify: 'COMMON_EMAIL_TEMPLATES_MODIFY' as ModifyAuthorityEnum,
    CommonEmailTemplatesView: 'COMMON_EMAIL_TEMPLATES_VIEW' as ModifyAuthorityEnum,
    CompaniesCreate: 'COMPANIES_CREATE' as ModifyAuthorityEnum,
    CompaniesDelete: 'COMPANIES_DELETE' as ModifyAuthorityEnum,
    CompaniesModify: 'COMPANIES_MODIFY' as ModifyAuthorityEnum,
    CompaniesTagsView: 'COMPANIES_TAGS_VIEW' as ModifyAuthorityEnum,
    CompaniesView: 'COMPANIES_VIEW' as ModifyAuthorityEnum,
    CompanyHoldingCreate: 'COMPANY_HOLDING_CREATE' as ModifyAuthorityEnum,
    CompanyHoldingDelete: 'COMPANY_HOLDING_DELETE' as ModifyAuthorityEnum,
    CompanyHoldingModify: 'COMPANY_HOLDING_MODIFY' as ModifyAuthorityEnum,
    CompanyHoldingView: 'COMPANY_HOLDING_VIEW' as ModifyAuthorityEnum,
    CompanyRiskReport: 'COMPANY_RISK_REPORT' as ModifyAuthorityEnum,
    ComplianceAccountCompanyCreate: 'COMPLIANCE_ACCOUNT_COMPANY_CREATE' as ModifyAuthorityEnum,
    ComplianceAccountCompanyDelete: 'COMPLIANCE_ACCOUNT_COMPANY_DELETE' as ModifyAuthorityEnum,
    ComplianceAccountCompanyForceModify:
      'COMPLIANCE_ACCOUNT_COMPANY_FORCE_MODIFY' as ModifyAuthorityEnum,
    ComplianceAccountCompanyModify: 'COMPLIANCE_ACCOUNT_COMPANY_MODIFY' as ModifyAuthorityEnum,
    ComplianceAccountCompanyView: 'COMPLIANCE_ACCOUNT_COMPANY_VIEW' as ModifyAuthorityEnum,
    CounterpartyCreate: 'COUNTERPARTY_CREATE' as ModifyAuthorityEnum,
    CounterpartyDelete: 'COUNTERPARTY_DELETE' as ModifyAuthorityEnum,
    CounterpartyModify: 'COUNTERPARTY_MODIFY' as ModifyAuthorityEnum,
    CounterpartyView: 'COUNTERPARTY_VIEW' as ModifyAuthorityEnum,
    CouponProjectionReport: 'COUPON_PROJECTION_REPORT' as ModifyAuthorityEnum,
    CrsReport: 'CRS_REPORT' as ModifyAuthorityEnum,
    CustodianAssetTypeMappingCreate: 'CUSTODIAN_ASSET_TYPE_MAPPING_CREATE' as ModifyAuthorityEnum,
    CustodianAssetTypeMappingModify: 'CUSTODIAN_ASSET_TYPE_MAPPING_MODIFY' as ModifyAuthorityEnum,
    CustodianAssetTypeMappingView: 'CUSTODIAN_ASSET_TYPE_MAPPING_VIEW' as ModifyAuthorityEnum,
    CustodianCreate: 'CUSTODIAN_CREATE' as ModifyAuthorityEnum,
    CustodianDelete: 'CUSTODIAN_DELETE' as ModifyAuthorityEnum,
    CustodianModify: 'CUSTODIAN_MODIFY' as ModifyAuthorityEnum,
    CustodianView: 'CUSTODIAN_VIEW' as ModifyAuthorityEnum,
    DashboardModify: 'DASHBOARD_MODIFY' as ModifyAuthorityEnum,
    DashboardView: 'DASHBOARD_VIEW' as ModifyAuthorityEnum,
    DbsDataModify: 'DBS_DATA_MODIFY' as ModifyAuthorityEnum,
    DbsDataView: 'DBS_DATA_VIEW' as ModifyAuthorityEnum,
    DeactivatedTransactionView: 'DEACTIVATED_TRANSACTION_VIEW' as ModifyAuthorityEnum,
    DepartmentsCreate: 'DEPARTMENTS_CREATE' as ModifyAuthorityEnum,
    DepartmentsDelete: 'DEPARTMENTS_DELETE' as ModifyAuthorityEnum,
    DepartmentsModify: 'DEPARTMENTS_MODIFY' as ModifyAuthorityEnum,
    DepartmentsView: 'DEPARTMENTS_VIEW' as ModifyAuthorityEnum,
    DolfinTransactionsModify: 'DOLFIN_TRANSACTIONS_MODIFY' as ModifyAuthorityEnum,
    DolfinTransactionsView: 'DOLFIN_TRANSACTIONS_VIEW' as ModifyAuthorityEnum,
    EdrDataModify: 'EDR_DATA_MODIFY' as ModifyAuthorityEnum,
    EdrDataView: 'EDR_DATA_VIEW' as ModifyAuthorityEnum,
    EdrLoaderSettingsCreate: 'EDR_LOADER_SETTINGS_CREATE' as ModifyAuthorityEnum,
    EdrLoaderSettingsDelete: 'EDR_LOADER_SETTINGS_DELETE' as ModifyAuthorityEnum,
    EdrLoaderSettingsModify: 'EDR_LOADER_SETTINGS_MODIFY' as ModifyAuthorityEnum,
    EdrLoaderSettingsView: 'EDR_LOADER_SETTINGS_VIEW' as ModifyAuthorityEnum,
    EmailTemplateCreate: 'EMAIL_TEMPLATE_CREATE' as ModifyAuthorityEnum,
    EmailTemplateDelete: 'EMAIL_TEMPLATE_DELETE' as ModifyAuthorityEnum,
    EmailTemplateModify: 'EMAIL_TEMPLATE_MODIFY' as ModifyAuthorityEnum,
    EmailTemplateView: 'EMAIL_TEMPLATE_VIEW' as ModifyAuthorityEnum,
    ExecgwReportsView: 'EXECGW_REPORTS_VIEW' as ModifyAuthorityEnum,
    FeesCreate: 'FEES_CREATE' as ModifyAuthorityEnum,
    FeesModify: 'FEES_MODIFY' as ModifyAuthorityEnum,
    FeesView: 'FEES_VIEW' as ModifyAuthorityEnum,
    FeesViewAll: 'FEES_VIEW_ALL' as ModifyAuthorityEnum,
    FieldsCreate: 'FIELDS_CREATE' as ModifyAuthorityEnum,
    FieldsDelete: 'FIELDS_DELETE' as ModifyAuthorityEnum,
    FieldsModify: 'FIELDS_MODIFY' as ModifyAuthorityEnum,
    FieldsView: 'FIELDS_VIEW' as ModifyAuthorityEnum,
    FileUserLogView: 'FILE_USER_LOG_VIEW' as ModifyAuthorityEnum,
    GapMonitorView: 'GAP_MONITOR_VIEW' as ModifyAuthorityEnum,
    GlobalAllocationCreate: 'GLOBAL_ALLOCATION_CREATE' as ModifyAuthorityEnum,
    GlobalAllocationModify: 'GLOBAL_ALLOCATION_MODIFY' as ModifyAuthorityEnum,
    GlobalAllocationView: 'GLOBAL_ALLOCATION_VIEW' as ModifyAuthorityEnum,
    GlobalAllocationWidgetView: 'GLOBAL_ALLOCATION_WIDGET_VIEW' as ModifyAuthorityEnum,
    GoogleAuthModify: 'GOOGLE_AUTH_MODIFY' as ModifyAuthorityEnum,
    GoogleAuthView: 'GOOGLE_AUTH_VIEW' as ModifyAuthorityEnum,
    GppDataModify: 'GPP_DATA_MODIFY' as ModifyAuthorityEnum,
    GppDataView: 'GPP_DATA_VIEW' as ModifyAuthorityEnum,
    GppLoaderSettingsCreate: 'GPP_LOADER_SETTINGS_CREATE' as ModifyAuthorityEnum,
    GppLoaderSettingsDelete: 'GPP_LOADER_SETTINGS_DELETE' as ModifyAuthorityEnum,
    GppLoaderSettingsModify: 'GPP_LOADER_SETTINGS_MODIFY' as ModifyAuthorityEnum,
    GppLoaderSettingsView: 'GPP_LOADER_SETTINGS_VIEW' as ModifyAuthorityEnum,
    HealthView: 'HEALTH_VIEW' as ModifyAuthorityEnum,
    HistoryView: 'HISTORY_VIEW' as ModifyAuthorityEnum,
    IbkrDataModify: 'IBKR_DATA_MODIFY' as ModifyAuthorityEnum,
    IbkrDataView: 'IBKR_DATA_VIEW' as ModifyAuthorityEnum,
    IbkrFuturesMappingCreate: 'IBKR_FUTURES_MAPPING_CREATE' as ModifyAuthorityEnum,
    IbkrFuturesMappingDelete: 'IBKR_FUTURES_MAPPING_DELETE' as ModifyAuthorityEnum,
    IbkrFuturesMappingModify: 'IBKR_FUTURES_MAPPING_MODIFY' as ModifyAuthorityEnum,
    IbkrFuturesMappingView: 'IBKR_FUTURES_MAPPING_VIEW' as ModifyAuthorityEnum,
    IbkrLoaderSettingsCreate: 'IBKR_LOADER_SETTINGS_CREATE' as ModifyAuthorityEnum,
    IbkrLoaderSettingsDelete: 'IBKR_LOADER_SETTINGS_DELETE' as ModifyAuthorityEnum,
    IbkrLoaderSettingsModify: 'IBKR_LOADER_SETTINGS_MODIFY' as ModifyAuthorityEnum,
    IbkrLoaderSettingsView: 'IBKR_LOADER_SETTINGS_VIEW' as ModifyAuthorityEnum,
    IncomePaymentTracker: 'INCOME_PAYMENT_TRACKER' as ModifyAuthorityEnum,
    InstrumentsCreate: 'INSTRUMENTS_CREATE' as ModifyAuthorityEnum,
    InstrumentsModify: 'INSTRUMENTS_MODIFY' as ModifyAuthorityEnum,
    InstrumentsView: 'INSTRUMENTS_VIEW' as ModifyAuthorityEnum,
    InstrumentToAgentView: 'INSTRUMENT_TO_AGENT_VIEW' as ModifyAuthorityEnum,
    InvestecDataModify: 'INVESTEC_DATA_MODIFY' as ModifyAuthorityEnum,
    InvestecDataView: 'INVESTEC_DATA_VIEW' as ModifyAuthorityEnum,
    InvestecLoaderSettingsCreate: 'INVESTEC_LOADER_SETTINGS_CREATE' as ModifyAuthorityEnum,
    InvestecLoaderSettingsDelete: 'INVESTEC_LOADER_SETTINGS_DELETE' as ModifyAuthorityEnum,
    InvestecLoaderSettingsModify: 'INVESTEC_LOADER_SETTINGS_MODIFY' as ModifyAuthorityEnum,
    InvestecLoaderSettingsView: 'INVESTEC_LOADER_SETTINGS_VIEW' as ModifyAuthorityEnum,
    InvestmentView: 'INVESTMENT_VIEW' as ModifyAuthorityEnum,
    IpAccess: 'IP_ACCESS' as ModifyAuthorityEnum,
    JbDataModify: 'JB_DATA_MODIFY' as ModifyAuthorityEnum,
    JbDataView: 'JB_DATA_VIEW' as ModifyAuthorityEnum,
    JbLoaderSettingsCreate: 'JB_LOADER_SETTINGS_CREATE' as ModifyAuthorityEnum,
    JbLoaderSettingsDelete: 'JB_LOADER_SETTINGS_DELETE' as ModifyAuthorityEnum,
    JbLoaderSettingsModify: 'JB_LOADER_SETTINGS_MODIFY' as ModifyAuthorityEnum,
    JbLoaderSettingsView: 'JB_LOADER_SETTINGS_VIEW' as ModifyAuthorityEnum,
    JpmDataModify: 'JPM_DATA_MODIFY' as ModifyAuthorityEnum,
    JpmDataView: 'JPM_DATA_VIEW' as ModifyAuthorityEnum,
    JpmLoaderSettingsCreate: 'JPM_LOADER_SETTINGS_CREATE' as ModifyAuthorityEnum,
    JpmLoaderSettingsDelete: 'JPM_LOADER_SETTINGS_DELETE' as ModifyAuthorityEnum,
    JpmLoaderSettingsModify: 'JPM_LOADER_SETTINGS_MODIFY' as ModifyAuthorityEnum,
    JpmLoaderSettingsView: 'JPM_LOADER_SETTINGS_VIEW' as ModifyAuthorityEnum,
    LeadCreate: 'LEAD_CREATE' as ModifyAuthorityEnum,
    LeadDelete: 'LEAD_DELETE' as ModifyAuthorityEnum,
    LeadModify: 'LEAD_MODIFY' as ModifyAuthorityEnum,
    LeadModifyAll: 'LEAD_MODIFY_ALL' as ModifyAuthorityEnum,
    LeadView: 'LEAD_VIEW' as ModifyAuthorityEnum,
    LeadViewAll: 'LEAD_VIEW_ALL' as ModifyAuthorityEnum,
    LogotypeDelete: 'LOGOTYPE_DELETE' as ModifyAuthorityEnum,
    LogotypeModify: 'LOGOTYPE_MODIFY' as ModifyAuthorityEnum,
    LogotypeView: 'LOGOTYPE_VIEW' as ModifyAuthorityEnum,
    ManagersCreate: 'MANAGERS_CREATE' as ModifyAuthorityEnum,
    ManagersDelete: 'MANAGERS_DELETE' as ModifyAuthorityEnum,
    ManagersForceModify: 'MANAGERS_FORCE_MODIFY' as ModifyAuthorityEnum,
    ManagersModify: 'MANAGERS_MODIFY' as ModifyAuthorityEnum,
    ManagersView: 'MANAGERS_VIEW' as ModifyAuthorityEnum,
    MandatesCreate: 'MANDATES_CREATE' as ModifyAuthorityEnum,
    MandatesDelete: 'MANDATES_DELETE' as ModifyAuthorityEnum,
    MandatesForceModify: 'MANDATES_FORCE_MODIFY' as ModifyAuthorityEnum,
    MandatesModify: 'MANDATES_MODIFY' as ModifyAuthorityEnum,
    MandatesModifyAll: 'MANDATES_MODIFY_ALL' as ModifyAuthorityEnum,
    MandatesView: 'MANDATES_VIEW' as ModifyAuthorityEnum,
    MandatesViewAll: 'MANDATES_VIEW_ALL' as ModifyAuthorityEnum,
    MandateRestrictionsCreate: 'MANDATE_RESTRICTIONS_CREATE' as ModifyAuthorityEnum,
    MandateRestrictionsDelete: 'MANDATE_RESTRICTIONS_DELETE' as ModifyAuthorityEnum,
    MandateRestrictionsModify: 'MANDATE_RESTRICTIONS_MODIFY' as ModifyAuthorityEnum,
    MandateRestrictionsModifyAll: 'MANDATE_RESTRICTIONS_MODIFY_ALL' as ModifyAuthorityEnum,
    MandateRestrictionsView: 'MANDATE_RESTRICTIONS_VIEW' as ModifyAuthorityEnum,
    MandateRestrictionsViewAll: 'MANDATE_RESTRICTIONS_VIEW_ALL' as ModifyAuthorityEnum,
    MandateRestrictionReportsCreate: 'MANDATE_RESTRICTION_REPORTS_CREATE' as ModifyAuthorityEnum,
    MandateRestrictionReportsDelete: 'MANDATE_RESTRICTION_REPORTS_DELETE' as ModifyAuthorityEnum,
    MandateRestrictionReportsModify: 'MANDATE_RESTRICTION_REPORTS_MODIFY' as ModifyAuthorityEnum,
    MandateRestrictionReportsModifyAll:
      'MANDATE_RESTRICTION_REPORTS_MODIFY_ALL' as ModifyAuthorityEnum,
    MandateRestrictionReportsView: 'MANDATE_RESTRICTION_REPORTS_VIEW' as ModifyAuthorityEnum,
    MandateRestrictionReportsViewAll: 'MANDATE_RESTRICTION_REPORTS_VIEW_ALL' as ModifyAuthorityEnum,
    MandateRestrictionTemplatesCreate:
      'MANDATE_RESTRICTION_TEMPLATES_CREATE' as ModifyAuthorityEnum,
    MandateRestrictionTemplatesDelete:
      'MANDATE_RESTRICTION_TEMPLATES_DELETE' as ModifyAuthorityEnum,
    MandateRestrictionTemplatesModify:
      'MANDATE_RESTRICTION_TEMPLATES_MODIFY' as ModifyAuthorityEnum,
    MandateRestrictionTemplatesModifyAll:
      'MANDATE_RESTRICTION_TEMPLATES_MODIFY_ALL' as ModifyAuthorityEnum,
    MandateRestrictionTemplatesView: 'MANDATE_RESTRICTION_TEMPLATES_VIEW' as ModifyAuthorityEnum,
    MandateRestrictionTemplatesViewAll:
      'MANDATE_RESTRICTION_TEMPLATES_VIEW_ALL' as ModifyAuthorityEnum,
    MandateRiskProfileCreate: 'MANDATE_RISK_PROFILE_CREATE' as ModifyAuthorityEnum,
    MandateRiskProfileDelete: 'MANDATE_RISK_PROFILE_DELETE' as ModifyAuthorityEnum,
    MandateRiskProfileForceModify: 'MANDATE_RISK_PROFILE_FORCE_MODIFY' as ModifyAuthorityEnum,
    MandateRiskProfileModify: 'MANDATE_RISK_PROFILE_MODIFY' as ModifyAuthorityEnum,
    MandateRiskProfileView: 'MANDATE_RISK_PROFILE_VIEW' as ModifyAuthorityEnum,
    MandateRiskProfileViewAll: 'MANDATE_RISK_PROFILE_VIEW_ALL' as ModifyAuthorityEnum,
    ManualImportPositionsCreate: 'MANUAL_IMPORT_POSITIONS_CREATE' as ModifyAuthorityEnum,
    ManualImportPositionsModify: 'MANUAL_IMPORT_POSITIONS_MODIFY' as ModifyAuthorityEnum,
    ManualImportPositionsView: 'MANUAL_IMPORT_POSITIONS_VIEW' as ModifyAuthorityEnum,
    ManualImportTransactionsCreate: 'MANUAL_IMPORT_TRANSACTIONS_CREATE' as ModifyAuthorityEnum,
    ManualImportTransactionsModify: 'MANUAL_IMPORT_TRANSACTIONS_MODIFY' as ModifyAuthorityEnum,
    ManualImportTransactionsView: 'MANUAL_IMPORT_TRANSACTIONS_VIEW' as ModifyAuthorityEnum,
    MarketplaceModify: 'MARKETPLACE_MODIFY' as ModifyAuthorityEnum,
    MarketplaceView: 'MARKETPLACE_VIEW' as ModifyAuthorityEnum,
    MarketSecuritiesModify: 'MARKET_SECURITIES_MODIFY' as ModifyAuthorityEnum,
    MarketSecuritiesView: 'MARKET_SECURITIES_VIEW' as ModifyAuthorityEnum,
    MifirModify: 'MIFIR_MODIFY' as ModifyAuthorityEnum,
    MifirReport: 'MIFIR_REPORT' as ModifyAuthorityEnum,
    MifirView: 'MIFIR_VIEW' as ModifyAuthorityEnum,
    ModelPortfoliosCreate: 'MODEL_PORTFOLIOS_CREATE' as ModifyAuthorityEnum,
    ModelPortfoliosDelete: 'MODEL_PORTFOLIOS_DELETE' as ModifyAuthorityEnum,
    ModelPortfoliosModify: 'MODEL_PORTFOLIOS_MODIFY' as ModifyAuthorityEnum,
    ModelPortfoliosView: 'MODEL_PORTFOLIOS_VIEW' as ModifyAuthorityEnum,
    MonthlyReconciliationReport: 'MONTHLY_RECONCILIATION_REPORT' as ModifyAuthorityEnum,
    NotificationTemplatesCreate: 'NOTIFICATION_TEMPLATES_CREATE' as ModifyAuthorityEnum,
    NotificationTemplatesDelete: 'NOTIFICATION_TEMPLATES_DELETE' as ModifyAuthorityEnum,
    NotificationTemplatesModify: 'NOTIFICATION_TEMPLATES_MODIFY' as ModifyAuthorityEnum,
    NotificationTemplatesView: 'NOTIFICATION_TEMPLATES_VIEW' as ModifyAuthorityEnum,
    NotificationUserLogView: 'NOTIFICATION_USER_LOG_VIEW' as ModifyAuthorityEnum,
    Omnibus: 'OMNIBUS' as ModifyAuthorityEnum,
    OnlinePricingView: 'ONLINE_PRICING_VIEW' as ModifyAuthorityEnum,
    OpportunityCreate: 'OPPORTUNITY_CREATE' as ModifyAuthorityEnum,
    OpportunityDelete: 'OPPORTUNITY_DELETE' as ModifyAuthorityEnum,
    OpportunityForceModify: 'OPPORTUNITY_FORCE_MODIFY' as ModifyAuthorityEnum,
    OpportunityModify: 'OPPORTUNITY_MODIFY' as ModifyAuthorityEnum,
    OpportunityModifyAll: 'OPPORTUNITY_MODIFY_ALL' as ModifyAuthorityEnum,
    OpportunityView: 'OPPORTUNITY_VIEW' as ModifyAuthorityEnum,
    OpportunityViewAll: 'OPPORTUNITY_VIEW_ALL' as ModifyAuthorityEnum,
    OrdersAssign: 'ORDERS_ASSIGN' as ModifyAuthorityEnum,
    OrdersCommonCreate: 'ORDERS_COMMON_CREATE' as ModifyAuthorityEnum,
    OrdersCommonDelete: 'ORDERS_COMMON_DELETE' as ModifyAuthorityEnum,
    OrdersCommonModify: 'ORDERS_COMMON_MODIFY' as ModifyAuthorityEnum,
    OrdersCommonView: 'ORDERS_COMMON_VIEW' as ModifyAuthorityEnum,
    OrdersCorpActionCreate: 'ORDERS_CORP_ACTION_CREATE' as ModifyAuthorityEnum,
    OrdersCorpActionDelete: 'ORDERS_CORP_ACTION_DELETE' as ModifyAuthorityEnum,
    OrdersCorpActionModify: 'ORDERS_CORP_ACTION_MODIFY' as ModifyAuthorityEnum,
    OrdersCorpActionView: 'ORDERS_CORP_ACTION_VIEW' as ModifyAuthorityEnum,
    OrdersCreate: 'ORDERS_CREATE' as ModifyAuthorityEnum,
    OrdersDelete: 'ORDERS_DELETE' as ModifyAuthorityEnum,
    OrdersDmaCreate: 'ORDERS_DMA_CREATE' as ModifyAuthorityEnum,
    OrdersForceModify: 'ORDERS_FORCE_MODIFY' as ModifyAuthorityEnum,
    OrdersModify: 'ORDERS_MODIFY' as ModifyAuthorityEnum,
    OrdersModifyAll: 'ORDERS_MODIFY_ALL' as ModifyAuthorityEnum,
    OrdersNonTradeCreate: 'ORDERS_NON_TRADE_CREATE' as ModifyAuthorityEnum,
    OrdersNonTradeDelete: 'ORDERS_NON_TRADE_DELETE' as ModifyAuthorityEnum,
    OrdersNonTradeModify: 'ORDERS_NON_TRADE_MODIFY' as ModifyAuthorityEnum,
    OrdersNonTradeView: 'ORDERS_NON_TRADE_VIEW' as ModifyAuthorityEnum,
    OrdersTechInfoModify: 'ORDERS_TECH_INFO_MODIFY' as ModifyAuthorityEnum,
    OrdersTechInfoView: 'ORDERS_TECH_INFO_VIEW' as ModifyAuthorityEnum,
    OrdersTradeCreate: 'ORDERS_TRADE_CREATE' as ModifyAuthorityEnum,
    OrdersTradeDelete: 'ORDERS_TRADE_DELETE' as ModifyAuthorityEnum,
    OrdersTradeModify: 'ORDERS_TRADE_MODIFY' as ModifyAuthorityEnum,
    OrdersTradeView: 'ORDERS_TRADE_VIEW' as ModifyAuthorityEnum,
    OrdersView: 'ORDERS_VIEW' as ModifyAuthorityEnum,
    OrdersViewAll: 'ORDERS_VIEW_ALL' as ModifyAuthorityEnum,
    PersonalAm: 'PERSONAL_AM' as ModifyAuthorityEnum,
    PnlReport: 'PNL_REPORT' as ModifyAuthorityEnum,
    PortfoliosCreate: 'PORTFOLIOS_CREATE' as ModifyAuthorityEnum,
    PortfoliosDelete: 'PORTFOLIOS_DELETE' as ModifyAuthorityEnum,
    PortfoliosForceModify: 'PORTFOLIOS_FORCE_MODIFY' as ModifyAuthorityEnum,
    PortfoliosModify: 'PORTFOLIOS_MODIFY' as ModifyAuthorityEnum,
    PortfoliosModifyAll: 'PORTFOLIOS_MODIFY_ALL' as ModifyAuthorityEnum,
    PortfoliosView: 'PORTFOLIOS_VIEW' as ModifyAuthorityEnum,
    PortfoliosViewAll: 'PORTFOLIOS_VIEW_ALL' as ModifyAuthorityEnum,
    PortfolioConfigurationView: 'PORTFOLIO_CONFIGURATION_VIEW' as ModifyAuthorityEnum,
    PortfolioCostsView: 'PORTFOLIO_COSTS_VIEW' as ModifyAuthorityEnum,
    PortfolioFeesView: 'PORTFOLIO_FEES_VIEW' as ModifyAuthorityEnum,
    PortfolioFlowsView: 'PORTFOLIO_FLOWS_VIEW' as ModifyAuthorityEnum,
    PortfolioOrdersAssign: 'PORTFOLIO_ORDERS_ASSIGN' as ModifyAuthorityEnum,
    PortfolioOrdersCreate: 'PORTFOLIO_ORDERS_CREATE' as ModifyAuthorityEnum,
    PortfolioOrdersDelete: 'PORTFOLIO_ORDERS_DELETE' as ModifyAuthorityEnum,
    PortfolioOrdersModify: 'PORTFOLIO_ORDERS_MODIFY' as ModifyAuthorityEnum,
    PortfolioOrdersView: 'PORTFOLIO_ORDERS_VIEW' as ModifyAuthorityEnum,
    PortfolioPnlView: 'PORTFOLIO_PNL_VIEW' as ModifyAuthorityEnum,
    PortfolioPositionsView: 'PORTFOLIO_POSITIONS_VIEW' as ModifyAuthorityEnum,
    PortfolioProfileModify: 'PORTFOLIO_PROFILE_MODIFY' as ModifyAuthorityEnum,
    PortfolioProfileView: 'PORTFOLIO_PROFILE_VIEW' as ModifyAuthorityEnum,
    PortfolioRestrictionsView: 'PORTFOLIO_RESTRICTIONS_VIEW' as ModifyAuthorityEnum,
    PortfolioTransactionsCreate: 'PORTFOLIO_TRANSACTIONS_CREATE' as ModifyAuthorityEnum,
    PortfolioTransactionsDelete: 'PORTFOLIO_TRANSACTIONS_DELETE' as ModifyAuthorityEnum,
    PortfolioTransactionsModify: 'PORTFOLIO_TRANSACTIONS_MODIFY' as ModifyAuthorityEnum,
    PortfolioTransactionsStatus: 'PORTFOLIO_TRANSACTIONS_STATUS' as ModifyAuthorityEnum,
    PortfolioTransactionsView: 'PORTFOLIO_TRANSACTIONS_VIEW' as ModifyAuthorityEnum,
    PortfolioValuationView: 'PORTFOLIO_VALUATION_VIEW' as ModifyAuthorityEnum,
    PositionsView: 'POSITIONS_VIEW' as ModifyAuthorityEnum,
    PricesLoadingMonitorView: 'PRICES_LOADING_MONITOR_VIEW' as ModifyAuthorityEnum,
    PrivateInstrumentCreate: 'PRIVATE_INSTRUMENT_CREATE' as ModifyAuthorityEnum,
    PrivateInstrumentDelete: 'PRIVATE_INSTRUMENT_DELETE' as ModifyAuthorityEnum,
    PrivateInstrumentModify: 'PRIVATE_INSTRUMENT_MODIFY' as ModifyAuthorityEnum,
    PrivateInstrumentModifyAll: 'PRIVATE_INSTRUMENT_MODIFY_ALL' as ModifyAuthorityEnum,
    PrivateInstrumentView: 'PRIVATE_INSTRUMENT_VIEW' as ModifyAuthorityEnum,
    PrivateInstrumentViewAll: 'PRIVATE_INSTRUMENT_VIEW_ALL' as ModifyAuthorityEnum,
    ProcessCreate: 'PROCESS_CREATE' as ModifyAuthorityEnum,
    ProcessDelete: 'PROCESS_DELETE' as ModifyAuthorityEnum,
    ProcessModify: 'PROCESS_MODIFY' as ModifyAuthorityEnum,
    ProcessView: 'PROCESS_VIEW' as ModifyAuthorityEnum,
    ProductGovernanceModify: 'PRODUCT_GOVERNANCE_MODIFY' as ModifyAuthorityEnum,
    ProductGovernanceView: 'PRODUCT_GOVERNANCE_VIEW' as ModifyAuthorityEnum,
    ProfilesCreate: 'PROFILES_CREATE' as ModifyAuthorityEnum,
    ProfilesDelete: 'PROFILES_DELETE' as ModifyAuthorityEnum,
    ProfilesModify: 'PROFILES_MODIFY' as ModifyAuthorityEnum,
    ProfilesView: 'PROFILES_VIEW' as ModifyAuthorityEnum,
    QuarterlyStatisticsReport: 'QUARTERLY_STATISTICS_REPORT' as ModifyAuthorityEnum,
    RbsFReport: 'RBS_F_REPORT' as ModifyAuthorityEnum,
    RecommendationCreate: 'RECOMMENDATION_CREATE' as ModifyAuthorityEnum,
    RecommendationForceModify: 'RECOMMENDATION_FORCE_MODIFY' as ModifyAuthorityEnum,
    RecommendationModify: 'RECOMMENDATION_MODIFY' as ModifyAuthorityEnum,
    RecommendationView: 'RECOMMENDATION_VIEW' as ModifyAuthorityEnum,
    RecommendationViewAll: 'RECOMMENDATION_VIEW_ALL' as ModifyAuthorityEnum,
    ReconciliationReport: 'RECONCILIATION_REPORT' as ModifyAuthorityEnum,
    RegulatorCreate: 'REGULATOR_CREATE' as ModifyAuthorityEnum,
    RegulatorDelete: 'REGULATOR_DELETE' as ModifyAuthorityEnum,
    RegulatorModify: 'REGULATOR_MODIFY' as ModifyAuthorityEnum,
    RegulatorView: 'REGULATOR_VIEW' as ModifyAuthorityEnum,
    RelunaOrdersReportsView: 'RELUNA_ORDERS_REPORTS_VIEW' as ModifyAuthorityEnum,
    ReportsCreate: 'REPORTS_CREATE' as ModifyAuthorityEnum,
    ReportsDelete: 'REPORTS_DELETE' as ModifyAuthorityEnum,
    ReportsModify: 'REPORTS_MODIFY' as ModifyAuthorityEnum,
    ReportsView: 'REPORTS_VIEW' as ModifyAuthorityEnum,
    RestrictionsCreate: 'RESTRICTIONS_CREATE' as ModifyAuthorityEnum,
    RestrictionsDelete: 'RESTRICTIONS_DELETE' as ModifyAuthorityEnum,
    RestrictionsModify: 'RESTRICTIONS_MODIFY' as ModifyAuthorityEnum,
    RestrictionsModifyAll: 'RESTRICTIONS_MODIFY_ALL' as ModifyAuthorityEnum,
    RestrictionsView: 'RESTRICTIONS_VIEW' as ModifyAuthorityEnum,
    RestrictionsViewAll: 'RESTRICTIONS_VIEW_ALL' as ModifyAuthorityEnum,
    RestrictionAlertView: 'RESTRICTION_ALERT_VIEW' as ModifyAuthorityEnum,
    RestrictionReportsCreate: 'RESTRICTION_REPORTS_CREATE' as ModifyAuthorityEnum,
    RestrictionReportsDelete: 'RESTRICTION_REPORTS_DELETE' as ModifyAuthorityEnum,
    RestrictionReportsModify: 'RESTRICTION_REPORTS_MODIFY' as ModifyAuthorityEnum,
    RestrictionReportsModifyAll: 'RESTRICTION_REPORTS_MODIFY_ALL' as ModifyAuthorityEnum,
    RestrictionReportsView: 'RESTRICTION_REPORTS_VIEW' as ModifyAuthorityEnum,
    RestrictionReportsViewAll: 'RESTRICTION_REPORTS_VIEW_ALL' as ModifyAuthorityEnum,
    RestrictionTemplatesCreate: 'RESTRICTION_TEMPLATES_CREATE' as ModifyAuthorityEnum,
    RestrictionTemplatesDelete: 'RESTRICTION_TEMPLATES_DELETE' as ModifyAuthorityEnum,
    RestrictionTemplatesModify: 'RESTRICTION_TEMPLATES_MODIFY' as ModifyAuthorityEnum,
    RestrictionTemplatesModifyAll: 'RESTRICTION_TEMPLATES_MODIFY_ALL' as ModifyAuthorityEnum,
    RestrictionTemplatesView: 'RESTRICTION_TEMPLATES_VIEW' as ModifyAuthorityEnum,
    RestrictionTemplatesViewAll: 'RESTRICTION_TEMPLATES_VIEW_ALL' as ModifyAuthorityEnum,
    RiskReportCreate: 'RISK_REPORT_CREATE' as ModifyAuthorityEnum,
    RiskReportView: 'RISK_REPORT_VIEW' as ModifyAuthorityEnum,
    RolesCreate: 'ROLES_CREATE' as ModifyAuthorityEnum,
    RolesDelete: 'ROLES_DELETE' as ModifyAuthorityEnum,
    RolesModify: 'ROLES_MODIFY' as ModifyAuthorityEnum,
    RolesView: 'ROLES_VIEW' as ModifyAuthorityEnum,
    Rts28Report: 'RTS28_REPORT' as ModifyAuthorityEnum,
    SaltedgeTransactionsModify: 'SALTEDGE_TRANSACTIONS_MODIFY' as ModifyAuthorityEnum,
    SaltedgeTransactionsView: 'SALTEDGE_TRANSACTIONS_VIEW' as ModifyAuthorityEnum,
    SendReports: 'SEND_REPORTS' as ModifyAuthorityEnum,
    SettingsModify: 'SETTINGS_MODIFY' as ModifyAuthorityEnum,
    SettingsView: 'SETTINGS_VIEW' as ModifyAuthorityEnum,
    ShopView: 'SHOP_VIEW' as ModifyAuthorityEnum,
    SiteInfoCreate: 'SITE_INFO_CREATE' as ModifyAuthorityEnum,
    SiteInfoDelete: 'SITE_INFO_DELETE' as ModifyAuthorityEnum,
    SiteInfoModify: 'SITE_INFO_MODIFY' as ModifyAuthorityEnum,
    SiteInfoView: 'SITE_INFO_VIEW' as ModifyAuthorityEnum,
    SovaDataModify: 'SOVA_DATA_MODIFY' as ModifyAuthorityEnum,
    SovaDataView: 'SOVA_DATA_VIEW' as ModifyAuthorityEnum,
    SovaOrdersModify: 'SOVA_ORDERS_MODIFY' as ModifyAuthorityEnum,
    SovaOrdersView: 'SOVA_ORDERS_VIEW' as ModifyAuthorityEnum,
    StatementOfEligibleFundsReport: 'STATEMENT_OF_ELIGIBLE_FUNDS_REPORT' as ModifyAuthorityEnum,
    StatisticsView: 'STATISTICS_VIEW' as ModifyAuthorityEnum,
    StrategiesCreate: 'STRATEGIES_CREATE' as ModifyAuthorityEnum,
    StrategiesDelete: 'STRATEGIES_DELETE' as ModifyAuthorityEnum,
    StrategiesForceModify: 'STRATEGIES_FORCE_MODIFY' as ModifyAuthorityEnum,
    StrategiesModify: 'STRATEGIES_MODIFY' as ModifyAuthorityEnum,
    StrategiesModifyAll: 'STRATEGIES_MODIFY_ALL' as ModifyAuthorityEnum,
    StrategiesView: 'STRATEGIES_VIEW' as ModifyAuthorityEnum,
    StrategiesViewAll: 'STRATEGIES_VIEW_ALL' as ModifyAuthorityEnum,
    StructuredProductsCreate: 'STRUCTURED_PRODUCTS_CREATE' as ModifyAuthorityEnum,
    StructuredProductsDelete: 'STRUCTURED_PRODUCTS_DELETE' as ModifyAuthorityEnum,
    StructuredProductsModify: 'STRUCTURED_PRODUCTS_MODIFY' as ModifyAuthorityEnum,
    StructuredProductsModifyAll: 'STRUCTURED_PRODUCTS_MODIFY_ALL' as ModifyAuthorityEnum,
    StructuredProductsView: 'STRUCTURED_PRODUCTS_VIEW' as ModifyAuthorityEnum,
    StructuredProductsViewAll: 'STRUCTURED_PRODUCTS_VIEW_ALL' as ModifyAuthorityEnum,
    SubportfoliosView: 'SUBPORTFOLIOS_VIEW' as ModifyAuthorityEnum,
    SubscriptionModify: 'SUBSCRIPTION_MODIFY' as ModifyAuthorityEnum,
    SubscriptionView: 'SUBSCRIPTION_VIEW' as ModifyAuthorityEnum,
    TableConstructorModify: 'TABLE_CONSTRUCTOR_MODIFY' as ModifyAuthorityEnum,
    TableConstructorView: 'TABLE_CONSTRUCTOR_VIEW' as ModifyAuthorityEnum,
    TaviraDataModify: 'TAVIRA_DATA_MODIFY' as ModifyAuthorityEnum,
    TaviraDataView: 'TAVIRA_DATA_VIEW' as ModifyAuthorityEnum,
    TransactionsCreate: 'TRANSACTIONS_CREATE' as ModifyAuthorityEnum,
    TransactionsDelete: 'TRANSACTIONS_DELETE' as ModifyAuthorityEnum,
    TransactionsForceModify: 'TRANSACTIONS_FORCE_MODIFY' as ModifyAuthorityEnum,
    TransactionsMatchingModify: 'TRANSACTIONS_MATCHING_MODIFY' as ModifyAuthorityEnum,
    TransactionsMatchingView: 'TRANSACTIONS_MATCHING_VIEW' as ModifyAuthorityEnum,
    TransactionsModify: 'TRANSACTIONS_MODIFY' as ModifyAuthorityEnum,
    TransactionsModifyAll: 'TRANSACTIONS_MODIFY_ALL' as ModifyAuthorityEnum,
    TransactionsStatus: 'TRANSACTIONS_STATUS' as ModifyAuthorityEnum,
    TransactionsView: 'TRANSACTIONS_VIEW' as ModifyAuthorityEnum,
    TransactionsViewAll: 'TRANSACTIONS_VIEW_ALL' as ModifyAuthorityEnum,
    TransactionLoaderCreate: 'TRANSACTION_LOADER_CREATE' as ModifyAuthorityEnum,
    TransactionLoaderModify: 'TRANSACTION_LOADER_MODIFY' as ModifyAuthorityEnum,
    TransactionLoaderView: 'TRANSACTION_LOADER_VIEW' as ModifyAuthorityEnum,
    TransactionWithoutBankView: 'TRANSACTION_WITHOUT_BANK_VIEW' as ModifyAuthorityEnum,
    TrimpDataModify: 'TRIMP_DATA_MODIFY' as ModifyAuthorityEnum,
    TrimpDataView: 'TRIMP_DATA_VIEW' as ModifyAuthorityEnum,
    Ttca: 'TTCA' as ModifyAuthorityEnum,
    UbsDataModify: 'UBS_DATA_MODIFY' as ModifyAuthorityEnum,
    UbsDataView: 'UBS_DATA_VIEW' as ModifyAuthorityEnum,
    ValueDateRulesModify: 'VALUE_DATE_RULES_MODIFY' as ModifyAuthorityEnum,
    ValueDateRulesView: 'VALUE_DATE_RULES_VIEW' as ModifyAuthorityEnum,
    VpDataModify: 'VP_DATA_MODIFY' as ModifyAuthorityEnum,
    VpDataView: 'VP_DATA_VIEW' as ModifyAuthorityEnum,
    VpLoaderSettingsCreate: 'VP_LOADER_SETTINGS_CREATE' as ModifyAuthorityEnum,
    VpLoaderSettingsDelete: 'VP_LOADER_SETTINGS_DELETE' as ModifyAuthorityEnum,
    VpLoaderSettingsModify: 'VP_LOADER_SETTINGS_MODIFY' as ModifyAuthorityEnum,
    VpLoaderSettingsView: 'VP_LOADER_SETTINGS_VIEW' as ModifyAuthorityEnum,
    VtbDataModify: 'VTB_DATA_MODIFY' as ModifyAuthorityEnum,
    VtbDataView: 'VTB_DATA_VIEW' as ModifyAuthorityEnum,
    ZohoSettingsCreate: 'ZOHO_SETTINGS_CREATE' as ModifyAuthorityEnum,
    ZohoSettingsDelete: 'ZOHO_SETTINGS_DELETE' as ModifyAuthorityEnum,
    ZohoSettingsModify: 'ZOHO_SETTINGS_MODIFY' as ModifyAuthorityEnum,
    ZohoSettingsView: 'ZOHO_SETTINGS_VIEW' as ModifyAuthorityEnum,
  };
  export type ReadAuthorityEnum =
    | 'ACCOUNTS_CREATE'
    | 'ACCOUNTS_DELETE'
    | 'ACCOUNTS_FORCE_MODIFY'
    | 'ACCOUNTS_MODIFY'
    | 'ACCOUNTS_MODIFY_ALL'
    | 'ACCOUNTS_VIEW'
    | 'ACCOUNTS_VIEW_ALL'
    | 'ACCOUNT_STRUCTURE'
    | 'ACTUATOR_VIEW'
    | 'ADVANCED_ANALYTICS_VIEW'
    | 'ALFA_DATA_MODIFY'
    | 'ALFA_DATA_VIEW'
    | 'AMAZON_WORK_DOCS'
    | 'BANK_ACCOUNTS_CREATE'
    | 'BANK_ACCOUNTS_DELETE'
    | 'BANK_ACCOUNTS_FORCE_MODIFY'
    | 'BANK_ACCOUNTS_MODIFY'
    | 'BANK_ACCOUNTS_MODIFY_ALL'
    | 'BANK_ACCOUNTS_VIEW'
    | 'BANK_ACCOUNTS_VIEW_ALL'
    | 'BANK_STATS_VIEW'
    | 'BKS_DATA_MODIFY'
    | 'BKS_DATA_VIEW'
    | 'BKS_LOADER_SETTINGS_CREATE'
    | 'BKS_LOADER_SETTINGS_DELETE'
    | 'BKS_LOADER_SETTINGS_MODIFY'
    | 'BKS_LOADER_SETTINGS_VIEW'
    | 'BLOOMBERG_REQUESTS_VIEW'
    | 'BROKER_CREATE'
    | 'BROKER_DELETE'
    | 'BROKER_MODIFY'
    | 'BROKER_VIEW'
    | 'BUSINESS_EVENTS_VIEW'
    | 'CASS_REPORT'
    | 'CBH_BS_DATA_MODIFY'
    | 'CBH_BS_DATA_VIEW'
    | 'CBH_DATA_MODIFY'
    | 'CBH_DATA_VIEW'
    | 'CBH_LOADER_SETTINGS_CREATE'
    | 'CBH_LOADER_SETTINGS_DELETE'
    | 'CBH_LOADER_SETTINGS_MODIFY'
    | 'CBH_LOADER_SETTINGS_VIEW'
    | 'CHANGE_REQUEST_SETTINGS_CREATE'
    | 'CHANGE_REQUEST_SETTINGS_DELETE'
    | 'CHANGE_REQUEST_SETTINGS_MODIFY'
    | 'CHANGE_REQUEST_SETTINGS_VIEW'
    | 'CLIENTS_CREATE'
    | 'CLIENTS_DELETE'
    | 'CLIENTS_MODIFY'
    | 'CLIENTS_MODIFY_ALL'
    | 'CLIENTS_VIEW'
    | 'CLIENTS_VIEW_ALL'
    | 'CLIENT_COUNT_REPORT'
    | 'CLIENT_FEES_REPORT'
    | 'CLIENT_VALUATION_REPORT'
    | 'CMAR_REPORT'
    | 'COMMON_EMAIL_TEMPLATES_CREATE'
    | 'COMMON_EMAIL_TEMPLATES_DELETE'
    | 'COMMON_EMAIL_TEMPLATES_MODIFY'
    | 'COMMON_EMAIL_TEMPLATES_VIEW'
    | 'COMPANIES_CREATE'
    | 'COMPANIES_DELETE'
    | 'COMPANIES_MODIFY'
    | 'COMPANIES_TAGS_VIEW'
    | 'COMPANIES_VIEW'
    | 'COMPANY_HOLDING_CREATE'
    | 'COMPANY_HOLDING_DELETE'
    | 'COMPANY_HOLDING_MODIFY'
    | 'COMPANY_HOLDING_VIEW'
    | 'COMPANY_RISK_REPORT'
    | 'COMPLIANCE_ACCOUNT_COMPANY_CREATE'
    | 'COMPLIANCE_ACCOUNT_COMPANY_DELETE'
    | 'COMPLIANCE_ACCOUNT_COMPANY_FORCE_MODIFY'
    | 'COMPLIANCE_ACCOUNT_COMPANY_MODIFY'
    | 'COMPLIANCE_ACCOUNT_COMPANY_VIEW'
    | 'COUNTERPARTY_CREATE'
    | 'COUNTERPARTY_DELETE'
    | 'COUNTERPARTY_MODIFY'
    | 'COUNTERPARTY_VIEW'
    | 'COUPON_PROJECTION_REPORT'
    | 'CRS_REPORT'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_CREATE'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_MODIFY'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_VIEW'
    | 'CUSTODIAN_CREATE'
    | 'CUSTODIAN_DELETE'
    | 'CUSTODIAN_MODIFY'
    | 'CUSTODIAN_VIEW'
    | 'DASHBOARD_MODIFY'
    | 'DASHBOARD_VIEW'
    | 'DBS_DATA_MODIFY'
    | 'DBS_DATA_VIEW'
    | 'DEACTIVATED_TRANSACTION_VIEW'
    | 'DEPARTMENTS_CREATE'
    | 'DEPARTMENTS_DELETE'
    | 'DEPARTMENTS_MODIFY'
    | 'DEPARTMENTS_VIEW'
    | 'DOLFIN_TRANSACTIONS_MODIFY'
    | 'DOLFIN_TRANSACTIONS_VIEW'
    | 'EDR_DATA_MODIFY'
    | 'EDR_DATA_VIEW'
    | 'EDR_LOADER_SETTINGS_CREATE'
    | 'EDR_LOADER_SETTINGS_DELETE'
    | 'EDR_LOADER_SETTINGS_MODIFY'
    | 'EDR_LOADER_SETTINGS_VIEW'
    | 'EMAIL_TEMPLATE_CREATE'
    | 'EMAIL_TEMPLATE_DELETE'
    | 'EMAIL_TEMPLATE_MODIFY'
    | 'EMAIL_TEMPLATE_VIEW'
    | 'EXECGW_REPORTS_VIEW'
    | 'FEES_CREATE'
    | 'FEES_MODIFY'
    | 'FEES_VIEW'
    | 'FEES_VIEW_ALL'
    | 'FIELDS_CREATE'
    | 'FIELDS_DELETE'
    | 'FIELDS_MODIFY'
    | 'FIELDS_VIEW'
    | 'FILE_USER_LOG_VIEW'
    | 'GAP_MONITOR_VIEW'
    | 'GLOBAL_ALLOCATION_CREATE'
    | 'GLOBAL_ALLOCATION_MODIFY'
    | 'GLOBAL_ALLOCATION_VIEW'
    | 'GLOBAL_ALLOCATION_WIDGET_VIEW'
    | 'GOOGLE_AUTH_MODIFY'
    | 'GOOGLE_AUTH_VIEW'
    | 'GPP_DATA_MODIFY'
    | 'GPP_DATA_VIEW'
    | 'GPP_LOADER_SETTINGS_CREATE'
    | 'GPP_LOADER_SETTINGS_DELETE'
    | 'GPP_LOADER_SETTINGS_MODIFY'
    | 'GPP_LOADER_SETTINGS_VIEW'
    | 'HEALTH_VIEW'
    | 'HISTORY_VIEW'
    | 'IBKR_DATA_MODIFY'
    | 'IBKR_DATA_VIEW'
    | 'IBKR_FUTURES_MAPPING_CREATE'
    | 'IBKR_FUTURES_MAPPING_DELETE'
    | 'IBKR_FUTURES_MAPPING_MODIFY'
    | 'IBKR_FUTURES_MAPPING_VIEW'
    | 'IBKR_LOADER_SETTINGS_CREATE'
    | 'IBKR_LOADER_SETTINGS_DELETE'
    | 'IBKR_LOADER_SETTINGS_MODIFY'
    | 'IBKR_LOADER_SETTINGS_VIEW'
    | 'INCOME_PAYMENT_TRACKER'
    | 'INSTRUMENTS_CREATE'
    | 'INSTRUMENTS_MODIFY'
    | 'INSTRUMENTS_VIEW'
    | 'INSTRUMENT_TO_AGENT_VIEW'
    | 'INVESTEC_DATA_MODIFY'
    | 'INVESTEC_DATA_VIEW'
    | 'INVESTEC_LOADER_SETTINGS_CREATE'
    | 'INVESTEC_LOADER_SETTINGS_DELETE'
    | 'INVESTEC_LOADER_SETTINGS_MODIFY'
    | 'INVESTEC_LOADER_SETTINGS_VIEW'
    | 'INVESTMENT_VIEW'
    | 'IP_ACCESS'
    | 'JB_DATA_MODIFY'
    | 'JB_DATA_VIEW'
    | 'JB_LOADER_SETTINGS_CREATE'
    | 'JB_LOADER_SETTINGS_DELETE'
    | 'JB_LOADER_SETTINGS_MODIFY'
    | 'JB_LOADER_SETTINGS_VIEW'
    | 'JPM_DATA_MODIFY'
    | 'JPM_DATA_VIEW'
    | 'JPM_LOADER_SETTINGS_CREATE'
    | 'JPM_LOADER_SETTINGS_DELETE'
    | 'JPM_LOADER_SETTINGS_MODIFY'
    | 'JPM_LOADER_SETTINGS_VIEW'
    | 'LEAD_CREATE'
    | 'LEAD_DELETE'
    | 'LEAD_MODIFY'
    | 'LEAD_MODIFY_ALL'
    | 'LEAD_VIEW'
    | 'LEAD_VIEW_ALL'
    | 'LOGOTYPE_DELETE'
    | 'LOGOTYPE_MODIFY'
    | 'LOGOTYPE_VIEW'
    | 'MANAGERS_CREATE'
    | 'MANAGERS_DELETE'
    | 'MANAGERS_FORCE_MODIFY'
    | 'MANAGERS_MODIFY'
    | 'MANAGERS_VIEW'
    | 'MANDATES_CREATE'
    | 'MANDATES_DELETE'
    | 'MANDATES_FORCE_MODIFY'
    | 'MANDATES_MODIFY'
    | 'MANDATES_MODIFY_ALL'
    | 'MANDATES_VIEW'
    | 'MANDATES_VIEW_ALL'
    | 'MANDATE_RESTRICTIONS_CREATE'
    | 'MANDATE_RESTRICTIONS_DELETE'
    | 'MANDATE_RESTRICTIONS_MODIFY'
    | 'MANDATE_RESTRICTIONS_MODIFY_ALL'
    | 'MANDATE_RESTRICTIONS_VIEW'
    | 'MANDATE_RESTRICTIONS_VIEW_ALL'
    | 'MANDATE_RESTRICTION_REPORTS_CREATE'
    | 'MANDATE_RESTRICTION_REPORTS_DELETE'
    | 'MANDATE_RESTRICTION_REPORTS_MODIFY'
    | 'MANDATE_RESTRICTION_REPORTS_MODIFY_ALL'
    | 'MANDATE_RESTRICTION_REPORTS_VIEW'
    | 'MANDATE_RESTRICTION_REPORTS_VIEW_ALL'
    | 'MANDATE_RESTRICTION_TEMPLATES_CREATE'
    | 'MANDATE_RESTRICTION_TEMPLATES_DELETE'
    | 'MANDATE_RESTRICTION_TEMPLATES_MODIFY'
    | 'MANDATE_RESTRICTION_TEMPLATES_MODIFY_ALL'
    | 'MANDATE_RESTRICTION_TEMPLATES_VIEW'
    | 'MANDATE_RESTRICTION_TEMPLATES_VIEW_ALL'
    | 'MANDATE_RISK_PROFILE_CREATE'
    | 'MANDATE_RISK_PROFILE_DELETE'
    | 'MANDATE_RISK_PROFILE_FORCE_MODIFY'
    | 'MANDATE_RISK_PROFILE_MODIFY'
    | 'MANDATE_RISK_PROFILE_VIEW'
    | 'MANDATE_RISK_PROFILE_VIEW_ALL'
    | 'MANUAL_IMPORT_POSITIONS_CREATE'
    | 'MANUAL_IMPORT_POSITIONS_MODIFY'
    | 'MANUAL_IMPORT_POSITIONS_VIEW'
    | 'MANUAL_IMPORT_TRANSACTIONS_CREATE'
    | 'MANUAL_IMPORT_TRANSACTIONS_MODIFY'
    | 'MANUAL_IMPORT_TRANSACTIONS_VIEW'
    | 'MARKETPLACE_MODIFY'
    | 'MARKETPLACE_VIEW'
    | 'MARKET_SECURITIES_MODIFY'
    | 'MARKET_SECURITIES_VIEW'
    | 'MIFIR_MODIFY'
    | 'MIFIR_REPORT'
    | 'MIFIR_VIEW'
    | 'MODEL_PORTFOLIOS_CREATE'
    | 'MODEL_PORTFOLIOS_DELETE'
    | 'MODEL_PORTFOLIOS_MODIFY'
    | 'MODEL_PORTFOLIOS_VIEW'
    | 'MONTHLY_RECONCILIATION_REPORT'
    | 'NOTIFICATION_TEMPLATES_CREATE'
    | 'NOTIFICATION_TEMPLATES_DELETE'
    | 'NOTIFICATION_TEMPLATES_MODIFY'
    | 'NOTIFICATION_TEMPLATES_VIEW'
    | 'NOTIFICATION_USER_LOG_VIEW'
    | 'OMNIBUS'
    | 'ONLINE_PRICING_VIEW'
    | 'OPPORTUNITY_CREATE'
    | 'OPPORTUNITY_DELETE'
    | 'OPPORTUNITY_FORCE_MODIFY'
    | 'OPPORTUNITY_MODIFY'
    | 'OPPORTUNITY_MODIFY_ALL'
    | 'OPPORTUNITY_VIEW'
    | 'OPPORTUNITY_VIEW_ALL'
    | 'ORDERS_ASSIGN'
    | 'ORDERS_COMMON_CREATE'
    | 'ORDERS_COMMON_DELETE'
    | 'ORDERS_COMMON_MODIFY'
    | 'ORDERS_COMMON_VIEW'
    | 'ORDERS_CORP_ACTION_CREATE'
    | 'ORDERS_CORP_ACTION_DELETE'
    | 'ORDERS_CORP_ACTION_MODIFY'
    | 'ORDERS_CORP_ACTION_VIEW'
    | 'ORDERS_CREATE'
    | 'ORDERS_DELETE'
    | 'ORDERS_DMA_CREATE'
    | 'ORDERS_FORCE_MODIFY'
    | 'ORDERS_MODIFY'
    | 'ORDERS_MODIFY_ALL'
    | 'ORDERS_NON_TRADE_CREATE'
    | 'ORDERS_NON_TRADE_DELETE'
    | 'ORDERS_NON_TRADE_MODIFY'
    | 'ORDERS_NON_TRADE_VIEW'
    | 'ORDERS_TECH_INFO_MODIFY'
    | 'ORDERS_TECH_INFO_VIEW'
    | 'ORDERS_TRADE_CREATE'
    | 'ORDERS_TRADE_DELETE'
    | 'ORDERS_TRADE_MODIFY'
    | 'ORDERS_TRADE_VIEW'
    | 'ORDERS_VIEW'
    | 'ORDERS_VIEW_ALL'
    | 'PERSONAL_AM'
    | 'PNL_REPORT'
    | 'PORTFOLIOS_CREATE'
    | 'PORTFOLIOS_DELETE'
    | 'PORTFOLIOS_FORCE_MODIFY'
    | 'PORTFOLIOS_MODIFY'
    | 'PORTFOLIOS_MODIFY_ALL'
    | 'PORTFOLIOS_VIEW'
    | 'PORTFOLIOS_VIEW_ALL'
    | 'PORTFOLIO_CONFIGURATION_VIEW'
    | 'PORTFOLIO_COSTS_VIEW'
    | 'PORTFOLIO_FEES_VIEW'
    | 'PORTFOLIO_FLOWS_VIEW'
    | 'PORTFOLIO_ORDERS_ASSIGN'
    | 'PORTFOLIO_ORDERS_CREATE'
    | 'PORTFOLIO_ORDERS_DELETE'
    | 'PORTFOLIO_ORDERS_MODIFY'
    | 'PORTFOLIO_ORDERS_VIEW'
    | 'PORTFOLIO_PNL_VIEW'
    | 'PORTFOLIO_POSITIONS_VIEW'
    | 'PORTFOLIO_PROFILE_MODIFY'
    | 'PORTFOLIO_PROFILE_VIEW'
    | 'PORTFOLIO_RESTRICTIONS_VIEW'
    | 'PORTFOLIO_TRANSACTIONS_CREATE'
    | 'PORTFOLIO_TRANSACTIONS_DELETE'
    | 'PORTFOLIO_TRANSACTIONS_MODIFY'
    | 'PORTFOLIO_TRANSACTIONS_STATUS'
    | 'PORTFOLIO_TRANSACTIONS_VIEW'
    | 'PORTFOLIO_VALUATION_VIEW'
    | 'POSITIONS_VIEW'
    | 'PRICES_LOADING_MONITOR_VIEW'
    | 'PRIVATE_INSTRUMENT_CREATE'
    | 'PRIVATE_INSTRUMENT_DELETE'
    | 'PRIVATE_INSTRUMENT_MODIFY'
    | 'PRIVATE_INSTRUMENT_MODIFY_ALL'
    | 'PRIVATE_INSTRUMENT_VIEW'
    | 'PRIVATE_INSTRUMENT_VIEW_ALL'
    | 'PROCESS_CREATE'
    | 'PROCESS_DELETE'
    | 'PROCESS_MODIFY'
    | 'PROCESS_VIEW'
    | 'PRODUCT_GOVERNANCE_MODIFY'
    | 'PRODUCT_GOVERNANCE_VIEW'
    | 'PROFILES_CREATE'
    | 'PROFILES_DELETE'
    | 'PROFILES_MODIFY'
    | 'PROFILES_VIEW'
    | 'QUARTERLY_STATISTICS_REPORT'
    | 'RBS_F_REPORT'
    | 'RECOMMENDATION_CREATE'
    | 'RECOMMENDATION_FORCE_MODIFY'
    | 'RECOMMENDATION_MODIFY'
    | 'RECOMMENDATION_VIEW'
    | 'RECOMMENDATION_VIEW_ALL'
    | 'RECONCILIATION_REPORT'
    | 'REGULATOR_CREATE'
    | 'REGULATOR_DELETE'
    | 'REGULATOR_MODIFY'
    | 'REGULATOR_VIEW'
    | 'RELUNA_ORDERS_REPORTS_VIEW'
    | 'REPORTS_CREATE'
    | 'REPORTS_DELETE'
    | 'REPORTS_MODIFY'
    | 'REPORTS_VIEW'
    | 'RESTRICTIONS_CREATE'
    | 'RESTRICTIONS_DELETE'
    | 'RESTRICTIONS_MODIFY'
    | 'RESTRICTIONS_MODIFY_ALL'
    | 'RESTRICTIONS_VIEW'
    | 'RESTRICTIONS_VIEW_ALL'
    | 'RESTRICTION_ALERT_VIEW'
    | 'RESTRICTION_REPORTS_CREATE'
    | 'RESTRICTION_REPORTS_DELETE'
    | 'RESTRICTION_REPORTS_MODIFY'
    | 'RESTRICTION_REPORTS_MODIFY_ALL'
    | 'RESTRICTION_REPORTS_VIEW'
    | 'RESTRICTION_REPORTS_VIEW_ALL'
    | 'RESTRICTION_TEMPLATES_CREATE'
    | 'RESTRICTION_TEMPLATES_DELETE'
    | 'RESTRICTION_TEMPLATES_MODIFY'
    | 'RESTRICTION_TEMPLATES_MODIFY_ALL'
    | 'RESTRICTION_TEMPLATES_VIEW'
    | 'RESTRICTION_TEMPLATES_VIEW_ALL'
    | 'RISK_REPORT_CREATE'
    | 'RISK_REPORT_VIEW'
    | 'ROLES_CREATE'
    | 'ROLES_DELETE'
    | 'ROLES_MODIFY'
    | 'ROLES_VIEW'
    | 'RTS28_REPORT'
    | 'SALTEDGE_TRANSACTIONS_MODIFY'
    | 'SALTEDGE_TRANSACTIONS_VIEW'
    | 'SEND_REPORTS'
    | 'SETTINGS_MODIFY'
    | 'SETTINGS_VIEW'
    | 'SHOP_VIEW'
    | 'SITE_INFO_CREATE'
    | 'SITE_INFO_DELETE'
    | 'SITE_INFO_MODIFY'
    | 'SITE_INFO_VIEW'
    | 'SOVA_DATA_MODIFY'
    | 'SOVA_DATA_VIEW'
    | 'SOVA_ORDERS_MODIFY'
    | 'SOVA_ORDERS_VIEW'
    | 'STATEMENT_OF_ELIGIBLE_FUNDS_REPORT'
    | 'STATISTICS_VIEW'
    | 'STRATEGIES_CREATE'
    | 'STRATEGIES_DELETE'
    | 'STRATEGIES_FORCE_MODIFY'
    | 'STRATEGIES_MODIFY'
    | 'STRATEGIES_MODIFY_ALL'
    | 'STRATEGIES_VIEW'
    | 'STRATEGIES_VIEW_ALL'
    | 'STRUCTURED_PRODUCTS_CREATE'
    | 'STRUCTURED_PRODUCTS_DELETE'
    | 'STRUCTURED_PRODUCTS_MODIFY'
    | 'STRUCTURED_PRODUCTS_MODIFY_ALL'
    | 'STRUCTURED_PRODUCTS_VIEW'
    | 'STRUCTURED_PRODUCTS_VIEW_ALL'
    | 'SUBPORTFOLIOS_VIEW'
    | 'SUBSCRIPTION_MODIFY'
    | 'SUBSCRIPTION_VIEW'
    | 'TABLE_CONSTRUCTOR_MODIFY'
    | 'TABLE_CONSTRUCTOR_VIEW'
    | 'TAVIRA_DATA_MODIFY'
    | 'TAVIRA_DATA_VIEW'
    | 'TRANSACTIONS_CREATE'
    | 'TRANSACTIONS_DELETE'
    | 'TRANSACTIONS_FORCE_MODIFY'
    | 'TRANSACTIONS_MATCHING_MODIFY'
    | 'TRANSACTIONS_MATCHING_VIEW'
    | 'TRANSACTIONS_MODIFY'
    | 'TRANSACTIONS_MODIFY_ALL'
    | 'TRANSACTIONS_STATUS'
    | 'TRANSACTIONS_VIEW'
    | 'TRANSACTIONS_VIEW_ALL'
    | 'TRANSACTION_LOADER_CREATE'
    | 'TRANSACTION_LOADER_MODIFY'
    | 'TRANSACTION_LOADER_VIEW'
    | 'TRANSACTION_WITHOUT_BANK_VIEW'
    | 'TRIMP_DATA_MODIFY'
    | 'TRIMP_DATA_VIEW'
    | 'TTCA'
    | 'UBS_DATA_MODIFY'
    | 'UBS_DATA_VIEW'
    | 'VALUE_DATE_RULES_MODIFY'
    | 'VALUE_DATE_RULES_VIEW'
    | 'VP_DATA_MODIFY'
    | 'VP_DATA_VIEW'
    | 'VP_LOADER_SETTINGS_CREATE'
    | 'VP_LOADER_SETTINGS_DELETE'
    | 'VP_LOADER_SETTINGS_MODIFY'
    | 'VP_LOADER_SETTINGS_VIEW'
    | 'VTB_DATA_MODIFY'
    | 'VTB_DATA_VIEW'
    | 'ZOHO_SETTINGS_CREATE'
    | 'ZOHO_SETTINGS_DELETE'
    | 'ZOHO_SETTINGS_MODIFY'
    | 'ZOHO_SETTINGS_VIEW';
  export const ReadAuthorityEnum = {
    AccountsCreate: 'ACCOUNTS_CREATE' as ReadAuthorityEnum,
    AccountsDelete: 'ACCOUNTS_DELETE' as ReadAuthorityEnum,
    AccountsForceModify: 'ACCOUNTS_FORCE_MODIFY' as ReadAuthorityEnum,
    AccountsModify: 'ACCOUNTS_MODIFY' as ReadAuthorityEnum,
    AccountsModifyAll: 'ACCOUNTS_MODIFY_ALL' as ReadAuthorityEnum,
    AccountsView: 'ACCOUNTS_VIEW' as ReadAuthorityEnum,
    AccountsViewAll: 'ACCOUNTS_VIEW_ALL' as ReadAuthorityEnum,
    AccountStructure: 'ACCOUNT_STRUCTURE' as ReadAuthorityEnum,
    ActuatorView: 'ACTUATOR_VIEW' as ReadAuthorityEnum,
    AdvancedAnalyticsView: 'ADVANCED_ANALYTICS_VIEW' as ReadAuthorityEnum,
    AlfaDataModify: 'ALFA_DATA_MODIFY' as ReadAuthorityEnum,
    AlfaDataView: 'ALFA_DATA_VIEW' as ReadAuthorityEnum,
    AmazonWorkDocs: 'AMAZON_WORK_DOCS' as ReadAuthorityEnum,
    BankAccountsCreate: 'BANK_ACCOUNTS_CREATE' as ReadAuthorityEnum,
    BankAccountsDelete: 'BANK_ACCOUNTS_DELETE' as ReadAuthorityEnum,
    BankAccountsForceModify: 'BANK_ACCOUNTS_FORCE_MODIFY' as ReadAuthorityEnum,
    BankAccountsModify: 'BANK_ACCOUNTS_MODIFY' as ReadAuthorityEnum,
    BankAccountsModifyAll: 'BANK_ACCOUNTS_MODIFY_ALL' as ReadAuthorityEnum,
    BankAccountsView: 'BANK_ACCOUNTS_VIEW' as ReadAuthorityEnum,
    BankAccountsViewAll: 'BANK_ACCOUNTS_VIEW_ALL' as ReadAuthorityEnum,
    BankStatsView: 'BANK_STATS_VIEW' as ReadAuthorityEnum,
    BksDataModify: 'BKS_DATA_MODIFY' as ReadAuthorityEnum,
    BksDataView: 'BKS_DATA_VIEW' as ReadAuthorityEnum,
    BksLoaderSettingsCreate: 'BKS_LOADER_SETTINGS_CREATE' as ReadAuthorityEnum,
    BksLoaderSettingsDelete: 'BKS_LOADER_SETTINGS_DELETE' as ReadAuthorityEnum,
    BksLoaderSettingsModify: 'BKS_LOADER_SETTINGS_MODIFY' as ReadAuthorityEnum,
    BksLoaderSettingsView: 'BKS_LOADER_SETTINGS_VIEW' as ReadAuthorityEnum,
    BloombergRequestsView: 'BLOOMBERG_REQUESTS_VIEW' as ReadAuthorityEnum,
    BrokerCreate: 'BROKER_CREATE' as ReadAuthorityEnum,
    BrokerDelete: 'BROKER_DELETE' as ReadAuthorityEnum,
    BrokerModify: 'BROKER_MODIFY' as ReadAuthorityEnum,
    BrokerView: 'BROKER_VIEW' as ReadAuthorityEnum,
    BusinessEventsView: 'BUSINESS_EVENTS_VIEW' as ReadAuthorityEnum,
    CassReport: 'CASS_REPORT' as ReadAuthorityEnum,
    CbhBsDataModify: 'CBH_BS_DATA_MODIFY' as ReadAuthorityEnum,
    CbhBsDataView: 'CBH_BS_DATA_VIEW' as ReadAuthorityEnum,
    CbhDataModify: 'CBH_DATA_MODIFY' as ReadAuthorityEnum,
    CbhDataView: 'CBH_DATA_VIEW' as ReadAuthorityEnum,
    CbhLoaderSettingsCreate: 'CBH_LOADER_SETTINGS_CREATE' as ReadAuthorityEnum,
    CbhLoaderSettingsDelete: 'CBH_LOADER_SETTINGS_DELETE' as ReadAuthorityEnum,
    CbhLoaderSettingsModify: 'CBH_LOADER_SETTINGS_MODIFY' as ReadAuthorityEnum,
    CbhLoaderSettingsView: 'CBH_LOADER_SETTINGS_VIEW' as ReadAuthorityEnum,
    ChangeRequestSettingsCreate: 'CHANGE_REQUEST_SETTINGS_CREATE' as ReadAuthorityEnum,
    ChangeRequestSettingsDelete: 'CHANGE_REQUEST_SETTINGS_DELETE' as ReadAuthorityEnum,
    ChangeRequestSettingsModify: 'CHANGE_REQUEST_SETTINGS_MODIFY' as ReadAuthorityEnum,
    ChangeRequestSettingsView: 'CHANGE_REQUEST_SETTINGS_VIEW' as ReadAuthorityEnum,
    ClientsCreate: 'CLIENTS_CREATE' as ReadAuthorityEnum,
    ClientsDelete: 'CLIENTS_DELETE' as ReadAuthorityEnum,
    ClientsModify: 'CLIENTS_MODIFY' as ReadAuthorityEnum,
    ClientsModifyAll: 'CLIENTS_MODIFY_ALL' as ReadAuthorityEnum,
    ClientsView: 'CLIENTS_VIEW' as ReadAuthorityEnum,
    ClientsViewAll: 'CLIENTS_VIEW_ALL' as ReadAuthorityEnum,
    ClientCountReport: 'CLIENT_COUNT_REPORT' as ReadAuthorityEnum,
    ClientFeesReport: 'CLIENT_FEES_REPORT' as ReadAuthorityEnum,
    ClientValuationReport: 'CLIENT_VALUATION_REPORT' as ReadAuthorityEnum,
    CmarReport: 'CMAR_REPORT' as ReadAuthorityEnum,
    CommonEmailTemplatesCreate: 'COMMON_EMAIL_TEMPLATES_CREATE' as ReadAuthorityEnum,
    CommonEmailTemplatesDelete: 'COMMON_EMAIL_TEMPLATES_DELETE' as ReadAuthorityEnum,
    CommonEmailTemplatesModify: 'COMMON_EMAIL_TEMPLATES_MODIFY' as ReadAuthorityEnum,
    CommonEmailTemplatesView: 'COMMON_EMAIL_TEMPLATES_VIEW' as ReadAuthorityEnum,
    CompaniesCreate: 'COMPANIES_CREATE' as ReadAuthorityEnum,
    CompaniesDelete: 'COMPANIES_DELETE' as ReadAuthorityEnum,
    CompaniesModify: 'COMPANIES_MODIFY' as ReadAuthorityEnum,
    CompaniesTagsView: 'COMPANIES_TAGS_VIEW' as ReadAuthorityEnum,
    CompaniesView: 'COMPANIES_VIEW' as ReadAuthorityEnum,
    CompanyHoldingCreate: 'COMPANY_HOLDING_CREATE' as ReadAuthorityEnum,
    CompanyHoldingDelete: 'COMPANY_HOLDING_DELETE' as ReadAuthorityEnum,
    CompanyHoldingModify: 'COMPANY_HOLDING_MODIFY' as ReadAuthorityEnum,
    CompanyHoldingView: 'COMPANY_HOLDING_VIEW' as ReadAuthorityEnum,
    CompanyRiskReport: 'COMPANY_RISK_REPORT' as ReadAuthorityEnum,
    ComplianceAccountCompanyCreate: 'COMPLIANCE_ACCOUNT_COMPANY_CREATE' as ReadAuthorityEnum,
    ComplianceAccountCompanyDelete: 'COMPLIANCE_ACCOUNT_COMPANY_DELETE' as ReadAuthorityEnum,
    ComplianceAccountCompanyForceModify:
      'COMPLIANCE_ACCOUNT_COMPANY_FORCE_MODIFY' as ReadAuthorityEnum,
    ComplianceAccountCompanyModify: 'COMPLIANCE_ACCOUNT_COMPANY_MODIFY' as ReadAuthorityEnum,
    ComplianceAccountCompanyView: 'COMPLIANCE_ACCOUNT_COMPANY_VIEW' as ReadAuthorityEnum,
    CounterpartyCreate: 'COUNTERPARTY_CREATE' as ReadAuthorityEnum,
    CounterpartyDelete: 'COUNTERPARTY_DELETE' as ReadAuthorityEnum,
    CounterpartyModify: 'COUNTERPARTY_MODIFY' as ReadAuthorityEnum,
    CounterpartyView: 'COUNTERPARTY_VIEW' as ReadAuthorityEnum,
    CouponProjectionReport: 'COUPON_PROJECTION_REPORT' as ReadAuthorityEnum,
    CrsReport: 'CRS_REPORT' as ReadAuthorityEnum,
    CustodianAssetTypeMappingCreate: 'CUSTODIAN_ASSET_TYPE_MAPPING_CREATE' as ReadAuthorityEnum,
    CustodianAssetTypeMappingModify: 'CUSTODIAN_ASSET_TYPE_MAPPING_MODIFY' as ReadAuthorityEnum,
    CustodianAssetTypeMappingView: 'CUSTODIAN_ASSET_TYPE_MAPPING_VIEW' as ReadAuthorityEnum,
    CustodianCreate: 'CUSTODIAN_CREATE' as ReadAuthorityEnum,
    CustodianDelete: 'CUSTODIAN_DELETE' as ReadAuthorityEnum,
    CustodianModify: 'CUSTODIAN_MODIFY' as ReadAuthorityEnum,
    CustodianView: 'CUSTODIAN_VIEW' as ReadAuthorityEnum,
    DashboardModify: 'DASHBOARD_MODIFY' as ReadAuthorityEnum,
    DashboardView: 'DASHBOARD_VIEW' as ReadAuthorityEnum,
    DbsDataModify: 'DBS_DATA_MODIFY' as ReadAuthorityEnum,
    DbsDataView: 'DBS_DATA_VIEW' as ReadAuthorityEnum,
    DeactivatedTransactionView: 'DEACTIVATED_TRANSACTION_VIEW' as ReadAuthorityEnum,
    DepartmentsCreate: 'DEPARTMENTS_CREATE' as ReadAuthorityEnum,
    DepartmentsDelete: 'DEPARTMENTS_DELETE' as ReadAuthorityEnum,
    DepartmentsModify: 'DEPARTMENTS_MODIFY' as ReadAuthorityEnum,
    DepartmentsView: 'DEPARTMENTS_VIEW' as ReadAuthorityEnum,
    DolfinTransactionsModify: 'DOLFIN_TRANSACTIONS_MODIFY' as ReadAuthorityEnum,
    DolfinTransactionsView: 'DOLFIN_TRANSACTIONS_VIEW' as ReadAuthorityEnum,
    EdrDataModify: 'EDR_DATA_MODIFY' as ReadAuthorityEnum,
    EdrDataView: 'EDR_DATA_VIEW' as ReadAuthorityEnum,
    EdrLoaderSettingsCreate: 'EDR_LOADER_SETTINGS_CREATE' as ReadAuthorityEnum,
    EdrLoaderSettingsDelete: 'EDR_LOADER_SETTINGS_DELETE' as ReadAuthorityEnum,
    EdrLoaderSettingsModify: 'EDR_LOADER_SETTINGS_MODIFY' as ReadAuthorityEnum,
    EdrLoaderSettingsView: 'EDR_LOADER_SETTINGS_VIEW' as ReadAuthorityEnum,
    EmailTemplateCreate: 'EMAIL_TEMPLATE_CREATE' as ReadAuthorityEnum,
    EmailTemplateDelete: 'EMAIL_TEMPLATE_DELETE' as ReadAuthorityEnum,
    EmailTemplateModify: 'EMAIL_TEMPLATE_MODIFY' as ReadAuthorityEnum,
    EmailTemplateView: 'EMAIL_TEMPLATE_VIEW' as ReadAuthorityEnum,
    ExecgwReportsView: 'EXECGW_REPORTS_VIEW' as ReadAuthorityEnum,
    FeesCreate: 'FEES_CREATE' as ReadAuthorityEnum,
    FeesModify: 'FEES_MODIFY' as ReadAuthorityEnum,
    FeesView: 'FEES_VIEW' as ReadAuthorityEnum,
    FeesViewAll: 'FEES_VIEW_ALL' as ReadAuthorityEnum,
    FieldsCreate: 'FIELDS_CREATE' as ReadAuthorityEnum,
    FieldsDelete: 'FIELDS_DELETE' as ReadAuthorityEnum,
    FieldsModify: 'FIELDS_MODIFY' as ReadAuthorityEnum,
    FieldsView: 'FIELDS_VIEW' as ReadAuthorityEnum,
    FileUserLogView: 'FILE_USER_LOG_VIEW' as ReadAuthorityEnum,
    GapMonitorView: 'GAP_MONITOR_VIEW' as ReadAuthorityEnum,
    GlobalAllocationCreate: 'GLOBAL_ALLOCATION_CREATE' as ReadAuthorityEnum,
    GlobalAllocationModify: 'GLOBAL_ALLOCATION_MODIFY' as ReadAuthorityEnum,
    GlobalAllocationView: 'GLOBAL_ALLOCATION_VIEW' as ReadAuthorityEnum,
    GlobalAllocationWidgetView: 'GLOBAL_ALLOCATION_WIDGET_VIEW' as ReadAuthorityEnum,
    GoogleAuthModify: 'GOOGLE_AUTH_MODIFY' as ReadAuthorityEnum,
    GoogleAuthView: 'GOOGLE_AUTH_VIEW' as ReadAuthorityEnum,
    GppDataModify: 'GPP_DATA_MODIFY' as ReadAuthorityEnum,
    GppDataView: 'GPP_DATA_VIEW' as ReadAuthorityEnum,
    GppLoaderSettingsCreate: 'GPP_LOADER_SETTINGS_CREATE' as ReadAuthorityEnum,
    GppLoaderSettingsDelete: 'GPP_LOADER_SETTINGS_DELETE' as ReadAuthorityEnum,
    GppLoaderSettingsModify: 'GPP_LOADER_SETTINGS_MODIFY' as ReadAuthorityEnum,
    GppLoaderSettingsView: 'GPP_LOADER_SETTINGS_VIEW' as ReadAuthorityEnum,
    HealthView: 'HEALTH_VIEW' as ReadAuthorityEnum,
    HistoryView: 'HISTORY_VIEW' as ReadAuthorityEnum,
    IbkrDataModify: 'IBKR_DATA_MODIFY' as ReadAuthorityEnum,
    IbkrDataView: 'IBKR_DATA_VIEW' as ReadAuthorityEnum,
    IbkrFuturesMappingCreate: 'IBKR_FUTURES_MAPPING_CREATE' as ReadAuthorityEnum,
    IbkrFuturesMappingDelete: 'IBKR_FUTURES_MAPPING_DELETE' as ReadAuthorityEnum,
    IbkrFuturesMappingModify: 'IBKR_FUTURES_MAPPING_MODIFY' as ReadAuthorityEnum,
    IbkrFuturesMappingView: 'IBKR_FUTURES_MAPPING_VIEW' as ReadAuthorityEnum,
    IbkrLoaderSettingsCreate: 'IBKR_LOADER_SETTINGS_CREATE' as ReadAuthorityEnum,
    IbkrLoaderSettingsDelete: 'IBKR_LOADER_SETTINGS_DELETE' as ReadAuthorityEnum,
    IbkrLoaderSettingsModify: 'IBKR_LOADER_SETTINGS_MODIFY' as ReadAuthorityEnum,
    IbkrLoaderSettingsView: 'IBKR_LOADER_SETTINGS_VIEW' as ReadAuthorityEnum,
    IncomePaymentTracker: 'INCOME_PAYMENT_TRACKER' as ReadAuthorityEnum,
    InstrumentsCreate: 'INSTRUMENTS_CREATE' as ReadAuthorityEnum,
    InstrumentsModify: 'INSTRUMENTS_MODIFY' as ReadAuthorityEnum,
    InstrumentsView: 'INSTRUMENTS_VIEW' as ReadAuthorityEnum,
    InstrumentToAgentView: 'INSTRUMENT_TO_AGENT_VIEW' as ReadAuthorityEnum,
    InvestecDataModify: 'INVESTEC_DATA_MODIFY' as ReadAuthorityEnum,
    InvestecDataView: 'INVESTEC_DATA_VIEW' as ReadAuthorityEnum,
    InvestecLoaderSettingsCreate: 'INVESTEC_LOADER_SETTINGS_CREATE' as ReadAuthorityEnum,
    InvestecLoaderSettingsDelete: 'INVESTEC_LOADER_SETTINGS_DELETE' as ReadAuthorityEnum,
    InvestecLoaderSettingsModify: 'INVESTEC_LOADER_SETTINGS_MODIFY' as ReadAuthorityEnum,
    InvestecLoaderSettingsView: 'INVESTEC_LOADER_SETTINGS_VIEW' as ReadAuthorityEnum,
    InvestmentView: 'INVESTMENT_VIEW' as ReadAuthorityEnum,
    IpAccess: 'IP_ACCESS' as ReadAuthorityEnum,
    JbDataModify: 'JB_DATA_MODIFY' as ReadAuthorityEnum,
    JbDataView: 'JB_DATA_VIEW' as ReadAuthorityEnum,
    JbLoaderSettingsCreate: 'JB_LOADER_SETTINGS_CREATE' as ReadAuthorityEnum,
    JbLoaderSettingsDelete: 'JB_LOADER_SETTINGS_DELETE' as ReadAuthorityEnum,
    JbLoaderSettingsModify: 'JB_LOADER_SETTINGS_MODIFY' as ReadAuthorityEnum,
    JbLoaderSettingsView: 'JB_LOADER_SETTINGS_VIEW' as ReadAuthorityEnum,
    JpmDataModify: 'JPM_DATA_MODIFY' as ReadAuthorityEnum,
    JpmDataView: 'JPM_DATA_VIEW' as ReadAuthorityEnum,
    JpmLoaderSettingsCreate: 'JPM_LOADER_SETTINGS_CREATE' as ReadAuthorityEnum,
    JpmLoaderSettingsDelete: 'JPM_LOADER_SETTINGS_DELETE' as ReadAuthorityEnum,
    JpmLoaderSettingsModify: 'JPM_LOADER_SETTINGS_MODIFY' as ReadAuthorityEnum,
    JpmLoaderSettingsView: 'JPM_LOADER_SETTINGS_VIEW' as ReadAuthorityEnum,
    LeadCreate: 'LEAD_CREATE' as ReadAuthorityEnum,
    LeadDelete: 'LEAD_DELETE' as ReadAuthorityEnum,
    LeadModify: 'LEAD_MODIFY' as ReadAuthorityEnum,
    LeadModifyAll: 'LEAD_MODIFY_ALL' as ReadAuthorityEnum,
    LeadView: 'LEAD_VIEW' as ReadAuthorityEnum,
    LeadViewAll: 'LEAD_VIEW_ALL' as ReadAuthorityEnum,
    LogotypeDelete: 'LOGOTYPE_DELETE' as ReadAuthorityEnum,
    LogotypeModify: 'LOGOTYPE_MODIFY' as ReadAuthorityEnum,
    LogotypeView: 'LOGOTYPE_VIEW' as ReadAuthorityEnum,
    ManagersCreate: 'MANAGERS_CREATE' as ReadAuthorityEnum,
    ManagersDelete: 'MANAGERS_DELETE' as ReadAuthorityEnum,
    ManagersForceModify: 'MANAGERS_FORCE_MODIFY' as ReadAuthorityEnum,
    ManagersModify: 'MANAGERS_MODIFY' as ReadAuthorityEnum,
    ManagersView: 'MANAGERS_VIEW' as ReadAuthorityEnum,
    MandatesCreate: 'MANDATES_CREATE' as ReadAuthorityEnum,
    MandatesDelete: 'MANDATES_DELETE' as ReadAuthorityEnum,
    MandatesForceModify: 'MANDATES_FORCE_MODIFY' as ReadAuthorityEnum,
    MandatesModify: 'MANDATES_MODIFY' as ReadAuthorityEnum,
    MandatesModifyAll: 'MANDATES_MODIFY_ALL' as ReadAuthorityEnum,
    MandatesView: 'MANDATES_VIEW' as ReadAuthorityEnum,
    MandatesViewAll: 'MANDATES_VIEW_ALL' as ReadAuthorityEnum,
    MandateRestrictionsCreate: 'MANDATE_RESTRICTIONS_CREATE' as ReadAuthorityEnum,
    MandateRestrictionsDelete: 'MANDATE_RESTRICTIONS_DELETE' as ReadAuthorityEnum,
    MandateRestrictionsModify: 'MANDATE_RESTRICTIONS_MODIFY' as ReadAuthorityEnum,
    MandateRestrictionsModifyAll: 'MANDATE_RESTRICTIONS_MODIFY_ALL' as ReadAuthorityEnum,
    MandateRestrictionsView: 'MANDATE_RESTRICTIONS_VIEW' as ReadAuthorityEnum,
    MandateRestrictionsViewAll: 'MANDATE_RESTRICTIONS_VIEW_ALL' as ReadAuthorityEnum,
    MandateRestrictionReportsCreate: 'MANDATE_RESTRICTION_REPORTS_CREATE' as ReadAuthorityEnum,
    MandateRestrictionReportsDelete: 'MANDATE_RESTRICTION_REPORTS_DELETE' as ReadAuthorityEnum,
    MandateRestrictionReportsModify: 'MANDATE_RESTRICTION_REPORTS_MODIFY' as ReadAuthorityEnum,
    MandateRestrictionReportsModifyAll:
      'MANDATE_RESTRICTION_REPORTS_MODIFY_ALL' as ReadAuthorityEnum,
    MandateRestrictionReportsView: 'MANDATE_RESTRICTION_REPORTS_VIEW' as ReadAuthorityEnum,
    MandateRestrictionReportsViewAll: 'MANDATE_RESTRICTION_REPORTS_VIEW_ALL' as ReadAuthorityEnum,
    MandateRestrictionTemplatesCreate: 'MANDATE_RESTRICTION_TEMPLATES_CREATE' as ReadAuthorityEnum,
    MandateRestrictionTemplatesDelete: 'MANDATE_RESTRICTION_TEMPLATES_DELETE' as ReadAuthorityEnum,
    MandateRestrictionTemplatesModify: 'MANDATE_RESTRICTION_TEMPLATES_MODIFY' as ReadAuthorityEnum,
    MandateRestrictionTemplatesModifyAll:
      'MANDATE_RESTRICTION_TEMPLATES_MODIFY_ALL' as ReadAuthorityEnum,
    MandateRestrictionTemplatesView: 'MANDATE_RESTRICTION_TEMPLATES_VIEW' as ReadAuthorityEnum,
    MandateRestrictionTemplatesViewAll:
      'MANDATE_RESTRICTION_TEMPLATES_VIEW_ALL' as ReadAuthorityEnum,
    MandateRiskProfileCreate: 'MANDATE_RISK_PROFILE_CREATE' as ReadAuthorityEnum,
    MandateRiskProfileDelete: 'MANDATE_RISK_PROFILE_DELETE' as ReadAuthorityEnum,
    MandateRiskProfileForceModify: 'MANDATE_RISK_PROFILE_FORCE_MODIFY' as ReadAuthorityEnum,
    MandateRiskProfileModify: 'MANDATE_RISK_PROFILE_MODIFY' as ReadAuthorityEnum,
    MandateRiskProfileView: 'MANDATE_RISK_PROFILE_VIEW' as ReadAuthorityEnum,
    MandateRiskProfileViewAll: 'MANDATE_RISK_PROFILE_VIEW_ALL' as ReadAuthorityEnum,
    ManualImportPositionsCreate: 'MANUAL_IMPORT_POSITIONS_CREATE' as ReadAuthorityEnum,
    ManualImportPositionsModify: 'MANUAL_IMPORT_POSITIONS_MODIFY' as ReadAuthorityEnum,
    ManualImportPositionsView: 'MANUAL_IMPORT_POSITIONS_VIEW' as ReadAuthorityEnum,
    ManualImportTransactionsCreate: 'MANUAL_IMPORT_TRANSACTIONS_CREATE' as ReadAuthorityEnum,
    ManualImportTransactionsModify: 'MANUAL_IMPORT_TRANSACTIONS_MODIFY' as ReadAuthorityEnum,
    ManualImportTransactionsView: 'MANUAL_IMPORT_TRANSACTIONS_VIEW' as ReadAuthorityEnum,
    MarketplaceModify: 'MARKETPLACE_MODIFY' as ReadAuthorityEnum,
    MarketplaceView: 'MARKETPLACE_VIEW' as ReadAuthorityEnum,
    MarketSecuritiesModify: 'MARKET_SECURITIES_MODIFY' as ReadAuthorityEnum,
    MarketSecuritiesView: 'MARKET_SECURITIES_VIEW' as ReadAuthorityEnum,
    MifirModify: 'MIFIR_MODIFY' as ReadAuthorityEnum,
    MifirReport: 'MIFIR_REPORT' as ReadAuthorityEnum,
    MifirView: 'MIFIR_VIEW' as ReadAuthorityEnum,
    ModelPortfoliosCreate: 'MODEL_PORTFOLIOS_CREATE' as ReadAuthorityEnum,
    ModelPortfoliosDelete: 'MODEL_PORTFOLIOS_DELETE' as ReadAuthorityEnum,
    ModelPortfoliosModify: 'MODEL_PORTFOLIOS_MODIFY' as ReadAuthorityEnum,
    ModelPortfoliosView: 'MODEL_PORTFOLIOS_VIEW' as ReadAuthorityEnum,
    MonthlyReconciliationReport: 'MONTHLY_RECONCILIATION_REPORT' as ReadAuthorityEnum,
    NotificationTemplatesCreate: 'NOTIFICATION_TEMPLATES_CREATE' as ReadAuthorityEnum,
    NotificationTemplatesDelete: 'NOTIFICATION_TEMPLATES_DELETE' as ReadAuthorityEnum,
    NotificationTemplatesModify: 'NOTIFICATION_TEMPLATES_MODIFY' as ReadAuthorityEnum,
    NotificationTemplatesView: 'NOTIFICATION_TEMPLATES_VIEW' as ReadAuthorityEnum,
    NotificationUserLogView: 'NOTIFICATION_USER_LOG_VIEW' as ReadAuthorityEnum,
    Omnibus: 'OMNIBUS' as ReadAuthorityEnum,
    OnlinePricingView: 'ONLINE_PRICING_VIEW' as ReadAuthorityEnum,
    OpportunityCreate: 'OPPORTUNITY_CREATE' as ReadAuthorityEnum,
    OpportunityDelete: 'OPPORTUNITY_DELETE' as ReadAuthorityEnum,
    OpportunityForceModify: 'OPPORTUNITY_FORCE_MODIFY' as ReadAuthorityEnum,
    OpportunityModify: 'OPPORTUNITY_MODIFY' as ReadAuthorityEnum,
    OpportunityModifyAll: 'OPPORTUNITY_MODIFY_ALL' as ReadAuthorityEnum,
    OpportunityView: 'OPPORTUNITY_VIEW' as ReadAuthorityEnum,
    OpportunityViewAll: 'OPPORTUNITY_VIEW_ALL' as ReadAuthorityEnum,
    OrdersAssign: 'ORDERS_ASSIGN' as ReadAuthorityEnum,
    OrdersCommonCreate: 'ORDERS_COMMON_CREATE' as ReadAuthorityEnum,
    OrdersCommonDelete: 'ORDERS_COMMON_DELETE' as ReadAuthorityEnum,
    OrdersCommonModify: 'ORDERS_COMMON_MODIFY' as ReadAuthorityEnum,
    OrdersCommonView: 'ORDERS_COMMON_VIEW' as ReadAuthorityEnum,
    OrdersCorpActionCreate: 'ORDERS_CORP_ACTION_CREATE' as ReadAuthorityEnum,
    OrdersCorpActionDelete: 'ORDERS_CORP_ACTION_DELETE' as ReadAuthorityEnum,
    OrdersCorpActionModify: 'ORDERS_CORP_ACTION_MODIFY' as ReadAuthorityEnum,
    OrdersCorpActionView: 'ORDERS_CORP_ACTION_VIEW' as ReadAuthorityEnum,
    OrdersCreate: 'ORDERS_CREATE' as ReadAuthorityEnum,
    OrdersDelete: 'ORDERS_DELETE' as ReadAuthorityEnum,
    OrdersDmaCreate: 'ORDERS_DMA_CREATE' as ReadAuthorityEnum,
    OrdersForceModify: 'ORDERS_FORCE_MODIFY' as ReadAuthorityEnum,
    OrdersModify: 'ORDERS_MODIFY' as ReadAuthorityEnum,
    OrdersModifyAll: 'ORDERS_MODIFY_ALL' as ReadAuthorityEnum,
    OrdersNonTradeCreate: 'ORDERS_NON_TRADE_CREATE' as ReadAuthorityEnum,
    OrdersNonTradeDelete: 'ORDERS_NON_TRADE_DELETE' as ReadAuthorityEnum,
    OrdersNonTradeModify: 'ORDERS_NON_TRADE_MODIFY' as ReadAuthorityEnum,
    OrdersNonTradeView: 'ORDERS_NON_TRADE_VIEW' as ReadAuthorityEnum,
    OrdersTechInfoModify: 'ORDERS_TECH_INFO_MODIFY' as ReadAuthorityEnum,
    OrdersTechInfoView: 'ORDERS_TECH_INFO_VIEW' as ReadAuthorityEnum,
    OrdersTradeCreate: 'ORDERS_TRADE_CREATE' as ReadAuthorityEnum,
    OrdersTradeDelete: 'ORDERS_TRADE_DELETE' as ReadAuthorityEnum,
    OrdersTradeModify: 'ORDERS_TRADE_MODIFY' as ReadAuthorityEnum,
    OrdersTradeView: 'ORDERS_TRADE_VIEW' as ReadAuthorityEnum,
    OrdersView: 'ORDERS_VIEW' as ReadAuthorityEnum,
    OrdersViewAll: 'ORDERS_VIEW_ALL' as ReadAuthorityEnum,
    PersonalAm: 'PERSONAL_AM' as ReadAuthorityEnum,
    PnlReport: 'PNL_REPORT' as ReadAuthorityEnum,
    PortfoliosCreate: 'PORTFOLIOS_CREATE' as ReadAuthorityEnum,
    PortfoliosDelete: 'PORTFOLIOS_DELETE' as ReadAuthorityEnum,
    PortfoliosForceModify: 'PORTFOLIOS_FORCE_MODIFY' as ReadAuthorityEnum,
    PortfoliosModify: 'PORTFOLIOS_MODIFY' as ReadAuthorityEnum,
    PortfoliosModifyAll: 'PORTFOLIOS_MODIFY_ALL' as ReadAuthorityEnum,
    PortfoliosView: 'PORTFOLIOS_VIEW' as ReadAuthorityEnum,
    PortfoliosViewAll: 'PORTFOLIOS_VIEW_ALL' as ReadAuthorityEnum,
    PortfolioConfigurationView: 'PORTFOLIO_CONFIGURATION_VIEW' as ReadAuthorityEnum,
    PortfolioCostsView: 'PORTFOLIO_COSTS_VIEW' as ReadAuthorityEnum,
    PortfolioFeesView: 'PORTFOLIO_FEES_VIEW' as ReadAuthorityEnum,
    PortfolioFlowsView: 'PORTFOLIO_FLOWS_VIEW' as ReadAuthorityEnum,
    PortfolioOrdersAssign: 'PORTFOLIO_ORDERS_ASSIGN' as ReadAuthorityEnum,
    PortfolioOrdersCreate: 'PORTFOLIO_ORDERS_CREATE' as ReadAuthorityEnum,
    PortfolioOrdersDelete: 'PORTFOLIO_ORDERS_DELETE' as ReadAuthorityEnum,
    PortfolioOrdersModify: 'PORTFOLIO_ORDERS_MODIFY' as ReadAuthorityEnum,
    PortfolioOrdersView: 'PORTFOLIO_ORDERS_VIEW' as ReadAuthorityEnum,
    PortfolioPnlView: 'PORTFOLIO_PNL_VIEW' as ReadAuthorityEnum,
    PortfolioPositionsView: 'PORTFOLIO_POSITIONS_VIEW' as ReadAuthorityEnum,
    PortfolioProfileModify: 'PORTFOLIO_PROFILE_MODIFY' as ReadAuthorityEnum,
    PortfolioProfileView: 'PORTFOLIO_PROFILE_VIEW' as ReadAuthorityEnum,
    PortfolioRestrictionsView: 'PORTFOLIO_RESTRICTIONS_VIEW' as ReadAuthorityEnum,
    PortfolioTransactionsCreate: 'PORTFOLIO_TRANSACTIONS_CREATE' as ReadAuthorityEnum,
    PortfolioTransactionsDelete: 'PORTFOLIO_TRANSACTIONS_DELETE' as ReadAuthorityEnum,
    PortfolioTransactionsModify: 'PORTFOLIO_TRANSACTIONS_MODIFY' as ReadAuthorityEnum,
    PortfolioTransactionsStatus: 'PORTFOLIO_TRANSACTIONS_STATUS' as ReadAuthorityEnum,
    PortfolioTransactionsView: 'PORTFOLIO_TRANSACTIONS_VIEW' as ReadAuthorityEnum,
    PortfolioValuationView: 'PORTFOLIO_VALUATION_VIEW' as ReadAuthorityEnum,
    PositionsView: 'POSITIONS_VIEW' as ReadAuthorityEnum,
    PricesLoadingMonitorView: 'PRICES_LOADING_MONITOR_VIEW' as ReadAuthorityEnum,
    PrivateInstrumentCreate: 'PRIVATE_INSTRUMENT_CREATE' as ReadAuthorityEnum,
    PrivateInstrumentDelete: 'PRIVATE_INSTRUMENT_DELETE' as ReadAuthorityEnum,
    PrivateInstrumentModify: 'PRIVATE_INSTRUMENT_MODIFY' as ReadAuthorityEnum,
    PrivateInstrumentModifyAll: 'PRIVATE_INSTRUMENT_MODIFY_ALL' as ReadAuthorityEnum,
    PrivateInstrumentView: 'PRIVATE_INSTRUMENT_VIEW' as ReadAuthorityEnum,
    PrivateInstrumentViewAll: 'PRIVATE_INSTRUMENT_VIEW_ALL' as ReadAuthorityEnum,
    ProcessCreate: 'PROCESS_CREATE' as ReadAuthorityEnum,
    ProcessDelete: 'PROCESS_DELETE' as ReadAuthorityEnum,
    ProcessModify: 'PROCESS_MODIFY' as ReadAuthorityEnum,
    ProcessView: 'PROCESS_VIEW' as ReadAuthorityEnum,
    ProductGovernanceModify: 'PRODUCT_GOVERNANCE_MODIFY' as ReadAuthorityEnum,
    ProductGovernanceView: 'PRODUCT_GOVERNANCE_VIEW' as ReadAuthorityEnum,
    ProfilesCreate: 'PROFILES_CREATE' as ReadAuthorityEnum,
    ProfilesDelete: 'PROFILES_DELETE' as ReadAuthorityEnum,
    ProfilesModify: 'PROFILES_MODIFY' as ReadAuthorityEnum,
    ProfilesView: 'PROFILES_VIEW' as ReadAuthorityEnum,
    QuarterlyStatisticsReport: 'QUARTERLY_STATISTICS_REPORT' as ReadAuthorityEnum,
    RbsFReport: 'RBS_F_REPORT' as ReadAuthorityEnum,
    RecommendationCreate: 'RECOMMENDATION_CREATE' as ReadAuthorityEnum,
    RecommendationForceModify: 'RECOMMENDATION_FORCE_MODIFY' as ReadAuthorityEnum,
    RecommendationModify: 'RECOMMENDATION_MODIFY' as ReadAuthorityEnum,
    RecommendationView: 'RECOMMENDATION_VIEW' as ReadAuthorityEnum,
    RecommendationViewAll: 'RECOMMENDATION_VIEW_ALL' as ReadAuthorityEnum,
    ReconciliationReport: 'RECONCILIATION_REPORT' as ReadAuthorityEnum,
    RegulatorCreate: 'REGULATOR_CREATE' as ReadAuthorityEnum,
    RegulatorDelete: 'REGULATOR_DELETE' as ReadAuthorityEnum,
    RegulatorModify: 'REGULATOR_MODIFY' as ReadAuthorityEnum,
    RegulatorView: 'REGULATOR_VIEW' as ReadAuthorityEnum,
    RelunaOrdersReportsView: 'RELUNA_ORDERS_REPORTS_VIEW' as ReadAuthorityEnum,
    ReportsCreate: 'REPORTS_CREATE' as ReadAuthorityEnum,
    ReportsDelete: 'REPORTS_DELETE' as ReadAuthorityEnum,
    ReportsModify: 'REPORTS_MODIFY' as ReadAuthorityEnum,
    ReportsView: 'REPORTS_VIEW' as ReadAuthorityEnum,
    RestrictionsCreate: 'RESTRICTIONS_CREATE' as ReadAuthorityEnum,
    RestrictionsDelete: 'RESTRICTIONS_DELETE' as ReadAuthorityEnum,
    RestrictionsModify: 'RESTRICTIONS_MODIFY' as ReadAuthorityEnum,
    RestrictionsModifyAll: 'RESTRICTIONS_MODIFY_ALL' as ReadAuthorityEnum,
    RestrictionsView: 'RESTRICTIONS_VIEW' as ReadAuthorityEnum,
    RestrictionsViewAll: 'RESTRICTIONS_VIEW_ALL' as ReadAuthorityEnum,
    RestrictionAlertView: 'RESTRICTION_ALERT_VIEW' as ReadAuthorityEnum,
    RestrictionReportsCreate: 'RESTRICTION_REPORTS_CREATE' as ReadAuthorityEnum,
    RestrictionReportsDelete: 'RESTRICTION_REPORTS_DELETE' as ReadAuthorityEnum,
    RestrictionReportsModify: 'RESTRICTION_REPORTS_MODIFY' as ReadAuthorityEnum,
    RestrictionReportsModifyAll: 'RESTRICTION_REPORTS_MODIFY_ALL' as ReadAuthorityEnum,
    RestrictionReportsView: 'RESTRICTION_REPORTS_VIEW' as ReadAuthorityEnum,
    RestrictionReportsViewAll: 'RESTRICTION_REPORTS_VIEW_ALL' as ReadAuthorityEnum,
    RestrictionTemplatesCreate: 'RESTRICTION_TEMPLATES_CREATE' as ReadAuthorityEnum,
    RestrictionTemplatesDelete: 'RESTRICTION_TEMPLATES_DELETE' as ReadAuthorityEnum,
    RestrictionTemplatesModify: 'RESTRICTION_TEMPLATES_MODIFY' as ReadAuthorityEnum,
    RestrictionTemplatesModifyAll: 'RESTRICTION_TEMPLATES_MODIFY_ALL' as ReadAuthorityEnum,
    RestrictionTemplatesView: 'RESTRICTION_TEMPLATES_VIEW' as ReadAuthorityEnum,
    RestrictionTemplatesViewAll: 'RESTRICTION_TEMPLATES_VIEW_ALL' as ReadAuthorityEnum,
    RiskReportCreate: 'RISK_REPORT_CREATE' as ReadAuthorityEnum,
    RiskReportView: 'RISK_REPORT_VIEW' as ReadAuthorityEnum,
    RolesCreate: 'ROLES_CREATE' as ReadAuthorityEnum,
    RolesDelete: 'ROLES_DELETE' as ReadAuthorityEnum,
    RolesModify: 'ROLES_MODIFY' as ReadAuthorityEnum,
    RolesView: 'ROLES_VIEW' as ReadAuthorityEnum,
    Rts28Report: 'RTS28_REPORT' as ReadAuthorityEnum,
    SaltedgeTransactionsModify: 'SALTEDGE_TRANSACTIONS_MODIFY' as ReadAuthorityEnum,
    SaltedgeTransactionsView: 'SALTEDGE_TRANSACTIONS_VIEW' as ReadAuthorityEnum,
    SendReports: 'SEND_REPORTS' as ReadAuthorityEnum,
    SettingsModify: 'SETTINGS_MODIFY' as ReadAuthorityEnum,
    SettingsView: 'SETTINGS_VIEW' as ReadAuthorityEnum,
    ShopView: 'SHOP_VIEW' as ReadAuthorityEnum,
    SiteInfoCreate: 'SITE_INFO_CREATE' as ReadAuthorityEnum,
    SiteInfoDelete: 'SITE_INFO_DELETE' as ReadAuthorityEnum,
    SiteInfoModify: 'SITE_INFO_MODIFY' as ReadAuthorityEnum,
    SiteInfoView: 'SITE_INFO_VIEW' as ReadAuthorityEnum,
    SovaDataModify: 'SOVA_DATA_MODIFY' as ReadAuthorityEnum,
    SovaDataView: 'SOVA_DATA_VIEW' as ReadAuthorityEnum,
    SovaOrdersModify: 'SOVA_ORDERS_MODIFY' as ReadAuthorityEnum,
    SovaOrdersView: 'SOVA_ORDERS_VIEW' as ReadAuthorityEnum,
    StatementOfEligibleFundsReport: 'STATEMENT_OF_ELIGIBLE_FUNDS_REPORT' as ReadAuthorityEnum,
    StatisticsView: 'STATISTICS_VIEW' as ReadAuthorityEnum,
    StrategiesCreate: 'STRATEGIES_CREATE' as ReadAuthorityEnum,
    StrategiesDelete: 'STRATEGIES_DELETE' as ReadAuthorityEnum,
    StrategiesForceModify: 'STRATEGIES_FORCE_MODIFY' as ReadAuthorityEnum,
    StrategiesModify: 'STRATEGIES_MODIFY' as ReadAuthorityEnum,
    StrategiesModifyAll: 'STRATEGIES_MODIFY_ALL' as ReadAuthorityEnum,
    StrategiesView: 'STRATEGIES_VIEW' as ReadAuthorityEnum,
    StrategiesViewAll: 'STRATEGIES_VIEW_ALL' as ReadAuthorityEnum,
    StructuredProductsCreate: 'STRUCTURED_PRODUCTS_CREATE' as ReadAuthorityEnum,
    StructuredProductsDelete: 'STRUCTURED_PRODUCTS_DELETE' as ReadAuthorityEnum,
    StructuredProductsModify: 'STRUCTURED_PRODUCTS_MODIFY' as ReadAuthorityEnum,
    StructuredProductsModifyAll: 'STRUCTURED_PRODUCTS_MODIFY_ALL' as ReadAuthorityEnum,
    StructuredProductsView: 'STRUCTURED_PRODUCTS_VIEW' as ReadAuthorityEnum,
    StructuredProductsViewAll: 'STRUCTURED_PRODUCTS_VIEW_ALL' as ReadAuthorityEnum,
    SubportfoliosView: 'SUBPORTFOLIOS_VIEW' as ReadAuthorityEnum,
    SubscriptionModify: 'SUBSCRIPTION_MODIFY' as ReadAuthorityEnum,
    SubscriptionView: 'SUBSCRIPTION_VIEW' as ReadAuthorityEnum,
    TableConstructorModify: 'TABLE_CONSTRUCTOR_MODIFY' as ReadAuthorityEnum,
    TableConstructorView: 'TABLE_CONSTRUCTOR_VIEW' as ReadAuthorityEnum,
    TaviraDataModify: 'TAVIRA_DATA_MODIFY' as ReadAuthorityEnum,
    TaviraDataView: 'TAVIRA_DATA_VIEW' as ReadAuthorityEnum,
    TransactionsCreate: 'TRANSACTIONS_CREATE' as ReadAuthorityEnum,
    TransactionsDelete: 'TRANSACTIONS_DELETE' as ReadAuthorityEnum,
    TransactionsForceModify: 'TRANSACTIONS_FORCE_MODIFY' as ReadAuthorityEnum,
    TransactionsMatchingModify: 'TRANSACTIONS_MATCHING_MODIFY' as ReadAuthorityEnum,
    TransactionsMatchingView: 'TRANSACTIONS_MATCHING_VIEW' as ReadAuthorityEnum,
    TransactionsModify: 'TRANSACTIONS_MODIFY' as ReadAuthorityEnum,
    TransactionsModifyAll: 'TRANSACTIONS_MODIFY_ALL' as ReadAuthorityEnum,
    TransactionsStatus: 'TRANSACTIONS_STATUS' as ReadAuthorityEnum,
    TransactionsView: 'TRANSACTIONS_VIEW' as ReadAuthorityEnum,
    TransactionsViewAll: 'TRANSACTIONS_VIEW_ALL' as ReadAuthorityEnum,
    TransactionLoaderCreate: 'TRANSACTION_LOADER_CREATE' as ReadAuthorityEnum,
    TransactionLoaderModify: 'TRANSACTION_LOADER_MODIFY' as ReadAuthorityEnum,
    TransactionLoaderView: 'TRANSACTION_LOADER_VIEW' as ReadAuthorityEnum,
    TransactionWithoutBankView: 'TRANSACTION_WITHOUT_BANK_VIEW' as ReadAuthorityEnum,
    TrimpDataModify: 'TRIMP_DATA_MODIFY' as ReadAuthorityEnum,
    TrimpDataView: 'TRIMP_DATA_VIEW' as ReadAuthorityEnum,
    Ttca: 'TTCA' as ReadAuthorityEnum,
    UbsDataModify: 'UBS_DATA_MODIFY' as ReadAuthorityEnum,
    UbsDataView: 'UBS_DATA_VIEW' as ReadAuthorityEnum,
    ValueDateRulesModify: 'VALUE_DATE_RULES_MODIFY' as ReadAuthorityEnum,
    ValueDateRulesView: 'VALUE_DATE_RULES_VIEW' as ReadAuthorityEnum,
    VpDataModify: 'VP_DATA_MODIFY' as ReadAuthorityEnum,
    VpDataView: 'VP_DATA_VIEW' as ReadAuthorityEnum,
    VpLoaderSettingsCreate: 'VP_LOADER_SETTINGS_CREATE' as ReadAuthorityEnum,
    VpLoaderSettingsDelete: 'VP_LOADER_SETTINGS_DELETE' as ReadAuthorityEnum,
    VpLoaderSettingsModify: 'VP_LOADER_SETTINGS_MODIFY' as ReadAuthorityEnum,
    VpLoaderSettingsView: 'VP_LOADER_SETTINGS_VIEW' as ReadAuthorityEnum,
    VtbDataModify: 'VTB_DATA_MODIFY' as ReadAuthorityEnum,
    VtbDataView: 'VTB_DATA_VIEW' as ReadAuthorityEnum,
    ZohoSettingsCreate: 'ZOHO_SETTINGS_CREATE' as ReadAuthorityEnum,
    ZohoSettingsDelete: 'ZOHO_SETTINGS_DELETE' as ReadAuthorityEnum,
    ZohoSettingsModify: 'ZOHO_SETTINGS_MODIFY' as ReadAuthorityEnum,
    ZohoSettingsView: 'ZOHO_SETTINGS_VIEW' as ReadAuthorityEnum,
  };
  export type StatusAuthorityEnum =
    | 'ACCOUNTS_CREATE'
    | 'ACCOUNTS_DELETE'
    | 'ACCOUNTS_FORCE_MODIFY'
    | 'ACCOUNTS_MODIFY'
    | 'ACCOUNTS_MODIFY_ALL'
    | 'ACCOUNTS_VIEW'
    | 'ACCOUNTS_VIEW_ALL'
    | 'ACCOUNT_STRUCTURE'
    | 'ACTUATOR_VIEW'
    | 'ADVANCED_ANALYTICS_VIEW'
    | 'ALFA_DATA_MODIFY'
    | 'ALFA_DATA_VIEW'
    | 'AMAZON_WORK_DOCS'
    | 'BANK_ACCOUNTS_CREATE'
    | 'BANK_ACCOUNTS_DELETE'
    | 'BANK_ACCOUNTS_FORCE_MODIFY'
    | 'BANK_ACCOUNTS_MODIFY'
    | 'BANK_ACCOUNTS_MODIFY_ALL'
    | 'BANK_ACCOUNTS_VIEW'
    | 'BANK_ACCOUNTS_VIEW_ALL'
    | 'BANK_STATS_VIEW'
    | 'BKS_DATA_MODIFY'
    | 'BKS_DATA_VIEW'
    | 'BKS_LOADER_SETTINGS_CREATE'
    | 'BKS_LOADER_SETTINGS_DELETE'
    | 'BKS_LOADER_SETTINGS_MODIFY'
    | 'BKS_LOADER_SETTINGS_VIEW'
    | 'BLOOMBERG_REQUESTS_VIEW'
    | 'BROKER_CREATE'
    | 'BROKER_DELETE'
    | 'BROKER_MODIFY'
    | 'BROKER_VIEW'
    | 'BUSINESS_EVENTS_VIEW'
    | 'CASS_REPORT'
    | 'CBH_BS_DATA_MODIFY'
    | 'CBH_BS_DATA_VIEW'
    | 'CBH_DATA_MODIFY'
    | 'CBH_DATA_VIEW'
    | 'CBH_LOADER_SETTINGS_CREATE'
    | 'CBH_LOADER_SETTINGS_DELETE'
    | 'CBH_LOADER_SETTINGS_MODIFY'
    | 'CBH_LOADER_SETTINGS_VIEW'
    | 'CHANGE_REQUEST_SETTINGS_CREATE'
    | 'CHANGE_REQUEST_SETTINGS_DELETE'
    | 'CHANGE_REQUEST_SETTINGS_MODIFY'
    | 'CHANGE_REQUEST_SETTINGS_VIEW'
    | 'CLIENTS_CREATE'
    | 'CLIENTS_DELETE'
    | 'CLIENTS_MODIFY'
    | 'CLIENTS_MODIFY_ALL'
    | 'CLIENTS_VIEW'
    | 'CLIENTS_VIEW_ALL'
    | 'CLIENT_COUNT_REPORT'
    | 'CLIENT_FEES_REPORT'
    | 'CLIENT_VALUATION_REPORT'
    | 'CMAR_REPORT'
    | 'COMMON_EMAIL_TEMPLATES_CREATE'
    | 'COMMON_EMAIL_TEMPLATES_DELETE'
    | 'COMMON_EMAIL_TEMPLATES_MODIFY'
    | 'COMMON_EMAIL_TEMPLATES_VIEW'
    | 'COMPANIES_CREATE'
    | 'COMPANIES_DELETE'
    | 'COMPANIES_MODIFY'
    | 'COMPANIES_TAGS_VIEW'
    | 'COMPANIES_VIEW'
    | 'COMPANY_HOLDING_CREATE'
    | 'COMPANY_HOLDING_DELETE'
    | 'COMPANY_HOLDING_MODIFY'
    | 'COMPANY_HOLDING_VIEW'
    | 'COMPANY_RISK_REPORT'
    | 'COMPLIANCE_ACCOUNT_COMPANY_CREATE'
    | 'COMPLIANCE_ACCOUNT_COMPANY_DELETE'
    | 'COMPLIANCE_ACCOUNT_COMPANY_FORCE_MODIFY'
    | 'COMPLIANCE_ACCOUNT_COMPANY_MODIFY'
    | 'COMPLIANCE_ACCOUNT_COMPANY_VIEW'
    | 'COUNTERPARTY_CREATE'
    | 'COUNTERPARTY_DELETE'
    | 'COUNTERPARTY_MODIFY'
    | 'COUNTERPARTY_VIEW'
    | 'COUPON_PROJECTION_REPORT'
    | 'CRS_REPORT'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_CREATE'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_MODIFY'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_VIEW'
    | 'CUSTODIAN_CREATE'
    | 'CUSTODIAN_DELETE'
    | 'CUSTODIAN_MODIFY'
    | 'CUSTODIAN_VIEW'
    | 'DASHBOARD_MODIFY'
    | 'DASHBOARD_VIEW'
    | 'DBS_DATA_MODIFY'
    | 'DBS_DATA_VIEW'
    | 'DEACTIVATED_TRANSACTION_VIEW'
    | 'DEPARTMENTS_CREATE'
    | 'DEPARTMENTS_DELETE'
    | 'DEPARTMENTS_MODIFY'
    | 'DEPARTMENTS_VIEW'
    | 'DOLFIN_TRANSACTIONS_MODIFY'
    | 'DOLFIN_TRANSACTIONS_VIEW'
    | 'EDR_DATA_MODIFY'
    | 'EDR_DATA_VIEW'
    | 'EDR_LOADER_SETTINGS_CREATE'
    | 'EDR_LOADER_SETTINGS_DELETE'
    | 'EDR_LOADER_SETTINGS_MODIFY'
    | 'EDR_LOADER_SETTINGS_VIEW'
    | 'EMAIL_TEMPLATE_CREATE'
    | 'EMAIL_TEMPLATE_DELETE'
    | 'EMAIL_TEMPLATE_MODIFY'
    | 'EMAIL_TEMPLATE_VIEW'
    | 'EXECGW_REPORTS_VIEW'
    | 'FEES_CREATE'
    | 'FEES_MODIFY'
    | 'FEES_VIEW'
    | 'FEES_VIEW_ALL'
    | 'FIELDS_CREATE'
    | 'FIELDS_DELETE'
    | 'FIELDS_MODIFY'
    | 'FIELDS_VIEW'
    | 'FILE_USER_LOG_VIEW'
    | 'GAP_MONITOR_VIEW'
    | 'GLOBAL_ALLOCATION_CREATE'
    | 'GLOBAL_ALLOCATION_MODIFY'
    | 'GLOBAL_ALLOCATION_VIEW'
    | 'GLOBAL_ALLOCATION_WIDGET_VIEW'
    | 'GOOGLE_AUTH_MODIFY'
    | 'GOOGLE_AUTH_VIEW'
    | 'GPP_DATA_MODIFY'
    | 'GPP_DATA_VIEW'
    | 'GPP_LOADER_SETTINGS_CREATE'
    | 'GPP_LOADER_SETTINGS_DELETE'
    | 'GPP_LOADER_SETTINGS_MODIFY'
    | 'GPP_LOADER_SETTINGS_VIEW'
    | 'HEALTH_VIEW'
    | 'HISTORY_VIEW'
    | 'IBKR_DATA_MODIFY'
    | 'IBKR_DATA_VIEW'
    | 'IBKR_FUTURES_MAPPING_CREATE'
    | 'IBKR_FUTURES_MAPPING_DELETE'
    | 'IBKR_FUTURES_MAPPING_MODIFY'
    | 'IBKR_FUTURES_MAPPING_VIEW'
    | 'IBKR_LOADER_SETTINGS_CREATE'
    | 'IBKR_LOADER_SETTINGS_DELETE'
    | 'IBKR_LOADER_SETTINGS_MODIFY'
    | 'IBKR_LOADER_SETTINGS_VIEW'
    | 'INCOME_PAYMENT_TRACKER'
    | 'INSTRUMENTS_CREATE'
    | 'INSTRUMENTS_MODIFY'
    | 'INSTRUMENTS_VIEW'
    | 'INSTRUMENT_TO_AGENT_VIEW'
    | 'INVESTEC_DATA_MODIFY'
    | 'INVESTEC_DATA_VIEW'
    | 'INVESTEC_LOADER_SETTINGS_CREATE'
    | 'INVESTEC_LOADER_SETTINGS_DELETE'
    | 'INVESTEC_LOADER_SETTINGS_MODIFY'
    | 'INVESTEC_LOADER_SETTINGS_VIEW'
    | 'INVESTMENT_VIEW'
    | 'IP_ACCESS'
    | 'JB_DATA_MODIFY'
    | 'JB_DATA_VIEW'
    | 'JB_LOADER_SETTINGS_CREATE'
    | 'JB_LOADER_SETTINGS_DELETE'
    | 'JB_LOADER_SETTINGS_MODIFY'
    | 'JB_LOADER_SETTINGS_VIEW'
    | 'JPM_DATA_MODIFY'
    | 'JPM_DATA_VIEW'
    | 'JPM_LOADER_SETTINGS_CREATE'
    | 'JPM_LOADER_SETTINGS_DELETE'
    | 'JPM_LOADER_SETTINGS_MODIFY'
    | 'JPM_LOADER_SETTINGS_VIEW'
    | 'LEAD_CREATE'
    | 'LEAD_DELETE'
    | 'LEAD_MODIFY'
    | 'LEAD_MODIFY_ALL'
    | 'LEAD_VIEW'
    | 'LEAD_VIEW_ALL'
    | 'LOGOTYPE_DELETE'
    | 'LOGOTYPE_MODIFY'
    | 'LOGOTYPE_VIEW'
    | 'MANAGERS_CREATE'
    | 'MANAGERS_DELETE'
    | 'MANAGERS_FORCE_MODIFY'
    | 'MANAGERS_MODIFY'
    | 'MANAGERS_VIEW'
    | 'MANDATES_CREATE'
    | 'MANDATES_DELETE'
    | 'MANDATES_FORCE_MODIFY'
    | 'MANDATES_MODIFY'
    | 'MANDATES_MODIFY_ALL'
    | 'MANDATES_VIEW'
    | 'MANDATES_VIEW_ALL'
    | 'MANDATE_RESTRICTIONS_CREATE'
    | 'MANDATE_RESTRICTIONS_DELETE'
    | 'MANDATE_RESTRICTIONS_MODIFY'
    | 'MANDATE_RESTRICTIONS_MODIFY_ALL'
    | 'MANDATE_RESTRICTIONS_VIEW'
    | 'MANDATE_RESTRICTIONS_VIEW_ALL'
    | 'MANDATE_RESTRICTION_REPORTS_CREATE'
    | 'MANDATE_RESTRICTION_REPORTS_DELETE'
    | 'MANDATE_RESTRICTION_REPORTS_MODIFY'
    | 'MANDATE_RESTRICTION_REPORTS_MODIFY_ALL'
    | 'MANDATE_RESTRICTION_REPORTS_VIEW'
    | 'MANDATE_RESTRICTION_REPORTS_VIEW_ALL'
    | 'MANDATE_RESTRICTION_TEMPLATES_CREATE'
    | 'MANDATE_RESTRICTION_TEMPLATES_DELETE'
    | 'MANDATE_RESTRICTION_TEMPLATES_MODIFY'
    | 'MANDATE_RESTRICTION_TEMPLATES_MODIFY_ALL'
    | 'MANDATE_RESTRICTION_TEMPLATES_VIEW'
    | 'MANDATE_RESTRICTION_TEMPLATES_VIEW_ALL'
    | 'MANDATE_RISK_PROFILE_CREATE'
    | 'MANDATE_RISK_PROFILE_DELETE'
    | 'MANDATE_RISK_PROFILE_FORCE_MODIFY'
    | 'MANDATE_RISK_PROFILE_MODIFY'
    | 'MANDATE_RISK_PROFILE_VIEW'
    | 'MANDATE_RISK_PROFILE_VIEW_ALL'
    | 'MANUAL_IMPORT_POSITIONS_CREATE'
    | 'MANUAL_IMPORT_POSITIONS_MODIFY'
    | 'MANUAL_IMPORT_POSITIONS_VIEW'
    | 'MANUAL_IMPORT_TRANSACTIONS_CREATE'
    | 'MANUAL_IMPORT_TRANSACTIONS_MODIFY'
    | 'MANUAL_IMPORT_TRANSACTIONS_VIEW'
    | 'MARKETPLACE_MODIFY'
    | 'MARKETPLACE_VIEW'
    | 'MARKET_SECURITIES_MODIFY'
    | 'MARKET_SECURITIES_VIEW'
    | 'MIFIR_MODIFY'
    | 'MIFIR_REPORT'
    | 'MIFIR_VIEW'
    | 'MODEL_PORTFOLIOS_CREATE'
    | 'MODEL_PORTFOLIOS_DELETE'
    | 'MODEL_PORTFOLIOS_MODIFY'
    | 'MODEL_PORTFOLIOS_VIEW'
    | 'MONTHLY_RECONCILIATION_REPORT'
    | 'NOTIFICATION_TEMPLATES_CREATE'
    | 'NOTIFICATION_TEMPLATES_DELETE'
    | 'NOTIFICATION_TEMPLATES_MODIFY'
    | 'NOTIFICATION_TEMPLATES_VIEW'
    | 'NOTIFICATION_USER_LOG_VIEW'
    | 'OMNIBUS'
    | 'ONLINE_PRICING_VIEW'
    | 'OPPORTUNITY_CREATE'
    | 'OPPORTUNITY_DELETE'
    | 'OPPORTUNITY_FORCE_MODIFY'
    | 'OPPORTUNITY_MODIFY'
    | 'OPPORTUNITY_MODIFY_ALL'
    | 'OPPORTUNITY_VIEW'
    | 'OPPORTUNITY_VIEW_ALL'
    | 'ORDERS_ASSIGN'
    | 'ORDERS_COMMON_CREATE'
    | 'ORDERS_COMMON_DELETE'
    | 'ORDERS_COMMON_MODIFY'
    | 'ORDERS_COMMON_VIEW'
    | 'ORDERS_CORP_ACTION_CREATE'
    | 'ORDERS_CORP_ACTION_DELETE'
    | 'ORDERS_CORP_ACTION_MODIFY'
    | 'ORDERS_CORP_ACTION_VIEW'
    | 'ORDERS_CREATE'
    | 'ORDERS_DELETE'
    | 'ORDERS_DMA_CREATE'
    | 'ORDERS_FORCE_MODIFY'
    | 'ORDERS_MODIFY'
    | 'ORDERS_MODIFY_ALL'
    | 'ORDERS_NON_TRADE_CREATE'
    | 'ORDERS_NON_TRADE_DELETE'
    | 'ORDERS_NON_TRADE_MODIFY'
    | 'ORDERS_NON_TRADE_VIEW'
    | 'ORDERS_TECH_INFO_MODIFY'
    | 'ORDERS_TECH_INFO_VIEW'
    | 'ORDERS_TRADE_CREATE'
    | 'ORDERS_TRADE_DELETE'
    | 'ORDERS_TRADE_MODIFY'
    | 'ORDERS_TRADE_VIEW'
    | 'ORDERS_VIEW'
    | 'ORDERS_VIEW_ALL'
    | 'PERSONAL_AM'
    | 'PNL_REPORT'
    | 'PORTFOLIOS_CREATE'
    | 'PORTFOLIOS_DELETE'
    | 'PORTFOLIOS_FORCE_MODIFY'
    | 'PORTFOLIOS_MODIFY'
    | 'PORTFOLIOS_MODIFY_ALL'
    | 'PORTFOLIOS_VIEW'
    | 'PORTFOLIOS_VIEW_ALL'
    | 'PORTFOLIO_CONFIGURATION_VIEW'
    | 'PORTFOLIO_COSTS_VIEW'
    | 'PORTFOLIO_FEES_VIEW'
    | 'PORTFOLIO_FLOWS_VIEW'
    | 'PORTFOLIO_ORDERS_ASSIGN'
    | 'PORTFOLIO_ORDERS_CREATE'
    | 'PORTFOLIO_ORDERS_DELETE'
    | 'PORTFOLIO_ORDERS_MODIFY'
    | 'PORTFOLIO_ORDERS_VIEW'
    | 'PORTFOLIO_PNL_VIEW'
    | 'PORTFOLIO_POSITIONS_VIEW'
    | 'PORTFOLIO_PROFILE_MODIFY'
    | 'PORTFOLIO_PROFILE_VIEW'
    | 'PORTFOLIO_RESTRICTIONS_VIEW'
    | 'PORTFOLIO_TRANSACTIONS_CREATE'
    | 'PORTFOLIO_TRANSACTIONS_DELETE'
    | 'PORTFOLIO_TRANSACTIONS_MODIFY'
    | 'PORTFOLIO_TRANSACTIONS_STATUS'
    | 'PORTFOLIO_TRANSACTIONS_VIEW'
    | 'PORTFOLIO_VALUATION_VIEW'
    | 'POSITIONS_VIEW'
    | 'PRICES_LOADING_MONITOR_VIEW'
    | 'PRIVATE_INSTRUMENT_CREATE'
    | 'PRIVATE_INSTRUMENT_DELETE'
    | 'PRIVATE_INSTRUMENT_MODIFY'
    | 'PRIVATE_INSTRUMENT_MODIFY_ALL'
    | 'PRIVATE_INSTRUMENT_VIEW'
    | 'PRIVATE_INSTRUMENT_VIEW_ALL'
    | 'PROCESS_CREATE'
    | 'PROCESS_DELETE'
    | 'PROCESS_MODIFY'
    | 'PROCESS_VIEW'
    | 'PRODUCT_GOVERNANCE_MODIFY'
    | 'PRODUCT_GOVERNANCE_VIEW'
    | 'PROFILES_CREATE'
    | 'PROFILES_DELETE'
    | 'PROFILES_MODIFY'
    | 'PROFILES_VIEW'
    | 'QUARTERLY_STATISTICS_REPORT'
    | 'RBS_F_REPORT'
    | 'RECOMMENDATION_CREATE'
    | 'RECOMMENDATION_FORCE_MODIFY'
    | 'RECOMMENDATION_MODIFY'
    | 'RECOMMENDATION_VIEW'
    | 'RECOMMENDATION_VIEW_ALL'
    | 'RECONCILIATION_REPORT'
    | 'REGULATOR_CREATE'
    | 'REGULATOR_DELETE'
    | 'REGULATOR_MODIFY'
    | 'REGULATOR_VIEW'
    | 'RELUNA_ORDERS_REPORTS_VIEW'
    | 'REPORTS_CREATE'
    | 'REPORTS_DELETE'
    | 'REPORTS_MODIFY'
    | 'REPORTS_VIEW'
    | 'RESTRICTIONS_CREATE'
    | 'RESTRICTIONS_DELETE'
    | 'RESTRICTIONS_MODIFY'
    | 'RESTRICTIONS_MODIFY_ALL'
    | 'RESTRICTIONS_VIEW'
    | 'RESTRICTIONS_VIEW_ALL'
    | 'RESTRICTION_ALERT_VIEW'
    | 'RESTRICTION_REPORTS_CREATE'
    | 'RESTRICTION_REPORTS_DELETE'
    | 'RESTRICTION_REPORTS_MODIFY'
    | 'RESTRICTION_REPORTS_MODIFY_ALL'
    | 'RESTRICTION_REPORTS_VIEW'
    | 'RESTRICTION_REPORTS_VIEW_ALL'
    | 'RESTRICTION_TEMPLATES_CREATE'
    | 'RESTRICTION_TEMPLATES_DELETE'
    | 'RESTRICTION_TEMPLATES_MODIFY'
    | 'RESTRICTION_TEMPLATES_MODIFY_ALL'
    | 'RESTRICTION_TEMPLATES_VIEW'
    | 'RESTRICTION_TEMPLATES_VIEW_ALL'
    | 'RISK_REPORT_CREATE'
    | 'RISK_REPORT_VIEW'
    | 'ROLES_CREATE'
    | 'ROLES_DELETE'
    | 'ROLES_MODIFY'
    | 'ROLES_VIEW'
    | 'RTS28_REPORT'
    | 'SALTEDGE_TRANSACTIONS_MODIFY'
    | 'SALTEDGE_TRANSACTIONS_VIEW'
    | 'SEND_REPORTS'
    | 'SETTINGS_MODIFY'
    | 'SETTINGS_VIEW'
    | 'SHOP_VIEW'
    | 'SITE_INFO_CREATE'
    | 'SITE_INFO_DELETE'
    | 'SITE_INFO_MODIFY'
    | 'SITE_INFO_VIEW'
    | 'SOVA_DATA_MODIFY'
    | 'SOVA_DATA_VIEW'
    | 'SOVA_ORDERS_MODIFY'
    | 'SOVA_ORDERS_VIEW'
    | 'STATEMENT_OF_ELIGIBLE_FUNDS_REPORT'
    | 'STATISTICS_VIEW'
    | 'STRATEGIES_CREATE'
    | 'STRATEGIES_DELETE'
    | 'STRATEGIES_FORCE_MODIFY'
    | 'STRATEGIES_MODIFY'
    | 'STRATEGIES_MODIFY_ALL'
    | 'STRATEGIES_VIEW'
    | 'STRATEGIES_VIEW_ALL'
    | 'STRUCTURED_PRODUCTS_CREATE'
    | 'STRUCTURED_PRODUCTS_DELETE'
    | 'STRUCTURED_PRODUCTS_MODIFY'
    | 'STRUCTURED_PRODUCTS_MODIFY_ALL'
    | 'STRUCTURED_PRODUCTS_VIEW'
    | 'STRUCTURED_PRODUCTS_VIEW_ALL'
    | 'SUBPORTFOLIOS_VIEW'
    | 'SUBSCRIPTION_MODIFY'
    | 'SUBSCRIPTION_VIEW'
    | 'TABLE_CONSTRUCTOR_MODIFY'
    | 'TABLE_CONSTRUCTOR_VIEW'
    | 'TAVIRA_DATA_MODIFY'
    | 'TAVIRA_DATA_VIEW'
    | 'TRANSACTIONS_CREATE'
    | 'TRANSACTIONS_DELETE'
    | 'TRANSACTIONS_FORCE_MODIFY'
    | 'TRANSACTIONS_MATCHING_MODIFY'
    | 'TRANSACTIONS_MATCHING_VIEW'
    | 'TRANSACTIONS_MODIFY'
    | 'TRANSACTIONS_MODIFY_ALL'
    | 'TRANSACTIONS_STATUS'
    | 'TRANSACTIONS_VIEW'
    | 'TRANSACTIONS_VIEW_ALL'
    | 'TRANSACTION_LOADER_CREATE'
    | 'TRANSACTION_LOADER_MODIFY'
    | 'TRANSACTION_LOADER_VIEW'
    | 'TRANSACTION_WITHOUT_BANK_VIEW'
    | 'TRIMP_DATA_MODIFY'
    | 'TRIMP_DATA_VIEW'
    | 'TTCA'
    | 'UBS_DATA_MODIFY'
    | 'UBS_DATA_VIEW'
    | 'VALUE_DATE_RULES_MODIFY'
    | 'VALUE_DATE_RULES_VIEW'
    | 'VP_DATA_MODIFY'
    | 'VP_DATA_VIEW'
    | 'VP_LOADER_SETTINGS_CREATE'
    | 'VP_LOADER_SETTINGS_DELETE'
    | 'VP_LOADER_SETTINGS_MODIFY'
    | 'VP_LOADER_SETTINGS_VIEW'
    | 'VTB_DATA_MODIFY'
    | 'VTB_DATA_VIEW'
    | 'ZOHO_SETTINGS_CREATE'
    | 'ZOHO_SETTINGS_DELETE'
    | 'ZOHO_SETTINGS_MODIFY'
    | 'ZOHO_SETTINGS_VIEW';
  export const StatusAuthorityEnum = {
    AccountsCreate: 'ACCOUNTS_CREATE' as StatusAuthorityEnum,
    AccountsDelete: 'ACCOUNTS_DELETE' as StatusAuthorityEnum,
    AccountsForceModify: 'ACCOUNTS_FORCE_MODIFY' as StatusAuthorityEnum,
    AccountsModify: 'ACCOUNTS_MODIFY' as StatusAuthorityEnum,
    AccountsModifyAll: 'ACCOUNTS_MODIFY_ALL' as StatusAuthorityEnum,
    AccountsView: 'ACCOUNTS_VIEW' as StatusAuthorityEnum,
    AccountsViewAll: 'ACCOUNTS_VIEW_ALL' as StatusAuthorityEnum,
    AccountStructure: 'ACCOUNT_STRUCTURE' as StatusAuthorityEnum,
    ActuatorView: 'ACTUATOR_VIEW' as StatusAuthorityEnum,
    AdvancedAnalyticsView: 'ADVANCED_ANALYTICS_VIEW' as StatusAuthorityEnum,
    AlfaDataModify: 'ALFA_DATA_MODIFY' as StatusAuthorityEnum,
    AlfaDataView: 'ALFA_DATA_VIEW' as StatusAuthorityEnum,
    AmazonWorkDocs: 'AMAZON_WORK_DOCS' as StatusAuthorityEnum,
    BankAccountsCreate: 'BANK_ACCOUNTS_CREATE' as StatusAuthorityEnum,
    BankAccountsDelete: 'BANK_ACCOUNTS_DELETE' as StatusAuthorityEnum,
    BankAccountsForceModify: 'BANK_ACCOUNTS_FORCE_MODIFY' as StatusAuthorityEnum,
    BankAccountsModify: 'BANK_ACCOUNTS_MODIFY' as StatusAuthorityEnum,
    BankAccountsModifyAll: 'BANK_ACCOUNTS_MODIFY_ALL' as StatusAuthorityEnum,
    BankAccountsView: 'BANK_ACCOUNTS_VIEW' as StatusAuthorityEnum,
    BankAccountsViewAll: 'BANK_ACCOUNTS_VIEW_ALL' as StatusAuthorityEnum,
    BankStatsView: 'BANK_STATS_VIEW' as StatusAuthorityEnum,
    BksDataModify: 'BKS_DATA_MODIFY' as StatusAuthorityEnum,
    BksDataView: 'BKS_DATA_VIEW' as StatusAuthorityEnum,
    BksLoaderSettingsCreate: 'BKS_LOADER_SETTINGS_CREATE' as StatusAuthorityEnum,
    BksLoaderSettingsDelete: 'BKS_LOADER_SETTINGS_DELETE' as StatusAuthorityEnum,
    BksLoaderSettingsModify: 'BKS_LOADER_SETTINGS_MODIFY' as StatusAuthorityEnum,
    BksLoaderSettingsView: 'BKS_LOADER_SETTINGS_VIEW' as StatusAuthorityEnum,
    BloombergRequestsView: 'BLOOMBERG_REQUESTS_VIEW' as StatusAuthorityEnum,
    BrokerCreate: 'BROKER_CREATE' as StatusAuthorityEnum,
    BrokerDelete: 'BROKER_DELETE' as StatusAuthorityEnum,
    BrokerModify: 'BROKER_MODIFY' as StatusAuthorityEnum,
    BrokerView: 'BROKER_VIEW' as StatusAuthorityEnum,
    BusinessEventsView: 'BUSINESS_EVENTS_VIEW' as StatusAuthorityEnum,
    CassReport: 'CASS_REPORT' as StatusAuthorityEnum,
    CbhBsDataModify: 'CBH_BS_DATA_MODIFY' as StatusAuthorityEnum,
    CbhBsDataView: 'CBH_BS_DATA_VIEW' as StatusAuthorityEnum,
    CbhDataModify: 'CBH_DATA_MODIFY' as StatusAuthorityEnum,
    CbhDataView: 'CBH_DATA_VIEW' as StatusAuthorityEnum,
    CbhLoaderSettingsCreate: 'CBH_LOADER_SETTINGS_CREATE' as StatusAuthorityEnum,
    CbhLoaderSettingsDelete: 'CBH_LOADER_SETTINGS_DELETE' as StatusAuthorityEnum,
    CbhLoaderSettingsModify: 'CBH_LOADER_SETTINGS_MODIFY' as StatusAuthorityEnum,
    CbhLoaderSettingsView: 'CBH_LOADER_SETTINGS_VIEW' as StatusAuthorityEnum,
    ChangeRequestSettingsCreate: 'CHANGE_REQUEST_SETTINGS_CREATE' as StatusAuthorityEnum,
    ChangeRequestSettingsDelete: 'CHANGE_REQUEST_SETTINGS_DELETE' as StatusAuthorityEnum,
    ChangeRequestSettingsModify: 'CHANGE_REQUEST_SETTINGS_MODIFY' as StatusAuthorityEnum,
    ChangeRequestSettingsView: 'CHANGE_REQUEST_SETTINGS_VIEW' as StatusAuthorityEnum,
    ClientsCreate: 'CLIENTS_CREATE' as StatusAuthorityEnum,
    ClientsDelete: 'CLIENTS_DELETE' as StatusAuthorityEnum,
    ClientsModify: 'CLIENTS_MODIFY' as StatusAuthorityEnum,
    ClientsModifyAll: 'CLIENTS_MODIFY_ALL' as StatusAuthorityEnum,
    ClientsView: 'CLIENTS_VIEW' as StatusAuthorityEnum,
    ClientsViewAll: 'CLIENTS_VIEW_ALL' as StatusAuthorityEnum,
    ClientCountReport: 'CLIENT_COUNT_REPORT' as StatusAuthorityEnum,
    ClientFeesReport: 'CLIENT_FEES_REPORT' as StatusAuthorityEnum,
    ClientValuationReport: 'CLIENT_VALUATION_REPORT' as StatusAuthorityEnum,
    CmarReport: 'CMAR_REPORT' as StatusAuthorityEnum,
    CommonEmailTemplatesCreate: 'COMMON_EMAIL_TEMPLATES_CREATE' as StatusAuthorityEnum,
    CommonEmailTemplatesDelete: 'COMMON_EMAIL_TEMPLATES_DELETE' as StatusAuthorityEnum,
    CommonEmailTemplatesModify: 'COMMON_EMAIL_TEMPLATES_MODIFY' as StatusAuthorityEnum,
    CommonEmailTemplatesView: 'COMMON_EMAIL_TEMPLATES_VIEW' as StatusAuthorityEnum,
    CompaniesCreate: 'COMPANIES_CREATE' as StatusAuthorityEnum,
    CompaniesDelete: 'COMPANIES_DELETE' as StatusAuthorityEnum,
    CompaniesModify: 'COMPANIES_MODIFY' as StatusAuthorityEnum,
    CompaniesTagsView: 'COMPANIES_TAGS_VIEW' as StatusAuthorityEnum,
    CompaniesView: 'COMPANIES_VIEW' as StatusAuthorityEnum,
    CompanyHoldingCreate: 'COMPANY_HOLDING_CREATE' as StatusAuthorityEnum,
    CompanyHoldingDelete: 'COMPANY_HOLDING_DELETE' as StatusAuthorityEnum,
    CompanyHoldingModify: 'COMPANY_HOLDING_MODIFY' as StatusAuthorityEnum,
    CompanyHoldingView: 'COMPANY_HOLDING_VIEW' as StatusAuthorityEnum,
    CompanyRiskReport: 'COMPANY_RISK_REPORT' as StatusAuthorityEnum,
    ComplianceAccountCompanyCreate: 'COMPLIANCE_ACCOUNT_COMPANY_CREATE' as StatusAuthorityEnum,
    ComplianceAccountCompanyDelete: 'COMPLIANCE_ACCOUNT_COMPANY_DELETE' as StatusAuthorityEnum,
    ComplianceAccountCompanyForceModify:
      'COMPLIANCE_ACCOUNT_COMPANY_FORCE_MODIFY' as StatusAuthorityEnum,
    ComplianceAccountCompanyModify: 'COMPLIANCE_ACCOUNT_COMPANY_MODIFY' as StatusAuthorityEnum,
    ComplianceAccountCompanyView: 'COMPLIANCE_ACCOUNT_COMPANY_VIEW' as StatusAuthorityEnum,
    CounterpartyCreate: 'COUNTERPARTY_CREATE' as StatusAuthorityEnum,
    CounterpartyDelete: 'COUNTERPARTY_DELETE' as StatusAuthorityEnum,
    CounterpartyModify: 'COUNTERPARTY_MODIFY' as StatusAuthorityEnum,
    CounterpartyView: 'COUNTERPARTY_VIEW' as StatusAuthorityEnum,
    CouponProjectionReport: 'COUPON_PROJECTION_REPORT' as StatusAuthorityEnum,
    CrsReport: 'CRS_REPORT' as StatusAuthorityEnum,
    CustodianAssetTypeMappingCreate: 'CUSTODIAN_ASSET_TYPE_MAPPING_CREATE' as StatusAuthorityEnum,
    CustodianAssetTypeMappingModify: 'CUSTODIAN_ASSET_TYPE_MAPPING_MODIFY' as StatusAuthorityEnum,
    CustodianAssetTypeMappingView: 'CUSTODIAN_ASSET_TYPE_MAPPING_VIEW' as StatusAuthorityEnum,
    CustodianCreate: 'CUSTODIAN_CREATE' as StatusAuthorityEnum,
    CustodianDelete: 'CUSTODIAN_DELETE' as StatusAuthorityEnum,
    CustodianModify: 'CUSTODIAN_MODIFY' as StatusAuthorityEnum,
    CustodianView: 'CUSTODIAN_VIEW' as StatusAuthorityEnum,
    DashboardModify: 'DASHBOARD_MODIFY' as StatusAuthorityEnum,
    DashboardView: 'DASHBOARD_VIEW' as StatusAuthorityEnum,
    DbsDataModify: 'DBS_DATA_MODIFY' as StatusAuthorityEnum,
    DbsDataView: 'DBS_DATA_VIEW' as StatusAuthorityEnum,
    DeactivatedTransactionView: 'DEACTIVATED_TRANSACTION_VIEW' as StatusAuthorityEnum,
    DepartmentsCreate: 'DEPARTMENTS_CREATE' as StatusAuthorityEnum,
    DepartmentsDelete: 'DEPARTMENTS_DELETE' as StatusAuthorityEnum,
    DepartmentsModify: 'DEPARTMENTS_MODIFY' as StatusAuthorityEnum,
    DepartmentsView: 'DEPARTMENTS_VIEW' as StatusAuthorityEnum,
    DolfinTransactionsModify: 'DOLFIN_TRANSACTIONS_MODIFY' as StatusAuthorityEnum,
    DolfinTransactionsView: 'DOLFIN_TRANSACTIONS_VIEW' as StatusAuthorityEnum,
    EdrDataModify: 'EDR_DATA_MODIFY' as StatusAuthorityEnum,
    EdrDataView: 'EDR_DATA_VIEW' as StatusAuthorityEnum,
    EdrLoaderSettingsCreate: 'EDR_LOADER_SETTINGS_CREATE' as StatusAuthorityEnum,
    EdrLoaderSettingsDelete: 'EDR_LOADER_SETTINGS_DELETE' as StatusAuthorityEnum,
    EdrLoaderSettingsModify: 'EDR_LOADER_SETTINGS_MODIFY' as StatusAuthorityEnum,
    EdrLoaderSettingsView: 'EDR_LOADER_SETTINGS_VIEW' as StatusAuthorityEnum,
    EmailTemplateCreate: 'EMAIL_TEMPLATE_CREATE' as StatusAuthorityEnum,
    EmailTemplateDelete: 'EMAIL_TEMPLATE_DELETE' as StatusAuthorityEnum,
    EmailTemplateModify: 'EMAIL_TEMPLATE_MODIFY' as StatusAuthorityEnum,
    EmailTemplateView: 'EMAIL_TEMPLATE_VIEW' as StatusAuthorityEnum,
    ExecgwReportsView: 'EXECGW_REPORTS_VIEW' as StatusAuthorityEnum,
    FeesCreate: 'FEES_CREATE' as StatusAuthorityEnum,
    FeesModify: 'FEES_MODIFY' as StatusAuthorityEnum,
    FeesView: 'FEES_VIEW' as StatusAuthorityEnum,
    FeesViewAll: 'FEES_VIEW_ALL' as StatusAuthorityEnum,
    FieldsCreate: 'FIELDS_CREATE' as StatusAuthorityEnum,
    FieldsDelete: 'FIELDS_DELETE' as StatusAuthorityEnum,
    FieldsModify: 'FIELDS_MODIFY' as StatusAuthorityEnum,
    FieldsView: 'FIELDS_VIEW' as StatusAuthorityEnum,
    FileUserLogView: 'FILE_USER_LOG_VIEW' as StatusAuthorityEnum,
    GapMonitorView: 'GAP_MONITOR_VIEW' as StatusAuthorityEnum,
    GlobalAllocationCreate: 'GLOBAL_ALLOCATION_CREATE' as StatusAuthorityEnum,
    GlobalAllocationModify: 'GLOBAL_ALLOCATION_MODIFY' as StatusAuthorityEnum,
    GlobalAllocationView: 'GLOBAL_ALLOCATION_VIEW' as StatusAuthorityEnum,
    GlobalAllocationWidgetView: 'GLOBAL_ALLOCATION_WIDGET_VIEW' as StatusAuthorityEnum,
    GoogleAuthModify: 'GOOGLE_AUTH_MODIFY' as StatusAuthorityEnum,
    GoogleAuthView: 'GOOGLE_AUTH_VIEW' as StatusAuthorityEnum,
    GppDataModify: 'GPP_DATA_MODIFY' as StatusAuthorityEnum,
    GppDataView: 'GPP_DATA_VIEW' as StatusAuthorityEnum,
    GppLoaderSettingsCreate: 'GPP_LOADER_SETTINGS_CREATE' as StatusAuthorityEnum,
    GppLoaderSettingsDelete: 'GPP_LOADER_SETTINGS_DELETE' as StatusAuthorityEnum,
    GppLoaderSettingsModify: 'GPP_LOADER_SETTINGS_MODIFY' as StatusAuthorityEnum,
    GppLoaderSettingsView: 'GPP_LOADER_SETTINGS_VIEW' as StatusAuthorityEnum,
    HealthView: 'HEALTH_VIEW' as StatusAuthorityEnum,
    HistoryView: 'HISTORY_VIEW' as StatusAuthorityEnum,
    IbkrDataModify: 'IBKR_DATA_MODIFY' as StatusAuthorityEnum,
    IbkrDataView: 'IBKR_DATA_VIEW' as StatusAuthorityEnum,
    IbkrFuturesMappingCreate: 'IBKR_FUTURES_MAPPING_CREATE' as StatusAuthorityEnum,
    IbkrFuturesMappingDelete: 'IBKR_FUTURES_MAPPING_DELETE' as StatusAuthorityEnum,
    IbkrFuturesMappingModify: 'IBKR_FUTURES_MAPPING_MODIFY' as StatusAuthorityEnum,
    IbkrFuturesMappingView: 'IBKR_FUTURES_MAPPING_VIEW' as StatusAuthorityEnum,
    IbkrLoaderSettingsCreate: 'IBKR_LOADER_SETTINGS_CREATE' as StatusAuthorityEnum,
    IbkrLoaderSettingsDelete: 'IBKR_LOADER_SETTINGS_DELETE' as StatusAuthorityEnum,
    IbkrLoaderSettingsModify: 'IBKR_LOADER_SETTINGS_MODIFY' as StatusAuthorityEnum,
    IbkrLoaderSettingsView: 'IBKR_LOADER_SETTINGS_VIEW' as StatusAuthorityEnum,
    IncomePaymentTracker: 'INCOME_PAYMENT_TRACKER' as StatusAuthorityEnum,
    InstrumentsCreate: 'INSTRUMENTS_CREATE' as StatusAuthorityEnum,
    InstrumentsModify: 'INSTRUMENTS_MODIFY' as StatusAuthorityEnum,
    InstrumentsView: 'INSTRUMENTS_VIEW' as StatusAuthorityEnum,
    InstrumentToAgentView: 'INSTRUMENT_TO_AGENT_VIEW' as StatusAuthorityEnum,
    InvestecDataModify: 'INVESTEC_DATA_MODIFY' as StatusAuthorityEnum,
    InvestecDataView: 'INVESTEC_DATA_VIEW' as StatusAuthorityEnum,
    InvestecLoaderSettingsCreate: 'INVESTEC_LOADER_SETTINGS_CREATE' as StatusAuthorityEnum,
    InvestecLoaderSettingsDelete: 'INVESTEC_LOADER_SETTINGS_DELETE' as StatusAuthorityEnum,
    InvestecLoaderSettingsModify: 'INVESTEC_LOADER_SETTINGS_MODIFY' as StatusAuthorityEnum,
    InvestecLoaderSettingsView: 'INVESTEC_LOADER_SETTINGS_VIEW' as StatusAuthorityEnum,
    InvestmentView: 'INVESTMENT_VIEW' as StatusAuthorityEnum,
    IpAccess: 'IP_ACCESS' as StatusAuthorityEnum,
    JbDataModify: 'JB_DATA_MODIFY' as StatusAuthorityEnum,
    JbDataView: 'JB_DATA_VIEW' as StatusAuthorityEnum,
    JbLoaderSettingsCreate: 'JB_LOADER_SETTINGS_CREATE' as StatusAuthorityEnum,
    JbLoaderSettingsDelete: 'JB_LOADER_SETTINGS_DELETE' as StatusAuthorityEnum,
    JbLoaderSettingsModify: 'JB_LOADER_SETTINGS_MODIFY' as StatusAuthorityEnum,
    JbLoaderSettingsView: 'JB_LOADER_SETTINGS_VIEW' as StatusAuthorityEnum,
    JpmDataModify: 'JPM_DATA_MODIFY' as StatusAuthorityEnum,
    JpmDataView: 'JPM_DATA_VIEW' as StatusAuthorityEnum,
    JpmLoaderSettingsCreate: 'JPM_LOADER_SETTINGS_CREATE' as StatusAuthorityEnum,
    JpmLoaderSettingsDelete: 'JPM_LOADER_SETTINGS_DELETE' as StatusAuthorityEnum,
    JpmLoaderSettingsModify: 'JPM_LOADER_SETTINGS_MODIFY' as StatusAuthorityEnum,
    JpmLoaderSettingsView: 'JPM_LOADER_SETTINGS_VIEW' as StatusAuthorityEnum,
    LeadCreate: 'LEAD_CREATE' as StatusAuthorityEnum,
    LeadDelete: 'LEAD_DELETE' as StatusAuthorityEnum,
    LeadModify: 'LEAD_MODIFY' as StatusAuthorityEnum,
    LeadModifyAll: 'LEAD_MODIFY_ALL' as StatusAuthorityEnum,
    LeadView: 'LEAD_VIEW' as StatusAuthorityEnum,
    LeadViewAll: 'LEAD_VIEW_ALL' as StatusAuthorityEnum,
    LogotypeDelete: 'LOGOTYPE_DELETE' as StatusAuthorityEnum,
    LogotypeModify: 'LOGOTYPE_MODIFY' as StatusAuthorityEnum,
    LogotypeView: 'LOGOTYPE_VIEW' as StatusAuthorityEnum,
    ManagersCreate: 'MANAGERS_CREATE' as StatusAuthorityEnum,
    ManagersDelete: 'MANAGERS_DELETE' as StatusAuthorityEnum,
    ManagersForceModify: 'MANAGERS_FORCE_MODIFY' as StatusAuthorityEnum,
    ManagersModify: 'MANAGERS_MODIFY' as StatusAuthorityEnum,
    ManagersView: 'MANAGERS_VIEW' as StatusAuthorityEnum,
    MandatesCreate: 'MANDATES_CREATE' as StatusAuthorityEnum,
    MandatesDelete: 'MANDATES_DELETE' as StatusAuthorityEnum,
    MandatesForceModify: 'MANDATES_FORCE_MODIFY' as StatusAuthorityEnum,
    MandatesModify: 'MANDATES_MODIFY' as StatusAuthorityEnum,
    MandatesModifyAll: 'MANDATES_MODIFY_ALL' as StatusAuthorityEnum,
    MandatesView: 'MANDATES_VIEW' as StatusAuthorityEnum,
    MandatesViewAll: 'MANDATES_VIEW_ALL' as StatusAuthorityEnum,
    MandateRestrictionsCreate: 'MANDATE_RESTRICTIONS_CREATE' as StatusAuthorityEnum,
    MandateRestrictionsDelete: 'MANDATE_RESTRICTIONS_DELETE' as StatusAuthorityEnum,
    MandateRestrictionsModify: 'MANDATE_RESTRICTIONS_MODIFY' as StatusAuthorityEnum,
    MandateRestrictionsModifyAll: 'MANDATE_RESTRICTIONS_MODIFY_ALL' as StatusAuthorityEnum,
    MandateRestrictionsView: 'MANDATE_RESTRICTIONS_VIEW' as StatusAuthorityEnum,
    MandateRestrictionsViewAll: 'MANDATE_RESTRICTIONS_VIEW_ALL' as StatusAuthorityEnum,
    MandateRestrictionReportsCreate: 'MANDATE_RESTRICTION_REPORTS_CREATE' as StatusAuthorityEnum,
    MandateRestrictionReportsDelete: 'MANDATE_RESTRICTION_REPORTS_DELETE' as StatusAuthorityEnum,
    MandateRestrictionReportsModify: 'MANDATE_RESTRICTION_REPORTS_MODIFY' as StatusAuthorityEnum,
    MandateRestrictionReportsModifyAll:
      'MANDATE_RESTRICTION_REPORTS_MODIFY_ALL' as StatusAuthorityEnum,
    MandateRestrictionReportsView: 'MANDATE_RESTRICTION_REPORTS_VIEW' as StatusAuthorityEnum,
    MandateRestrictionReportsViewAll: 'MANDATE_RESTRICTION_REPORTS_VIEW_ALL' as StatusAuthorityEnum,
    MandateRestrictionTemplatesCreate:
      'MANDATE_RESTRICTION_TEMPLATES_CREATE' as StatusAuthorityEnum,
    MandateRestrictionTemplatesDelete:
      'MANDATE_RESTRICTION_TEMPLATES_DELETE' as StatusAuthorityEnum,
    MandateRestrictionTemplatesModify:
      'MANDATE_RESTRICTION_TEMPLATES_MODIFY' as StatusAuthorityEnum,
    MandateRestrictionTemplatesModifyAll:
      'MANDATE_RESTRICTION_TEMPLATES_MODIFY_ALL' as StatusAuthorityEnum,
    MandateRestrictionTemplatesView: 'MANDATE_RESTRICTION_TEMPLATES_VIEW' as StatusAuthorityEnum,
    MandateRestrictionTemplatesViewAll:
      'MANDATE_RESTRICTION_TEMPLATES_VIEW_ALL' as StatusAuthorityEnum,
    MandateRiskProfileCreate: 'MANDATE_RISK_PROFILE_CREATE' as StatusAuthorityEnum,
    MandateRiskProfileDelete: 'MANDATE_RISK_PROFILE_DELETE' as StatusAuthorityEnum,
    MandateRiskProfileForceModify: 'MANDATE_RISK_PROFILE_FORCE_MODIFY' as StatusAuthorityEnum,
    MandateRiskProfileModify: 'MANDATE_RISK_PROFILE_MODIFY' as StatusAuthorityEnum,
    MandateRiskProfileView: 'MANDATE_RISK_PROFILE_VIEW' as StatusAuthorityEnum,
    MandateRiskProfileViewAll: 'MANDATE_RISK_PROFILE_VIEW_ALL' as StatusAuthorityEnum,
    ManualImportPositionsCreate: 'MANUAL_IMPORT_POSITIONS_CREATE' as StatusAuthorityEnum,
    ManualImportPositionsModify: 'MANUAL_IMPORT_POSITIONS_MODIFY' as StatusAuthorityEnum,
    ManualImportPositionsView: 'MANUAL_IMPORT_POSITIONS_VIEW' as StatusAuthorityEnum,
    ManualImportTransactionsCreate: 'MANUAL_IMPORT_TRANSACTIONS_CREATE' as StatusAuthorityEnum,
    ManualImportTransactionsModify: 'MANUAL_IMPORT_TRANSACTIONS_MODIFY' as StatusAuthorityEnum,
    ManualImportTransactionsView: 'MANUAL_IMPORT_TRANSACTIONS_VIEW' as StatusAuthorityEnum,
    MarketplaceModify: 'MARKETPLACE_MODIFY' as StatusAuthorityEnum,
    MarketplaceView: 'MARKETPLACE_VIEW' as StatusAuthorityEnum,
    MarketSecuritiesModify: 'MARKET_SECURITIES_MODIFY' as StatusAuthorityEnum,
    MarketSecuritiesView: 'MARKET_SECURITIES_VIEW' as StatusAuthorityEnum,
    MifirModify: 'MIFIR_MODIFY' as StatusAuthorityEnum,
    MifirReport: 'MIFIR_REPORT' as StatusAuthorityEnum,
    MifirView: 'MIFIR_VIEW' as StatusAuthorityEnum,
    ModelPortfoliosCreate: 'MODEL_PORTFOLIOS_CREATE' as StatusAuthorityEnum,
    ModelPortfoliosDelete: 'MODEL_PORTFOLIOS_DELETE' as StatusAuthorityEnum,
    ModelPortfoliosModify: 'MODEL_PORTFOLIOS_MODIFY' as StatusAuthorityEnum,
    ModelPortfoliosView: 'MODEL_PORTFOLIOS_VIEW' as StatusAuthorityEnum,
    MonthlyReconciliationReport: 'MONTHLY_RECONCILIATION_REPORT' as StatusAuthorityEnum,
    NotificationTemplatesCreate: 'NOTIFICATION_TEMPLATES_CREATE' as StatusAuthorityEnum,
    NotificationTemplatesDelete: 'NOTIFICATION_TEMPLATES_DELETE' as StatusAuthorityEnum,
    NotificationTemplatesModify: 'NOTIFICATION_TEMPLATES_MODIFY' as StatusAuthorityEnum,
    NotificationTemplatesView: 'NOTIFICATION_TEMPLATES_VIEW' as StatusAuthorityEnum,
    NotificationUserLogView: 'NOTIFICATION_USER_LOG_VIEW' as StatusAuthorityEnum,
    Omnibus: 'OMNIBUS' as StatusAuthorityEnum,
    OnlinePricingView: 'ONLINE_PRICING_VIEW' as StatusAuthorityEnum,
    OpportunityCreate: 'OPPORTUNITY_CREATE' as StatusAuthorityEnum,
    OpportunityDelete: 'OPPORTUNITY_DELETE' as StatusAuthorityEnum,
    OpportunityForceModify: 'OPPORTUNITY_FORCE_MODIFY' as StatusAuthorityEnum,
    OpportunityModify: 'OPPORTUNITY_MODIFY' as StatusAuthorityEnum,
    OpportunityModifyAll: 'OPPORTUNITY_MODIFY_ALL' as StatusAuthorityEnum,
    OpportunityView: 'OPPORTUNITY_VIEW' as StatusAuthorityEnum,
    OpportunityViewAll: 'OPPORTUNITY_VIEW_ALL' as StatusAuthorityEnum,
    OrdersAssign: 'ORDERS_ASSIGN' as StatusAuthorityEnum,
    OrdersCommonCreate: 'ORDERS_COMMON_CREATE' as StatusAuthorityEnum,
    OrdersCommonDelete: 'ORDERS_COMMON_DELETE' as StatusAuthorityEnum,
    OrdersCommonModify: 'ORDERS_COMMON_MODIFY' as StatusAuthorityEnum,
    OrdersCommonView: 'ORDERS_COMMON_VIEW' as StatusAuthorityEnum,
    OrdersCorpActionCreate: 'ORDERS_CORP_ACTION_CREATE' as StatusAuthorityEnum,
    OrdersCorpActionDelete: 'ORDERS_CORP_ACTION_DELETE' as StatusAuthorityEnum,
    OrdersCorpActionModify: 'ORDERS_CORP_ACTION_MODIFY' as StatusAuthorityEnum,
    OrdersCorpActionView: 'ORDERS_CORP_ACTION_VIEW' as StatusAuthorityEnum,
    OrdersCreate: 'ORDERS_CREATE' as StatusAuthorityEnum,
    OrdersDelete: 'ORDERS_DELETE' as StatusAuthorityEnum,
    OrdersDmaCreate: 'ORDERS_DMA_CREATE' as StatusAuthorityEnum,
    OrdersForceModify: 'ORDERS_FORCE_MODIFY' as StatusAuthorityEnum,
    OrdersModify: 'ORDERS_MODIFY' as StatusAuthorityEnum,
    OrdersModifyAll: 'ORDERS_MODIFY_ALL' as StatusAuthorityEnum,
    OrdersNonTradeCreate: 'ORDERS_NON_TRADE_CREATE' as StatusAuthorityEnum,
    OrdersNonTradeDelete: 'ORDERS_NON_TRADE_DELETE' as StatusAuthorityEnum,
    OrdersNonTradeModify: 'ORDERS_NON_TRADE_MODIFY' as StatusAuthorityEnum,
    OrdersNonTradeView: 'ORDERS_NON_TRADE_VIEW' as StatusAuthorityEnum,
    OrdersTechInfoModify: 'ORDERS_TECH_INFO_MODIFY' as StatusAuthorityEnum,
    OrdersTechInfoView: 'ORDERS_TECH_INFO_VIEW' as StatusAuthorityEnum,
    OrdersTradeCreate: 'ORDERS_TRADE_CREATE' as StatusAuthorityEnum,
    OrdersTradeDelete: 'ORDERS_TRADE_DELETE' as StatusAuthorityEnum,
    OrdersTradeModify: 'ORDERS_TRADE_MODIFY' as StatusAuthorityEnum,
    OrdersTradeView: 'ORDERS_TRADE_VIEW' as StatusAuthorityEnum,
    OrdersView: 'ORDERS_VIEW' as StatusAuthorityEnum,
    OrdersViewAll: 'ORDERS_VIEW_ALL' as StatusAuthorityEnum,
    PersonalAm: 'PERSONAL_AM' as StatusAuthorityEnum,
    PnlReport: 'PNL_REPORT' as StatusAuthorityEnum,
    PortfoliosCreate: 'PORTFOLIOS_CREATE' as StatusAuthorityEnum,
    PortfoliosDelete: 'PORTFOLIOS_DELETE' as StatusAuthorityEnum,
    PortfoliosForceModify: 'PORTFOLIOS_FORCE_MODIFY' as StatusAuthorityEnum,
    PortfoliosModify: 'PORTFOLIOS_MODIFY' as StatusAuthorityEnum,
    PortfoliosModifyAll: 'PORTFOLIOS_MODIFY_ALL' as StatusAuthorityEnum,
    PortfoliosView: 'PORTFOLIOS_VIEW' as StatusAuthorityEnum,
    PortfoliosViewAll: 'PORTFOLIOS_VIEW_ALL' as StatusAuthorityEnum,
    PortfolioConfigurationView: 'PORTFOLIO_CONFIGURATION_VIEW' as StatusAuthorityEnum,
    PortfolioCostsView: 'PORTFOLIO_COSTS_VIEW' as StatusAuthorityEnum,
    PortfolioFeesView: 'PORTFOLIO_FEES_VIEW' as StatusAuthorityEnum,
    PortfolioFlowsView: 'PORTFOLIO_FLOWS_VIEW' as StatusAuthorityEnum,
    PortfolioOrdersAssign: 'PORTFOLIO_ORDERS_ASSIGN' as StatusAuthorityEnum,
    PortfolioOrdersCreate: 'PORTFOLIO_ORDERS_CREATE' as StatusAuthorityEnum,
    PortfolioOrdersDelete: 'PORTFOLIO_ORDERS_DELETE' as StatusAuthorityEnum,
    PortfolioOrdersModify: 'PORTFOLIO_ORDERS_MODIFY' as StatusAuthorityEnum,
    PortfolioOrdersView: 'PORTFOLIO_ORDERS_VIEW' as StatusAuthorityEnum,
    PortfolioPnlView: 'PORTFOLIO_PNL_VIEW' as StatusAuthorityEnum,
    PortfolioPositionsView: 'PORTFOLIO_POSITIONS_VIEW' as StatusAuthorityEnum,
    PortfolioProfileModify: 'PORTFOLIO_PROFILE_MODIFY' as StatusAuthorityEnum,
    PortfolioProfileView: 'PORTFOLIO_PROFILE_VIEW' as StatusAuthorityEnum,
    PortfolioRestrictionsView: 'PORTFOLIO_RESTRICTIONS_VIEW' as StatusAuthorityEnum,
    PortfolioTransactionsCreate: 'PORTFOLIO_TRANSACTIONS_CREATE' as StatusAuthorityEnum,
    PortfolioTransactionsDelete: 'PORTFOLIO_TRANSACTIONS_DELETE' as StatusAuthorityEnum,
    PortfolioTransactionsModify: 'PORTFOLIO_TRANSACTIONS_MODIFY' as StatusAuthorityEnum,
    PortfolioTransactionsStatus: 'PORTFOLIO_TRANSACTIONS_STATUS' as StatusAuthorityEnum,
    PortfolioTransactionsView: 'PORTFOLIO_TRANSACTIONS_VIEW' as StatusAuthorityEnum,
    PortfolioValuationView: 'PORTFOLIO_VALUATION_VIEW' as StatusAuthorityEnum,
    PositionsView: 'POSITIONS_VIEW' as StatusAuthorityEnum,
    PricesLoadingMonitorView: 'PRICES_LOADING_MONITOR_VIEW' as StatusAuthorityEnum,
    PrivateInstrumentCreate: 'PRIVATE_INSTRUMENT_CREATE' as StatusAuthorityEnum,
    PrivateInstrumentDelete: 'PRIVATE_INSTRUMENT_DELETE' as StatusAuthorityEnum,
    PrivateInstrumentModify: 'PRIVATE_INSTRUMENT_MODIFY' as StatusAuthorityEnum,
    PrivateInstrumentModifyAll: 'PRIVATE_INSTRUMENT_MODIFY_ALL' as StatusAuthorityEnum,
    PrivateInstrumentView: 'PRIVATE_INSTRUMENT_VIEW' as StatusAuthorityEnum,
    PrivateInstrumentViewAll: 'PRIVATE_INSTRUMENT_VIEW_ALL' as StatusAuthorityEnum,
    ProcessCreate: 'PROCESS_CREATE' as StatusAuthorityEnum,
    ProcessDelete: 'PROCESS_DELETE' as StatusAuthorityEnum,
    ProcessModify: 'PROCESS_MODIFY' as StatusAuthorityEnum,
    ProcessView: 'PROCESS_VIEW' as StatusAuthorityEnum,
    ProductGovernanceModify: 'PRODUCT_GOVERNANCE_MODIFY' as StatusAuthorityEnum,
    ProductGovernanceView: 'PRODUCT_GOVERNANCE_VIEW' as StatusAuthorityEnum,
    ProfilesCreate: 'PROFILES_CREATE' as StatusAuthorityEnum,
    ProfilesDelete: 'PROFILES_DELETE' as StatusAuthorityEnum,
    ProfilesModify: 'PROFILES_MODIFY' as StatusAuthorityEnum,
    ProfilesView: 'PROFILES_VIEW' as StatusAuthorityEnum,
    QuarterlyStatisticsReport: 'QUARTERLY_STATISTICS_REPORT' as StatusAuthorityEnum,
    RbsFReport: 'RBS_F_REPORT' as StatusAuthorityEnum,
    RecommendationCreate: 'RECOMMENDATION_CREATE' as StatusAuthorityEnum,
    RecommendationForceModify: 'RECOMMENDATION_FORCE_MODIFY' as StatusAuthorityEnum,
    RecommendationModify: 'RECOMMENDATION_MODIFY' as StatusAuthorityEnum,
    RecommendationView: 'RECOMMENDATION_VIEW' as StatusAuthorityEnum,
    RecommendationViewAll: 'RECOMMENDATION_VIEW_ALL' as StatusAuthorityEnum,
    ReconciliationReport: 'RECONCILIATION_REPORT' as StatusAuthorityEnum,
    RegulatorCreate: 'REGULATOR_CREATE' as StatusAuthorityEnum,
    RegulatorDelete: 'REGULATOR_DELETE' as StatusAuthorityEnum,
    RegulatorModify: 'REGULATOR_MODIFY' as StatusAuthorityEnum,
    RegulatorView: 'REGULATOR_VIEW' as StatusAuthorityEnum,
    RelunaOrdersReportsView: 'RELUNA_ORDERS_REPORTS_VIEW' as StatusAuthorityEnum,
    ReportsCreate: 'REPORTS_CREATE' as StatusAuthorityEnum,
    ReportsDelete: 'REPORTS_DELETE' as StatusAuthorityEnum,
    ReportsModify: 'REPORTS_MODIFY' as StatusAuthorityEnum,
    ReportsView: 'REPORTS_VIEW' as StatusAuthorityEnum,
    RestrictionsCreate: 'RESTRICTIONS_CREATE' as StatusAuthorityEnum,
    RestrictionsDelete: 'RESTRICTIONS_DELETE' as StatusAuthorityEnum,
    RestrictionsModify: 'RESTRICTIONS_MODIFY' as StatusAuthorityEnum,
    RestrictionsModifyAll: 'RESTRICTIONS_MODIFY_ALL' as StatusAuthorityEnum,
    RestrictionsView: 'RESTRICTIONS_VIEW' as StatusAuthorityEnum,
    RestrictionsViewAll: 'RESTRICTIONS_VIEW_ALL' as StatusAuthorityEnum,
    RestrictionAlertView: 'RESTRICTION_ALERT_VIEW' as StatusAuthorityEnum,
    RestrictionReportsCreate: 'RESTRICTION_REPORTS_CREATE' as StatusAuthorityEnum,
    RestrictionReportsDelete: 'RESTRICTION_REPORTS_DELETE' as StatusAuthorityEnum,
    RestrictionReportsModify: 'RESTRICTION_REPORTS_MODIFY' as StatusAuthorityEnum,
    RestrictionReportsModifyAll: 'RESTRICTION_REPORTS_MODIFY_ALL' as StatusAuthorityEnum,
    RestrictionReportsView: 'RESTRICTION_REPORTS_VIEW' as StatusAuthorityEnum,
    RestrictionReportsViewAll: 'RESTRICTION_REPORTS_VIEW_ALL' as StatusAuthorityEnum,
    RestrictionTemplatesCreate: 'RESTRICTION_TEMPLATES_CREATE' as StatusAuthorityEnum,
    RestrictionTemplatesDelete: 'RESTRICTION_TEMPLATES_DELETE' as StatusAuthorityEnum,
    RestrictionTemplatesModify: 'RESTRICTION_TEMPLATES_MODIFY' as StatusAuthorityEnum,
    RestrictionTemplatesModifyAll: 'RESTRICTION_TEMPLATES_MODIFY_ALL' as StatusAuthorityEnum,
    RestrictionTemplatesView: 'RESTRICTION_TEMPLATES_VIEW' as StatusAuthorityEnum,
    RestrictionTemplatesViewAll: 'RESTRICTION_TEMPLATES_VIEW_ALL' as StatusAuthorityEnum,
    RiskReportCreate: 'RISK_REPORT_CREATE' as StatusAuthorityEnum,
    RiskReportView: 'RISK_REPORT_VIEW' as StatusAuthorityEnum,
    RolesCreate: 'ROLES_CREATE' as StatusAuthorityEnum,
    RolesDelete: 'ROLES_DELETE' as StatusAuthorityEnum,
    RolesModify: 'ROLES_MODIFY' as StatusAuthorityEnum,
    RolesView: 'ROLES_VIEW' as StatusAuthorityEnum,
    Rts28Report: 'RTS28_REPORT' as StatusAuthorityEnum,
    SaltedgeTransactionsModify: 'SALTEDGE_TRANSACTIONS_MODIFY' as StatusAuthorityEnum,
    SaltedgeTransactionsView: 'SALTEDGE_TRANSACTIONS_VIEW' as StatusAuthorityEnum,
    SendReports: 'SEND_REPORTS' as StatusAuthorityEnum,
    SettingsModify: 'SETTINGS_MODIFY' as StatusAuthorityEnum,
    SettingsView: 'SETTINGS_VIEW' as StatusAuthorityEnum,
    ShopView: 'SHOP_VIEW' as StatusAuthorityEnum,
    SiteInfoCreate: 'SITE_INFO_CREATE' as StatusAuthorityEnum,
    SiteInfoDelete: 'SITE_INFO_DELETE' as StatusAuthorityEnum,
    SiteInfoModify: 'SITE_INFO_MODIFY' as StatusAuthorityEnum,
    SiteInfoView: 'SITE_INFO_VIEW' as StatusAuthorityEnum,
    SovaDataModify: 'SOVA_DATA_MODIFY' as StatusAuthorityEnum,
    SovaDataView: 'SOVA_DATA_VIEW' as StatusAuthorityEnum,
    SovaOrdersModify: 'SOVA_ORDERS_MODIFY' as StatusAuthorityEnum,
    SovaOrdersView: 'SOVA_ORDERS_VIEW' as StatusAuthorityEnum,
    StatementOfEligibleFundsReport: 'STATEMENT_OF_ELIGIBLE_FUNDS_REPORT' as StatusAuthorityEnum,
    StatisticsView: 'STATISTICS_VIEW' as StatusAuthorityEnum,
    StrategiesCreate: 'STRATEGIES_CREATE' as StatusAuthorityEnum,
    StrategiesDelete: 'STRATEGIES_DELETE' as StatusAuthorityEnum,
    StrategiesForceModify: 'STRATEGIES_FORCE_MODIFY' as StatusAuthorityEnum,
    StrategiesModify: 'STRATEGIES_MODIFY' as StatusAuthorityEnum,
    StrategiesModifyAll: 'STRATEGIES_MODIFY_ALL' as StatusAuthorityEnum,
    StrategiesView: 'STRATEGIES_VIEW' as StatusAuthorityEnum,
    StrategiesViewAll: 'STRATEGIES_VIEW_ALL' as StatusAuthorityEnum,
    StructuredProductsCreate: 'STRUCTURED_PRODUCTS_CREATE' as StatusAuthorityEnum,
    StructuredProductsDelete: 'STRUCTURED_PRODUCTS_DELETE' as StatusAuthorityEnum,
    StructuredProductsModify: 'STRUCTURED_PRODUCTS_MODIFY' as StatusAuthorityEnum,
    StructuredProductsModifyAll: 'STRUCTURED_PRODUCTS_MODIFY_ALL' as StatusAuthorityEnum,
    StructuredProductsView: 'STRUCTURED_PRODUCTS_VIEW' as StatusAuthorityEnum,
    StructuredProductsViewAll: 'STRUCTURED_PRODUCTS_VIEW_ALL' as StatusAuthorityEnum,
    SubportfoliosView: 'SUBPORTFOLIOS_VIEW' as StatusAuthorityEnum,
    SubscriptionModify: 'SUBSCRIPTION_MODIFY' as StatusAuthorityEnum,
    SubscriptionView: 'SUBSCRIPTION_VIEW' as StatusAuthorityEnum,
    TableConstructorModify: 'TABLE_CONSTRUCTOR_MODIFY' as StatusAuthorityEnum,
    TableConstructorView: 'TABLE_CONSTRUCTOR_VIEW' as StatusAuthorityEnum,
    TaviraDataModify: 'TAVIRA_DATA_MODIFY' as StatusAuthorityEnum,
    TaviraDataView: 'TAVIRA_DATA_VIEW' as StatusAuthorityEnum,
    TransactionsCreate: 'TRANSACTIONS_CREATE' as StatusAuthorityEnum,
    TransactionsDelete: 'TRANSACTIONS_DELETE' as StatusAuthorityEnum,
    TransactionsForceModify: 'TRANSACTIONS_FORCE_MODIFY' as StatusAuthorityEnum,
    TransactionsMatchingModify: 'TRANSACTIONS_MATCHING_MODIFY' as StatusAuthorityEnum,
    TransactionsMatchingView: 'TRANSACTIONS_MATCHING_VIEW' as StatusAuthorityEnum,
    TransactionsModify: 'TRANSACTIONS_MODIFY' as StatusAuthorityEnum,
    TransactionsModifyAll: 'TRANSACTIONS_MODIFY_ALL' as StatusAuthorityEnum,
    TransactionsStatus: 'TRANSACTIONS_STATUS' as StatusAuthorityEnum,
    TransactionsView: 'TRANSACTIONS_VIEW' as StatusAuthorityEnum,
    TransactionsViewAll: 'TRANSACTIONS_VIEW_ALL' as StatusAuthorityEnum,
    TransactionLoaderCreate: 'TRANSACTION_LOADER_CREATE' as StatusAuthorityEnum,
    TransactionLoaderModify: 'TRANSACTION_LOADER_MODIFY' as StatusAuthorityEnum,
    TransactionLoaderView: 'TRANSACTION_LOADER_VIEW' as StatusAuthorityEnum,
    TransactionWithoutBankView: 'TRANSACTION_WITHOUT_BANK_VIEW' as StatusAuthorityEnum,
    TrimpDataModify: 'TRIMP_DATA_MODIFY' as StatusAuthorityEnum,
    TrimpDataView: 'TRIMP_DATA_VIEW' as StatusAuthorityEnum,
    Ttca: 'TTCA' as StatusAuthorityEnum,
    UbsDataModify: 'UBS_DATA_MODIFY' as StatusAuthorityEnum,
    UbsDataView: 'UBS_DATA_VIEW' as StatusAuthorityEnum,
    ValueDateRulesModify: 'VALUE_DATE_RULES_MODIFY' as StatusAuthorityEnum,
    ValueDateRulesView: 'VALUE_DATE_RULES_VIEW' as StatusAuthorityEnum,
    VpDataModify: 'VP_DATA_MODIFY' as StatusAuthorityEnum,
    VpDataView: 'VP_DATA_VIEW' as StatusAuthorityEnum,
    VpLoaderSettingsCreate: 'VP_LOADER_SETTINGS_CREATE' as StatusAuthorityEnum,
    VpLoaderSettingsDelete: 'VP_LOADER_SETTINGS_DELETE' as StatusAuthorityEnum,
    VpLoaderSettingsModify: 'VP_LOADER_SETTINGS_MODIFY' as StatusAuthorityEnum,
    VpLoaderSettingsView: 'VP_LOADER_SETTINGS_VIEW' as StatusAuthorityEnum,
    VtbDataModify: 'VTB_DATA_MODIFY' as StatusAuthorityEnum,
    VtbDataView: 'VTB_DATA_VIEW' as StatusAuthorityEnum,
    ZohoSettingsCreate: 'ZOHO_SETTINGS_CREATE' as StatusAuthorityEnum,
    ZohoSettingsDelete: 'ZOHO_SETTINGS_DELETE' as StatusAuthorityEnum,
    ZohoSettingsModify: 'ZOHO_SETTINGS_MODIFY' as StatusAuthorityEnum,
    ZohoSettingsView: 'ZOHO_SETTINGS_VIEW' as StatusAuthorityEnum,
  };
  export type ViewAllAuthorityEnum =
    | 'ACCOUNTS_CREATE'
    | 'ACCOUNTS_DELETE'
    | 'ACCOUNTS_FORCE_MODIFY'
    | 'ACCOUNTS_MODIFY'
    | 'ACCOUNTS_MODIFY_ALL'
    | 'ACCOUNTS_VIEW'
    | 'ACCOUNTS_VIEW_ALL'
    | 'ACCOUNT_STRUCTURE'
    | 'ACTUATOR_VIEW'
    | 'ADVANCED_ANALYTICS_VIEW'
    | 'ALFA_DATA_MODIFY'
    | 'ALFA_DATA_VIEW'
    | 'AMAZON_WORK_DOCS'
    | 'BANK_ACCOUNTS_CREATE'
    | 'BANK_ACCOUNTS_DELETE'
    | 'BANK_ACCOUNTS_FORCE_MODIFY'
    | 'BANK_ACCOUNTS_MODIFY'
    | 'BANK_ACCOUNTS_MODIFY_ALL'
    | 'BANK_ACCOUNTS_VIEW'
    | 'BANK_ACCOUNTS_VIEW_ALL'
    | 'BANK_STATS_VIEW'
    | 'BKS_DATA_MODIFY'
    | 'BKS_DATA_VIEW'
    | 'BKS_LOADER_SETTINGS_CREATE'
    | 'BKS_LOADER_SETTINGS_DELETE'
    | 'BKS_LOADER_SETTINGS_MODIFY'
    | 'BKS_LOADER_SETTINGS_VIEW'
    | 'BLOOMBERG_REQUESTS_VIEW'
    | 'BROKER_CREATE'
    | 'BROKER_DELETE'
    | 'BROKER_MODIFY'
    | 'BROKER_VIEW'
    | 'BUSINESS_EVENTS_VIEW'
    | 'CASS_REPORT'
    | 'CBH_BS_DATA_MODIFY'
    | 'CBH_BS_DATA_VIEW'
    | 'CBH_DATA_MODIFY'
    | 'CBH_DATA_VIEW'
    | 'CBH_LOADER_SETTINGS_CREATE'
    | 'CBH_LOADER_SETTINGS_DELETE'
    | 'CBH_LOADER_SETTINGS_MODIFY'
    | 'CBH_LOADER_SETTINGS_VIEW'
    | 'CHANGE_REQUEST_SETTINGS_CREATE'
    | 'CHANGE_REQUEST_SETTINGS_DELETE'
    | 'CHANGE_REQUEST_SETTINGS_MODIFY'
    | 'CHANGE_REQUEST_SETTINGS_VIEW'
    | 'CLIENTS_CREATE'
    | 'CLIENTS_DELETE'
    | 'CLIENTS_MODIFY'
    | 'CLIENTS_MODIFY_ALL'
    | 'CLIENTS_VIEW'
    | 'CLIENTS_VIEW_ALL'
    | 'CLIENT_COUNT_REPORT'
    | 'CLIENT_FEES_REPORT'
    | 'CLIENT_VALUATION_REPORT'
    | 'CMAR_REPORT'
    | 'COMMON_EMAIL_TEMPLATES_CREATE'
    | 'COMMON_EMAIL_TEMPLATES_DELETE'
    | 'COMMON_EMAIL_TEMPLATES_MODIFY'
    | 'COMMON_EMAIL_TEMPLATES_VIEW'
    | 'COMPANIES_CREATE'
    | 'COMPANIES_DELETE'
    | 'COMPANIES_MODIFY'
    | 'COMPANIES_TAGS_VIEW'
    | 'COMPANIES_VIEW'
    | 'COMPANY_HOLDING_CREATE'
    | 'COMPANY_HOLDING_DELETE'
    | 'COMPANY_HOLDING_MODIFY'
    | 'COMPANY_HOLDING_VIEW'
    | 'COMPANY_RISK_REPORT'
    | 'COMPLIANCE_ACCOUNT_COMPANY_CREATE'
    | 'COMPLIANCE_ACCOUNT_COMPANY_DELETE'
    | 'COMPLIANCE_ACCOUNT_COMPANY_FORCE_MODIFY'
    | 'COMPLIANCE_ACCOUNT_COMPANY_MODIFY'
    | 'COMPLIANCE_ACCOUNT_COMPANY_VIEW'
    | 'COUNTERPARTY_CREATE'
    | 'COUNTERPARTY_DELETE'
    | 'COUNTERPARTY_MODIFY'
    | 'COUNTERPARTY_VIEW'
    | 'COUPON_PROJECTION_REPORT'
    | 'CRS_REPORT'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_CREATE'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_MODIFY'
    | 'CUSTODIAN_ASSET_TYPE_MAPPING_VIEW'
    | 'CUSTODIAN_CREATE'
    | 'CUSTODIAN_DELETE'
    | 'CUSTODIAN_MODIFY'
    | 'CUSTODIAN_VIEW'
    | 'DASHBOARD_MODIFY'
    | 'DASHBOARD_VIEW'
    | 'DBS_DATA_MODIFY'
    | 'DBS_DATA_VIEW'
    | 'DEACTIVATED_TRANSACTION_VIEW'
    | 'DEPARTMENTS_CREATE'
    | 'DEPARTMENTS_DELETE'
    | 'DEPARTMENTS_MODIFY'
    | 'DEPARTMENTS_VIEW'
    | 'DOLFIN_TRANSACTIONS_MODIFY'
    | 'DOLFIN_TRANSACTIONS_VIEW'
    | 'EDR_DATA_MODIFY'
    | 'EDR_DATA_VIEW'
    | 'EDR_LOADER_SETTINGS_CREATE'
    | 'EDR_LOADER_SETTINGS_DELETE'
    | 'EDR_LOADER_SETTINGS_MODIFY'
    | 'EDR_LOADER_SETTINGS_VIEW'
    | 'EMAIL_TEMPLATE_CREATE'
    | 'EMAIL_TEMPLATE_DELETE'
    | 'EMAIL_TEMPLATE_MODIFY'
    | 'EMAIL_TEMPLATE_VIEW'
    | 'EXECGW_REPORTS_VIEW'
    | 'FEES_CREATE'
    | 'FEES_MODIFY'
    | 'FEES_VIEW'
    | 'FEES_VIEW_ALL'
    | 'FIELDS_CREATE'
    | 'FIELDS_DELETE'
    | 'FIELDS_MODIFY'
    | 'FIELDS_VIEW'
    | 'FILE_USER_LOG_VIEW'
    | 'GAP_MONITOR_VIEW'
    | 'GLOBAL_ALLOCATION_CREATE'
    | 'GLOBAL_ALLOCATION_MODIFY'
    | 'GLOBAL_ALLOCATION_VIEW'
    | 'GLOBAL_ALLOCATION_WIDGET_VIEW'
    | 'GOOGLE_AUTH_MODIFY'
    | 'GOOGLE_AUTH_VIEW'
    | 'GPP_DATA_MODIFY'
    | 'GPP_DATA_VIEW'
    | 'GPP_LOADER_SETTINGS_CREATE'
    | 'GPP_LOADER_SETTINGS_DELETE'
    | 'GPP_LOADER_SETTINGS_MODIFY'
    | 'GPP_LOADER_SETTINGS_VIEW'
    | 'HEALTH_VIEW'
    | 'HISTORY_VIEW'
    | 'IBKR_DATA_MODIFY'
    | 'IBKR_DATA_VIEW'
    | 'IBKR_FUTURES_MAPPING_CREATE'
    | 'IBKR_FUTURES_MAPPING_DELETE'
    | 'IBKR_FUTURES_MAPPING_MODIFY'
    | 'IBKR_FUTURES_MAPPING_VIEW'
    | 'IBKR_LOADER_SETTINGS_CREATE'
    | 'IBKR_LOADER_SETTINGS_DELETE'
    | 'IBKR_LOADER_SETTINGS_MODIFY'
    | 'IBKR_LOADER_SETTINGS_VIEW'
    | 'INCOME_PAYMENT_TRACKER'
    | 'INSTRUMENTS_CREATE'
    | 'INSTRUMENTS_MODIFY'
    | 'INSTRUMENTS_VIEW'
    | 'INSTRUMENT_TO_AGENT_VIEW'
    | 'INVESTEC_DATA_MODIFY'
    | 'INVESTEC_DATA_VIEW'
    | 'INVESTEC_LOADER_SETTINGS_CREATE'
    | 'INVESTEC_LOADER_SETTINGS_DELETE'
    | 'INVESTEC_LOADER_SETTINGS_MODIFY'
    | 'INVESTEC_LOADER_SETTINGS_VIEW'
    | 'INVESTMENT_VIEW'
    | 'IP_ACCESS'
    | 'JB_DATA_MODIFY'
    | 'JB_DATA_VIEW'
    | 'JB_LOADER_SETTINGS_CREATE'
    | 'JB_LOADER_SETTINGS_DELETE'
    | 'JB_LOADER_SETTINGS_MODIFY'
    | 'JB_LOADER_SETTINGS_VIEW'
    | 'JPM_DATA_MODIFY'
    | 'JPM_DATA_VIEW'
    | 'JPM_LOADER_SETTINGS_CREATE'
    | 'JPM_LOADER_SETTINGS_DELETE'
    | 'JPM_LOADER_SETTINGS_MODIFY'
    | 'JPM_LOADER_SETTINGS_VIEW'
    | 'LEAD_CREATE'
    | 'LEAD_DELETE'
    | 'LEAD_MODIFY'
    | 'LEAD_MODIFY_ALL'
    | 'LEAD_VIEW'
    | 'LEAD_VIEW_ALL'
    | 'LOGOTYPE_DELETE'
    | 'LOGOTYPE_MODIFY'
    | 'LOGOTYPE_VIEW'
    | 'MANAGERS_CREATE'
    | 'MANAGERS_DELETE'
    | 'MANAGERS_FORCE_MODIFY'
    | 'MANAGERS_MODIFY'
    | 'MANAGERS_VIEW'
    | 'MANDATES_CREATE'
    | 'MANDATES_DELETE'
    | 'MANDATES_FORCE_MODIFY'
    | 'MANDATES_MODIFY'
    | 'MANDATES_MODIFY_ALL'
    | 'MANDATES_VIEW'
    | 'MANDATES_VIEW_ALL'
    | 'MANDATE_RESTRICTIONS_CREATE'
    | 'MANDATE_RESTRICTIONS_DELETE'
    | 'MANDATE_RESTRICTIONS_MODIFY'
    | 'MANDATE_RESTRICTIONS_MODIFY_ALL'
    | 'MANDATE_RESTRICTIONS_VIEW'
    | 'MANDATE_RESTRICTIONS_VIEW_ALL'
    | 'MANDATE_RESTRICTION_REPORTS_CREATE'
    | 'MANDATE_RESTRICTION_REPORTS_DELETE'
    | 'MANDATE_RESTRICTION_REPORTS_MODIFY'
    | 'MANDATE_RESTRICTION_REPORTS_MODIFY_ALL'
    | 'MANDATE_RESTRICTION_REPORTS_VIEW'
    | 'MANDATE_RESTRICTION_REPORTS_VIEW_ALL'
    | 'MANDATE_RESTRICTION_TEMPLATES_CREATE'
    | 'MANDATE_RESTRICTION_TEMPLATES_DELETE'
    | 'MANDATE_RESTRICTION_TEMPLATES_MODIFY'
    | 'MANDATE_RESTRICTION_TEMPLATES_MODIFY_ALL'
    | 'MANDATE_RESTRICTION_TEMPLATES_VIEW'
    | 'MANDATE_RESTRICTION_TEMPLATES_VIEW_ALL'
    | 'MANDATE_RISK_PROFILE_CREATE'
    | 'MANDATE_RISK_PROFILE_DELETE'
    | 'MANDATE_RISK_PROFILE_FORCE_MODIFY'
    | 'MANDATE_RISK_PROFILE_MODIFY'
    | 'MANDATE_RISK_PROFILE_VIEW'
    | 'MANDATE_RISK_PROFILE_VIEW_ALL'
    | 'MANUAL_IMPORT_POSITIONS_CREATE'
    | 'MANUAL_IMPORT_POSITIONS_MODIFY'
    | 'MANUAL_IMPORT_POSITIONS_VIEW'
    | 'MANUAL_IMPORT_TRANSACTIONS_CREATE'
    | 'MANUAL_IMPORT_TRANSACTIONS_MODIFY'
    | 'MANUAL_IMPORT_TRANSACTIONS_VIEW'
    | 'MARKETPLACE_MODIFY'
    | 'MARKETPLACE_VIEW'
    | 'MARKET_SECURITIES_MODIFY'
    | 'MARKET_SECURITIES_VIEW'
    | 'MIFIR_MODIFY'
    | 'MIFIR_REPORT'
    | 'MIFIR_VIEW'
    | 'MODEL_PORTFOLIOS_CREATE'
    | 'MODEL_PORTFOLIOS_DELETE'
    | 'MODEL_PORTFOLIOS_MODIFY'
    | 'MODEL_PORTFOLIOS_VIEW'
    | 'MONTHLY_RECONCILIATION_REPORT'
    | 'NOTIFICATION_TEMPLATES_CREATE'
    | 'NOTIFICATION_TEMPLATES_DELETE'
    | 'NOTIFICATION_TEMPLATES_MODIFY'
    | 'NOTIFICATION_TEMPLATES_VIEW'
    | 'NOTIFICATION_USER_LOG_VIEW'
    | 'OMNIBUS'
    | 'ONLINE_PRICING_VIEW'
    | 'OPPORTUNITY_CREATE'
    | 'OPPORTUNITY_DELETE'
    | 'OPPORTUNITY_FORCE_MODIFY'
    | 'OPPORTUNITY_MODIFY'
    | 'OPPORTUNITY_MODIFY_ALL'
    | 'OPPORTUNITY_VIEW'
    | 'OPPORTUNITY_VIEW_ALL'
    | 'ORDERS_ASSIGN'
    | 'ORDERS_COMMON_CREATE'
    | 'ORDERS_COMMON_DELETE'
    | 'ORDERS_COMMON_MODIFY'
    | 'ORDERS_COMMON_VIEW'
    | 'ORDERS_CORP_ACTION_CREATE'
    | 'ORDERS_CORP_ACTION_DELETE'
    | 'ORDERS_CORP_ACTION_MODIFY'
    | 'ORDERS_CORP_ACTION_VIEW'
    | 'ORDERS_CREATE'
    | 'ORDERS_DELETE'
    | 'ORDERS_DMA_CREATE'
    | 'ORDERS_FORCE_MODIFY'
    | 'ORDERS_MODIFY'
    | 'ORDERS_MODIFY_ALL'
    | 'ORDERS_NON_TRADE_CREATE'
    | 'ORDERS_NON_TRADE_DELETE'
    | 'ORDERS_NON_TRADE_MODIFY'
    | 'ORDERS_NON_TRADE_VIEW'
    | 'ORDERS_TECH_INFO_MODIFY'
    | 'ORDERS_TECH_INFO_VIEW'
    | 'ORDERS_TRADE_CREATE'
    | 'ORDERS_TRADE_DELETE'
    | 'ORDERS_TRADE_MODIFY'
    | 'ORDERS_TRADE_VIEW'
    | 'ORDERS_VIEW'
    | 'ORDERS_VIEW_ALL'
    | 'PERSONAL_AM'
    | 'PNL_REPORT'
    | 'PORTFOLIOS_CREATE'
    | 'PORTFOLIOS_DELETE'
    | 'PORTFOLIOS_FORCE_MODIFY'
    | 'PORTFOLIOS_MODIFY'
    | 'PORTFOLIOS_MODIFY_ALL'
    | 'PORTFOLIOS_VIEW'
    | 'PORTFOLIOS_VIEW_ALL'
    | 'PORTFOLIO_CONFIGURATION_VIEW'
    | 'PORTFOLIO_COSTS_VIEW'
    | 'PORTFOLIO_FEES_VIEW'
    | 'PORTFOLIO_FLOWS_VIEW'
    | 'PORTFOLIO_ORDERS_ASSIGN'
    | 'PORTFOLIO_ORDERS_CREATE'
    | 'PORTFOLIO_ORDERS_DELETE'
    | 'PORTFOLIO_ORDERS_MODIFY'
    | 'PORTFOLIO_ORDERS_VIEW'
    | 'PORTFOLIO_PNL_VIEW'
    | 'PORTFOLIO_POSITIONS_VIEW'
    | 'PORTFOLIO_PROFILE_MODIFY'
    | 'PORTFOLIO_PROFILE_VIEW'
    | 'PORTFOLIO_RESTRICTIONS_VIEW'
    | 'PORTFOLIO_TRANSACTIONS_CREATE'
    | 'PORTFOLIO_TRANSACTIONS_DELETE'
    | 'PORTFOLIO_TRANSACTIONS_MODIFY'
    | 'PORTFOLIO_TRANSACTIONS_STATUS'
    | 'PORTFOLIO_TRANSACTIONS_VIEW'
    | 'PORTFOLIO_VALUATION_VIEW'
    | 'POSITIONS_VIEW'
    | 'PRICES_LOADING_MONITOR_VIEW'
    | 'PRIVATE_INSTRUMENT_CREATE'
    | 'PRIVATE_INSTRUMENT_DELETE'
    | 'PRIVATE_INSTRUMENT_MODIFY'
    | 'PRIVATE_INSTRUMENT_MODIFY_ALL'
    | 'PRIVATE_INSTRUMENT_VIEW'
    | 'PRIVATE_INSTRUMENT_VIEW_ALL'
    | 'PROCESS_CREATE'
    | 'PROCESS_DELETE'
    | 'PROCESS_MODIFY'
    | 'PROCESS_VIEW'
    | 'PRODUCT_GOVERNANCE_MODIFY'
    | 'PRODUCT_GOVERNANCE_VIEW'
    | 'PROFILES_CREATE'
    | 'PROFILES_DELETE'
    | 'PROFILES_MODIFY'
    | 'PROFILES_VIEW'
    | 'QUARTERLY_STATISTICS_REPORT'
    | 'RBS_F_REPORT'
    | 'RECOMMENDATION_CREATE'
    | 'RECOMMENDATION_FORCE_MODIFY'
    | 'RECOMMENDATION_MODIFY'
    | 'RECOMMENDATION_VIEW'
    | 'RECOMMENDATION_VIEW_ALL'
    | 'RECONCILIATION_REPORT'
    | 'REGULATOR_CREATE'
    | 'REGULATOR_DELETE'
    | 'REGULATOR_MODIFY'
    | 'REGULATOR_VIEW'
    | 'RELUNA_ORDERS_REPORTS_VIEW'
    | 'REPORTS_CREATE'
    | 'REPORTS_DELETE'
    | 'REPORTS_MODIFY'
    | 'REPORTS_VIEW'
    | 'RESTRICTIONS_CREATE'
    | 'RESTRICTIONS_DELETE'
    | 'RESTRICTIONS_MODIFY'
    | 'RESTRICTIONS_MODIFY_ALL'
    | 'RESTRICTIONS_VIEW'
    | 'RESTRICTIONS_VIEW_ALL'
    | 'RESTRICTION_ALERT_VIEW'
    | 'RESTRICTION_REPORTS_CREATE'
    | 'RESTRICTION_REPORTS_DELETE'
    | 'RESTRICTION_REPORTS_MODIFY'
    | 'RESTRICTION_REPORTS_MODIFY_ALL'
    | 'RESTRICTION_REPORTS_VIEW'
    | 'RESTRICTION_REPORTS_VIEW_ALL'
    | 'RESTRICTION_TEMPLATES_CREATE'
    | 'RESTRICTION_TEMPLATES_DELETE'
    | 'RESTRICTION_TEMPLATES_MODIFY'
    | 'RESTRICTION_TEMPLATES_MODIFY_ALL'
    | 'RESTRICTION_TEMPLATES_VIEW'
    | 'RESTRICTION_TEMPLATES_VIEW_ALL'
    | 'RISK_REPORT_CREATE'
    | 'RISK_REPORT_VIEW'
    | 'ROLES_CREATE'
    | 'ROLES_DELETE'
    | 'ROLES_MODIFY'
    | 'ROLES_VIEW'
    | 'RTS28_REPORT'
    | 'SALTEDGE_TRANSACTIONS_MODIFY'
    | 'SALTEDGE_TRANSACTIONS_VIEW'
    | 'SEND_REPORTS'
    | 'SETTINGS_MODIFY'
    | 'SETTINGS_VIEW'
    | 'SHOP_VIEW'
    | 'SITE_INFO_CREATE'
    | 'SITE_INFO_DELETE'
    | 'SITE_INFO_MODIFY'
    | 'SITE_INFO_VIEW'
    | 'SOVA_DATA_MODIFY'
    | 'SOVA_DATA_VIEW'
    | 'SOVA_ORDERS_MODIFY'
    | 'SOVA_ORDERS_VIEW'
    | 'STATEMENT_OF_ELIGIBLE_FUNDS_REPORT'
    | 'STATISTICS_VIEW'
    | 'STRATEGIES_CREATE'
    | 'STRATEGIES_DELETE'
    | 'STRATEGIES_FORCE_MODIFY'
    | 'STRATEGIES_MODIFY'
    | 'STRATEGIES_MODIFY_ALL'
    | 'STRATEGIES_VIEW'
    | 'STRATEGIES_VIEW_ALL'
    | 'STRUCTURED_PRODUCTS_CREATE'
    | 'STRUCTURED_PRODUCTS_DELETE'
    | 'STRUCTURED_PRODUCTS_MODIFY'
    | 'STRUCTURED_PRODUCTS_MODIFY_ALL'
    | 'STRUCTURED_PRODUCTS_VIEW'
    | 'STRUCTURED_PRODUCTS_VIEW_ALL'
    | 'SUBPORTFOLIOS_VIEW'
    | 'SUBSCRIPTION_MODIFY'
    | 'SUBSCRIPTION_VIEW'
    | 'TABLE_CONSTRUCTOR_MODIFY'
    | 'TABLE_CONSTRUCTOR_VIEW'
    | 'TAVIRA_DATA_MODIFY'
    | 'TAVIRA_DATA_VIEW'
    | 'TRANSACTIONS_CREATE'
    | 'TRANSACTIONS_DELETE'
    | 'TRANSACTIONS_FORCE_MODIFY'
    | 'TRANSACTIONS_MATCHING_MODIFY'
    | 'TRANSACTIONS_MATCHING_VIEW'
    | 'TRANSACTIONS_MODIFY'
    | 'TRANSACTIONS_MODIFY_ALL'
    | 'TRANSACTIONS_STATUS'
    | 'TRANSACTIONS_VIEW'
    | 'TRANSACTIONS_VIEW_ALL'
    | 'TRANSACTION_LOADER_CREATE'
    | 'TRANSACTION_LOADER_MODIFY'
    | 'TRANSACTION_LOADER_VIEW'
    | 'TRANSACTION_WITHOUT_BANK_VIEW'
    | 'TRIMP_DATA_MODIFY'
    | 'TRIMP_DATA_VIEW'
    | 'TTCA'
    | 'UBS_DATA_MODIFY'
    | 'UBS_DATA_VIEW'
    | 'VALUE_DATE_RULES_MODIFY'
    | 'VALUE_DATE_RULES_VIEW'
    | 'VP_DATA_MODIFY'
    | 'VP_DATA_VIEW'
    | 'VP_LOADER_SETTINGS_CREATE'
    | 'VP_LOADER_SETTINGS_DELETE'
    | 'VP_LOADER_SETTINGS_MODIFY'
    | 'VP_LOADER_SETTINGS_VIEW'
    | 'VTB_DATA_MODIFY'
    | 'VTB_DATA_VIEW'
    | 'ZOHO_SETTINGS_CREATE'
    | 'ZOHO_SETTINGS_DELETE'
    | 'ZOHO_SETTINGS_MODIFY'
    | 'ZOHO_SETTINGS_VIEW';
  export const ViewAllAuthorityEnum = {
    AccountsCreate: 'ACCOUNTS_CREATE' as ViewAllAuthorityEnum,
    AccountsDelete: 'ACCOUNTS_DELETE' as ViewAllAuthorityEnum,
    AccountsForceModify: 'ACCOUNTS_FORCE_MODIFY' as ViewAllAuthorityEnum,
    AccountsModify: 'ACCOUNTS_MODIFY' as ViewAllAuthorityEnum,
    AccountsModifyAll: 'ACCOUNTS_MODIFY_ALL' as ViewAllAuthorityEnum,
    AccountsView: 'ACCOUNTS_VIEW' as ViewAllAuthorityEnum,
    AccountsViewAll: 'ACCOUNTS_VIEW_ALL' as ViewAllAuthorityEnum,
    AccountStructure: 'ACCOUNT_STRUCTURE' as ViewAllAuthorityEnum,
    ActuatorView: 'ACTUATOR_VIEW' as ViewAllAuthorityEnum,
    AdvancedAnalyticsView: 'ADVANCED_ANALYTICS_VIEW' as ViewAllAuthorityEnum,
    AlfaDataModify: 'ALFA_DATA_MODIFY' as ViewAllAuthorityEnum,
    AlfaDataView: 'ALFA_DATA_VIEW' as ViewAllAuthorityEnum,
    AmazonWorkDocs: 'AMAZON_WORK_DOCS' as ViewAllAuthorityEnum,
    BankAccountsCreate: 'BANK_ACCOUNTS_CREATE' as ViewAllAuthorityEnum,
    BankAccountsDelete: 'BANK_ACCOUNTS_DELETE' as ViewAllAuthorityEnum,
    BankAccountsForceModify: 'BANK_ACCOUNTS_FORCE_MODIFY' as ViewAllAuthorityEnum,
    BankAccountsModify: 'BANK_ACCOUNTS_MODIFY' as ViewAllAuthorityEnum,
    BankAccountsModifyAll: 'BANK_ACCOUNTS_MODIFY_ALL' as ViewAllAuthorityEnum,
    BankAccountsView: 'BANK_ACCOUNTS_VIEW' as ViewAllAuthorityEnum,
    BankAccountsViewAll: 'BANK_ACCOUNTS_VIEW_ALL' as ViewAllAuthorityEnum,
    BankStatsView: 'BANK_STATS_VIEW' as ViewAllAuthorityEnum,
    BksDataModify: 'BKS_DATA_MODIFY' as ViewAllAuthorityEnum,
    BksDataView: 'BKS_DATA_VIEW' as ViewAllAuthorityEnum,
    BksLoaderSettingsCreate: 'BKS_LOADER_SETTINGS_CREATE' as ViewAllAuthorityEnum,
    BksLoaderSettingsDelete: 'BKS_LOADER_SETTINGS_DELETE' as ViewAllAuthorityEnum,
    BksLoaderSettingsModify: 'BKS_LOADER_SETTINGS_MODIFY' as ViewAllAuthorityEnum,
    BksLoaderSettingsView: 'BKS_LOADER_SETTINGS_VIEW' as ViewAllAuthorityEnum,
    BloombergRequestsView: 'BLOOMBERG_REQUESTS_VIEW' as ViewAllAuthorityEnum,
    BrokerCreate: 'BROKER_CREATE' as ViewAllAuthorityEnum,
    BrokerDelete: 'BROKER_DELETE' as ViewAllAuthorityEnum,
    BrokerModify: 'BROKER_MODIFY' as ViewAllAuthorityEnum,
    BrokerView: 'BROKER_VIEW' as ViewAllAuthorityEnum,
    BusinessEventsView: 'BUSINESS_EVENTS_VIEW' as ViewAllAuthorityEnum,
    CassReport: 'CASS_REPORT' as ViewAllAuthorityEnum,
    CbhBsDataModify: 'CBH_BS_DATA_MODIFY' as ViewAllAuthorityEnum,
    CbhBsDataView: 'CBH_BS_DATA_VIEW' as ViewAllAuthorityEnum,
    CbhDataModify: 'CBH_DATA_MODIFY' as ViewAllAuthorityEnum,
    CbhDataView: 'CBH_DATA_VIEW' as ViewAllAuthorityEnum,
    CbhLoaderSettingsCreate: 'CBH_LOADER_SETTINGS_CREATE' as ViewAllAuthorityEnum,
    CbhLoaderSettingsDelete: 'CBH_LOADER_SETTINGS_DELETE' as ViewAllAuthorityEnum,
    CbhLoaderSettingsModify: 'CBH_LOADER_SETTINGS_MODIFY' as ViewAllAuthorityEnum,
    CbhLoaderSettingsView: 'CBH_LOADER_SETTINGS_VIEW' as ViewAllAuthorityEnum,
    ChangeRequestSettingsCreate: 'CHANGE_REQUEST_SETTINGS_CREATE' as ViewAllAuthorityEnum,
    ChangeRequestSettingsDelete: 'CHANGE_REQUEST_SETTINGS_DELETE' as ViewAllAuthorityEnum,
    ChangeRequestSettingsModify: 'CHANGE_REQUEST_SETTINGS_MODIFY' as ViewAllAuthorityEnum,
    ChangeRequestSettingsView: 'CHANGE_REQUEST_SETTINGS_VIEW' as ViewAllAuthorityEnum,
    ClientsCreate: 'CLIENTS_CREATE' as ViewAllAuthorityEnum,
    ClientsDelete: 'CLIENTS_DELETE' as ViewAllAuthorityEnum,
    ClientsModify: 'CLIENTS_MODIFY' as ViewAllAuthorityEnum,
    ClientsModifyAll: 'CLIENTS_MODIFY_ALL' as ViewAllAuthorityEnum,
    ClientsView: 'CLIENTS_VIEW' as ViewAllAuthorityEnum,
    ClientsViewAll: 'CLIENTS_VIEW_ALL' as ViewAllAuthorityEnum,
    ClientCountReport: 'CLIENT_COUNT_REPORT' as ViewAllAuthorityEnum,
    ClientFeesReport: 'CLIENT_FEES_REPORT' as ViewAllAuthorityEnum,
    ClientValuationReport: 'CLIENT_VALUATION_REPORT' as ViewAllAuthorityEnum,
    CmarReport: 'CMAR_REPORT' as ViewAllAuthorityEnum,
    CommonEmailTemplatesCreate: 'COMMON_EMAIL_TEMPLATES_CREATE' as ViewAllAuthorityEnum,
    CommonEmailTemplatesDelete: 'COMMON_EMAIL_TEMPLATES_DELETE' as ViewAllAuthorityEnum,
    CommonEmailTemplatesModify: 'COMMON_EMAIL_TEMPLATES_MODIFY' as ViewAllAuthorityEnum,
    CommonEmailTemplatesView: 'COMMON_EMAIL_TEMPLATES_VIEW' as ViewAllAuthorityEnum,
    CompaniesCreate: 'COMPANIES_CREATE' as ViewAllAuthorityEnum,
    CompaniesDelete: 'COMPANIES_DELETE' as ViewAllAuthorityEnum,
    CompaniesModify: 'COMPANIES_MODIFY' as ViewAllAuthorityEnum,
    CompaniesTagsView: 'COMPANIES_TAGS_VIEW' as ViewAllAuthorityEnum,
    CompaniesView: 'COMPANIES_VIEW' as ViewAllAuthorityEnum,
    CompanyHoldingCreate: 'COMPANY_HOLDING_CREATE' as ViewAllAuthorityEnum,
    CompanyHoldingDelete: 'COMPANY_HOLDING_DELETE' as ViewAllAuthorityEnum,
    CompanyHoldingModify: 'COMPANY_HOLDING_MODIFY' as ViewAllAuthorityEnum,
    CompanyHoldingView: 'COMPANY_HOLDING_VIEW' as ViewAllAuthorityEnum,
    CompanyRiskReport: 'COMPANY_RISK_REPORT' as ViewAllAuthorityEnum,
    ComplianceAccountCompanyCreate: 'COMPLIANCE_ACCOUNT_COMPANY_CREATE' as ViewAllAuthorityEnum,
    ComplianceAccountCompanyDelete: 'COMPLIANCE_ACCOUNT_COMPANY_DELETE' as ViewAllAuthorityEnum,
    ComplianceAccountCompanyForceModify:
      'COMPLIANCE_ACCOUNT_COMPANY_FORCE_MODIFY' as ViewAllAuthorityEnum,
    ComplianceAccountCompanyModify: 'COMPLIANCE_ACCOUNT_COMPANY_MODIFY' as ViewAllAuthorityEnum,
    ComplianceAccountCompanyView: 'COMPLIANCE_ACCOUNT_COMPANY_VIEW' as ViewAllAuthorityEnum,
    CounterpartyCreate: 'COUNTERPARTY_CREATE' as ViewAllAuthorityEnum,
    CounterpartyDelete: 'COUNTERPARTY_DELETE' as ViewAllAuthorityEnum,
    CounterpartyModify: 'COUNTERPARTY_MODIFY' as ViewAllAuthorityEnum,
    CounterpartyView: 'COUNTERPARTY_VIEW' as ViewAllAuthorityEnum,
    CouponProjectionReport: 'COUPON_PROJECTION_REPORT' as ViewAllAuthorityEnum,
    CrsReport: 'CRS_REPORT' as ViewAllAuthorityEnum,
    CustodianAssetTypeMappingCreate: 'CUSTODIAN_ASSET_TYPE_MAPPING_CREATE' as ViewAllAuthorityEnum,
    CustodianAssetTypeMappingModify: 'CUSTODIAN_ASSET_TYPE_MAPPING_MODIFY' as ViewAllAuthorityEnum,
    CustodianAssetTypeMappingView: 'CUSTODIAN_ASSET_TYPE_MAPPING_VIEW' as ViewAllAuthorityEnum,
    CustodianCreate: 'CUSTODIAN_CREATE' as ViewAllAuthorityEnum,
    CustodianDelete: 'CUSTODIAN_DELETE' as ViewAllAuthorityEnum,
    CustodianModify: 'CUSTODIAN_MODIFY' as ViewAllAuthorityEnum,
    CustodianView: 'CUSTODIAN_VIEW' as ViewAllAuthorityEnum,
    DashboardModify: 'DASHBOARD_MODIFY' as ViewAllAuthorityEnum,
    DashboardView: 'DASHBOARD_VIEW' as ViewAllAuthorityEnum,
    DbsDataModify: 'DBS_DATA_MODIFY' as ViewAllAuthorityEnum,
    DbsDataView: 'DBS_DATA_VIEW' as ViewAllAuthorityEnum,
    DeactivatedTransactionView: 'DEACTIVATED_TRANSACTION_VIEW' as ViewAllAuthorityEnum,
    DepartmentsCreate: 'DEPARTMENTS_CREATE' as ViewAllAuthorityEnum,
    DepartmentsDelete: 'DEPARTMENTS_DELETE' as ViewAllAuthorityEnum,
    DepartmentsModify: 'DEPARTMENTS_MODIFY' as ViewAllAuthorityEnum,
    DepartmentsView: 'DEPARTMENTS_VIEW' as ViewAllAuthorityEnum,
    DolfinTransactionsModify: 'DOLFIN_TRANSACTIONS_MODIFY' as ViewAllAuthorityEnum,
    DolfinTransactionsView: 'DOLFIN_TRANSACTIONS_VIEW' as ViewAllAuthorityEnum,
    EdrDataModify: 'EDR_DATA_MODIFY' as ViewAllAuthorityEnum,
    EdrDataView: 'EDR_DATA_VIEW' as ViewAllAuthorityEnum,
    EdrLoaderSettingsCreate: 'EDR_LOADER_SETTINGS_CREATE' as ViewAllAuthorityEnum,
    EdrLoaderSettingsDelete: 'EDR_LOADER_SETTINGS_DELETE' as ViewAllAuthorityEnum,
    EdrLoaderSettingsModify: 'EDR_LOADER_SETTINGS_MODIFY' as ViewAllAuthorityEnum,
    EdrLoaderSettingsView: 'EDR_LOADER_SETTINGS_VIEW' as ViewAllAuthorityEnum,
    EmailTemplateCreate: 'EMAIL_TEMPLATE_CREATE' as ViewAllAuthorityEnum,
    EmailTemplateDelete: 'EMAIL_TEMPLATE_DELETE' as ViewAllAuthorityEnum,
    EmailTemplateModify: 'EMAIL_TEMPLATE_MODIFY' as ViewAllAuthorityEnum,
    EmailTemplateView: 'EMAIL_TEMPLATE_VIEW' as ViewAllAuthorityEnum,
    ExecgwReportsView: 'EXECGW_REPORTS_VIEW' as ViewAllAuthorityEnum,
    FeesCreate: 'FEES_CREATE' as ViewAllAuthorityEnum,
    FeesModify: 'FEES_MODIFY' as ViewAllAuthorityEnum,
    FeesView: 'FEES_VIEW' as ViewAllAuthorityEnum,
    FeesViewAll: 'FEES_VIEW_ALL' as ViewAllAuthorityEnum,
    FieldsCreate: 'FIELDS_CREATE' as ViewAllAuthorityEnum,
    FieldsDelete: 'FIELDS_DELETE' as ViewAllAuthorityEnum,
    FieldsModify: 'FIELDS_MODIFY' as ViewAllAuthorityEnum,
    FieldsView: 'FIELDS_VIEW' as ViewAllAuthorityEnum,
    FileUserLogView: 'FILE_USER_LOG_VIEW' as ViewAllAuthorityEnum,
    GapMonitorView: 'GAP_MONITOR_VIEW' as ViewAllAuthorityEnum,
    GlobalAllocationCreate: 'GLOBAL_ALLOCATION_CREATE' as ViewAllAuthorityEnum,
    GlobalAllocationModify: 'GLOBAL_ALLOCATION_MODIFY' as ViewAllAuthorityEnum,
    GlobalAllocationView: 'GLOBAL_ALLOCATION_VIEW' as ViewAllAuthorityEnum,
    GlobalAllocationWidgetView: 'GLOBAL_ALLOCATION_WIDGET_VIEW' as ViewAllAuthorityEnum,
    GoogleAuthModify: 'GOOGLE_AUTH_MODIFY' as ViewAllAuthorityEnum,
    GoogleAuthView: 'GOOGLE_AUTH_VIEW' as ViewAllAuthorityEnum,
    GppDataModify: 'GPP_DATA_MODIFY' as ViewAllAuthorityEnum,
    GppDataView: 'GPP_DATA_VIEW' as ViewAllAuthorityEnum,
    GppLoaderSettingsCreate: 'GPP_LOADER_SETTINGS_CREATE' as ViewAllAuthorityEnum,
    GppLoaderSettingsDelete: 'GPP_LOADER_SETTINGS_DELETE' as ViewAllAuthorityEnum,
    GppLoaderSettingsModify: 'GPP_LOADER_SETTINGS_MODIFY' as ViewAllAuthorityEnum,
    GppLoaderSettingsView: 'GPP_LOADER_SETTINGS_VIEW' as ViewAllAuthorityEnum,
    HealthView: 'HEALTH_VIEW' as ViewAllAuthorityEnum,
    HistoryView: 'HISTORY_VIEW' as ViewAllAuthorityEnum,
    IbkrDataModify: 'IBKR_DATA_MODIFY' as ViewAllAuthorityEnum,
    IbkrDataView: 'IBKR_DATA_VIEW' as ViewAllAuthorityEnum,
    IbkrFuturesMappingCreate: 'IBKR_FUTURES_MAPPING_CREATE' as ViewAllAuthorityEnum,
    IbkrFuturesMappingDelete: 'IBKR_FUTURES_MAPPING_DELETE' as ViewAllAuthorityEnum,
    IbkrFuturesMappingModify: 'IBKR_FUTURES_MAPPING_MODIFY' as ViewAllAuthorityEnum,
    IbkrFuturesMappingView: 'IBKR_FUTURES_MAPPING_VIEW' as ViewAllAuthorityEnum,
    IbkrLoaderSettingsCreate: 'IBKR_LOADER_SETTINGS_CREATE' as ViewAllAuthorityEnum,
    IbkrLoaderSettingsDelete: 'IBKR_LOADER_SETTINGS_DELETE' as ViewAllAuthorityEnum,
    IbkrLoaderSettingsModify: 'IBKR_LOADER_SETTINGS_MODIFY' as ViewAllAuthorityEnum,
    IbkrLoaderSettingsView: 'IBKR_LOADER_SETTINGS_VIEW' as ViewAllAuthorityEnum,
    IncomePaymentTracker: 'INCOME_PAYMENT_TRACKER' as ViewAllAuthorityEnum,
    InstrumentsCreate: 'INSTRUMENTS_CREATE' as ViewAllAuthorityEnum,
    InstrumentsModify: 'INSTRUMENTS_MODIFY' as ViewAllAuthorityEnum,
    InstrumentsView: 'INSTRUMENTS_VIEW' as ViewAllAuthorityEnum,
    InstrumentToAgentView: 'INSTRUMENT_TO_AGENT_VIEW' as ViewAllAuthorityEnum,
    InvestecDataModify: 'INVESTEC_DATA_MODIFY' as ViewAllAuthorityEnum,
    InvestecDataView: 'INVESTEC_DATA_VIEW' as ViewAllAuthorityEnum,
    InvestecLoaderSettingsCreate: 'INVESTEC_LOADER_SETTINGS_CREATE' as ViewAllAuthorityEnum,
    InvestecLoaderSettingsDelete: 'INVESTEC_LOADER_SETTINGS_DELETE' as ViewAllAuthorityEnum,
    InvestecLoaderSettingsModify: 'INVESTEC_LOADER_SETTINGS_MODIFY' as ViewAllAuthorityEnum,
    InvestecLoaderSettingsView: 'INVESTEC_LOADER_SETTINGS_VIEW' as ViewAllAuthorityEnum,
    InvestmentView: 'INVESTMENT_VIEW' as ViewAllAuthorityEnum,
    IpAccess: 'IP_ACCESS' as ViewAllAuthorityEnum,
    JbDataModify: 'JB_DATA_MODIFY' as ViewAllAuthorityEnum,
    JbDataView: 'JB_DATA_VIEW' as ViewAllAuthorityEnum,
    JbLoaderSettingsCreate: 'JB_LOADER_SETTINGS_CREATE' as ViewAllAuthorityEnum,
    JbLoaderSettingsDelete: 'JB_LOADER_SETTINGS_DELETE' as ViewAllAuthorityEnum,
    JbLoaderSettingsModify: 'JB_LOADER_SETTINGS_MODIFY' as ViewAllAuthorityEnum,
    JbLoaderSettingsView: 'JB_LOADER_SETTINGS_VIEW' as ViewAllAuthorityEnum,
    JpmDataModify: 'JPM_DATA_MODIFY' as ViewAllAuthorityEnum,
    JpmDataView: 'JPM_DATA_VIEW' as ViewAllAuthorityEnum,
    JpmLoaderSettingsCreate: 'JPM_LOADER_SETTINGS_CREATE' as ViewAllAuthorityEnum,
    JpmLoaderSettingsDelete: 'JPM_LOADER_SETTINGS_DELETE' as ViewAllAuthorityEnum,
    JpmLoaderSettingsModify: 'JPM_LOADER_SETTINGS_MODIFY' as ViewAllAuthorityEnum,
    JpmLoaderSettingsView: 'JPM_LOADER_SETTINGS_VIEW' as ViewAllAuthorityEnum,
    LeadCreate: 'LEAD_CREATE' as ViewAllAuthorityEnum,
    LeadDelete: 'LEAD_DELETE' as ViewAllAuthorityEnum,
    LeadModify: 'LEAD_MODIFY' as ViewAllAuthorityEnum,
    LeadModifyAll: 'LEAD_MODIFY_ALL' as ViewAllAuthorityEnum,
    LeadView: 'LEAD_VIEW' as ViewAllAuthorityEnum,
    LeadViewAll: 'LEAD_VIEW_ALL' as ViewAllAuthorityEnum,
    LogotypeDelete: 'LOGOTYPE_DELETE' as ViewAllAuthorityEnum,
    LogotypeModify: 'LOGOTYPE_MODIFY' as ViewAllAuthorityEnum,
    LogotypeView: 'LOGOTYPE_VIEW' as ViewAllAuthorityEnum,
    ManagersCreate: 'MANAGERS_CREATE' as ViewAllAuthorityEnum,
    ManagersDelete: 'MANAGERS_DELETE' as ViewAllAuthorityEnum,
    ManagersForceModify: 'MANAGERS_FORCE_MODIFY' as ViewAllAuthorityEnum,
    ManagersModify: 'MANAGERS_MODIFY' as ViewAllAuthorityEnum,
    ManagersView: 'MANAGERS_VIEW' as ViewAllAuthorityEnum,
    MandatesCreate: 'MANDATES_CREATE' as ViewAllAuthorityEnum,
    MandatesDelete: 'MANDATES_DELETE' as ViewAllAuthorityEnum,
    MandatesForceModify: 'MANDATES_FORCE_MODIFY' as ViewAllAuthorityEnum,
    MandatesModify: 'MANDATES_MODIFY' as ViewAllAuthorityEnum,
    MandatesModifyAll: 'MANDATES_MODIFY_ALL' as ViewAllAuthorityEnum,
    MandatesView: 'MANDATES_VIEW' as ViewAllAuthorityEnum,
    MandatesViewAll: 'MANDATES_VIEW_ALL' as ViewAllAuthorityEnum,
    MandateRestrictionsCreate: 'MANDATE_RESTRICTIONS_CREATE' as ViewAllAuthorityEnum,
    MandateRestrictionsDelete: 'MANDATE_RESTRICTIONS_DELETE' as ViewAllAuthorityEnum,
    MandateRestrictionsModify: 'MANDATE_RESTRICTIONS_MODIFY' as ViewAllAuthorityEnum,
    MandateRestrictionsModifyAll: 'MANDATE_RESTRICTIONS_MODIFY_ALL' as ViewAllAuthorityEnum,
    MandateRestrictionsView: 'MANDATE_RESTRICTIONS_VIEW' as ViewAllAuthorityEnum,
    MandateRestrictionsViewAll: 'MANDATE_RESTRICTIONS_VIEW_ALL' as ViewAllAuthorityEnum,
    MandateRestrictionReportsCreate: 'MANDATE_RESTRICTION_REPORTS_CREATE' as ViewAllAuthorityEnum,
    MandateRestrictionReportsDelete: 'MANDATE_RESTRICTION_REPORTS_DELETE' as ViewAllAuthorityEnum,
    MandateRestrictionReportsModify: 'MANDATE_RESTRICTION_REPORTS_MODIFY' as ViewAllAuthorityEnum,
    MandateRestrictionReportsModifyAll:
      'MANDATE_RESTRICTION_REPORTS_MODIFY_ALL' as ViewAllAuthorityEnum,
    MandateRestrictionReportsView: 'MANDATE_RESTRICTION_REPORTS_VIEW' as ViewAllAuthorityEnum,
    MandateRestrictionReportsViewAll:
      'MANDATE_RESTRICTION_REPORTS_VIEW_ALL' as ViewAllAuthorityEnum,
    MandateRestrictionTemplatesCreate:
      'MANDATE_RESTRICTION_TEMPLATES_CREATE' as ViewAllAuthorityEnum,
    MandateRestrictionTemplatesDelete:
      'MANDATE_RESTRICTION_TEMPLATES_DELETE' as ViewAllAuthorityEnum,
    MandateRestrictionTemplatesModify:
      'MANDATE_RESTRICTION_TEMPLATES_MODIFY' as ViewAllAuthorityEnum,
    MandateRestrictionTemplatesModifyAll:
      'MANDATE_RESTRICTION_TEMPLATES_MODIFY_ALL' as ViewAllAuthorityEnum,
    MandateRestrictionTemplatesView: 'MANDATE_RESTRICTION_TEMPLATES_VIEW' as ViewAllAuthorityEnum,
    MandateRestrictionTemplatesViewAll:
      'MANDATE_RESTRICTION_TEMPLATES_VIEW_ALL' as ViewAllAuthorityEnum,
    MandateRiskProfileCreate: 'MANDATE_RISK_PROFILE_CREATE' as ViewAllAuthorityEnum,
    MandateRiskProfileDelete: 'MANDATE_RISK_PROFILE_DELETE' as ViewAllAuthorityEnum,
    MandateRiskProfileForceModify: 'MANDATE_RISK_PROFILE_FORCE_MODIFY' as ViewAllAuthorityEnum,
    MandateRiskProfileModify: 'MANDATE_RISK_PROFILE_MODIFY' as ViewAllAuthorityEnum,
    MandateRiskProfileView: 'MANDATE_RISK_PROFILE_VIEW' as ViewAllAuthorityEnum,
    MandateRiskProfileViewAll: 'MANDATE_RISK_PROFILE_VIEW_ALL' as ViewAllAuthorityEnum,
    ManualImportPositionsCreate: 'MANUAL_IMPORT_POSITIONS_CREATE' as ViewAllAuthorityEnum,
    ManualImportPositionsModify: 'MANUAL_IMPORT_POSITIONS_MODIFY' as ViewAllAuthorityEnum,
    ManualImportPositionsView: 'MANUAL_IMPORT_POSITIONS_VIEW' as ViewAllAuthorityEnum,
    ManualImportTransactionsCreate: 'MANUAL_IMPORT_TRANSACTIONS_CREATE' as ViewAllAuthorityEnum,
    ManualImportTransactionsModify: 'MANUAL_IMPORT_TRANSACTIONS_MODIFY' as ViewAllAuthorityEnum,
    ManualImportTransactionsView: 'MANUAL_IMPORT_TRANSACTIONS_VIEW' as ViewAllAuthorityEnum,
    MarketplaceModify: 'MARKETPLACE_MODIFY' as ViewAllAuthorityEnum,
    MarketplaceView: 'MARKETPLACE_VIEW' as ViewAllAuthorityEnum,
    MarketSecuritiesModify: 'MARKET_SECURITIES_MODIFY' as ViewAllAuthorityEnum,
    MarketSecuritiesView: 'MARKET_SECURITIES_VIEW' as ViewAllAuthorityEnum,
    MifirModify: 'MIFIR_MODIFY' as ViewAllAuthorityEnum,
    MifirReport: 'MIFIR_REPORT' as ViewAllAuthorityEnum,
    MifirView: 'MIFIR_VIEW' as ViewAllAuthorityEnum,
    ModelPortfoliosCreate: 'MODEL_PORTFOLIOS_CREATE' as ViewAllAuthorityEnum,
    ModelPortfoliosDelete: 'MODEL_PORTFOLIOS_DELETE' as ViewAllAuthorityEnum,
    ModelPortfoliosModify: 'MODEL_PORTFOLIOS_MODIFY' as ViewAllAuthorityEnum,
    ModelPortfoliosView: 'MODEL_PORTFOLIOS_VIEW' as ViewAllAuthorityEnum,
    MonthlyReconciliationReport: 'MONTHLY_RECONCILIATION_REPORT' as ViewAllAuthorityEnum,
    NotificationTemplatesCreate: 'NOTIFICATION_TEMPLATES_CREATE' as ViewAllAuthorityEnum,
    NotificationTemplatesDelete: 'NOTIFICATION_TEMPLATES_DELETE' as ViewAllAuthorityEnum,
    NotificationTemplatesModify: 'NOTIFICATION_TEMPLATES_MODIFY' as ViewAllAuthorityEnum,
    NotificationTemplatesView: 'NOTIFICATION_TEMPLATES_VIEW' as ViewAllAuthorityEnum,
    NotificationUserLogView: 'NOTIFICATION_USER_LOG_VIEW' as ViewAllAuthorityEnum,
    Omnibus: 'OMNIBUS' as ViewAllAuthorityEnum,
    OnlinePricingView: 'ONLINE_PRICING_VIEW' as ViewAllAuthorityEnum,
    OpportunityCreate: 'OPPORTUNITY_CREATE' as ViewAllAuthorityEnum,
    OpportunityDelete: 'OPPORTUNITY_DELETE' as ViewAllAuthorityEnum,
    OpportunityForceModify: 'OPPORTUNITY_FORCE_MODIFY' as ViewAllAuthorityEnum,
    OpportunityModify: 'OPPORTUNITY_MODIFY' as ViewAllAuthorityEnum,
    OpportunityModifyAll: 'OPPORTUNITY_MODIFY_ALL' as ViewAllAuthorityEnum,
    OpportunityView: 'OPPORTUNITY_VIEW' as ViewAllAuthorityEnum,
    OpportunityViewAll: 'OPPORTUNITY_VIEW_ALL' as ViewAllAuthorityEnum,
    OrdersAssign: 'ORDERS_ASSIGN' as ViewAllAuthorityEnum,
    OrdersCommonCreate: 'ORDERS_COMMON_CREATE' as ViewAllAuthorityEnum,
    OrdersCommonDelete: 'ORDERS_COMMON_DELETE' as ViewAllAuthorityEnum,
    OrdersCommonModify: 'ORDERS_COMMON_MODIFY' as ViewAllAuthorityEnum,
    OrdersCommonView: 'ORDERS_COMMON_VIEW' as ViewAllAuthorityEnum,
    OrdersCorpActionCreate: 'ORDERS_CORP_ACTION_CREATE' as ViewAllAuthorityEnum,
    OrdersCorpActionDelete: 'ORDERS_CORP_ACTION_DELETE' as ViewAllAuthorityEnum,
    OrdersCorpActionModify: 'ORDERS_CORP_ACTION_MODIFY' as ViewAllAuthorityEnum,
    OrdersCorpActionView: 'ORDERS_CORP_ACTION_VIEW' as ViewAllAuthorityEnum,
    OrdersCreate: 'ORDERS_CREATE' as ViewAllAuthorityEnum,
    OrdersDelete: 'ORDERS_DELETE' as ViewAllAuthorityEnum,
    OrdersDmaCreate: 'ORDERS_DMA_CREATE' as ViewAllAuthorityEnum,
    OrdersForceModify: 'ORDERS_FORCE_MODIFY' as ViewAllAuthorityEnum,
    OrdersModify: 'ORDERS_MODIFY' as ViewAllAuthorityEnum,
    OrdersModifyAll: 'ORDERS_MODIFY_ALL' as ViewAllAuthorityEnum,
    OrdersNonTradeCreate: 'ORDERS_NON_TRADE_CREATE' as ViewAllAuthorityEnum,
    OrdersNonTradeDelete: 'ORDERS_NON_TRADE_DELETE' as ViewAllAuthorityEnum,
    OrdersNonTradeModify: 'ORDERS_NON_TRADE_MODIFY' as ViewAllAuthorityEnum,
    OrdersNonTradeView: 'ORDERS_NON_TRADE_VIEW' as ViewAllAuthorityEnum,
    OrdersTechInfoModify: 'ORDERS_TECH_INFO_MODIFY' as ViewAllAuthorityEnum,
    OrdersTechInfoView: 'ORDERS_TECH_INFO_VIEW' as ViewAllAuthorityEnum,
    OrdersTradeCreate: 'ORDERS_TRADE_CREATE' as ViewAllAuthorityEnum,
    OrdersTradeDelete: 'ORDERS_TRADE_DELETE' as ViewAllAuthorityEnum,
    OrdersTradeModify: 'ORDERS_TRADE_MODIFY' as ViewAllAuthorityEnum,
    OrdersTradeView: 'ORDERS_TRADE_VIEW' as ViewAllAuthorityEnum,
    OrdersView: 'ORDERS_VIEW' as ViewAllAuthorityEnum,
    OrdersViewAll: 'ORDERS_VIEW_ALL' as ViewAllAuthorityEnum,
    PersonalAm: 'PERSONAL_AM' as ViewAllAuthorityEnum,
    PnlReport: 'PNL_REPORT' as ViewAllAuthorityEnum,
    PortfoliosCreate: 'PORTFOLIOS_CREATE' as ViewAllAuthorityEnum,
    PortfoliosDelete: 'PORTFOLIOS_DELETE' as ViewAllAuthorityEnum,
    PortfoliosForceModify: 'PORTFOLIOS_FORCE_MODIFY' as ViewAllAuthorityEnum,
    PortfoliosModify: 'PORTFOLIOS_MODIFY' as ViewAllAuthorityEnum,
    PortfoliosModifyAll: 'PORTFOLIOS_MODIFY_ALL' as ViewAllAuthorityEnum,
    PortfoliosView: 'PORTFOLIOS_VIEW' as ViewAllAuthorityEnum,
    PortfoliosViewAll: 'PORTFOLIOS_VIEW_ALL' as ViewAllAuthorityEnum,
    PortfolioConfigurationView: 'PORTFOLIO_CONFIGURATION_VIEW' as ViewAllAuthorityEnum,
    PortfolioCostsView: 'PORTFOLIO_COSTS_VIEW' as ViewAllAuthorityEnum,
    PortfolioFeesView: 'PORTFOLIO_FEES_VIEW' as ViewAllAuthorityEnum,
    PortfolioFlowsView: 'PORTFOLIO_FLOWS_VIEW' as ViewAllAuthorityEnum,
    PortfolioOrdersAssign: 'PORTFOLIO_ORDERS_ASSIGN' as ViewAllAuthorityEnum,
    PortfolioOrdersCreate: 'PORTFOLIO_ORDERS_CREATE' as ViewAllAuthorityEnum,
    PortfolioOrdersDelete: 'PORTFOLIO_ORDERS_DELETE' as ViewAllAuthorityEnum,
    PortfolioOrdersModify: 'PORTFOLIO_ORDERS_MODIFY' as ViewAllAuthorityEnum,
    PortfolioOrdersView: 'PORTFOLIO_ORDERS_VIEW' as ViewAllAuthorityEnum,
    PortfolioPnlView: 'PORTFOLIO_PNL_VIEW' as ViewAllAuthorityEnum,
    PortfolioPositionsView: 'PORTFOLIO_POSITIONS_VIEW' as ViewAllAuthorityEnum,
    PortfolioProfileModify: 'PORTFOLIO_PROFILE_MODIFY' as ViewAllAuthorityEnum,
    PortfolioProfileView: 'PORTFOLIO_PROFILE_VIEW' as ViewAllAuthorityEnum,
    PortfolioRestrictionsView: 'PORTFOLIO_RESTRICTIONS_VIEW' as ViewAllAuthorityEnum,
    PortfolioTransactionsCreate: 'PORTFOLIO_TRANSACTIONS_CREATE' as ViewAllAuthorityEnum,
    PortfolioTransactionsDelete: 'PORTFOLIO_TRANSACTIONS_DELETE' as ViewAllAuthorityEnum,
    PortfolioTransactionsModify: 'PORTFOLIO_TRANSACTIONS_MODIFY' as ViewAllAuthorityEnum,
    PortfolioTransactionsStatus: 'PORTFOLIO_TRANSACTIONS_STATUS' as ViewAllAuthorityEnum,
    PortfolioTransactionsView: 'PORTFOLIO_TRANSACTIONS_VIEW' as ViewAllAuthorityEnum,
    PortfolioValuationView: 'PORTFOLIO_VALUATION_VIEW' as ViewAllAuthorityEnum,
    PositionsView: 'POSITIONS_VIEW' as ViewAllAuthorityEnum,
    PricesLoadingMonitorView: 'PRICES_LOADING_MONITOR_VIEW' as ViewAllAuthorityEnum,
    PrivateInstrumentCreate: 'PRIVATE_INSTRUMENT_CREATE' as ViewAllAuthorityEnum,
    PrivateInstrumentDelete: 'PRIVATE_INSTRUMENT_DELETE' as ViewAllAuthorityEnum,
    PrivateInstrumentModify: 'PRIVATE_INSTRUMENT_MODIFY' as ViewAllAuthorityEnum,
    PrivateInstrumentModifyAll: 'PRIVATE_INSTRUMENT_MODIFY_ALL' as ViewAllAuthorityEnum,
    PrivateInstrumentView: 'PRIVATE_INSTRUMENT_VIEW' as ViewAllAuthorityEnum,
    PrivateInstrumentViewAll: 'PRIVATE_INSTRUMENT_VIEW_ALL' as ViewAllAuthorityEnum,
    ProcessCreate: 'PROCESS_CREATE' as ViewAllAuthorityEnum,
    ProcessDelete: 'PROCESS_DELETE' as ViewAllAuthorityEnum,
    ProcessModify: 'PROCESS_MODIFY' as ViewAllAuthorityEnum,
    ProcessView: 'PROCESS_VIEW' as ViewAllAuthorityEnum,
    ProductGovernanceModify: 'PRODUCT_GOVERNANCE_MODIFY' as ViewAllAuthorityEnum,
    ProductGovernanceView: 'PRODUCT_GOVERNANCE_VIEW' as ViewAllAuthorityEnum,
    ProfilesCreate: 'PROFILES_CREATE' as ViewAllAuthorityEnum,
    ProfilesDelete: 'PROFILES_DELETE' as ViewAllAuthorityEnum,
    ProfilesModify: 'PROFILES_MODIFY' as ViewAllAuthorityEnum,
    ProfilesView: 'PROFILES_VIEW' as ViewAllAuthorityEnum,
    QuarterlyStatisticsReport: 'QUARTERLY_STATISTICS_REPORT' as ViewAllAuthorityEnum,
    RbsFReport: 'RBS_F_REPORT' as ViewAllAuthorityEnum,
    RecommendationCreate: 'RECOMMENDATION_CREATE' as ViewAllAuthorityEnum,
    RecommendationForceModify: 'RECOMMENDATION_FORCE_MODIFY' as ViewAllAuthorityEnum,
    RecommendationModify: 'RECOMMENDATION_MODIFY' as ViewAllAuthorityEnum,
    RecommendationView: 'RECOMMENDATION_VIEW' as ViewAllAuthorityEnum,
    RecommendationViewAll: 'RECOMMENDATION_VIEW_ALL' as ViewAllAuthorityEnum,
    ReconciliationReport: 'RECONCILIATION_REPORT' as ViewAllAuthorityEnum,
    RegulatorCreate: 'REGULATOR_CREATE' as ViewAllAuthorityEnum,
    RegulatorDelete: 'REGULATOR_DELETE' as ViewAllAuthorityEnum,
    RegulatorModify: 'REGULATOR_MODIFY' as ViewAllAuthorityEnum,
    RegulatorView: 'REGULATOR_VIEW' as ViewAllAuthorityEnum,
    RelunaOrdersReportsView: 'RELUNA_ORDERS_REPORTS_VIEW' as ViewAllAuthorityEnum,
    ReportsCreate: 'REPORTS_CREATE' as ViewAllAuthorityEnum,
    ReportsDelete: 'REPORTS_DELETE' as ViewAllAuthorityEnum,
    ReportsModify: 'REPORTS_MODIFY' as ViewAllAuthorityEnum,
    ReportsView: 'REPORTS_VIEW' as ViewAllAuthorityEnum,
    RestrictionsCreate: 'RESTRICTIONS_CREATE' as ViewAllAuthorityEnum,
    RestrictionsDelete: 'RESTRICTIONS_DELETE' as ViewAllAuthorityEnum,
    RestrictionsModify: 'RESTRICTIONS_MODIFY' as ViewAllAuthorityEnum,
    RestrictionsModifyAll: 'RESTRICTIONS_MODIFY_ALL' as ViewAllAuthorityEnum,
    RestrictionsView: 'RESTRICTIONS_VIEW' as ViewAllAuthorityEnum,
    RestrictionsViewAll: 'RESTRICTIONS_VIEW_ALL' as ViewAllAuthorityEnum,
    RestrictionAlertView: 'RESTRICTION_ALERT_VIEW' as ViewAllAuthorityEnum,
    RestrictionReportsCreate: 'RESTRICTION_REPORTS_CREATE' as ViewAllAuthorityEnum,
    RestrictionReportsDelete: 'RESTRICTION_REPORTS_DELETE' as ViewAllAuthorityEnum,
    RestrictionReportsModify: 'RESTRICTION_REPORTS_MODIFY' as ViewAllAuthorityEnum,
    RestrictionReportsModifyAll: 'RESTRICTION_REPORTS_MODIFY_ALL' as ViewAllAuthorityEnum,
    RestrictionReportsView: 'RESTRICTION_REPORTS_VIEW' as ViewAllAuthorityEnum,
    RestrictionReportsViewAll: 'RESTRICTION_REPORTS_VIEW_ALL' as ViewAllAuthorityEnum,
    RestrictionTemplatesCreate: 'RESTRICTION_TEMPLATES_CREATE' as ViewAllAuthorityEnum,
    RestrictionTemplatesDelete: 'RESTRICTION_TEMPLATES_DELETE' as ViewAllAuthorityEnum,
    RestrictionTemplatesModify: 'RESTRICTION_TEMPLATES_MODIFY' as ViewAllAuthorityEnum,
    RestrictionTemplatesModifyAll: 'RESTRICTION_TEMPLATES_MODIFY_ALL' as ViewAllAuthorityEnum,
    RestrictionTemplatesView: 'RESTRICTION_TEMPLATES_VIEW' as ViewAllAuthorityEnum,
    RestrictionTemplatesViewAll: 'RESTRICTION_TEMPLATES_VIEW_ALL' as ViewAllAuthorityEnum,
    RiskReportCreate: 'RISK_REPORT_CREATE' as ViewAllAuthorityEnum,
    RiskReportView: 'RISK_REPORT_VIEW' as ViewAllAuthorityEnum,
    RolesCreate: 'ROLES_CREATE' as ViewAllAuthorityEnum,
    RolesDelete: 'ROLES_DELETE' as ViewAllAuthorityEnum,
    RolesModify: 'ROLES_MODIFY' as ViewAllAuthorityEnum,
    RolesView: 'ROLES_VIEW' as ViewAllAuthorityEnum,
    Rts28Report: 'RTS28_REPORT' as ViewAllAuthorityEnum,
    SaltedgeTransactionsModify: 'SALTEDGE_TRANSACTIONS_MODIFY' as ViewAllAuthorityEnum,
    SaltedgeTransactionsView: 'SALTEDGE_TRANSACTIONS_VIEW' as ViewAllAuthorityEnum,
    SendReports: 'SEND_REPORTS' as ViewAllAuthorityEnum,
    SettingsModify: 'SETTINGS_MODIFY' as ViewAllAuthorityEnum,
    SettingsView: 'SETTINGS_VIEW' as ViewAllAuthorityEnum,
    ShopView: 'SHOP_VIEW' as ViewAllAuthorityEnum,
    SiteInfoCreate: 'SITE_INFO_CREATE' as ViewAllAuthorityEnum,
    SiteInfoDelete: 'SITE_INFO_DELETE' as ViewAllAuthorityEnum,
    SiteInfoModify: 'SITE_INFO_MODIFY' as ViewAllAuthorityEnum,
    SiteInfoView: 'SITE_INFO_VIEW' as ViewAllAuthorityEnum,
    SovaDataModify: 'SOVA_DATA_MODIFY' as ViewAllAuthorityEnum,
    SovaDataView: 'SOVA_DATA_VIEW' as ViewAllAuthorityEnum,
    SovaOrdersModify: 'SOVA_ORDERS_MODIFY' as ViewAllAuthorityEnum,
    SovaOrdersView: 'SOVA_ORDERS_VIEW' as ViewAllAuthorityEnum,
    StatementOfEligibleFundsReport: 'STATEMENT_OF_ELIGIBLE_FUNDS_REPORT' as ViewAllAuthorityEnum,
    StatisticsView: 'STATISTICS_VIEW' as ViewAllAuthorityEnum,
    StrategiesCreate: 'STRATEGIES_CREATE' as ViewAllAuthorityEnum,
    StrategiesDelete: 'STRATEGIES_DELETE' as ViewAllAuthorityEnum,
    StrategiesForceModify: 'STRATEGIES_FORCE_MODIFY' as ViewAllAuthorityEnum,
    StrategiesModify: 'STRATEGIES_MODIFY' as ViewAllAuthorityEnum,
    StrategiesModifyAll: 'STRATEGIES_MODIFY_ALL' as ViewAllAuthorityEnum,
    StrategiesView: 'STRATEGIES_VIEW' as ViewAllAuthorityEnum,
    StrategiesViewAll: 'STRATEGIES_VIEW_ALL' as ViewAllAuthorityEnum,
    StructuredProductsCreate: 'STRUCTURED_PRODUCTS_CREATE' as ViewAllAuthorityEnum,
    StructuredProductsDelete: 'STRUCTURED_PRODUCTS_DELETE' as ViewAllAuthorityEnum,
    StructuredProductsModify: 'STRUCTURED_PRODUCTS_MODIFY' as ViewAllAuthorityEnum,
    StructuredProductsModifyAll: 'STRUCTURED_PRODUCTS_MODIFY_ALL' as ViewAllAuthorityEnum,
    StructuredProductsView: 'STRUCTURED_PRODUCTS_VIEW' as ViewAllAuthorityEnum,
    StructuredProductsViewAll: 'STRUCTURED_PRODUCTS_VIEW_ALL' as ViewAllAuthorityEnum,
    SubportfoliosView: 'SUBPORTFOLIOS_VIEW' as ViewAllAuthorityEnum,
    SubscriptionModify: 'SUBSCRIPTION_MODIFY' as ViewAllAuthorityEnum,
    SubscriptionView: 'SUBSCRIPTION_VIEW' as ViewAllAuthorityEnum,
    TableConstructorModify: 'TABLE_CONSTRUCTOR_MODIFY' as ViewAllAuthorityEnum,
    TableConstructorView: 'TABLE_CONSTRUCTOR_VIEW' as ViewAllAuthorityEnum,
    TaviraDataModify: 'TAVIRA_DATA_MODIFY' as ViewAllAuthorityEnum,
    TaviraDataView: 'TAVIRA_DATA_VIEW' as ViewAllAuthorityEnum,
    TransactionsCreate: 'TRANSACTIONS_CREATE' as ViewAllAuthorityEnum,
    TransactionsDelete: 'TRANSACTIONS_DELETE' as ViewAllAuthorityEnum,
    TransactionsForceModify: 'TRANSACTIONS_FORCE_MODIFY' as ViewAllAuthorityEnum,
    TransactionsMatchingModify: 'TRANSACTIONS_MATCHING_MODIFY' as ViewAllAuthorityEnum,
    TransactionsMatchingView: 'TRANSACTIONS_MATCHING_VIEW' as ViewAllAuthorityEnum,
    TransactionsModify: 'TRANSACTIONS_MODIFY' as ViewAllAuthorityEnum,
    TransactionsModifyAll: 'TRANSACTIONS_MODIFY_ALL' as ViewAllAuthorityEnum,
    TransactionsStatus: 'TRANSACTIONS_STATUS' as ViewAllAuthorityEnum,
    TransactionsView: 'TRANSACTIONS_VIEW' as ViewAllAuthorityEnum,
    TransactionsViewAll: 'TRANSACTIONS_VIEW_ALL' as ViewAllAuthorityEnum,
    TransactionLoaderCreate: 'TRANSACTION_LOADER_CREATE' as ViewAllAuthorityEnum,
    TransactionLoaderModify: 'TRANSACTION_LOADER_MODIFY' as ViewAllAuthorityEnum,
    TransactionLoaderView: 'TRANSACTION_LOADER_VIEW' as ViewAllAuthorityEnum,
    TransactionWithoutBankView: 'TRANSACTION_WITHOUT_BANK_VIEW' as ViewAllAuthorityEnum,
    TrimpDataModify: 'TRIMP_DATA_MODIFY' as ViewAllAuthorityEnum,
    TrimpDataView: 'TRIMP_DATA_VIEW' as ViewAllAuthorityEnum,
    Ttca: 'TTCA' as ViewAllAuthorityEnum,
    UbsDataModify: 'UBS_DATA_MODIFY' as ViewAllAuthorityEnum,
    UbsDataView: 'UBS_DATA_VIEW' as ViewAllAuthorityEnum,
    ValueDateRulesModify: 'VALUE_DATE_RULES_MODIFY' as ViewAllAuthorityEnum,
    ValueDateRulesView: 'VALUE_DATE_RULES_VIEW' as ViewAllAuthorityEnum,
    VpDataModify: 'VP_DATA_MODIFY' as ViewAllAuthorityEnum,
    VpDataView: 'VP_DATA_VIEW' as ViewAllAuthorityEnum,
    VpLoaderSettingsCreate: 'VP_LOADER_SETTINGS_CREATE' as ViewAllAuthorityEnum,
    VpLoaderSettingsDelete: 'VP_LOADER_SETTINGS_DELETE' as ViewAllAuthorityEnum,
    VpLoaderSettingsModify: 'VP_LOADER_SETTINGS_MODIFY' as ViewAllAuthorityEnum,
    VpLoaderSettingsView: 'VP_LOADER_SETTINGS_VIEW' as ViewAllAuthorityEnum,
    VtbDataModify: 'VTB_DATA_MODIFY' as ViewAllAuthorityEnum,
    VtbDataView: 'VTB_DATA_VIEW' as ViewAllAuthorityEnum,
    ZohoSettingsCreate: 'ZOHO_SETTINGS_CREATE' as ViewAllAuthorityEnum,
    ZohoSettingsDelete: 'ZOHO_SETTINGS_DELETE' as ViewAllAuthorityEnum,
    ZohoSettingsModify: 'ZOHO_SETTINGS_MODIFY' as ViewAllAuthorityEnum,
    ZohoSettingsView: 'ZOHO_SETTINGS_VIEW' as ViewAllAuthorityEnum,
  };
}
