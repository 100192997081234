import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, combineLatest, map, of, tap } from 'rxjs';
import { AuthoritiesService } from '@shared/api/authorities.service';
import { ClientsService } from '@shared/api/clients.service';
import { ManagersService } from '@shared/api/managers.service';
import { LocalStorageConstants } from '@shared/constants/local-storage-constants';
import { ClientDTO, ManagerDTO, RoleDTO, UsageDTO } from '@shared/dto/accounts/models';
import { ModuleAccessProductDTO } from '@shared/dto/gateway-secured/models';
import { AuthoritiesCondition, Authority } from '@shared/types/authority';

@Injectable({
  providedIn: 'root',
})
export class RoleState {
  public readonly GlobalRoleCode = 'GLOBAL';
  public readonly ManagerRoleCode = 'MANAGER';
  public readonly ClientRoleCode = 'CLIENT';

  public manager$ = new BehaviorSubject<ManagerDTO>(null);
  public client$ = new BehaviorSubject<ClientDTO>(null);
  public authorities$ = new BehaviorSubject<Record<string, string>>({});
  public disabledAuthorities$ = new BehaviorSubject<ModuleAccessProductDTO[]>([]);
  public role$ = new BehaviorSubject<RoleDTO>(null);
  public userId$ = new BehaviorSubject<string>(null);
  public userEmail$ = new BehaviorSubject<string>(null);
  public userName$ = new BehaviorSubject<string>(null);
  public userLogin$ = new BehaviorSubject<string>(null);
  public managersUsage$ = new BehaviorSubject<UsageDTO>(null);
  public clientsUsage$ = new BehaviorSubject<UsageDTO>(null);

  constructor(
    private managersService: ManagersService,
    private clientsService: ClientsService,
    private authoritiesService: AuthoritiesService,
  ) {
    this.init();
  }

  private init(): void {
    this.authorities$.next(this.getAuthorities());
    this.role$.next(JSON.parse(localStorage.getItem(LocalStorageConstants.Role)));
    this.userId$.next(localStorage.getItem(LocalStorageConstants.UserId));
    this.userEmail$.next(localStorage.getItem(LocalStorageConstants.Email));
    this.userName$.next(localStorage.getItem(LocalStorageConstants.Name));
    this.userLogin$.next(localStorage.getItem(LocalStorageConstants.UserName));
  }

  public hasAuthorities(
    authorities: Authority[],
    condition: AuthoritiesCondition = 'all',
  ): boolean {
    if (!this.authorities$.value) {
      return false;
    }

    if (!authorities?.length) {
      return true;
    }

    switch (condition) {
      case 'all': {
        for (const authority of authorities) {
          if (!this.authorities$.value[authority]) {
            // eslint-disable-next-line no-console
            console.log('RoleState: has not an authority', authority);
            return false;
          }
        }

        return true;
      }

      case 'one_of': {
        for (const authority of authorities) {
          if (this.authorities$.value[authority]) {
            return true;
          }
        }

        // eslint-disable-next-line no-console
        console.log('RoleState: has not one of authorities', authorities);
        return false;
      }

      default:
        return false;
    }
  }

  public needSubscription(): boolean {
    return (
      //this.settingsState.siteName === SiteName.Reluna &&
      (this.userIsGlobal || this.userIsManager) && this.hasAuthorities(['SUBSCRIPTION_VIEW'])
    );
  }

  public needSubscriptionForAuthorities(
    authorities: Authority[],
    condition: AuthoritiesCondition = 'all',
  ): boolean {
    if (!authorities?.length) {
      return false;
    }

    return (
      !this.hasAuthorities(authorities, condition) &&
      this.needSubscription() &&
      this.hasDisabledAuthorities(authorities, condition)
    );
  }

  public hasDisabledAuthorities(
    authorities: Authority[],
    condition: AuthoritiesCondition = 'all',
  ): boolean {
    if (!authorities?.length) {
      return false;
    }

    if (!this.disabledAuthorities$.value.length) {
      return false;
    }

    const _find = (authority: Authority): boolean => {
      return !!this.disabledAuthorities$.value.find((item) => item.authorities.includes(authority));
    };

    let findDisabledAuthorities = false;

    switch (condition) {
      case 'all':
        findDisabledAuthorities = authorities.every((authority) => _find(authority));
        break;

      case 'one_of':
        findDisabledAuthorities = authorities.some((authority) => _find(authority));
        break;

      default:
        break;
    }

    return findDisabledAuthorities;
  }

  public get userIsClient(): boolean {
    const role = this.role$.value;
    return role?.code && role.code === this.ClientRoleCode;
  }

  public get userIsManager(): boolean {
    const role = this.role$.value;
    return role?.code && role.code === this.ManagerRoleCode;
  }

  public get userIsGlobal(): boolean {
    const role = this.role$.value;
    return role?.code && role.code === this.GlobalRoleCode;
  }

  public loadCurrentUser(): Observable<boolean> {
    return this.userIsClient ? this.loadClient() : this.loadManager();
  }

  public loadDisabledAuthoritiesIfRequired(): Observable<boolean> {
    if (this.needSubscription()) {
      if (!this.disabledAuthorities$.value.length) {
        return this.authoritiesService.getDisabledAuthorities().pipe(
          tap((data) => this.disabledAuthorities$.next(data)),
          map(() => true),
          catchError(() => of(false)),
        );
      } else {
        return of(true);
      }
    } else {
      return of(true);
    }
  }

  public loadAllUsagesIfRequired(): Observable<void> {
    if (this.needSubscription()) {
      return combineLatest([this.loadManagersUsage(), this.loadClientsUsage()]).pipe(
        map(() => null),
      );
    } else {
      return of(null);
    }
  }

  public loadManagersUsage(): Observable<void> {
    return this.managersService.getUsage().pipe(
      tap((data) => this.managersUsage$.next(data)),
      map(() => null),
    );
  }

  public loadClientsUsage(): Observable<void> {
    return this.clientsService.getUsage().pipe(
      tap((data) => this.clientsUsage$.next(data)),
      map(() => null),
    );
  }

  private getAuthorities(): Record<string, string> {
    const authorities = (
      (JSON.parse(localStorage.getItem(LocalStorageConstants.Authorities)) as string[]) || []
    ).reduce(
      (res, item) => {
        res[item] = item;
        return res;
      },
      {} as Record<string, string>,
    );

    return authorities;
  }

  private loadManager(): Observable<boolean> {
    const userId = localStorage.getItem(LocalStorageConstants.UserId);

    if (userId) {
      return this.managersService.getOne(userId).pipe(
        tap((manager) => this.manager$.next(manager)),
        map((data) => !!data),
      );
    } else {
      return of(false);
    }
  }

  private loadClient(): Observable<boolean> {
    const userId = localStorage.getItem(LocalStorageConstants.UserId);

    if (userId) {
      return this.clientsService.getOne(userId).pipe(
        tap((client) => this.client$.next(client)),
        map((data) => !!data),
      );
    } else {
      return of(false);
    }
  }
}
